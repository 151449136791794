import SearchableDropdown from '../SearchableDropdown';
import { countries } from '../../constants/global';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';

const SearchableDropdownDemo = () => {
  const [state, setState] = useState([]);
  const [state2, setState2] = useState('');
  return (
    <div>
      <Form.Check
        className="me-2  opacity-1 checkbox-red"
        type="checkbox"
        onChange={(e) => {
          setState2(e.target.checked);
        }}
        value={true}
        onClick={() => {}}
        checked={state2}
      />
      <SearchableDropdown
        multiple
        onSelect={(data) => setState(data)}
        selected={state}
        options={countries.map((el) => ({ label: el.name, value: el.code }))}
      />
      <br />
      <SearchableDropdown
        writeSelected
        selectAll
        parClass={'w-100'}
        multiple
        excluded={[]}
        onExclude={(data) => console.log('exCountry', data)}
        onSelect={(data) => setState(data)}
        selected={state}
        options={countries.map((el) => ({ label: el.name, value: el.code }))}
      />
    </div>
  );
};

export default SearchableDropdownDemo;
