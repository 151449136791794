import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { hmac256 } from '../../utils/common';
import { snakeCaseToCamelObj, snakeToCamelRec } from '../../utils/converters';
import { current } from '@reduxjs/toolkit';
const baseUrl = process.env.REACT_APP_STUDIO_API;
export const studioApi = createApi({
  reducerPath: 'studioApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  tagTypes: [
    'games',
    'draftGames',
    'gameMetrics',
    'gameList',
    'marketingPdfs',
    'studioList',
    'chatHistory',
    'gameVideos',
    'gaStudioList',
  ],
  keepUnusedDataFor: 600, // keep for 10min
  endpoints: (builder) => ({
    getGames: builder.query({
      query: () => ({
        url: '/games',
        headers: { Authorization: hmac256(baseUrl + '/games') },
      }),
      providesTags: ['games'],
      transformResponse: (response) => {
        const games = snakeToCamelRec(response);
        let parsedArray = [];
        let bundlesIds = {};
        games.forEach((value) => {
          let cur = bundlesIds[value.bundleId];
          if (cur !== undefined) cur[value.os] ? cur[value.os].push(value) : (cur[value.os] = [value]);
          else
            bundlesIds[value.bundleId] = {
              [value.os]: [{ ...value }],
            };
        });
        for (const key in bundlesIds) {
          let games = bundlesIds[key];
          let game = {};
          if (games.ios) {
            game = { ...games.ios[0], multipleOs: false };
          }
          if (games.android) {
            if (game.os) {
              game.multipleOs = true;
              game.ios = { ...game };
            }

            // use android as default
            games.android.forEach((el) => {
              if (el?.store === 'google')
                game = {
                  ...game,
                  ...el,
                  avgCpi: ((el.avgCpi || 0) + (game?.avgCpi || 0)) / 2,
                  avgRD1: ((el.avgRD1 || 0) + (game?.avgRD1 || 0)) / 2,
                  avgRD7: ((el.avgRD7 || 0) + (game?.avgRD7 || 0)) / 2,
                };
              else if (el?.store === 'amazon') parsedArray.push({ ...el, multipleOs: false });
            });
          }
          if (game.bundleId) parsedArray.push(game);
        }
        return parsedArray;
      },
    }),
    getGameList: builder.query({
      query: () => ({
        url: '/games/list',
        headers: { Authorization: hmac256(baseUrl + '/games/list') },
      }),
      providesTags: ['gameList'],
      transformResponse: (response) => {
        const games = snakeToCamelRec(response);
        let parsedArray = [];
        let bundlesIds = {};

        games.forEach((value) => {
          let cur = bundlesIds[value.bundleId];
          if (cur) cur[value.os] ? cur[value.os].push(value) : (cur[value.os] = [value]);
          else
            bundlesIds[value.bundleId] = {
              [value.os]: [{ ...value }],
            };
        });
        for (const key in bundlesIds) {
          let games = bundlesIds[key];
          let game = {};
          if (games.ios) {
            game = { ...games.ios[0], multipleOs: false };
          }
          if (games.android) {
            if (game.os) game.multipleOs = true;

            // use android as default
            games.android.forEach((el) => {
              if (el?.store === 'google') game = { ...game, ...el };
              else if (el?.store === 'amazon') parsedArray.push({ ...el, multipleOs: false });
            });
          }
          if (game.bundleId) parsedArray.push(game);
        }
        return parsedArray;
      },
    }),
    getDraftGames: builder.query({
      query: () => ({
        url: '/draft_games',
        headers: { Authorization: hmac256(baseUrl + '/draft_games') },
      }),
      providesTags: ['draftGames'],
      transformResponse: (response) => snakeToCamelRec(response),
    }),
    getGameMetrics: builder.query({
      query: (id) => ({
        url: `/games/${id}`,
        headers: { Authorization: hmac256(baseUrl + `/games/${id}`) },
      }),
      providesTags: (result, error, bundleId) => (result ? [{ type: 'gameMetrics', id: bundleId }] : []),
      transformResponse: (response) => snakeToCamelRec(response),
    }),

    updateEmail: builder.mutation({
      query: (args) => {
        return {
          url: '/change_infos_game',
          method: 'post',
          body: args,
          headers: { Authorization: hmac256(args) },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          studioApi.util.updateQueryData('getGames', undefined, (draft) =>
            current(draft)?.map((el) =>
              el.bundleId === args?.bundle_id && (args?.store?.length ? args?.store?.includes(el.store) : true)
                ? { ...el, bizdevEmail: args.bizdev_email }
                : el,
            ),
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    getGameVideos: builder.query({
      query: ({ bundleId, appId, studioId }) => {
        let url = `/sql_videos?bundle_id=${encodeURIComponent(bundleId)}&app_id=${encodeURIComponent(
          appId,
        )}&studio_id=${encodeURIComponent(studioId)}`;
        return {
          url,
          headers: { Authorization: hmac256(baseUrl + url) },
        };
      },
      providesTags: (result, error, { bundleId, appId, studioId }) =>
        result ? [{ type: 'gameVideos', id: bundleId + appId + studioId }] : [],
      transformResponse: (response) => {
        return response.map((el) => ({ type: el.type, file: el.video_url, id: el.id }));
      },
    }),
    createSqlVideos: builder.mutation({
      query: (videos) => ({
        url: '/sql_videos',
        method: 'post',
        body: { videos },
        headers: { Authorization: hmac256({ videos }) },
      }),
      invalidatesTags: (result) => (result ? ['gameVideos'] : []),
    }),
    deleteSqlVideos: builder.mutation({
      query: ({ ids, appId, studioId }) => ({
        url: '/sql_videos',
        method: 'delete',
        body: { ids, app_id: appId, studio_id: studioId },
        headers: { Authorization: hmac256(baseUrl + '/sql_videos') },
      }),
      invalidatesTags: (result) => (result ? ['gameVideos'] : []),
    }),
    createDraftGame: builder.mutation({
      query: (args) => ({
        url: '/draft_games',
        method: 'post',
        body: args,
        headers: { Authorization: hmac256(args) },
      }),
      invalidatesTags: (result) => (result ? ['draftGames'] : []),
    }),
    updateDraftGame: builder.mutation({
      query: (args) => ({
        url: '/draft_games',
        method: 'put',
        body: args,
        headers: { Authorization: hmac256(args) },
      }),
      invalidatesTags: (result) => (result ? ['draftGames'] : []),
    }),
    publishDraftGame: builder.mutation({
      query: (id) => ({
        url: '/draft_games/publish',
        method: 'post',
        body: { id },
        headers: { Authorization: hmac256({ id }) },
      }),
      transformResponse: (response) => snakeToCamelRec(response),
      invalidatesTags: (result) => (result ? ['draftGames', 'games', 'gameList'] : []),
    }),

    deleteDraftGame: builder.mutation({
      query: (body) => ({
        url: `/draft_games`,
        method: 'delete',
        body,
        headers: {
          Authorization: hmac256(baseUrl + `/draft_games`),
        },
      }),
      invalidatesTags: (result) => {
        return result ? ['draftGames'] : [];
      },
    }),
    changeGameStatus: builder.mutation({
      query: (args) => ({
        url: '/change_status_game',
        method: 'post',
        body: args,
        headers: { Authorization: hmac256(args) },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          studioApi.util.updateQueryData('getGames', undefined, (draft) =>
            current(draft).map((el) =>
              el.bundleId === args.bundle_id && (args?.store?.length ? args?.store?.includes(el.store) : true)
                ? { ...el, status: args.status }
                : el,
            ),
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    listStudios: builder.query({
      keepUnusedDataFor: 600, //keep data for 10 min
      query: () => ({
        url: '/list_studios',
        headers: { Authorization: hmac256(baseUrl + '/list_studios') },
      }),
      transformResponse: (response) => snakeToCamelRec(response),
      providesTags: ['studioList'],
    }),

    getMarketingPdfs: builder.query({
      query: () => ({
        url: '/marketing_pdfs',
        headers: {
          Authorization: hmac256(baseUrl + '/marketing_pdfs'),
        },
      }),
      transformResponse: (response) => {
        return response?.map((el) => ({ id: el.id, name: el.pdf_name, url: el.pdf_url, date: el.pdf_date })) || [];
      },
      providesTags: ['marketingPdfs'],
    }),
    createMarketingPdf: builder.mutation({
      query: (args) => ({
        url: '/marketing_pdfs',
        method: 'post',
        body: args,
        headers: {
          Authorization: hmac256(args),
        },
      }),
      invalidatesTags: (result) => {
        return result ? ['marketingPdfs'] : [];
      },
    }),
    deleteMarketingPdf: builder.mutation({
      query: (id) => ({
        url: `/marketing_pdfs/${id}`,
        method: 'delete',
        headers: {
          Authorization: hmac256(baseUrl + `/marketing_pdfs/${id}`),
        },
      }),
      invalidatesTags: (result) => {
        return result ? ['marketingPdfs'] : [];
      },
    }),
    createStudio: builder.mutation({
      query: (args) => ({
        url: '/studio',
        method: 'POST',
        body: args,
        headers: {
          Authorization: hmac256(args),
        },
      }),
      invalidatesTags: (result) => {
        return result ? ['studioList'] : [];
      },
    }),
    createGameAnalyticsStudio: builder.mutation({
      query: ({ studioName, studio_id }) => {
        const payload = { name: studioName };
        if (studio_id) {
          payload.studio_id = studio_id;
        }
        return {
          url: '/game_analytics/studios',
          method: 'POST',
          body: payload,
          headers: {
            Authorization: hmac256(payload),
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedData } = await queryFulfilled;
          if (updatedData?.id) {
            dispatch(
              studioApi.util.updateQueryData('listGameAnalyticsStudios', undefined, (draft) => {
                draft.push(updatedData);
                return draft;
              }),
            );
          }
        } catch {}
      },
    }),
    createGameAnalyticsGame: builder.mutation({
      query: (payload) => ({
        url: '/game_analytics/games',
        method: 'POST',
        body: payload,
        headers: {
          Authorization: hmac256(payload),
        },
      }),
      invalidatesTags: ['games'],
    }),
    sendInvites: builder.mutation({
      query: (payload) => ({
        url: '/game_analytics/invites',
        method: 'POST',
        body: payload,
        headers: {
          Authorization: hmac256(payload),
        },
      }),
    }),

    listGameAnalyticsStudios: builder.query({
      query: () => ({
        url: '/game_analytics/studios',
        method: 'GET',
        headers: {
          Authorization: hmac256(baseUrl + '/game_analytics/studios'),
        },
      }),
      providesTags: ['gaStudioList'],
      transformResponse: (response) => response.map((el) => snakeCaseToCamelObj(el)),
    }),
    updateStudio: builder.mutation({
      query: (args) => ({
        url: '/studio/' + encodeURIComponent(args.id),
        method: 'PUT',
        body: args,
        headers: {
          Authorization: hmac256(args),
        },
      }),
      async onQueryStarted({ id, ga_studio_id, name, email, bizdev_email }, { dispatch, queryFulfilled, getState }) {
        const patchResult = dispatch(
          studioApi.util.updateQueryData('listStudios', undefined, (draft) => {
            const i = draft?.findIndex((s) => s.studioId === id);
            if (i > -1) {
              if (ga_studio_id) draft[i].gaStudioId = ga_studio_id;
              if (name) draft[i].name = name;
              if (email) draft[i].email = email;
              if (bizdev_email) draft[i].bizdevEmail = bizdev_email;
            }
            return draft;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
        return patchResult;
      },
    }),
    getImage: builder.mutation({
      query: (args) => ({
        url: `/get_image?url=${encodeURIComponent(args)}`,
        headers: {
          Authorization: hmac256(baseUrl + `/get_image?url=${encodeURIComponent(args)}`),
        },
        responseHandler: async (response) => {
          const reader = response.body.getReader();
          const stream = new ReadableStream({
            start(controller) {
              return pump();
              function pump() {
                return reader.read().then(({ done, value }) => {
                  // When no more data needs to be consumed, close the stream
                  if (done) {
                    controller.close();
                    return;
                  }
                  // Enqueue the next data chunk into our target stream
                  controller.enqueue(value);
                  return pump();
                });
              }
            },
          });
          let tmp = new Response(stream);
          let blob = await tmp.blob();
          return URL.createObjectURL(blob);
        },
      }),
    }),
    getGameConfig: builder.query({
      query: ({ bundleId }) => ({
        url: `/games/config/${bundleId}`,
        headers: {
          Authorization: hmac256(baseUrl + `/games/config/${bundleId}`),
        },
      }),
    }),
  }),
});

export const {
  useListStudiosQuery,
  useGetGamesQuery,
  useGetGameListQuery,
  useGetGameMetricsQuery,
  useGetGameVideosQuery,
  useUpdateEmailMutation,
  useDeleteSqlVideosMutation,
  useCreateSqlVideosMutation,
  useGetDraftGamesQuery,
  useCreateDraftGameMutation,
  useUpdateDraftGameMutation,
  usePublishDraftGameMutation,
  useDeleteDraftGameMutation,
  useGetMarketingPdfsQuery,
  useCreateMarketingPdfMutation,
  useDeleteMarketingPdfMutation,
  useChangeGameStatusMutation,
  useCreateStudioMutation,
  useCreateGameAnalyticsStudioMutation,
  useCreateGameAnalyticsGameMutation,
  useListGameAnalyticsStudiosQuery,
  useUpdateStudioMutation,
  useGetImageMutation,
  useSendInvitesMutation,
  useGetGameConfigQuery,
} = studioApi;
