import React, { useEffect, useState } from 'react';

import { LineChart, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, Line } from 'recharts';
import moment from 'moment';

function GraphDoubleAxis({ metrics, dataKey, dataKey2, color, color2 }) {
  const [formattedMetrics, setformattedMetrics] = useState([]);
  useEffect(() => {
    let result = [];

    result = metrics
      .map(({ date, ...rest }) => {
        if (rest[dataKey] !== null) {
          return {
            date: moment(date).format('D MMM YY'),
            ...rest,
          };
        } else {
          return false;
        }
      })
      .filter(Boolean);

    result = Object.values(result);
    result = result.map((item) => {
      if (!Number.isInteger(item.ret_d1)) {
        return { ...item, ret_d1: Math.round(item.ret_d1 * 100) };
      }
      return { ...item };
    });
    setformattedMetrics(result);
  }, [metrics]);

  return (
    <div className="global-chart">
      <ResponsiveContainer width="95%" height={250}>
        <LineChart data={formattedMetrics} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
          <CartesianGrid stroke="#DEDEDE" vertical={false} strokeDasharray="5 5" />
          <Tooltip
            contentStyle={{
              borderRadius: 4,
              padding: '4px 8px',
              fontSize: 14,
            }}
            formatter={function (value, name) {
              if (name === 'ret_d1') {
                return `${value}%`;
              } else {
                return `${value}`;
              }
            }}
          />
          <Line type="monotone" dataKey={dataKey} stroke={color} strokeWidth={2} yAxisId="left" dot={<></>} />

          <Line type="monotone" dataKey={dataKey2} stroke={color2} strokeWidth={2} yAxisId="right" dot={<></>} />

          <XAxis dataKey="date" angle={16} height={60} width={16} fontSize={12} dy={8} dx={8} />

          <YAxis width={44} fontSize={12} yAxisId="left" padding={{ bottom: 5, top: 5 }} />
          <YAxis
            width={44}
            fontSize={12}
            yAxisId="right"
            orientation="right"
            domain={[0, (dataMax) => dataMax.toFixed(3)]}
            padding={{ bottom: 5, top: 5 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

GraphDoubleAxis.defaultProps = {
  bgColor1: 'rgba(0, 44, 255, 0.15)',
  bgColor2: 'rgba(0, 44, 255, 0.02)',
  bgColor21: 'rgba(0, 212, 138, 0.15)',
  bgColor22: 'rgba(0, 212, 138, 0.02)',
  color: '#002CFF',
  color2: '#00D48A',
};
export default GraphDoubleAxis;
