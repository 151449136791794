import * as Joi from 'joi';

export const CreateStudioSchema = Joi.object({
  name: Joi.string().required().trim().messages({ 'string.empty': 'Studio Name is required' }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.empty': 'Email is required',
      'string.email': 'Email must be in a valid format (e.g., example@example.com)',
    }),
  bizdevEmail: Joi.string()
    .trim()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'object.unknown': 'Contact Email is required',
      'string.empty': 'Contact Email is required',
      'any.required': 'Contact Email is required',
      'string.email': 'Invalid Email',
    }),
}).options({ abortEarly: false, allowUnknown: true });

export default CreateStudioSchema;
