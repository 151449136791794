import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../data/slices/user';
import { R_TERMS_CONDITIONS } from '../../../constants/routes';
import { getCookie, delCookie } from '../../../utils/common';
import AuthLayout from '../../../Layouts/AuthLayout/AuthLayout';
import LoginForm from './loginForm';

function Login() {
  const dispatch = useDispatch();
  useEffect(() => {
    if (getCookie('user-id') !== null) {
      delCookie('user-id');
      dispatch(setUser({}));
    }
  }, []);

  return (
    <AuthLayout>
      <div className="login-form">
        <h2>Welcome Back 👋🏻</h2>
        <p className="text-muted">Glad to see you again! Sing in to manage your games with Studio Platform.</p>

        <LoginForm />

        <Link to={R_TERMS_CONDITIONS} className=" fs-8">
          Terms & Conditions
        </Link>
      </div>
    </AuthLayout>
  );
}

export default Login;
