import { Button, Form, Modal } from 'react-bootstrap';
import Loader from '../../../../../Components/Loader';
import React, { useState } from 'react';
import { useSendInvitesMutation } from '../../../../../data/api/studioApi';
import { confirmAlert } from '../../../../../Components/ConfirmModal';

const InviteUsers = ({ gaStudioId, gaGameId }) => {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [emails, setEmails] = useState(['']);
  const [errors, setErrors] = useState([]);

  const [sendInvites, { isLoading }] = useSendInvitesMutation();
  const handleSubmit = () => {
    sendInvites({ gameId: gaGameId, studioId: gaStudioId, emails: emails.filter((email) => email) })
      .unwrap()
      .then(() => {
        confirmAlert({
          variant: 'success',
          title: 'Invites sent successfully',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
        setShowInviteModal(false);
        setEmails(['']);
      })
      .catch((err) => {
        confirmAlert({
          variant: 'danger',
          title: 'Failed to send invites',
          desc: err?.data?.details ? (
            <ul>
              {err?.data?.details.map((el) => (
                <li>
                  {el?.title}
                  {el?.detail && <p className="fs-7 text-secondary">{el.detail}</p>}
                </li>
              ))}
            </ul>
          ) : undefined,
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
      });
  };
  const handleTextChange = (e, index) => {
    let text = e.target.value;
    if (text || text === '') {
      let newArray = [...emails];
      newArray[index] = text?.split(' ')?.join('');
      setEmails(newArray);
      setErrors({});
    }
  };

  const handleDeleteParam = (index) => {
    const tmp = [...emails];
    tmp.splice(index, 1);
    setEmails(tmp);
  };
  return (
    <>
      <Button
        variant="outline-secondary"
        size={'sm'}
        className="p-1 ms-auto outline-none"
        onClick={() => {
          setShowInviteModal(true);
        }}
      >
        Invite Users
      </Button>

      <Modal show={showInviteModal} onHide={() => setShowInviteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Invite Users </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : (
            <>
              <p>
                List emails of users you want to invite to this game (up to 20). They will be able to view the game in
                Game Analytics
              </p>

              {emails.length > 0 &&
                emails.map((elem, index) => {
                  return (
                    <div key={index} className="mb-2 d-flex align-items-center">
                      <Form.Group
                        className="position-relative me-3 flex-grow-1 flex-basis-0"
                        controlId={`email${index}`}
                      >
                        <Form.Control
                          type="text"
                          placeholder="User Email"
                          value={elem}
                          isInvalid={errors[index]}
                          onChange={(e) => handleTextChange(e, index)}
                          name="email"
                        />
                        <Form.Control.Feedback tooltip type="invalid">
                          {errors[index]}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div style={{ width: 30 }}>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => {
                            handleDeleteParam(index);
                          }}
                          disabled={emails.length === 1}
                        >
                          -
                        </Button>
                      </div>
                    </div>
                  );
                })}
              <Button
                variant="outline-secondary"
                className="w-100 mb-2"
                disabled={!emails[emails.length - 1] || emails.length > 19}
                size="sm"
                onClick={() => setEmails([...emails, ''])}
                id="add-params"
              >
                + Add more
              </Button>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowInviteModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
            Send Invites
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InviteUsers;
