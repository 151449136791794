import Joi from 'joi';

export const createAccountSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.empty': 'Email is required',
      'string.email': 'Email must be in a valid format (e.g., example@example.com)',
    }),
  password: Joi.string().pattern(new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')).required().messages({
    'string.empty': 'Password is required',
    'string.pattern.base':
      'Password must contain at least one lowercase letter, one uppercase letter, one digit, and be at least 8 characters long.',
  }),
  firstName: Joi.string()
    .required()
    .messages({ 'string.empty': 'First Name is required', 'any.required': 'First Name is required' }),
  lastName: Joi.string()
    .required()
    .messages({ 'string.empty': 'Last Name is required', 'any.required': 'Last Name is required' }),
  studioIds: Joi.array().items(Joi.string()).min(1).max(100).required().messages({
    'string.empty': 'Studio Name is required',
    'any.required': 'Studio Name is required',
  }),
  role: Joi.string().valid('USER', 'MANAGER', 'SDK', 'VIEWER', 'ADMIN').required().messages({
    'string.empty': 'Role is required',
    'any.only': 'Role must be either "USER" or "ADMIN"',
  }),
}).options({ abortEarly: false, allowUnknown: true });

export default createAccountSchema;
