import Dropdown from 'react-bootstrap/Dropdown';
import BlockIcon from '../Components/Icons/BlockIcon';

/** Import API **/
import { useDispatch, useSelector } from 'react-redux';
import { studioSelectedSelector } from '../data/slices/user';
import { useListStudiosQuery } from '../data/api/studioApi';
import { setStudioSelected } from '../data/slices/user';
import React, { useState, useMemo } from 'react';
import PeopleGroupIcon from '../Components/Icons/PeopleGroupIcon';
import { useUserPermission } from '../Components/permissions/UserPermissions';
import { InputGroup, Nav } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import SearchIcon from '../Components/Icons/SearchIcon';

function SelectStudio({ dropDirection }) {
  const dispatch = useDispatch();
  const studioPermission = useUserPermission('select.studio');
  const studioSelected = useSelector(studioSelectedSelector);
  const [search, setSearch] = useState('');
  const { data: studioList } = useListStudiosQuery(undefined, {
    skip: !studioPermission,
  });
  const handleSelectStudio = (studio) => {
    dispatch(setStudioSelected(studio));
    setSearch('');
  };

  const filteredStudioList = useMemo(() => {
    if (!studioList) return [];
    return studioList.filter((studio) => studio.name.toLowerCase().includes(search.toLowerCase()));
  }, [studioList, search]);

  return (
    <Dropdown
      className={`no-arrow border rounded user-dropdown p-0 ${dropDirection === 'up' ? 'dropUp' : ''}`}
      drop={dropDirection}
    >
      <Dropdown.Toggle as={'div'}>
        <div
          style={{ paddingLeft: '10px' }}
          className={`d-flex color-button  rounded align-items-center cursor-pointer overflow-hidden gap-1 pt-1 pb-1 pe-2 ${
            studioSelected?.studioId ? 'bg-pale-white' : ''
          }`}
        >
          <PeopleGroupIcon size={25} color="#a4a4a5" />
          <div className="text-truncate color-text overflow-hidden user-dropdown-tittle p-0">
            {studioSelected?.name || 'Select Studio'}
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ maxHeight: '300px', maxWidth: '230px', overflow: 'scroll' }}>
        <Nav>
          <Nav.Item>
            <Form.Group>
              <InputGroup>
                <Form.Control
                  className="bg-white"
                  type="text"
                  aria-describedby=""
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  autoComplete={'off'}
                />
                <InputGroup.Text className="bg-white">
                  <SearchIcon size={20} />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Nav.Item>
        </Nav>
        <Dropdown.Item
          onClick={() => {
            handleSelectStudio({});
          }}
        >
          None <BlockIcon />
        </Dropdown.Item>
        {filteredStudioList &&
          filteredStudioList.map((value, index) => (
            <Dropdown.Item
              className={'overflow-hidden text-truncate'}
              key={index}
              active={value.studioId === studioSelected?.studioId}
              onClick={() => {
                handleSelectStudio(value);
              }}
            >
              {value.name}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default SelectStudio;
