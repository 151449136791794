import { useEffect } from 'react';

import DraftGameCard from './DraftGameCard';

/** Import Utils **/
import sortingGames from '../../../../utils/sortingGames';
import { useGetDraftGamesQuery, useListStudiosQuery } from '../../../../data/api/studioApi';
import { useSelector } from 'react-redux';
import { studioSelectedSelector } from '../../../../data/slices/user';
import Loader from '../../../../Components/Loader';
import { useQuery } from '../../../../utils/customHooks';
import { useUserPermission } from '../../../../Components/permissions/UserPermissions';

function DraftGameListTable({ data, setData }) {
  const query = useQuery();
  const currentPage = query.get('page') || 1;
  const selectStudioPermission = useUserPermission('select.studio');
  useListStudiosQuery(undefined, { skip: !selectStudioPermission }); //fetch for draft's card

  const studioSelected = useSelector(studioSelectedSelector);

  const { data: draftGames, isLoading: isLoadingDraftGames } = useGetDraftGamesQuery();

  useEffect(() => {
    const gameName = query.get('search') || '',
      bizDevEmail = query.get('email') || '',
      categorySelected = query.get('category') || '',
      draftSort = query.get('sort') || 'dateDown';
    if (draftGames) {
      let parsedArray = [...draftGames];

      if (studioSelected.company) {
        parsedArray = parsedArray.filter((el) => {
          return el.studioId === studioSelected.studioId;
        });
      }

      let searchGameFilter = parsedArray.filter((game) => {
        let filters = {
          appName: gameName,
          bizdevEmail: bizDevEmail,
          category: categorySelected,
        };
        return Object.keys(filters).every((key) => {
          if (!filters[key]) return true;
          if (key === 'appName' && game[key].toLowerCase().includes(filters[key].toLowerCase())) {
            return true;
          } else if (game[key].toLowerCase().includes(filters[key].toLowerCase())) {
            return true;
          } else {
            return false;
          }
        });
      });
      let sorted = sortingGames(draftSort, searchGameFilter);

      const indexOfLastPost = currentPage * data.postsPerPage;
      const indexOfFirstPost = indexOfLastPost - data.postsPerPage;
      const currentPosts = sorted.slice(indexOfFirstPost, indexOfLastPost);
      setData({
        sortedGames: currentPosts,
        error: currentPosts.length ? '' : 'No Draft Game Found...!',
        total: sorted.length,
      });
    }
  }, [draftGames, studioSelected, query]);

  return isLoadingDraftGames ? (
    <tr>
      <td colSpan={6}>
        <div className="d-flex justify-content-center w-100">
          <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
        </div>
      </td>
    </tr>
  ) : (
    <>
      {data.error ? (
        <tr>
          <td colSpan="7" id="pagination-td">
            <p className="fs-4 m-2">{data.error}</p>
          </td>
        </tr>
      ) : (
        data.sortedGames &&
        data.sortedGames.map((value, index) => {
          return <DraftGameCard key={index} data={value} />;
        })
      )}
    </>
  );
}
export default DraftGameListTable;
