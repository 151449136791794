import UpdateAccountForm from './UpdateAccountForm';

function UpdateAccount() {
  return (
    <div className="container">
      <div className="row mt-3">
        <div className=" col-md-6 col-12 ">
          <div className="  bg-white rounded px-4 py-3 ">
            <h6 className="fw-bold">Profile Details</h6>
            <UpdateAccountForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateAccount;
