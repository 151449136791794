import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserPermissions, userPermissionsSelector } from '../../data/slices/user';
import { Restricted } from '../permissions/UserPermissions';
import { Form, InputGroup } from 'react-bootstrap';
import permissionsByRole from '../../constants/permissions';

const UserPermissionDemo = () => {
  const dispatch = useDispatch();
  const userPermissions = useSelector(userPermissionsSelector);
  const [user, setUser] = useState({ role: 'USER' });

  useEffect(() => {
    user.role && dispatch(setUserPermissions(permissionsByRole[user.role] || []));
  }, [dispatch, user]);
  return (
    <>
      <Form.Group className="mb-2 select-drop">
        <InputGroup hasValidation>
          <Form.Select
            className="form-select"
            value={user.role}
            onChange={(e) => setUser({ ...user, role: e.target.value })}
          >
            {Object.keys(permissionsByRole).map((role, index) => (
              <option key={index} value={role}>
                {role}
              </option>
            ))}
          </Form.Select>
        </InputGroup>
      </Form.Group>
      <div className="d-flex gap-1">
        <Restricted
          permission="element.create"
          fallback={<div>No permission to add elements</div>}
          userPermissions={userPermissions}
        >
          <div className="btn btn-success">Add</div>
        </Restricted>
        <Restricted
          permission="element.delete"
          fallback={<div>No permission to delete elements</div>}
          userPermissions={userPermissions}
        >
          <div className="btn btn-success">Delete</div>
        </Restricted>
        <Restricted
          permission="element.read"
          fallback={<div>No permission to list elements</div>}
          userPermissions={userPermissions}
        >
          <div className="btn btn-success">List</div>
        </Restricted>
        <Restricted
          permission="element.update"
          fallback={<div>No permission to edit elements</div>}
          userPermissions={userPermissions}
        >
          <div className="btn btn-success">Edit</div>
        </Restricted>
      </div>
    </>
  );
};

export default UserPermissionDemo;
