import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import AddIcon from '../../../Components/Icons/AddIcon';
import CreateTestModal from './AbTest/CreateTestModal';
import CreateConfigModal from './Config/CreateConfigModal';
import { Restricted } from '../../../Components/permissions/UserPermissions';

function AbTestCreateBtns({ type = 'ABTEST', id, state }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const [showConfigModal, setShowConfigModal] = useState(false);

  const handleButtonClick = () => {
    setIsDropdownOpen(false);
    if (type === 'ABTEST') {
      setShowTestModal(true);
    } else if (type === 'CONFIG') {
      setShowConfigModal(true);
    }
  };

  const handleDropdownEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownLeave = () => {
    setIsDropdownOpen(false);
  };

  return (
    <Restricted permission="abTest.create">
      <div className="position-relative z-5" onMouseEnter={handleDropdownEnter} onMouseLeave={handleDropdownLeave}>
        <Button variant="success" className="position-relative z-2" onClick={handleButtonClick} id="createTest">
          <AddIcon size={20} className="me-2" />
          {type === 'ABTEST' ? 'Submit A/B Test' : 'Submit Config'}
        </Button>

        <Button
          variant="success"
          style={{ top: '32px' }}
          className={` ${
            !isDropdownOpen ? 'd-none' : ''
          } fs-7  rounded-bottom rounded-top-0 position-absolute end-0 opacity-75 w-100`}
          onClick={(e) => {
            e.stopPropagation();
            if (type === 'ABTEST') setShowConfigModal(true);
            else setShowTestModal(true);
          }}
        >
          {type === 'ABTEST' ? 'Or Config' : 'Or A/B Test'}
        </Button>
      </div>
      {showTestModal && (
        <CreateTestModal
          gameId={state?.id || id}
          onClose={() => {
            setShowTestModal(false);
          }}
        />
      )}

      {showConfigModal && (
        <CreateConfigModal
          id={state?.id || id}
          onClose={() => {
            setShowConfigModal(false);
          }}
        />
      )}
    </Restricted>
  );
}

export default AbTestCreateBtns;
