import * as PropTypes from 'prop-types';

const PlayIcon = ({ size, color }) => {
  return (
    <svg height={size} width={size} viewBox="0 0 12 12" fill={color}>
      <path
        d="M3.66518 3.43575L4.07367 2.82036L4.48216 2.61523L4.78747 2.82036L6.93309 3.64088L9.17977 5.2819L9.38401 6.30754L6.11611 8.35882L4.6864 9.38447L3.66518 8.76908L3.46094 6.30754L3.66518 3.43575Z"
        strokeWidth="0.846154"
      />
      <path d="M9.48055 4.61611L5.02209 2.05919C4.78083 1.91988 4.50701 1.8469 4.22842 1.84766C3.94984 1.84843 3.67642 1.92291 3.43593 2.06354C3.19545 2.20418 2.99647 2.40595 2.8592 2.64838C2.72194 2.8908 2.65127 3.16523 2.6544 3.4438V8.57611C2.6544 8.99474 2.8207 9.39623 3.11672 9.69225C3.41274 9.98827 3.81422 10.1546 4.23286 10.1546C4.50998 10.1541 4.78213 10.0809 5.02209 9.94227L9.48055 7.38534C9.72012 7.24669 9.91901 7.04748 10.0573 6.8077C10.1956 6.56791 10.2683 6.29598 10.2683 6.01919C10.2683 5.74239 10.1956 5.47046 10.0573 5.23068C9.91901 4.99089 9.72012 4.79168 9.48055 4.65303V4.61611ZM9.01901 6.54996L4.56055 9.1438C4.46062 9.20044 4.34772 9.2302 4.23286 9.2302C4.118 9.2302 4.00509 9.20044 3.90517 9.1438C3.80552 9.08627 3.72277 9.00352 3.66525 8.90387C3.60773 8.80421 3.57745 8.69117 3.57747 8.57611V3.42534C3.57745 3.31028 3.60773 3.19724 3.66525 3.09758C3.72277 2.99793 3.80552 2.91518 3.90517 2.85765C4.00551 2.80187 4.11807 2.77175 4.23286 2.76996C4.34756 2.77231 4.46 2.8024 4.56055 2.85765L9.01901 5.43303C9.1187 5.49054 9.20148 5.57328 9.25904 5.67294C9.3166 5.77259 9.3469 5.88564 9.3469 6.00073C9.3469 6.11581 9.3166 6.22886 9.25904 6.32852C9.20148 6.42817 9.1187 6.51091 9.01901 6.56842V6.54996Z" />
    </svg>
  );
};
PlayIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

PlayIcon.defaultProps = {
  size: 24,
  color: '#fff',
};

export default PlayIcon;
