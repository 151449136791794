import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import SettingsIcon from '../../Components/Icons/SettingsIcon';
import { getCookie, setCookie } from '../../utils/common';

const UserConsentModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const updateConsent = () => {
    window.gtag('consent', 'update', {
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      analytics_storage: 'granted',
    });
  };

  const handleAccept = () => {
    setCookie('user-consent', 'granted', 300);
    updateConsent();
    setShowModal(false);
    setIsVisible(false);
  };

  const handleReject = () => {
    setCookie('user-consent', 'denied', 300);
    setShowModal(false);
    setIsVisible(false);
  };

  useEffect(() => {
    const userConsentCookie = getCookie('user-consent');
    if (!userConsentCookie) {
      setIsVisible(true);
    } else if (userConsentCookie === 'granted') {
      updateConsent();
    }
  }, []);

  return isVisible ? (
    <>
      <div
        id="user-consent-confirmation"
        className="w-100 bg-white position-fixed bottom-0 end-0 py-3 d-flex  justify-content-around z-3"
      >
        <div>
          <div>
            <h2>We respect your privacy</h2>
            <p>
              By continuing browsing this site, you accept the use of cookies used to produce visitor statistics on our
              site. We use Google Analytics technology.
            </p>
          </div>
          <div className="d-flex gap-3 flex-column">
            <div className="d-flex gap-2">
              <Button variant="warning" onClick={handleReject}>
                REJECT
              </Button>
              <Button variant="primary" onClick={handleAccept}>
                ACCEPT
              </Button>
            </div>
            <div className="d-flex gap-2 cursor-pointer" onClick={handleButtonClick}>
              <SettingsIcon />
              <p>Privacy Settings</p>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header>
          <div className="d-flex gap-2">
            <SettingsIcon />
            <Modal.Title>Privacy Settings</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="pb-3 border-bottom">
            You can set your consent preferences below and determine how you want your data to be used based on the
            purposes mentioned.
          </div>
          <h5 className="pt-3">01. GOOGLE ANALYTICS</h5>
          <p>
            We use Google Tag Manager and cookie technology to evaluate the performance of our website. In particular,
            we record your IP address, the pages you visit and your visit time. Deactivate the button opposite if you
            refuse the use of this technology and the processing of your personal data for these purposes.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2">
            <Button variant="warning" onClick={handleReject}>
              REJECT
            </Button>
            <Button variant="primary" onClick={handleAccept}>
              ACCEPT
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  ) : null;
};

export default UserConsentModal;
