import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';

class GaLogo extends PureComponent {
  static propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
  };

  static defaultProps = {
    size: 50,
    color: '#000000',
  };

  render() {
    const { size, color } = this.props;

    return (
      <svg height={size} width={size} viewBox="0 0 1024 1024">
        <path
          fill={color}
          d="M476 1.1C352.4 10 237.4 62.6 150 150 22.1 277.9-28.6 462.1 15.6 638.2c47.7 190 202.8 337.6 395.4 376.2 55.5 11.2 119.9 12.8 176.3 4.5 96.4-14.1 186.5-55.6 261.3-120.4 14.5-12.6 43.7-42.2 55.3-56 61.9-74.1 101.3-161.6 115-255.2 6.9-46.7 6.9-103.5.1-149.8-21.3-144.9-103.5-273.3-226.2-353.9C719 35.2 635.5 7.2 546 1c-16.8-1.1-53.3-1.1-70 .1zM434 285c32.7 2.4 56.8 7.8 85.9 19.5l4.3 1.8-.7 6.6c-2.3 23.4-9.9 49.9-21 73.8l-4.3 9.2-11.9-4c-26.7-8.9-40.1-11.3-65.3-11.3-21.8-.1-30.3 1.2-43.9 6.5-10.9 4.4-18.5 9.3-27.5 17.9-13.3 12.7-21.7 27.2-27.6 47.4-8.7 29.8-10.1 74.4-3.5 107.1 3.1 15.2 5.5 22.7 11 34 8.9 18.3 21.4 30.9 39 39.6 26.2 12.8 56.6 14.1 92.6 3.9l3.5-1 10.2-32.3c5.6-17.7 10.2-32.6 10.2-33 0-.4-16.8-.7-37.4-.7-20.6 0-37.7-.4-38-.8-1.8-2.9-3.9-26.6-3.9-42.7.1-16.2 2.1-38.8 3.8-41.5.4-.6 31.2-1 85.7-1 46.8 0 84.9-.2 84.7-.4-.3-.3-14.4-2.8-31.4-5.6-17.1-2.9-31.1-5.3-31.3-5.5-.5-.5 56.9-181.8 57.8-182.8 1.5-1.7 37.4-4.7 55.6-4.7 15.6 0 38.4 1.5 54.5 3.6l4.6.5 8 26.2c4.5 14.4 25.2 81.5 46.1 149.2 20.9 67.6 44.6 144.4 52.7 170.5 8.1 26.1 17.8 57.6 21.6 69.9 3.8 12.3 6.6 22.8 6.2 23.2-1.9 1.8-43.8 5.9-60.3 5.9-10.7 0-32.8-2-44.5-4.1-6.5-1.1-6.5-1.1-7.6-5.3-.6-2.2-5.4-19.6-10.6-38.6-13.4-49.2-31.1-117.9-52.9-205.4-12.6-50.6-19.1-75.2-19.6-74-.4 1.1-13 45.1-27.9 97.9-29.3 103.9-56.1 198.9-58.4 207.1l-1.5 5.2-16.9 6c-19.9 7.2-32.1 10.5-49.1 13.1-42.5 6.7-91.2 5.3-126-3.5-27-6.9-54.6-20.5-75.5-37.2-50.4-40.3-76.7-108.4-74.2-191.7 3-98.2 48.6-172.7 124.6-203.6C356.3 286.8 391.5 282 434 285z"
        />
      </svg>
    );
  }
}

export default GaLogo;
