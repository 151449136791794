import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import FileSaver from 'file-saver';
import AbTestGraph from './Charts/abTestGraph';
import { useSelector } from 'react-redux';
import { gameAbTestByIdSelector } from '../../../../data/slices/abTests';
import ArrowStickDown from '../../../../Components/Icons/ArrowStickDown';
import * as PropTypes from 'prop-types';
import { getGameById } from '../../../../data/slices/studio';
import { abTestsApi } from '../../../../data/api/abTestsApi';

function ResultsTable({ csvName, csv, title, bgColor, data, tableVariant }) {
  let { gameId, testId } = useParams();
  let foundGame = useSelector(getGameById(gameId));
  const abTestSelected = useSelector((state) =>
    gameAbTestByIdSelector(
      abTestsApi.endpoints.getGameAbtests.select({
        bundleId: foundGame.bundleId,
        store: foundGame?.store,
      })(state),
      testId
    )
  );

  const [selectedHeader, setSelectedHeader] = useState('');

  const handleDownloadCSV = () => {
    let name = abTestSelected.name + ' - ' + csvName;
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    return new Promise((resolve) => {
      FileSaver.saveAs(csvData, name);
      resolve(true);
    });
  };

  function selectHeader() {
    if (data.meta && (csvName === 'CohortedRevenue' || csvName === 'LtvIapPivot')) {
      return 'ARPU';
    }
    if (csvName === 'CohortedRevenue') {
      return selectedHeader || 'ltv30';
    } else {
      return selectedHeader;
    }
  }

  const positiveOrNegative = (variation) => {
    if (Math.sign(variation) === 1) return 'text-success';
    else if (Math.sign(variation) === -1) return 'text-danger';
    else if (Math.sign(variation) === 0) return 'text-muted';
    else return '';
  };
  const renderVariation = (variation) => {
    return variation ? (
      <span
        className={`${positiveOrNegative(
          variation
        )} d-inline-flex align-items-center ms-1 fs-8 rounded px-1 cursor-pointer`}
      >
        {variation && (
          <>
            {Math.sign(variation) !== 0 && (
              <ArrowStickDown style={{ marginRight: 2 }} size={8} rotate={Math.sign(variation) === 1 ? 'up' : ''} />
            )}
            {Math.abs(variation)}%
          </>
        )}
      </span>
    ) : null;
  };
  return (
    <div className="bg-white rounded my-3 p-3 ">
      <>
        <div className="d-flex ms-2 mb-2">
          <div className="fw-bold">{title}</div>
          {csvName !== 'Overview' && csv && (
            <Link to="#" className="ms-auto fs-7" onClick={() => handleDownloadCSV()}>
              Download as CSV
            </Link>
          )}
        </div>

        <div className="row">
          <div
            className={`abtest_block ${
              ['Percent/Rewarded', 'Overview'].includes(csvName) ? 'block col-lg-12' : 'block col-lg-8'
            }`}
          >
            <div className="table-responsive  ">
              <table className="table table-hover table-border-dashed bg-white sticky-first-column m-0 fs-7 rounded overflow-hidden">
                <thead>
                  <tr className="bg-light border-bottom">
                    {data?.header?.map((value, index) => {
                      let notClickable =
                        index === 0 ||
                        ['Interstitials/User', 'Rewarded/Users'].includes(csvName) ||
                        (['CohortedRevenue', 'LtvIapPivot'].includes(csvName) && data.meta);
                      return (
                        <th
                          className={`text-nowrap text-truncate overflow-hidden ${
                            notClickable ? '' : 'cursor-pointer'
                          }`}
                          scope="col"
                          style={{ maxWidth: 180 }}
                          key={value + index}
                          onClick={() => (notClickable ? null : setSelectedHeader(value))}
                        >
                          {value}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((dataRow, rowIndex, dataTable) => {
                    let meta;
                    if (data.meta) {
                      meta = data.meta?.[rowIndex] || null;
                    }
                    return (
                      <tr key={rowIndex}>
                        {dataRow.map((data, dataIndex, row) => {
                          if (data === null || data === '') data = '-';
                          let fraction = 4;
                          if (['SessionTime', 'Impressions'].includes(csvName)) fraction = 2;
                          if (!isNaN(data) && data % 1 > 0) data = Number(data).toFixed(fraction);

                          if (tableVariant === 'compareHorizontal' || tableVariant === 'compareVertical') {
                            let variation = (
                              100 *
                              (data / (tableVariant === 'compareHorizontal' ? dataTable[0][dataIndex] : row[1]) - 1)
                            ).toFixed(1);
                            if (tableVariant === 'compareHorizontal' && rowIndex < 1) variation = null;
                            if (tableVariant === 'compareVertical' && dataIndex < 2) variation = null;
                            if (csvName === 'Overview' && dataIndex === 3) variation = null;
                            if (!isFinite(variation) || parseFloat(variation) === 0.0) {
                              variation = null;
                            }

                            return meta && !dataIndex && csvName !== 'Impressions' ? (
                              <td className={`text-nowrap`} key={'key' + data + dataIndex}>
                                <div className="position-relative">{data}</div>
                              </td>
                            ) : (
                              <td className="text-nowrap" key={'key' + data + dataIndex}>
                                {data && (
                                  <>
                                    {csvName === 'Overview' && dataIndex === 2 ? Number(data).toFixed(2) + '%' : data}

                                    {!Number.isNaN(+data) && renderVariation(variation)}
                                  </>
                                )}
                              </td>
                            );
                          } else {
                            // retention tab
                            if (!isNaN(data) && data % 1 > 0 && dataIndex > 1) data = (data * 100).toFixed(2) + '%';
                            return (
                              <td className="text-nowrap" key={'key' + data + dataIndex}>
                                {data}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {csvName && !['Percent/Rewarded', 'Overview'].includes(csvName) && data?.data?.length && (
            <AbTestGraph
              bgColor={bgColor && bgColor}
              header={data.header}
              data={data.data}
              meta={data?.meta || null}
              csvName={csvName}
              selectedHeader={selectHeader()}
            />
          )}
        </div>
      </>
    </div>
  );
}
ResultsTable.propTypes = {
  csvName: PropTypes.string,
  csv: PropTypes.string,
  bgColor: PropTypes.string,
  data: PropTypes.object,
  tableVariant: PropTypes.oneOf(['compareHorizontal', 'compareVertical', 'default']),
  title: PropTypes.string,
};
ResultsTable.defaultProps = {
  tableVariant: 'default',
};
export default ResultsTable;
