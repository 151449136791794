import GuardedRoute from '../../Modules/GuardedRoute';
import EmptyLayout from '../../Layouts/EmptyLayout';
import { R_UM_ACCOUNTS, R_UM_STUDIOS } from '../../constants/routes';
import { Link, Switch, useRouteMatch } from 'react-router-dom';
import CreateAccountModal from './CreateAccount/CreateAccountModal';
import UserList from './UserList';
import StudioList from './StudioList';
import CreateStudioModal from './CreateStudio/CreateStudioModal';
import { Restricted } from '../../Components/permissions/UserPermissions';

const UserManagement = () => {
  return (
    <div className="container-fluid mb-4">
      <div className="row top-bar mb-4 px-md-2 bg-white">
        <div className="col-12 d-flex align-items-center">
          <div className="top-bar-tab-btns">
            <Link className={`btn ${useRouteMatch(R_UM_ACCOUNTS)?.isExact ? 'active' : ''}`} to={R_UM_ACCOUNTS}>
              Accounts
            </Link>
            <Link className={`btn ${useRouteMatch(R_UM_STUDIOS)?.isExact ? 'active' : ''}`} to={R_UM_STUDIOS}>
              Studios
            </Link>
          </div>
          <div className="ms-auto me-2">
            <CreateAccountModal />
          </div>
          <Restricted permission="studio.create">
            <CreateStudioModal />
          </Restricted>
        </div>
      </div>
      <Switch>
        <GuardedRoute
          admin
          exact
          layout={EmptyLayout}
          title="Account Management"
          path={R_UM_ACCOUNTS}
          component={UserList}
        />
        <GuardedRoute admin layout={EmptyLayout} title="Studio Management" path={R_UM_STUDIOS} component={StudioList} />
      </Switch>
    </div>
  );
};

export default UserManagement;
