import ArrowUpFull from '../../../../Components/Icons/ArrowUpFull';
import { useQuery } from '../../../../utils/customHooks';
import { useHistory } from 'react-router-dom';

function GamesSortBar() {
  const history = useHistory();
  const query = useQuery();
  const sort = query.get('sort') || 'dateDown';

  const handleSortChange = (name) => {
    let value;
    if (sort === name + 'Down') {
      value = name + 'Up';
    } else {
      value = name + 'Down';
    }
    query.set('sort', value);
    history.push(history.location.pathname + '?' + query.toString());
  };
  const RenderArrow = ({ name }) => {
    return (
      <span className="ms-2">
        {sort === name + 'Down' ? (
          <ArrowUpFull rotate="down" color="#2d3363" size={12} />
        ) : sort === name + 'Up' ? (
          <ArrowUpFull color="#2d3363" size={12} />
        ) : (
          <ArrowUpFull rotate="down" size={12} />
        )}
      </span>
    );
  };
  return (
    <>
      <tr className="">
        <th scope="col">
          <span className="text-nowrap cursor-pointer  fs-7" onClick={() => handleSortChange('name')}>
            Name
            <RenderArrow name="name" />
          </span>
        </th>
        <th scope="col">
          <span className="text-nowrap cursor-pointer fs-7" onClick={() => handleSortChange('date')}>
            Campaign Date
            <RenderArrow name="date" />
          </span>
        </th>
        <th scope="col" width={120} className="text-nowrap fs-7 text-center">
          Status
        </th>

        <th scope="col" width={88} className="text-center ">
          <span className="text-nowrap text-center cursor-pointer fs-7" onClick={() => handleSortChange('cpi')}>
            CPI
            <RenderArrow name="cpi" />
          </span>
        </th>

        <th scope="col" width={88} className="text-center ">
          <span className="text-nowrap text-center cursor-pointer fs-7" onClick={() => handleSortChange('d1')}>
            RR D1
            <RenderArrow name="d1" />
          </span>
        </th>

        <th scope="col" width={88} className="text-center">
          <span className="text-nowrap text-center cursor-pointer fs-7" onClick={() => handleSortChange('d7')}>
            RR D7
            <RenderArrow name="d7" />
          </span>
        </th>
      </tr>
    </>
  );
}

export default GamesSortBar;
