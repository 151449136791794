import { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import CancelIcon from '../../../Components/Icons/CancelIcon';
import { useGetMarketingPdfsQuery } from '../../../data/api/studioApi';
import UploadIcon from '../../../Components/Icons/UploadIcon';

const DropOrChooseFile = ({ setState }) => {
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState({
    error: '',
    success: '',
  });

  const { data: all } = useGetMarketingPdfsQuery();

  const onDropHandler = (ev) => {
    ev.preventDefault();

    let file = '';
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      file = [...ev.dataTransfer.items].find((item) => item.kind === 'file').getAsFile();
    } else {
      // Use DataTransfer interface to access the file(s)
      file = ev.dataTransfer.files[0];
    }
    setFiles([...files, file]);
  };
  const onDragOver = (ev) => ev.preventDefault();
  useEffect(() => {
    setState({ files: files, error: '' });

    if (files && files.length >= 1) {
      all.find((item) => {
        if (item.pdf_name !== files[0].name) {
          setMessage({ error: '' });
          return false;
        } else {
          setMessage({ error: "You can't upload the same file twice" });
          setFiles([]);
          return true;
        }
      });
    }
  }, [files, message.error]);

  const removeFile = () => {
    setFiles([]);
  };

  return (
    <div>
      {files[0] && !message.error ? (
        <div className="pdf-view-container position-relative">
          <Document file={files[0]}>
            <Page pageNumber={1} />
            <div onClick={removeFile} className="position-absolute top-0 end-0 me-2">
              <CancelIcon size={'1em'} color="#3f3f3f" onClick={removeFile} />
            </div>
          </Document>
        </div>
      ) : (
        <>
          <div id="drop_zone" onDrop={onDropHandler} onDragOver={onDragOver}>
            {message.error && (
              <div className="alert alert-danger mt-3 fs-7 " role="alert">
                {message.error || 'Fetching error.'}
              </div>
            )}

            <label
              htmlFor={'file_picker'}
              className="w-100 bg-light-400 rounded cursor-pointer
                    text-muted  fs-7 text-center
                    d-flex flex-column  justify-content-center"
              style={{ minHeight: 200 }}
            >
              <div className="mb-1">
                <UploadIcon />
              </div>
              Drag & Drop Video Files <br />
              or <br />
              <span className="btn-link text-primary">Chose File</span>
            </label>
            <input
              id="file_picker"
              type="file"
              accept="application/pdf"
              onChange={(ev) => {
                setFiles([...files, ev.target.files[0]]);
              }}
              style={{ display: 'none' }}
            ></input>
          </div>
        </>
      )}
    </div>
  );
};

export default DropOrChooseFile;
