import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import MetricVariation from './MetricVariation';

const COLORS = [
  '#002cff',
  '#B74FFF',
  '#ff9232',
  '#009881',
  '#9B1B34',
  '#6685ff',
  '#FFD152',
  '#bd34eb',
  '#F08080',
  '#cafcd7',
  '#ff3f5c',
  '#202655',
  '#db7024',
  '#0EFFD9',
  '#4d6f9d',
  '#ffe0ad',
  '#CEDC85',
];

const MetricsTableChart = ({ data, legendList }) => {
  const [lineProps, setLineProps] = useState(
    legendList.reduce(
      (a, key) => {
        a[key] = false;
        return a;
      },
      { hover: null },
    ),
  );

  const handleLegendMouseEnter = (e) => {
    if (!lineProps[e.dataKey]) {
      setLineProps({ ...lineProps, hover: e.dataKey });
    }
  };

  const handleLegendMouseLeave = () => {
    setLineProps({ ...lineProps, hover: null });
  };

  const selectBar = (e) => {
    let key = e.dataKey.replace('.value', '');
    setLineProps({
      ...lineProps,
      [key]: !lineProps[key],
      hover: null,
    });
  };

  const renderText = (value) => {
    return <span className="cursor-pointer">{value.replace('.value', '')}</span>;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      let pl = payload[0].payload;
      return (
        <div className="bg-white fs-7 border rounded px-2 py-1">
          <p className="mb-1 fs-6">Date: {pl?.date}</p>
          {legendList.map((key, index) => {
            let selectedData = pl[key];
            if (!selectedData || lineProps[key]) return null;
            let popup = selectedData?.popup;
            return (
              <div className="d-flex flex-grow-1 flex-nowrap align-items-center p-1">
                <span style={{ maxWidth: '200px' }} className="overflow-hidden text-truncate text-nowrap">
                  {key}
                </span>
                : {selectedData.value}
                {selectedData?.diffFromBase && (
                  <MetricVariation confidence={popup?.confidence} diffFromBase={selectedData?.diffFromBase} />
                )}
                {popup && (
                  <>
                    {popup?.confidence && (
                      <span className="ms-1 p-1 border rounded fs-7 ">
                        Certainty: {popup?.pValue > 1 ? 0 : (popup?.confidence * 100).toFixed(2) || 'N/A'}%
                      </span>
                    )}
                    {popup?.pValue && (
                      <span className="ms-1 p-1 border rounded fs-7 ">P-val:{popup?.pValue.toFixed(4)}</span>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="json-metrics-charts" style={{ position: 'relative', zIndex: 7, height: 500 }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 48,
            left: 260,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" angle={16} height={60} width={16} fontSize={12} dy={10} dx={8} />
          <YAxis fontSize={12} />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            wrapperStyle={{
              width: 260,
              left: 20,
              overflow: 'hidden',
            }}
            layout="vetical"
            verticalAlign="top"
            align="left"
            iconType={'circle'}
            onClick={selectBar}
            onMouseOver={handleLegendMouseEnter}
            onMouseOut={handleLegendMouseLeave}
            formatter={renderText}
          />
          {legendList.map((item, itemIndex) => (
            <Line
              key={itemIndex}
              hide={lineProps[item]}
              type="monotone"
              dataKey={item + '.value'}
              stroke={COLORS[itemIndex]}
              activeDot={{ r: 8 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

MetricsTableChart.propTypes = PropTypes.shape({
  date: PropTypes.string,
  baseCohort: PropTypes.shape({
    value: PropTypes.string.isRequired,
    diffFromBase: PropTypes.number.isRequired,
    className: PropTypes.string.isRequired,
    rest: PropTypes.object.isRequired,
    popup: PropTypes.shape({
      confidence: PropTypes.number.isRequired,
      diffFromBase: PropTypes.number.isRequired,
      pValue: PropTypes.number.isRequired,
      tTest: PropTypes.number.isRequired,
    }),
  }),
  inGameAds_: PropTypes.shape({
    value: PropTypes.string.isRequired,
    diffFromBase: PropTypes.number.isRequired,
    className: PropTypes.string.isRequired,
    rest: PropTypes.object.isRequired,
    popup: PropTypes.shape({
      confidence: PropTypes.number.isRequired,
      diffFromBase: PropTypes.number.isRequired,
      pValue: PropTypes.number.isRequired,
      tTest: PropTypes.number.isRequired,
    }),
  }),
  isCompletion: PropTypes.shape({
    value: PropTypes.string.isRequired,
    diffFromBase: PropTypes.number.isRequired,
    className: PropTypes.string.isRequired,
    rest: PropTypes.object.isRequired,
    popup: PropTypes.shape({
      confidence: PropTypes.number.isRequired,
      diffFromBase: PropTypes.number.isRequired,
      pValue: PropTypes.number.isRequired,
      tTest: PropTypes.number.isRequired,
    }),
  }),
});
export default MetricsTableChart;
