const CohortParamsInfo = ({ disableHeader, cohort }) => {
  return (
    <div className="table-responsive w-100">
      <table className="table fs-7 table-hover table-border-dashed m-0">
        {!disableHeader && (
          <thead>
            <tr>
              <th scope="col" width="50%">
                Params
              </th>
              <th scope="col " width="50%">
                Values
              </th>
            </tr>
          </thead>
        )}
        {cohort && (
          <tbody>
            {(cohort.params || []).map((param, index) => (
              <tr key={index}>
                <td>{param.name}</td>
                <td className="text-truncate">{param.value}</td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default CohortParamsInfo;
