import Form from 'react-bootstrap/Form';
import { GAME_CATEGORIES, GAME_KEYWORDS } from '../../../../constants/game';
import { BIZ_DEV_EMAIL_LIST } from '../../../../constants/global';
import { useListStudiosQuery } from '../../../../data/api/studioApi';
import { Col, InputGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import TagsDropdown from '../../../../Components/TagsDropdown';
import { useUserPermission } from '../../../../Components/permissions/UserPermissions';
import React from 'react';

function InfosView({ errors, data, setData, onNewGame }) {
  const selectStudioPermission = useUserPermission('select.studio');
  const { data: studioList } = useListStudiosQuery(undefined, {
    skip: !selectStudioPermission,
  });

  const handleSelectStudio = (studioId = '') => {
    setData({ studioId: studioId });
  };

  const handleTextChange = (text, field) => {
    return setData({
      [field]: text,
    });
  };

  const handleChangeKeyword = (keyword) => {
    if (!data.tags.includes(keyword)) {
      setData({ tags: [...data.tags, keyword].filter(Boolean) });
    } else {
      setData({ tags: data.tags.filter((el) => el !== keyword && el !== '') });
    }
  };

  return (
    <Form noValidate autoComplete={'off'}>
      <Row className="g-3">
        <Form.Group className="position-relative" as={Col} controlId="gameName">
          <Form.Label>
            Game Name <span className="text-danger fs-7">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            aria-describedby=""
            placeholder="Type name"
            value={data.appName}
            required
            isInvalid={errors.appName}
            onChange={(text) => handleTextChange(text.target.value, 'appName')}
            name="app-name"
          />

          <Form.Control.Feedback tooltip type="invalid">
            {errors.appName}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="position-relative" as={Col} sm={6} controlId="gameBundleId">
          <Form.Label>
            Bundle ID <span className="text-danger fs-7">*</span>
          </Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              type="text"
              className="form-control"
              aria-describedby=""
              placeholder="Bundle Id"
              value={data.bundleId}
              isInvalid={errors.bundleId}
              onChange={(text) => handleTextChange(text.target.value, 'bundleId')}
              disabled={!onNewGame}
              name="bundle-id"
            />
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip {...props}>
                  If you are already created your game in an another OS write the same bundle Id
                </Tooltip>
              )}
            >
              <InputGroup.Text className="cursor-pointer">
                <InfoIcon size={14} />
              </InputGroup.Text>
            </OverlayTrigger>
            <Form.Control.Feedback tooltip type="invalid">
              {errors.bundleId}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="position-relative" as={Col} xs={data.store === 'google' ? 12 : 6} controlId="targetOs">
          <Form.Label>
            Store <span className="text-danger fs-7">*</span>
          </Form.Label>
          <Form.Select
            aria-label="OS"
            value={data.store}
            onChange={(text) => handleTextChange(text.target.value, 'store')}
            isInvalid={errors.os}
            name="store"
            disabled={!onNewGame}
          >
            <option value={'google'}>Google</option>
            <option value={'apple'}>Apple</option>
            <option value={'amazon'}>Amazon</option>
          </Form.Select>
          <Form.Control.Feedback tooltip type="invalid">
            {errors.store}
          </Form.Control.Feedback>
        </Form.Group>

        {data.store !== 'google' && (
          <Form.Group className="position-relative" as={Col} sm={6} controlId="gameStoreId">
            <Form.Label>
              Store ID <span className="text-danger fs-7">*</span>
            </Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                className="form-control"
                aria-describedby="Store Id"
                placeholder="Type store Id"
                value={data.appId}
                isInvalid={errors.appId}
                onChange={(text) => handleTextChange(text.target.value, 'appId')}
                disabled={!onNewGame}
                name="store-id"
              />

              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => (
                  <Tooltip {...props}>Store id mean Apple Id - For Android, Store Id = Bundle Id</Tooltip>
                )}
              >
                <InputGroup.Text className="cursor-pointer">
                  <InfoIcon size={16} />
                </InputGroup.Text>
              </OverlayTrigger>
              <Form.Control.Feedback tooltip type="invalid">
                {errors.appId}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        )}

        <Form.Group as={Col} className="position-relative" controlId="gameEmail">
          <Form.Label>
            Contact Email <span className="text-danger fs-7">*</span>
          </Form.Label>
          <Form.Select
            required
            className="form-select"
            value={data.bizdevEmail}
            isInvalid={errors.bizdevEmail}
            onChange={(text) => handleTextChange(text.target.value, 'bizdevEmail')}
            name="email"
          >
            <option value={''}>Contact Email</option>
            {BIZ_DEV_EMAIL_LIST.map((value, index) => (
              <option value={value} key={index}>
                {value}
              </option>
            ))}
          </Form.Select>

          <Form.Control.Feedback tooltip type="invalid">
            {errors.bizdevEmail}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          as={Col}
          xs={selectStudioPermission ? 6 : 12}
          controlId="gameCategory"
          className="position-relative"
        >
          <Form.Label>Category</Form.Label>
          <Form.Select
            className="form-select"
            value={data.category}
            isInvalid={errors.category}
            onChange={(text) => handleTextChange(text.target.value, 'category')}
            name="category"
          >
            <option value={''}>Select Category</option>
            {GAME_CATEGORIES.map((value, index) => (
              <option key={index} value={value}>
                {value}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback tooltip type="invalid">
            {errors.category}
          </Form.Control.Feedback>
        </Form.Group>

        {selectStudioPermission && (
          <Form.Group className="position-relative" as={Col} xs={12} controlId="gameStudio">
            <Form.Label>
              Studio <span className="text-danger fs-7">*</span>
            </Form.Label>
            <Form.Select
              isInvalid={errors.studioId}
              className="form-select"
              value={data.studioId || ''}
              onChange={(e) => {
                handleSelectStudio(e.target.value);
              }}
              disabled={!onNewGame}
              name="studio"
            >
              <option value={''}>Assigne Studio</option>

              {studioList &&
                studioList.map((value, index) => {
                  return (
                    <option value={value.studioId} key={index}>
                      {value.name}
                    </option>
                  );
                })}
            </Form.Select>
            <Form.Control.Feedback tooltip type="invalid">
              {errors.studioId}
            </Form.Control.Feedback>
          </Form.Group>
        )}

        <Form.Group as={Col} xs={12} controlId="gameCategory">
          <Form.Label>Tags</Form.Label>

          <TagsDropdown
            onChange={handleChangeKeyword}
            values={data.tags}
            options={GAME_KEYWORDS}
            placeholder={'Select Tags'}
          />
        </Form.Group>
      </Row>
    </Form>
  );
}

export default InfosView;
