import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { hmac256 } from '../../utils/common';
const baseUrl = process.env.REACT_APP_APP_OPENAI_API;

export const openAiApi = createApi({
  reducerPath: 'openAiApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, api) => {
      //headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  keepUnusedDataFor: 600, // keep for 10min
  tagTypes: ['chatHistoryList'],
  endpoints: (builder) => ({
    imageGeneration: builder.mutation({
      query: (args) => {
        const url = `/image/generations`;
        return {
          url,
          method: 'post',
          body: args,
          headers: {
            Authorization: hmac256(args),
          },
        };
      },
    }),
    imageVariations: builder.mutation({
      query: ({ file, number, size }) => {
        const url = `/image/variations`;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('nbImages', number);
        formData.append('imageSize', size);
        return {
          url,
          method: 'post',
          body: formData,
          formData: true,
          headers: {
            Authorization: hmac256('file=icon&raw=binary'),
          },
        };
      },
    }),
    completion: builder.mutation({
      query: (args) => {
        const url = `/chat/message`;
        return {
          url,
          method: 'post',
          body: args,
          headers: {
            Authorization: hmac256(args),
          },
        };
      },
    }),
    getChatHistoryList: builder.query({
      query: (model) => ({
        url: '/chat/history?model=' + model,
        headers: {
          Authorization: hmac256(baseUrl + '/chat/history?model=' + model),
        },
      }),
      providesTags: ['chatHistoryList'],
    }),
    getChatHistory: builder.mutation({
      query: ({ title, model }) => ({
        url: '/chat/history/' + encodeURIComponent(title) + '?model=' + model,
        headers: {
          Authorization: hmac256(baseUrl + '/chat/history/' + encodeURIComponent(title) + '?model=' + model),
        },
      }),
    }),
    updateChatTitle: builder.mutation({
      query: (args) => ({
        url: '/chat/history',
        method: 'put',
        body: args,
        headers: {
          Authorization: hmac256(args),
        },
      }),
      invalidatesTags: (result) => (result ? ['chatHistoryList'] : []),
    }),

    deleteChatHistory: builder.mutation({
      query: ({ title, model }) => ({
        url: '/chat/history/' + encodeURIComponent(title),
        method: 'delete',
        body: { model },
        headers: {
          Authorization: hmac256(baseUrl + '/chat/history/' + encodeURIComponent(title)),
        },
      }),
      invalidatesTags: (result) => (result ? ['chatHistoryList'] : []),
    }),
  }),
});

export const {
  useImageGenerationMutation,
  useImageVariationsMutation,
  useCompletionMutation,
  useGetChatHistoryListQuery,
  useUpdateChatTitleMutation,
  useGetChatHistoryMutation,
  useDeleteChatHistoryMutation,
} = openAiApi;
