import { useParams } from 'react-router-dom';
import './gameList.scss';
import { useQuery, useSetState } from '../../../utils/customHooks';
import GameListTable from './GamesListTable/GameListTable';
import GameSearchFilter from '../../../Modules/GameSearchFilter';
import GameStatusFilter from './GameStatusFilter';
import DraftGameListTable from './DraftGameListTable/DraftGameListTable';
import DraftSortBar from './DraftGameListTable/DraftSortBar';
import GamesSortBar from './GamesListTable/GamesSortBar';
import Pagination from '../../../Components/Pagination';
import CreateGameModal from '../CreateGame/CreateGameModal';

function Home() {
  const { filter } = useParams();
  const query = useQuery();
  const [state, setState] = useSetState({
    sortedGames: [],
    error: '',
    postsPerPage: 15,
    total: 0,
  });

  return (
    <>
      <div className="container-fluid d-flex flex-column">
        <div className="row top-bar align-items-center  flex-wrap bg-white">
          <div className="col-lg-12 d-flex align-items-center game-wrapper">
            <GameStatusFilter setData={setState} />
            <div className="ms-lg-auto d-flex">
              <CreateGameModal />
            </div>
          </div>
        </div>
        <div className="row px-md-2">
          <div className="col-12 bg-body">
            <div className="flex-lg-row flex-column d-flex ">
              <GameSearchFilter />
              <div className="ms-auto mt-2">
                <Pagination total={state.total} perPage={state.postsPerPage} page={Number(query.get('page')) || 1} />
              </div>
            </div>
            <div className="table-responsive mt-2" id="game-table">
              <table className="table table-hover align-middle rounded overflow-hidden bg-white tap-n-table">
                <thead>{filter === 'draft' ? <DraftSortBar /> : <GamesSortBar />}</thead>
                <tbody>
                  {filter === 'draft' ? (
                    <DraftGameListTable data={state} setData={setState} />
                  ) : (
                    <GameListTable data={state} setData={setState} />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
