import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import { CommentDraftGame } from '../data/api/Querys';
import { useSetState } from '../utils/customHooks';
import { studioApi } from '../data/api/studioApi';
import { useDispatch } from 'react-redux';
import { confirmAlert } from '../Components/ConfirmModal';

function CommentModal({ data, onClose }) {
  const dispatch = useDispatch();
  const { commentText, commentInfos, commentAppId } = data;

  const [state, setState] = useSetState({
    loading: false,
    comment: commentText,
  });

  useEffect(() => {
    if (commentText) {
      setState({ comment: commentText });
    }
  }, [commentText]);

  const handleTextChange = (event) => {
    setState({ comment: event.target.value });
  };

  const handleSave = () => {
    if (commentText) {
      CommentDraftGame(commentAppId, state.comment).then(() => {
        confirmAlert({
          title: 'You have successfully created AB test',
          variant: 'success',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch((err) => {});
        dispatch(studioApi.util.invalidateTags(['draftGames']));
        onClose();
      });
    }
  };

  return (
    <>
      <Modal show onHide={onClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Notes for {commentInfos}</Modal.Title>
        </Modal.Header>

        <Form className="idea-comment-modal">
          <Modal.Body>
            <div className="row">
              <div className="col-sm-12">
                <Form.Group className="form-group" controlId="formBasicSelect">
                  <Form.Label className="col-form-label">Select Norm Type</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    value={state.comment}
                    className="idea-comment-form-control"
                    onChange={handleTextChange}
                  />
                </Form.Group>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button type="button" onClick={onClose} className="btn btn-txt" data-bs-dismiss="modal">
              ABORT
            </button>
            <button type="button" onClick={handleSave} className="btn btn-next" data-bs-dismiss="modal">
              SAVE
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default CommentModal;
