import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import EditIcon from '../../../Components/Icons/EditIcon';
import Loader from '../../../Components/Loader';
import Form from 'react-bootstrap/Form';
import { useUpdateUserMutation } from '../../../data/api/userApi';
import { useSetState } from '../../../utils/customHooks';
import { confirmAlert } from '../../../Components/ConfirmModal';
import AccountForm from './AccountForm';
import { formatJoiErrorsArray } from '../../../utils/validation';
import updateAccountSchema from './_validationSchemas/updateAccountSchema';
import { useListStudiosQuery } from '../../../data/api/studioApi';

function UpdateAccountModal({ data }) {
  const [updateUser, { isLoading, error }] = useUpdateUserMutation();
  const { data: studioList } = useListStudiosQuery();
  const [errors, setErrors] = useState({});

  const [showForm, setShowForm] = useState(false);
  const [state, setState] = useSetState({
    firstName: '',
    lastName: '',
    role: 'USER',
    studioIds: [],
    error: '',
    status: 'status',
    email: '',
    disabledStudios: [],
  });

  useEffect(() => {
    if (showForm && data && studioList) {
      let disabledStudios = [];
      Object.entries(data.studios).forEach(
        (el) =>
          !studioList.some((studio) => studio.studioId === el[0]) &&
          disabledStudios.push({ value: el[0], label: el[1] }),
      );

      setState({
        firstName: data.first_name,
        lastName: data.last_name,
        role: data.role,
        studioIds: Object.keys(data.studios),
        error: '',
        status: data.active,
        email: data.email,
        disabledStudios,
      });
    }
  }, [data, showForm, studioList]);

  const handleClose = () => {
    setShowForm(false);
  };

  const handleTextChange = (text, field) => {
    if (field === 'status') {
      setState({ [field]: text === 'Active' ? 1 : 0 });
    } else {
      setState({ [field]: text });
    }
    if (errors && errors[field]) {
      setErrors({ ...errors, [field]: '' });
    }
  };

  const handleUpdateAccount = (e) => {
    e.preventDefault();
    const formatUpdateAccountValidation = (details) => {
      let err = details.map((error) => {
        return {
          path: error.path,
          message: error.message,
        };
      });
      return formatJoiErrorsArray(err);
    };

    const { error } = updateAccountSchema.validate(state);
    if (error) {
      return setErrors(formatUpdateAccountValidation(error.details));
    }
    let deletedStudios = [];
    if (data?.studios) {
      deletedStudios = Object.keys(data.studios).filter((id) => !state.studioIds.includes(id));
    }

    updateUser({
      id: data.id,
      email: state.email,
      first_name: state.firstName,
      last_name: state.lastName,
      role: state.role,
      studio_ids: state.studioIds,
      status: state.status,
      studios_deleted: deletedStudios,
    })
      .unwrap()
      .then(() => {
        confirmAlert({
          title: 'Account has been successfully updated',
          variant: 'success',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
        handleClose();
      })
      .catch((err) => {
        if (err?.data?.details) {
          return setErrors(formatUpdateAccountValidation(err.data.details));
        }
      });
  };

  return (
    <>
      <Button
        type="button"
        variant="info"
        className="d-flex"
        onClick={() => setShowForm(true)}
        id="update-modal-open-btn"
      >
        <EditIcon size={20} />
      </Button>
      {showForm && (
        <Form onSubmit={handleUpdateAccount}>
          <Modal centered dialogClassName="modal-md" show={showForm} onHide={handleClose} id="update-user-modal">
            <Modal.Header closeButton>
              <Modal.Title>Update User Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                {isLoading ? (
                  <div className="d-flex justify-content-center w-100">
                    <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
                  </div>
                ) : (
                  <>
                    {error && <div className="alert alert-danger fs-7">{error?.data?.message}</div>}
                    <AccountForm
                      state={state}
                      setState={setState}
                      errors={errors}
                      handleTextChange={handleTextChange}
                      data={data}
                      disabledStudios={state.disabledStudios}
                    />
                  </>
                )}
              </>
            </Modal.Body>
            <Modal.Footer className="d-flex">
              <div className="ms-auto">
                <Button type="button" variant="outline-light" className=" ms-auto" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="button" variant="primary" className=" ms-3" onClick={handleUpdateAccount} id="update-btn">
                  Update
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </>
  );
}

export default UpdateAccountModal;
