import FolderIcon from '../../Components/Icons/FolderIcon';
import ImageUrlApiProxy from '../ImageUrlApiProxy';
import * as PropTypes from 'prop-types';

const DriveFileCard = ({ border, height, width, file }) => {
  return (
    <div
      style={{ width }}
      className={`cursor-pointer overflow-hidden d-flex flex-column border-2 border rounded ${border} `}
    >
      <div className="d-flex justify-content-center ">
        {file.mimeType === 'application/vnd.google-apps.folder' ? (
          <div className="p-2">
            <FolderIcon size={90} />
          </div>
        ) : (
          <ImageUrlApiProxy
            height={height}
            className="img-fit-contain"
            url={file.thumbnailLink}
            alt={file.name}
            title={file.name}
          />
        )}
      </div>
      <p className="text-truncate m-0 py-1 px-2 border-top ">{file.name}</p>
    </div>
  );
};
DriveFileCard.propTypes = {
  border: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  file: PropTypes.object,
};
DriveFileCard.defaultProps = {
  border: '',
  height: 90,
  width: null,
};
export default DriveFileCard;
