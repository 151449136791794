import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { hmac256 } from '../../utils/common';
import { snakeCaseToCamelObj, snakeToCamelRec } from '../../utils/converters';
import { current } from '@reduxjs/toolkit';

const baseUrl = process.env.REACT_APP_ABTESTS_API;

export const abTestsApi = createApi({
  reducerPath: 'abTestsApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['gameAbtests', 'gameAbtestsMetrics', 'cohorts'],
  keepUnusedDataFor: 600, //keep data for 10 min
  endpoints: (builder) => ({
    getAbTestGames: builder.query({
      query: () => ({
        url: '/get_all_abtest_game',
        headers: { Authorization: hmac256(baseUrl + '/get_all_abtest_game') },
      }),
      transformResponse: (response) => {
        let games = snakeToCamelRec(response);
        let parsedArray = [];
        let bundlesIds = {};

        games.forEach((value) => {
          let cur = bundlesIds[value.bundleId];
          if (cur !== undefined) cur[value.os] ? cur[value.os].push(value) : (cur[value.os] = [value]);
          else
            bundlesIds[value.bundleId] = {
              [value.os]: [{ ...value }],
            };
        });

        for (const key in bundlesIds) {
          let games = bundlesIds[key];
          let game = {};
          if (games.ios) {
            game = { ...games.ios[0], multipleOs: false };
          }
          if (games.android) {
            if (game.os) game.multipleOs = true;

            // use android as default
            games.android.forEach((el) => {
              if (el?.store === 'google') game = { ...game, ...el };
              else if (el?.store === 'amazon') parsedArray.push({ ...el, multipleOs: false });
            });
          }
          if (game.bundleId) parsedArray.push(game);
        }
        return parsedArray;
      },
    }),
    getGameAbtests: builder.query({
      query: ({ bundleId, store }) => ({
        url: `/tests/${bundleId}` + (store ? `?store=${store}` : ''),
        headers: { Authorization: hmac256(baseUrl + `/tests/${bundleId}` + (store ? `?store=${store}` : '')) },
      }),
      providesTags: (result, error, { bundleId, store }) =>
        result ? [{ type: 'gameAbtests', id: bundleId?.toLowerCase() + ':' + store }] : [],
      transformResponse: (response) => {
        return response
          .map((el) => snakeCaseToCamelObj(el))
          .sort((a, b) => (a.endTs < b.endTs ? 1 : b.endTs < a.endTs ? -1 : 0));
      },
    }),

    getGameConfigs: builder.query({
      query: ({ bundleId, store }) => ({
        url: `/configs/${bundleId}` + (store ? `?store=${store}` : ''),
        headers: { Authorization: hmac256(baseUrl + `/configs/${bundleId}` + (store ? `?store=${store}` : '')) },
      }),
      providesTags: (result, error, { bundleId, store }) =>
        result ? [{ type: 'gameConfigs', id: bundleId?.toLowerCase() + ':' + store }] : [],
      transformResponse: (response) => {
        return response
          .map((el) => snakeCaseToCamelObj(el))
          .sort((a, b) => (a.endTs < b.endTs ? 1 : b.endTs < a.endTs ? -1 : 0));
      },
    }),

    getCohorts: builder.query({
      query: (id) => ({
        url: `/cohorts/${id}`,
        headers: { Authorization: hmac256(baseUrl + `/cohorts/${id}`) },
      }),
      providesTags: (result, error, id) => (result ? [{ type: 'cohorts', id: id }] : []),
      transformResponse: (response) => {
        return response.sort((a, b) => {
          if (a.name === 'BaseCohort') return -1;
          if (b.name === 'BaseCohort') return 1;
          return 0;
        });
      },
    }),

    disableCohort: builder.mutation({
      query: ({ testId, bundleId, id, active }) => ({
        url: `/cohorts/status`,
        method: 'PUT',
        headers: { Authorization: hmac256({ active, testId, bundleId, id }) },
        body: { active, testId, bundleId, id },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { testId, id, active } = args;
        const patchResult = dispatch(
          abTestsApi.util.updateQueryData('getCohorts', testId, (draft) =>
            current(draft)?.map((cohort) => (cohort.id === id ? { ...cohort, active } : cohort)),
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    getGameAbTestMetrics: builder.query({
      query: ({ cohortId, bundleId, startTs, os = 'android', countryIso = '' }) => {
        const url = `/report/${bundleId}/${startTs}/${cohortId}/${os}?country_iso=${countryIso}`;
        return {
          url: url,
          headers: { Authorization: hmac256(baseUrl + url) },
        };
      },
      providesTags: (result) => (result ? ['gameAbtestsMetrics'] : []),
    }),

    getGameAbTestMetricsOld: builder.query({
      query: ({ cohortId, os = 'android', countryIso = '' }) => {
        const url = `/report/${cohortId}/${os}/${countryIso ? countryIso : ''}`;
        return {
          url: url,
          headers: { Authorization: hmac256(baseUrl + url) },
        };
      },
      providesTags: (result) => (result ? ['gameAbtestsMetrics'] : []),
    }),

    eligibleToJsonMetrics: builder.query({
      query: (bundleId) => ({
        url: `/json/report/check/${bundleId}`,
        headers: { Authorization: hmac256(baseUrl + `/json/report/check/${bundleId}`) },
      }),
    }),

    getGameNetworks: builder.query({
      query: (getBundleId) => ({
        url: '/json/report/networks' + (getBundleId ? '/' + getBundleId : ''),
        headers: { Authorization: hmac256(baseUrl + '/json/report/networks' + (getBundleId ? '/' + getBundleId : '')) },
      }),
    }),

    getGameAbTestJsonMetrics: builder.query({
      query: ({ abTestId, country, idfa, build, os, from, to, network, ads, outliers, mediation }) => {
        let params = [];
        if (country) params.push('country=' + country);
        if (network) params.push('network=' + network);
        if (mediation) params.push('mediation=' + mediation);
        if (idfa) params.push('has_idfa=' + idfa);
        if (build) params.push('min_build=' + build);
        if (ads) params.push('extra_rev_partners=' + ads);
        if (outliers) params.push('outliers=' + outliers);

        const url = `/json/report/${abTestId}/${os}/${from}/${to}?${params.join('&')}`;
        return {
          url: url,
          headers: { Authorization: hmac256(baseUrl + url) },
        };
      },
      transformResponse: (response) => ({
        ...response,
        summary:
          response?.summary?.sort((a, b) => {
            return a?.user_cohort?.toLowerCase() === 'basecohort'
              ? -1
              : b?.user_cohort?.toLowerCase() === 'basecohort'
                ? 1
                : a?.user_cohort.localeCompare(b?.user_cohort);
          }) || [],
        dates: !response?.dates
          ? null
          : Object.keys(response?.dates)
              .sort((a, b) => new Date(a) - new Date(b))
              .reduce((obj, key) => {
                //sort each cohort in date
                obj[key] = Object.keys(response?.dates[key])
                  .sort((a, b) =>
                    a.toLowerCase() === 'basecohort' ? -1 : b.toLowerCase() === 'basecohort' ? 1 : a.localeCompare(b),
                  )
                  .reduce((obj, key2) => {
                    obj[key2] = response?.dates[key][key2];
                    return obj;
                  }, {});
                return obj;
              }, {}),
      }),
      providesTags: (result) => (result ? ['gameAbtestsMetrics'] : []),
    }),
    createGameAbTest: builder.mutation({
      query: (args) => {
        return {
          url: '/tests',
          method: 'post',
          body: args,
          headers: { Authorization: hmac256(args) },
        };
      },
      invalidatesTags: (result, error, args) => {
        return result ? [{ type: 'gameAbtests', id: args.app_id?.toLowerCase() }] : [];
      },
    }),

    createGameConfig: builder.mutation({
      query: (args) => {
        return {
          url: '/configs',
          method: 'post',
          body: args,
          headers: { Authorization: hmac256(args) },
        };
      },
      invalidatesTags: (result) => {
        return result ? [{ type: 'gameConfigs' }] : [];
      },
    }),
    changeAbTestStatus: builder.mutation({
      query: (args) => {
        return {
          url: '/change_status',
          method: 'post',
          body: args,
          headers: { Authorization: hmac256(args) },
        };
      },
      async onQueryStarted(args, { getState, dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          for (const { endpointName, originalArgs } of abTestsApi.util.selectInvalidatedBy(getState(), [
            { type: 'gameAbtests' },
          ])) {
            if (endpointName !== 'getGameAbtests') continue;
            dispatch(
              abTestsApi.util.updateQueryData('getGameAbtests', originalArgs, (draft) => {
                let index = draft.findIndex((el) => el.id === args.id);
                if (index > -1) {
                  if (args.isStatusChanged) {
                    draft[index].status = args.status;
                  } else {
                    draft[index].quality = args.quality;
                  }
                }
              }),
            );
          }
        } catch {}
      },
    }),

    changeABTestGroup: builder.mutation({
      query: (args) => {
        return {
          url: `/update/${args.type?.toLowerCase()}`,
          method: 'put',
          body: args,
          headers: { Authorization: hmac256(args) },
        };
      },
      invalidatesTags: (result, error, args) => {
        return result && args.params
          ? [
              {
                type: args.type === 'CONFIG' ? 'gameConfigs' : args.type === 'ABTEST' ? 'gameAbtests' : 'cohorts',
              },
            ]
          : [];
      },
      async onQueryStarted(args, { getState, dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          const affectedEndpoints = [
            { type: 'gameAbtests', action: 'getGameAbtests' },
            { type: 'gameConfigs', action: 'getGameConfigs' },
            { type: 'cohorts', action: 'getCohorts' },
          ];

          for (const { type, action } of affectedEndpoints) {
            const endpointData = abTestsApi.util.selectInvalidatedBy(getState(), [{ type }]);
            for (const { endpointName, originalArgs } of endpointData) {
              if (endpointName === action) {
                dispatch(
                  abTestsApi.util.updateQueryData(action, originalArgs, (draft) => {
                    const targetItemIndex = draft.findIndex((el) => el.id === args.id);
                    if (targetItemIndex !== -1) {
                      const targetItem = draft[targetItemIndex];
                      if (args.status) targetItem.status = args.status;
                      if (args.quality) targetItem.quality = args.quality;
                      if (args.deviceId) targetItem.deviceId = args.deviceId;
                      if (args.startTs) targetItem.startTs = args.startTs;
                      if (args.endTs) targetItem.endTs = args.endTs;
                      if (args.country) targetItem.country = args.country;
                      if (args.cohort) targetItem.cohort = args.cohort;
                      if (args.name) targetItem.name = args.name;
                      if (args.test_id) targetItem.test_id = args.test_id;
                      if (args.params) {
                        targetItem.params = [
                          ...targetItem.params.filter((el) => !args.paramsDeleted.includes(el.id)),
                          ...args.params,
                        ];
                      }
                    }
                    return draft;
                  }),
                );
              }
            }
          }
        } catch (error) {
          console.error('An error occurred:', error);
        }
      },
    }),

    updateAbTestConfig: builder.mutation({
      query: (args) => {
        return {
          url: '/',
          method: 'put',
          body: args,
          headers: { Authorization: hmac256(args) },
        };
      },
      invalidatesTags: (result, error, args) => {
        return result ? [{ type: 'gameAbtests', id: args.app_id?.toLowerCase() }] : [];
      },
    }),
    listStudios: builder.query({
      query: () => ({
        url: '/list_studios',
        headers: { Authorization: hmac256(baseUrl + '/list_studios') },
      }),
    }),
    getRunningTests: builder.query({
      query: () => ({
        url: `/running_tests`,
        headers: { Authorization: hmac256(baseUrl + `/running_tests`) },
      }),
      transformResponse: snakeToCamelRec,
    }),
  }),
});

export const {
  useGetAbTestGamesQuery,
  useGetCohortsQuery,
  useGetGameAbtestsQuery,
  useGetGameConfigsQuery,
  useGetGameAbTestMetricsQuery,
  useDisableCohortMutation,
  useGetGameAbTestJsonMetricsQuery,
  useGetGameAbTestMetricsOldQuery,
  useChangeABTestGroupMutation,
  useCreateGameAbTestMutation,
  useCreateGameConfigMutation,
  useEligibleToJsonMetricsQuery,
  useGetGameNetworksQuery,
  useGetRunningTestsQuery,
} = abTestsApi;
