import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import TnTable from '../../Components/Table/TnTable';
import GameOsIcons from '../AbTest/_Components/GameOsIcons';
import TapNationPng from '../../assets/logoTapNation/LogoTapNation40.png';
import TapNationLogo from '../../assets/logoTapNation/LogoTapNation40.png';
import { useGetRunningTestsQuery } from '../../data/api/abTestsApi';
import { generatePath, useHistory } from 'react-router-dom';
import { R_AB_TEST_METRICS } from '../../constants/routes';

function DashboardTable() {
  const { data } = useGetRunningTestsQuery({});
  const [showModal, setShowModal] = useState(false);
  const [transformedData, setTransformedData] = useState([]);
  let history = useHistory();

  const handleSeeAbTests = (el) => {
    history.push(
      generatePath(R_AB_TEST_METRICS, {
        gameId: el.gameID,
        testId: el.id,
      }),
    );
  };
  useEffect(() => {
    if (data) {
      const transformedData = data.map((test) => {
        const firstGame = test.game[0];
        return {
          ...test,
          startTs: <div className="text-nowrap">{moment(test.startTs).format('ll')}</div>,
          endTs: <div className="text-nowrap">{moment(test.endTs).format('ll')}</div>,
          gameOs: <GameOsIcons item={test} width="40px" height="30px" />,
          game: (
            <div className="d-flex">
              <div className="overflow-hidden text-nowrap me-3 flex-shrink-0" style={{ width: 56 }}>
                <img
                  width={56}
                  height={56}
                  className="rounded img-fit-contain"
                  src={firstGame.logoUrl === null || firstGame.logoUrl === '' ? TapNationPng : firstGame.logoUrl}
                  alt={firstGame.appName}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = TapNationLogo;
                  }}
                />
              </div>
              <div>
                <h6>{firstGame.appName}</h6>
                {test.bundleId}
              </div>
            </div>
          ),
          gameAppName: firstGame ? firstGame.appName : null,
          gameID: firstGame ? firstGame.id : null,
        };
      });
      setTransformedData(transformedData);
    }
  }, [data]);

  const header = [
    { key: 'game', label: 'Logo' },
    { key: 'name', label: 'Test' },
    { key: 'gameOs', label: 'Os' },
    { key: 'startTs', label: 'Start' },
    { key: 'endTs', label: 'End' },
  ];

  return (
    <div className="w-100">
      <p>Currently Running Tests: {data?.length}</p>
      <div className="d-flex">
        <Button variant="info" className="ms-auto" onClick={() => setShowModal(true)}>
          Show list
        </Button>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Running A/B Test List</Modal.Title>
        </Modal.Header>
        <div className=""></div>
        <Modal.Body className="overflow-x-hidden overflow-x-scroll">
          <TnTable onRowClick={handleSeeAbTests} header={header} data={transformedData} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DashboardTable;
