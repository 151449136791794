import * as PropTypes from 'prop-types';

const CopyIcon = ({ size, color }) => {
  return (
    <svg fill={color} height={size} width={size} viewBox="0 0 200 200">
      <path d="M145,17.5H90a30.09,30.09,0,0,0-30,30H55a30.09,30.09,0,0,0-30,30v75a30.09,30.09,0,0,0,30,30h55a30.09,30.09,0,0,0,30-30h5a30.09,30.09,0,0,0,30-30v-75a30.09,30.09,0,0,0-30-30Zm-25,135a10,10,0,0,1-10,10H55a10,10,0,0,1-10-10v-75a10,10,0,0,1,10-10h55a10,10,0,0,1,10,10Zm35-30a10,10,0,0,1-10,10h-5v-55a30.09,30.09,0,0,0-30-30H80a10,10,0,0,1,10-10h55a10,10,0,0,1,10,10v75Zm-55,10H65a10,10,0,0,0,0,20h35a10,10,0,0,0,0-20Zm0-30H65a10,10,0,0,0,0,20h35a10,10,0,0,0,0-20Z" />
    </svg>
  );
};
CopyIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

CopyIcon.defaultProps = {
  size: 24,
  color: 'currentColor',
};

export default CopyIcon;
