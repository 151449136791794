import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import moment from 'moment';
import './marketingInsight.scss';
import { MONTHS } from '../../constants/global';
import { useSetState } from '../../utils/customHooks';
import Loader from '../../Components/Loader';
import DeleteIcon from '../../Components/Icons/DeleteIcon';
import DocumentDownloadIcon from '../../Components/Icons/DocumentDownloadIcon';
import UploadPdfModal from './UploadPdf/UploadPdfModal';
import { confirmAlert } from '../../Components/ConfirmModal';
import { DeletePDF } from '../../data/api/Querys';
import { useDeleteMarketingPdfMutation, useGetMarketingPdfsQuery } from '../../data/api/studioApi';
import PdfList from './_Components/PdfList';
import PdfViewer from './_Components/PdfViewer';
import { useDownloadPdfQuery } from '../../data/api/googleCloudApi';
import { Restricted } from '../../Components/permissions/UserPermissions';

function MarketInsight() {
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [filter, setFilter] = useSetState({ year: '', month: '' });

  const { data: pdfs, isLoading: isLoadingPdfs, refetch: refetchPdfs } = useGetMarketingPdfsQuery();
  const { data: downloadedPdf, isFetching, error } = useDownloadPdfQuery(selectedPdf?.url, { skip: !selectedPdf });
  const [deletePdf, { isLoading: isDeleting }] = useDeleteMarketingPdfMutation();
  const handleDownloadbtn = () => {
    let alink = document.createElement('a');
    alink.href = downloadedPdf;
    alink.download = selectedPdf.name + moment(selectedPdf?.date || new Date()).format('ll') + '.pdf';
    alink.click();
  };

  const filteredPdfs = (pdfs || [])
    .filter((el) => (filter.year ? Number(filter.year) === moment(el.date).year() : true))
    .filter((el) => (filter.month ? Number(filter.month) === moment(el.date).month() : true));

  const handleDeletePdf = () => {
    if (!selectedPdf) {
      return;
    }
    confirmAlert({
      title: 'Are you sure you?',
      desc: 'Delete pdf: ' + selectedPdf.name,
      variant: 'danger',
    })
      .then(() => {
        DeletePDF(selectedPdf.url); // needs fail catch
        deletePdf(selectedPdf.id)
          .unwrap()
          .then(() => {
            refetchPdfs();
            setSelectedPdf(null);
          })
          .catch((error) => console.log('error', error));
      })
      .catch(() => {});
  };
  return (
    <div className="container-fluid">
      <Restricted permission="PDF.create">
        <div className="row top-bar align-items-center">
          <div className="col-xl-12 d-flex">
            <div className="ms-auto d-flex">
              <UploadPdfModal />
              <Button variant="danger" className="ms-3" disabled={!selectedPdf} onClick={handleDeletePdf}>
                <DeleteIcon size={20} color="#fff" /> Delete
              </Button>
            </div>
          </div>
        </div>
      </Restricted>

      <div className="row data-filter-container my-3">
        <div className="col-12 d-flex flex-wrap">
          <Form.Select
            className="bg-white nav-item"
            value={filter.year}
            onChange={(e) => setFilter({ year: e.target.value })}
          >
            <option defaultValue value={''}>
              Year
            </option>
            {[2024, 2023, 2022, 2021].map((value, index) => (
              <option value={value} key={index}>
                {value}
              </option>
            ))}
          </Form.Select>
          <Form.Select
            className="bg-white  nav-item"
            value={filter.month}
            onChange={(e) => setFilter({ month: e.target.value })}
          >
            <option defaultValue value={''}>
              Month
            </option>
            {MONTHS.map((value, index) => (
              <option value={index} key={index}>
                {value}
              </option>
            ))}
          </Form.Select>

          <div className="ms-auto">
            <Button
              onClick={() => {
                handleDownloadbtn();
              }}
              disabled={!downloadedPdf}
            >
              <DocumentDownloadIcon color="#fff" /> Download PDF
            </Button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex flex-wrap">
          {isLoadingPdfs ? (
            <div className="d-flex justify-content-center w-100">
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : filteredPdfs?.length > 0 ? (
            <>
              <PdfList pdfs={filteredPdfs} selected={selectedPdf} onSelect={setSelectedPdf} />
              {isFetching || isDeleting ? (
                <div className="d-flex justify-content-center flex-grow-1">
                  <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
                </div>
              ) : error ? (
                'Pdf not found'
              ) : (
                downloadedPdf && <PdfViewer pdf={downloadedPdf} />
              )}
            </>
          ) : (
            <p className="fs-4 ">No PDF found...!</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default MarketInsight;
