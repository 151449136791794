import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { hmac256 } from '../../utils/common';
const baseUrl = process.env.REACT_APP_FG_AI_API;

export const aiAgentApi = createApi({
  reducerPath: 'aiAgentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    sendMessage: builder.mutation({
      query: (args) => ({
        url: '/agent/ask',
        method: 'POST',
        body: args,
        headers: {
          Authorization: hmac256(args),
        },
      }),
    }),
    sendMessage2: builder.query({
      query: (args) => ({
        url: '/agent/ask',
        method: 'POST',
        body: args,
        headers: {
          Authorization: hmac256(args),
        },
      }),
    }),
  }),
});

export const { useSendMessageMutation, useSendMessage2Query } = aiAgentApi;
