import { Button, Form, Alert } from 'react-bootstrap';

const Params = ({ errors, paramsArray, handleParamTextChange, handleAddParam, handleDeleteParam }) => {
  const handleTextChange = (e, index) => {
    let text = e.target.value.match(/([a-zA-Z0-9_-])/g)?.join('') || '';
    if (text || text === '') {
      handleParamTextChange(text, 'name', index);
    }
  };
  const handleAdd = () => {
    handleAddParam();
  };
  return (
    <>
      <div className="d-flex mb-1">
        <Form.Label className="fs-7 flex-grow-1">Param Name</Form.Label>
        <Form.Label className="fs-7 flex-grow-1">Param Value</Form.Label>
        <div style={{ width: 30 }} />
      </div>
      {paramsArray.length > 0 &&
        paramsArray.map((elem, index) => {
          return (
            <div key={index} className="mb-2 d-flex align-items-center">
              <Form.Group
                className="position-relative me-3 flex-grow-1 flex-basis-0"
                controlId={`cohortParamName${index}`}
              >
                <Form.Control
                  type="text"
                  placeholder="Param Name"
                  value={elem.name}
                  isInvalid={errors[index + '.name'] || errors['params.' + index + '.name']}
                  onChange={(e) => handleTextChange(e, index)}
                  name="param-name"
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors[index + '.name'] || errors['params.' + index + '.name']}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="position-relative me-2 flex-grow-1 flex-basis-0 "
                controlId={`cohortParamValue${index}`}
              >
                <Form.Control
                  type="text"
                  placeholder="Param Value"
                  value={elem.value}
                  isInvalid={errors[index + '.value'] || errors['params.' + index + '.value']}
                  onChange={(text) => handleParamTextChange(text.target.value, 'value', index)}
                  name="param-value"
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors[index + '.value'] || errors['params.' + index + '.value']}
                </Form.Control.Feedback>
              </Form.Group>
              <div style={{ width: 30 }}>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => {
                    handleDeleteParam(index);
                  }}
                  disabled={paramsArray.length === 1}
                >
                  -
                </Button>
              </div>
            </div>
          );
        })}
      <Button
        variant="outline-secondary"
        className="w-100 mb-2"
        disabled={!paramsArray[paramsArray.length - 1]?.['name'] || !paramsArray[paramsArray.length - 1]?.['value']}
        size="sm"
        onClick={handleAdd}
        id="add-params"
      >
        + Add more parameters
      </Button>
      {errors?.params && (
        <Alert className="fs-8 p-2" variant="danger">
          {errors.params}
        </Alert>
      )}
      {errors?.['array.max'] && (
        <Alert className="fs-8 p-2" variant="danger">
          {errors['array.max']}
        </Alert>
      )}
    </>
  );
};

export default Params;
