import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

const TagsDropdown = ({ values, options, onChange, placeholder = 'Select Tags', placement = 'auto', ...rest }) => {
  const popover = (
    <Popover style={{ maxWidth: '400px' }} className="shadow">
      <Popover.Body className={'d-flex flex-wrap '}>
        {options.map((option) => (
          <Button
            key={option}
            size="sm"
            variant={values.includes(option) ? 'success' : 'light'}
            onClick={() => onChange(option)}
            className="fs-6  m-1 "
          >
            {option}
          </Button>
        ))}
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger rootClose rootCloseEvent="mousedown" trigger="click" placement={placement} overlay={popover}>
      <div className="p-2 fs-7 text-muted  border border-1 border cursor-pointer rounded tags text-nowrap text-truncate">
        {values.length ? <>{values.join(', ')}</> : <span className="text-muted">{placeholder}</span>}
      </div>
    </OverlayTrigger>
  );
};

export default TagsDropdown;
