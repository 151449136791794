import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import InfoIcon from '../../../../Components/Icons/InfoIcon';

const SqlQueryModal = ({ data }) => {
  const [show, setShow] = useState();
  return (
    <>
      <Button
        className="btn-sm p-1"
        variant="info"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
        }}
      >
        <InfoIcon color="#fff" />
      </Button>
      <Modal centered size="xl" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton onClick={(e) => e.stopPropagation()}>
          <Modal.Title>Metrics queries</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto">
          <div className="d-flex flex-wrap">
            <div>
              <h5>Summary Query</h5>
              <pre className="p-1 rounded bg-light-400 fs-7">{data?.summary}</pre>
            </div>
            <div>
              <h5>Dates Query</h5>
              <pre className="p-1 rounded bg-light-400 fs-7">{data.dates}</pre>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SqlQueryModal;
