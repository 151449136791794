import { useState } from 'react';
import { Button, InputGroup, Modal } from 'react-bootstrap';
import AddIcon from '../../../Components/Icons/AddIcon';
import { useSetState } from '../../../utils/customHooks';
import { CloudUploadPDF, DeletePDF } from '../../../data/api/Querys';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import CalendarIcon from '../../../Components/Icons/CalendarIcon';
import DropOrChooseFile from './DropZone';
import Loader from '../../../Components/Loader';
import Form from 'react-bootstrap/Form';
import InfoIcon from '../../../Components/Icons/InfoIcon';
import { useCreateMarketingPdfMutation } from '../../../data/api/studioApi';
import { confirmAlert } from '../../../Components/ConfirmModal';
import moment from 'moment';

const UploadPdfModal = () => {
  const [showForm, setShowForm] = useState(false);
  const initialState = {
    loading: false,
    error: '',
    files: [],
    name: '',
    default: new Date(),
    date: moment.utc().startOf('day').format(),
  };
  const [state, setState] = useSetState(initialState);
  const [createPdf] = useCreateMarketingPdfMutation();
  const handleUploadPDF = async (e) => {
    e.preventDefault();

    if (state.files[0]) {
      setState({ loading: true });
    }
    if (state.files.length === 0) {
      return setState({
        error: 'Pdf file is required',
        loading: false,
      });
    }
    if (state.files[0].size > 40000000) {
      return setState({
        error: 'File size must not exceed 40MB',
        loading: false,
      });
    }
    if (!state.date) {
      return setState({
        error: 'Select a date!',
        loading: false,
      });
    }
    if (!state.name) {
      return setState({
        error: 'Title required',
        loading: false,
      });
    }
    try {
      let result = await CloudUploadPDF(state.files[0]);

      createPdf({
        pdf_name: state.name,
        pdf_url: result.data.link,
        pdf_date: moment(state.date).format('YYYY-MM-DD'),
      })
        .unwrap()
        .then(() => {
          confirmAlert({
            title: 'You have successfully uploaded PDF',
            variant: 'success',
            confirmBtn: false,
            cancelText: 'Ok',
          }).catch((err) => {});
          handleClose();
        })
        .catch((e) => {
          DeletePDF(result.data.link);
        });
    } catch (error) {
      setState({
        error: error?.message || 'Error uploading',
        loading: false,
      });
      console.log('err', error);
    }
  };

  const handleClose = () => {
    setShowForm(false);
    setState(initialState);
  };

  return (
    <>
      <Button type="button" variant="success" className=" d-flex" onClick={() => setShowForm(true)}>
        <AddIcon size={20} className="me-1" /> Add PDF
      </Button>
      <Modal centered dialogClassName="modal-md" show={showForm} onHide={handleClose}>
        <Form onSubmit={handleUploadPDF}>
          <Modal.Header closeButton>
            <Modal.Title>Add new PDF</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pdf-upload-modal">
            {state.loading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 240 }}>
                <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
              </div>
            ) : (
              <>
                {state.error && (
                  <div className="alert alert-danger mb-3 d-flex align-items-center fs-7" role="alert">
                    <div className=" me-3">
                      <InfoIcon />
                    </div>
                    <div>{state.error}</div>
                  </div>
                )}
                <Form.Group className="position-relative cursor-pointer" controlId="datepicker">
                  <Form.Label className="fs-7">
                    Title <span className="text-danger fs-7">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    aria-describedby=""
                    placeholder="Market Insights Weekly..."
                    value={state.name}
                    autoComplete={'off'}
                    //isInvalid={errors?.bundleId}
                    onChange={(text) => setState({ name: text.target.value, error: '' })}
                  />
                </Form.Group>
                <Form.Group className="position-relative cursor-pointer my-3" controlId="datepicker">
                  <Form.Label className="fs-7">
                    PDF Date <span className="text-danger fs-7">*</span>
                  </Form.Label>
                  <InputGroup hasValidation className="d-flex">
                    <DatePicker
                      id="datepicker"
                      className="form-control"
                      selected={state.date}
                      onChange={(date) => setState({ date: moment(date).utc().startOf('day').format(), error: '' })}
                      maxDate={state.default}
                      dateFormat="dd/MM/yyyy"
                    />
                    <InputGroup.Text className="cursor-pointer">
                      <CalendarIcon size={14} />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <DropOrChooseFile setState={setState} />
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="ms-auto">
              <Button disabled={state.loading} variant="primary" className="d-inline-flex" type="submit">
                Submit PDF
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default UploadPdfModal;
