import { useEffect, useRef, useState } from 'react';
import { hmac256 } from '../../../utils/common';
import { EventSourcePolyfill } from 'event-source-polyfill';
import Loader from '../../../Components/Loader';
import { useDispatch } from 'react-redux';
import { openAiApi } from '../../../data/api/openAiApi';
import { formatMessage } from '../chatUtils';

const EventSource = EventSourcePolyfill;

const MessageStream = ({ aiModel, title, message }) => {
  const eventSourceRef = useRef(null);
  const [response, setResponse] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!message.content && !eventSourceRef.current && message.prompt) {
      const url = createEventSourceUrl(message.prompt, title, aiModel);
      // Initialize new EventSource
      eventSourceRef.current = new EventSource(url, {
        withCredentials: true,
        headers: {
          //'Content-Type': 'text/event-stream',
          Accept: 'text/event-stream',
          authorization: hmac256(url),
          'Cache-Control': 'no-cache',
          Connection: 'keep-alive',
        },
      });
      eventSourceRef.current.onopen = function (event) {
        if (message.clearHistory) dispatch(openAiApi.util.invalidateTags(['chatHistoryList']));
      };
      eventSourceRef.current.onmessage = function (event) {
        if (event.data) setResponse((prevState) => prevState + JSON.parse(event.data));
      };
      eventSourceRef.current.onerror = function (err) {
        if (eventSourceRef.current.readyState === EventSource.CLOSED) {
          console.log('EventSource connection closed.');
        }
        eventSourceRef.current?.close();
      };
    }
  }, [message.prompt, title, aiModel]);

  const createEventSourceUrl = (msg, title, aiModel) => {
    const query = new URLSearchParams();
    query.set('model', aiModel);
    query.set('title', title);
    query.set('message', msg);

    // Return a complete URL, assuming your server runs on localhost:3000 and accepts query in URL
    return `${process.env.REACT_APP_APP_OPENAI_API}/chat/stream?${query.toString()}`;
  };

  return message?.content || response ? (
    <div dangerouslySetInnerHTML={{ __html: formatMessage(message?.content || response) }}></div>
  ) : (
    <div className=" ">
      <Loader parentStyle="me-2" size={20} color={'#3F96C7'} />
    </div>
  );
};

export default MessageStream;
