const SearchIcon = ({ size, color }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16699 9.1665C4.16699 6.409 6.40949 4.1665 9.16699 4.1665C11.9245 4.1665 14.167 6.409 14.167 9.1665C14.167 11.924 11.9245 14.1665 9.16699 14.1665C6.40949 14.1665 4.16699 11.924 4.16699 9.1665ZM17.2558 16.0775L14.4267 13.2475C15.3042 12.1192 15.8333 10.705 15.8333 9.16667C15.8333 5.49083 12.8425 2.5 9.16667 2.5C5.49083 2.5 2.5 5.49083 2.5 9.16667C2.5 12.8425 5.49083 15.8333 9.16667 15.8333C10.705 15.8333 12.1192 15.3042 13.2475 14.4267L16.0775 17.2558C16.24 17.4183 16.4533 17.5 16.6667 17.5C16.88 17.5 17.0933 17.4183 17.2558 17.2558C17.5817 16.93 17.5817 16.4033 17.2558 16.0775Z"
      />
    </svg>
  );
};
SearchIcon.defaultProps = {
  size: 24,
  color: '#4D6F9D',
};

export default SearchIcon;
