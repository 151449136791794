import TopAppCard from './TopAppCard';
import { useListTopAppsQuery } from '../../data/api/appMagikApi';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import Loader from '../../Components/Loader';
import TopAppsFilterBar from './TopAppsFilterBar';
import { useQuery } from '../../utils/customHooks';
import Pagination from '../../Components/Pagination';
import { useDebounce } from '../../utils/useDebounce';

const TopApps = () => {
  const [showScreenshots, setShowScreenshots] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState(null);
  const [loadMore, setLoadMore] = useState(null);
  const query = useQuery();
  const search = query.get('search') || '';
  const searchQuery = useDebounce(search, 1300);

  const {
    data: apiResponse,
    isFetching,
    error,
  } = useListTopAppsQuery(
    { search: searchQuery, os: query.get('os'), date: query.get('date'), page: loadMore || query.get('page') },
    { skip: !query.has('date') }
  );
  useEffect(() => {
    if (apiResponse?.data) {
      if (loadMore) {
        setData([...data, ...apiResponse?.data]);
      } else {
        setData(apiResponse?.data);
        setMeta(apiResponse?.meta);
      }
    }
  }, [apiResponse]);
  const handleLoadMore = () => {
    setLoadMore((loadMore || Number(query.get('page')) || 1) + 1);
  };
  return (
    <div className="container-fluid">
      <div className="row px-md-2">
        <div className=" col-lg-12 d-flex align-items-center flex-lg-row flex-column">
          <TopAppsFilterBar onChange={() => setLoadMore(null)} />
          <div className="ms-auto d-flex align-items-center show-details">
            <div className="d-flex align-items-center">
              <label htmlFor="custom-switch" className="text-secondary fs-7 pe-2 cursor-pointer text-nowrap">
                Show Details
              </label>
              <Form.Check
                type="switch"
                checked={showScreenshots}
                id="custom-switch"
                onChange={() => {
                  setShowScreenshots(!showScreenshots);
                }}
              />
            </div>
            <div className="my-3 my-lg-0 pagination-wrapper">
              {!!meta?.total && (
                <Pagination
                  total={meta?.total || 0}
                  perPage={meta?.perPage}
                  page={loadMore || Number(query.get('page')) || 1}
                  onChange={() => {
                    setLoadMore(null);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-12 mt-4">
          {!loadMore && (isFetching || !query.has('date')) ? (
            <div className="d-flex justify-content-center">
              <Loader parentStyle="loader" size={38} color={'#3F96C7'} />
            </div>
          ) : error ? (
            <div className="alert alert-danger p-2 mb-2 fs-7 " role="alert">
              {error?.data?.message?.message || error?.data?.message || 'Something went wrong.'}
            </div>
          ) : data?.length ? (
            <>
              {data.map((el, index) => (
                <TopAppCard key={index + el.bundle_id} data={el} showScreenshots={showScreenshots} />
              ))}
              {!!meta?.total && (
                <div className="col-12 d-flex justify-content-center mb-3">
                  {isFetching ? (
                    <Loader parentStyle="loader" size={38} color={'#3F96C7'} />
                  ) : (
                    <Pagination
                      loadMore
                      scrollTopBtn
                      label={false}
                      size="lg"
                      total={meta?.total || 0}
                      perPage={meta?.perPage}
                      page={loadMore || Number(query.get('page')) || 1}
                      onChange={(page) => {
                        if (page === 'more') {
                          handleLoadMore();
                        } else {
                          setLoadMore(null);
                        }
                      }}
                    />
                  )}
                </div>
              )}
            </>
          ) : (
            <div>Apps data was not found!</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopApps;
