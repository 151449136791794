import React from 'react';

function ArrowDown({ size = 24, color = '#7E98BB', rotate, ...rest }) {
  return (
    <svg
      style={
        rotate === 'left'
          ? { transform: 'rotate(90deg)' }
          : rotate === 'right'
          ? { transform: 'rotate(-90deg)' }
          : rotate === 'up'
          ? { transform: 'rotate(180deg)' }
          : {}
      }
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      {...rest}
      viewBox="0 0 14 8"
    >
      <path d="M1.07111 1.11548C1.12188 0.993625 1.19627 0.883024 1.29 0.790061C1.38296 0.696332 1.49356 0.621939 1.61542 0.57117C1.73728 0.520401 1.86799 0.494263 2 0.494263C2.13201 0.494263 2.26272 0.520401 2.38458 0.57117C2.50644 0.621939 2.61704 0.696332 2.71 0.790061L7 5.09006L11.29 0.790061C11.383 0.696332 11.4936 0.621938 11.6154 0.571169C11.7373 0.520401 11.868 0.494262 12 0.494262C12.132 0.494262 12.2627 0.520401 12.3846 0.571169C12.5064 0.621938 12.617 0.696332 12.71 0.79006C12.8037 0.883024 12.8781 0.993625 12.9289 1.11548C12.9797 1.23734 13.0058 1.36805 13.0058 1.50006C13.0058 1.63207 12.9797 1.76278 12.9289 1.88464C12.8781 2.0065 12.8037 2.1171 12.71 2.21006L7.71 7.21006C7.61704 7.30379 7.50644 7.37818 7.38458 7.42895C7.26272 7.47972 7.13201 7.50586 7 7.50586C6.86799 7.50586 6.73728 7.47972 6.61542 7.42895C6.49356 7.37818 6.38296 7.30379 6.29 7.21006L1.29 2.21006C1.19627 2.1171 1.12188 2.0065 1.07111 1.88464C1.02034 1.76278 0.994201 1.63207 0.994201 1.50006C0.994201 1.36805 1.02034 1.23734 1.07111 1.11548Z" />
    </svg>
  );
}

export default ArrowDown;
