import { Button, Card, Form, InputGroup } from 'react-bootstrap';
import SendIcon from '../../../Components/Icons/SendIcon';
import React, { useState } from 'react';
import { GAME_CATEGORIES, GAME_KEYWORDS } from '../../../constants/game';
import PitchMessage from './PitchMessage';

const GamePitch = () => {
  const [input, setInput] = useState('');
  const [gameCategory, setGameCategory] = useState('');
  const [gameStyle, setGameStyle] = useState('');
  const [messages, setMessages] = useState([]);
  const [validation, setValidation] = useState({ gameCategory: '', gameStyle: '' });

  const handleSend = () => {
    if (!gameCategory || !gameStyle) {
      setValidation({
        gameCategory: !gameCategory ? 'Please select a game category.' : '',
        gameStyle: !gameStyle ? 'Please select a game style.' : '',
      });
      return;
    }

    setInput('');
    setGameStyle('');
    setGameCategory('');
    setValidation({});

    const requestText = `
      Pitch me a ${gameCategory} mobile game concept based on your knowledge base, portrait oriented, level based, ${gameStyle} art style. 
      Split the output in multiple bullet points: category, art style, mechanic, gameplay, title. 
      Provide a very detailed game visual.
      ${input}
    `;

    let msg = {
      time: Date.now(),
      role: 'user',
      content: `style: ${gameStyle}\ncategory: ${gameCategory}.${input && '\n' + input}`,
    };
    let aiMsg = { time: Date.now() + 5, role: 'assistant', prompt: requestText.trim() };

    setMessages((prevVal) => [aiMsg, msg, ...prevVal]);
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className="d-flex flex-basis-0 overflow-hidden flex-column flex-grow-1 mb-4">
      <div className="overflow-hidden bg-white shadow-sm d-flex flex-grow-1">
        <div className="d-flex px-3 flex-column-reverse flex-grow-1 overflow-auto">
          {messages?.map((el) => (
            <Card
              key={el.time}
              className={`my-2 fs-7 ${el?.role === 'assistant' ? 'bg-primary-danger' : 'bg-pale-white'}`}
            >
              <Card.Body className="py-2">
                <pre>
                  <PitchMessage message={el} />
                </pre>
              </Card.Body>
            </Card>
          ))}
          <Card className={`bg-primary-100 fs-7 my-2`}>
            <Card.Body className="py-2">
              <pre>Hello! Should we generate new game ideas?</pre>
            </Card.Body>
          </Card>
        </div>
      </div>
      <Form noValidate className="px-3">
        <div className="d-flex pt-2">
          <Form.Group className="mb-3 me-3 position-relative" controlId="gameCategoryControl">
            <Form.Label>Game Category</Form.Label>
            <Form.Control
              required
              type="text"
              list="gameCategorys"
              placeholder="Type game category"
              aria-label="Game ideas"
              className="rounded"
              value={gameCategory}
              isInvalid={!!validation.gameCategory}
              onChange={(e) => {
                setGameCategory(e.target.value);
                setValidation((prev) => ({ ...prev, gameCategory: '' }));
              }}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {validation.gameCategory}
            </Form.Control.Feedback>
            <datalist id="gameCategorys" className="rounded bg-transparent">
              {GAME_CATEGORIES.map((value, index) => (
                <option value={value} key={index}>
                  {value}
                </option>
              ))}
            </datalist>
          </Form.Group>
          <Form.Group className="mb-3 me-3 position-relative" controlId="gameStyleControl">
            <Form.Label>Game Style</Form.Label>
            <Form.Control
              required
              type="text"
              list="gameTags"
              placeholder="Type game style"
              aria-label="Game ideas"
              className="rounded"
              value={gameStyle}
              isInvalid={!!validation.gameStyle}
              onChange={(e) => {
                setGameStyle(e.target.value);
                setValidation((prev) => ({ ...prev, gameStyle: '' }));
              }}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {validation.gameStyle}
            </Form.Control.Feedback>
            <datalist id="gameTags" className="rounded bg-transparent">
              {GAME_KEYWORDS.map((value, index) => (
                <option value={value} key={index}>
                  {value}
                </option>
              ))}
            </datalist>
          </Form.Group>
        </div>

        <InputGroup className="mb-2 my-2">
          <Form.Control
            as="textarea"
            rows={4}
            resize="none"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleEnter}
            className="no-resize"
            placeholder="Provide additional information..."
            aria-label="Send a message"
          />
          <Button variant="outline-light send-button" onClick={handleSend}>
            <SendIcon />
          </Button>
        </InputGroup>
      </Form>
    </div>
  );
};

export default GamePitch;
