import React from 'react';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, Area, CartesianGrid, AreaChart } from 'recharts';
import moment from 'moment';

function Graph({ metrics, dataKey, color, bgColor1, bgColor2 }) {
  let formattedMetrics = [];

  formattedMetrics = metrics
    .map((el) => {
      if (el[dataKey] !== null) {
        return {
          ...el,
          date: moment(el.date).format('D MMM YY'),
          ret_d1: Math.round(el.ret_d1 * 100),
          ret_d3: Math.round(el.ret_d3 * 100),
          ret_d7: Math.round(el.ret_d7 * 100),
          ret_d14: Math.round(el.ret_d14 * 100),
        };
      } else {
        return false;
      }
    })
    .filter(Boolean);
  return (
    <ResponsiveContainer width="95%" height={250}>
      <AreaChart data={formattedMetrics}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={bgColor1} stopOpacity={1} />
            <stop offset="99%" stopColor={bgColor2} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis tickCount={10} dataKey="date" angle={16} height={60} width={16} fontSize={12} dy={8} dx={8} />
        <YAxis width={44} fontSize={12} domain={[0, (dataMax) => dataMax.toFixed(3)]} padding={{}} />
        <CartesianGrid stroke="#DEDEDE" vertical={false} strokeDasharray="5 5" />
        <Tooltip
          contentStyle={{
            borderRadius: 4,
            padding: '4px 8px',
            fontSize: 14,
          }}
          formatter={function (value, name) {
            if (name.startsWith('ret')) {
              return `${value}%`;
            } else {
              return `${value}`;
            }
          }}
        />
        <Area type="monotone" strokeWidth="2" dataKey={dataKey} color={color} fillOpacity={1} fill="url(#colorUv)" />
      </AreaChart>
    </ResponsiveContainer>
  );
}
Graph.defaultProps = {
  bgColor1: 'rgba(0, 44, 255, 0.15)',
  bgColor2: 'rgba(0, 44, 255, 0.02)',
  color: '#002CFF',
};

export default Graph;
