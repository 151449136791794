import React from 'react';

function MoonIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill={props.color}
      viewBox="0 0 21 20"
    >
      <path
        d="M19.64 10.9997C19.4957 10.8803 19.3207 10.804 19.1351 10.7792C18.9494 10.7545 18.7606 10.7823 18.59 10.8597C17.5326 11.3435 16.3829 11.5926 15.22 11.5897C13.0689 11.5871 11.006 10.7341 9.48115 9.21677C7.95632 7.69943 7.09321 5.64077 7.08001 3.48966C7.08457 2.8155 7.16848 2.14421 7.33001 1.48966C7.36429 1.3152 7.35143 1.13476 7.29277 0.966917C7.2341 0.799078 7.13175 0.649915 6.99626 0.534795C6.86076 0.419674 6.69702 0.342761 6.52191 0.311978C6.3468 0.281194 6.16665 0.297655 6.00001 0.359663C4.43234 1.06457 3.06958 2.15642 2.03977 3.53265C1.00996 4.90887 0.346893 6.52429 0.112862 8.22715C-0.121168 9.93001 0.0815189 11.6644 0.701883 13.2674C1.32225 14.8704 2.33993 16.2894 3.65931 17.3911C4.9787 18.4928 6.55649 19.241 8.24446 19.5655C9.93243 19.8899 11.6752 19.7799 13.309 19.2459C14.9428 18.7118 16.414 17.7712 17.5844 16.5124C18.7548 15.2536 19.5861 13.7179 20 12.0497C20.0504 11.8587 20.0431 11.657 19.9791 11.4701C19.915 11.2833 19.797 11.1196 19.64 10.9997ZM10.14 17.6897C8.46177 17.6778 6.82821 17.1475 5.46301 16.1714C4.0978 15.1953 3.06768 13.821 2.5137 12.2368C1.95971 10.6526 1.90895 8.93585 2.36835 7.32167C2.82776 5.70749 3.77487 4.27476 5.08001 3.21966V3.48966C5.08266 6.17815 6.15183 8.75576 8.05287 10.6568C9.95392 12.5578 12.5315 13.627 15.22 13.6297C15.9259 13.6323 16.63 13.5585 17.32 13.4097C16.6299 14.7152 15.5965 15.8075 14.3312 16.5688C13.0659 17.3301 11.6167 17.7315 10.14 17.7297V17.6897Z"
        // fill="white"
      />
    </svg>
  );
}

export default MoonIcon;
