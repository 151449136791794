import { useSelector } from 'react-redux';
import { topBarTitleSelector } from '../../data/slices/global';
import ArrowDown from '../../Components/Icons/ArrowDown';
import { useHistory } from 'react-router-dom';
import SearchInput from '../../Pages/AbTest/CreateAbTestModal/_Components/SearchInput';

const TopBar = () => {
  const history = useHistory();
  const topBarTitle = useSelector(topBarTitleSelector);

  return (
    <div className="container-fluid top-bar-fixed-cont d-print-none bg-white border-bottom">
      <div className="row top-bar bar align-items-center px-md-2">
        <div className="col-lg-3 col-md-3 col-sm-6 col-6 d-md-flex fs-5 fw-bold align-items-center">
          {topBarTitle.back && (
            <button
              className="btn btn-sm bg-light-400 me-2"
              onClick={() => {
                if (topBarTitle.back === 'goBack') {
                  history.goBack();
                } else {
                  history.push(topBarTitle.back);
                }
              }}
            >
              <ArrowDown rotate="left" size={14} />
            </button>
          )}
          {topBarTitle.title}
        </div>
        <div className="col-lg-6 col-md-7 col-sm-6 col-6">
          <SearchInput />
        </div>
        <div className="w-auto d-flex position-absolute translate-middle-y top-50 end-0 ">
          {/*<div className="divider ms-2" />*/}
          {/*<UserAccount />*/}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
