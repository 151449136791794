import React from 'react';

function AppleIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 23}
      fill={props.color || '#95CF00'}
      className="bi bi-clipboard2-data"
      viewBox="0 0 24 24"
    >
      <path d="M12.5,6h.1A5.51,5.51,0,0,0,17,.5a.5.5,0,0,0-.19-.39A.49.49,0,0,0,16.4,0,5.51,5.51,0,0,0,12,5.51a.5.5,0,0,0,.5.5Z" />
      <path d="M21.67,16.53a4.3,4.3,0,0,1-2.67-4,4.46,4.46,0,0,1,2.21-3.85.5.5,0,0,0,.12-.77A6,6,0,0,0,17,6a6.12,6.12,0,0,0-2.34.52A5.58,5.58,0,0,1,12.5,7a5.58,5.58,0,0,1-2.16-.48A6.12,6.12,0,0,0,8,6c-.61,0-6,.2-6,7,0,5.56,3.8,11,6,11a6.63,6.63,0,0,0,2.81-.59A4.1,4.1,0,0,1,12.5,23a4.1,4.1,0,0,1,1.69.41A6.63,6.63,0,0,0,17,24c1.79,0,3.9-3.53,5-6.84A.5.5,0,0,0,21.67,16.53Z" />
    </svg>
  );
}

export default AppleIcon;
