import { Alert, Col, Form, FormControl, Row } from 'react-bootstrap';
import moment from 'moment/moment';
import { abTestCountries } from '../../../../constants/abTestCountries';
import SearchableDropdown from '../../../../Components/SearchableDropdown';
import CohortDateSelect from './cohortDateSelect';
import CohortList from './cohortList';
import { useSelector } from 'react-redux';
import { getGameById, getGamesByBundleId } from '../../../../data/slices/studio';
import React from 'react';
import { useGetGameNetworksQuery } from '../../../../data/api/abTestsApi';
import { capitalizeFirstLetter } from '../../../../utils/converters';

function AbtestForm({ state, setState, eligible, errors }) {
  const foundGame = useSelector(getGameById(state.id));
  const foundGames = useSelector(getGamesByBundleId(state.bundleId));
  const { data: networks, isFetching } = useGetGameNetworksQuery(foundGame.bundleId);
  const handleDateChange = (startDate, endDate) => {
    setState({
      startDate: moment.utc(startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      endDate: moment.utc(endDate).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }),
    });
  };
  const handleBuildChange = (text, field) => {
    if (text.match(/([0-9_.])$/g) || text === '') {
      return setState({ [field]: text.split(' ').join('') });
    } else {
      return null;
    }
  };

  const handleDeviceChange = (text, field) => {
    if (text.match(/([0-9a-fA-F-])$/g) || text === '') {
      return setState({ [field]: text.split(' ').join('') });
    } else {
      return null;
    }
  };

  function handleTotalPChange(e, setState) {
    const totalPValue = Number(e.target.value);
    if (!isNaN(totalPValue) && totalPValue >= 0 && totalPValue <= 100) {
      setState({ totalP: totalPValue });
    }
  }

  const handleOsChange = (e) => {
    let os = e.target.value;
    setState({ os });
    if (os === 'android' && state.store.includes('apple')) {
      setState({ store: state.store.filter((s) => s !== 'apple') });
    } else if (os === 'ios') {
      setState({ store: state.store.includes('apple') ? ['apple'] : [] });
    }
  };

  return (
    <Form noValidate autoComplete="off">
      <Row className={'g-2'}>
        <Form.Group className="position-relative" as={Col} xs={12} controlId="Name">
          <Form.Label className="fs-7">
            A/B Test Name <span className="text-danger fs-7">*</span>
          </Form.Label>
          <FormControl
            type="text"
            value={state.name}
            placeholder="Type Name"
            onChange={(e) => setState({ name: e.target.value })}
            isInvalid={errors.name}
            name="name"
          />

          <Form.Control.Feedback tooltip type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="position-relative" as={Col} xs={6} controlId="targetOs">
          <Form.Label className="fs-7">
            Os <span className="text-danger fs-7">*</span>
          </Form.Label>
          <Form.Select
            aria-label="OS"
            value={state.os}
            onChange={handleOsChange}
            isInvalid={errors.os}
            disabled={!foundGame?.multipleOs}
          >
            <option value={'all'}>iOS / Android</option>
            <option value={'ios'}>iOS</option>
            <option value={'android'}>Android</option>
          </Form.Select>
          <Form.Control.Feedback tooltip type="invalid">
            {errors.os}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="position-relative" as={Col} xs={6} controlId="buildVersion">
          <Form.Label className="fs-7 ">
            Minimum Build Version <span className="text-danger fs-7">*</span>
          </Form.Label>
          <FormControl
            type="text"
            value={state.build}
            isInvalid={errors.build}
            placeholder="Type Build version"
            onChange={(text) => handleBuildChange(text.target.value, 'build')}
            name="build-version"
          />
          <Form.Control.Feedback tooltip type="invalid">
            {errors.build}
          </Form.Control.Feedback>
        </Form.Group>
        {!!networks?.length && !isFetching && (
          <Form.Group className="position-relative" as={Col} xs={12} controlId="targetOs">
            <Form.Label className="fs-7">Source network</Form.Label>
            <Form.Select
              aria-label="networks"
              value={state.network}
              onChange={(e) => {
                setState({ network: e.target.value });
              }}
            >
              <option>All</option>
              {networks.map((el) => (
                <option value={el}>{capitalizeFirstLetter(el)}</option>
              ))}
            </Form.Select>
          </Form.Group>
        )}
        {foundGames?.length > 1 && (
          <Form.Group className="position-relative" as={Col} xs={12} controlId="store">
            <Form.Label className="fs-7">Store</Form.Label>
            <SearchableDropdown
              placeholder={'Select specific store or leave empty'}
              isInvalid={errors.store}
              writeSelected
              parClass={errors.store && 'is-invalid'}
              multiple
              disableSearch
              collapseGroup
              onSelect={(data) => setState({ store: data })}
              selected={state.store}
              options={[
                state.os !== 'android' && { value: 'apple', label: 'Apple' },
                state.os !== 'ios' && { value: 'google', label: 'Google' },
                state.os !== 'ios' && { value: 'amazon', label: 'Amazon' },
              ].filter(Boolean)}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {errors.store}
            </Form.Control.Feedback>
          </Form.Group>
        )}
        <Form.Group className="position-relative" as={Col} xs={6} controlId="deviceId">
          <Form.Label className="fs-7 ">
            Device Id <span className="text-muted fs-7"> (testing on one device)</span>
          </Form.Label>
          <FormControl
            type="text"
            placeholder="Type Device Id"
            value={state.device}
            onChange={(text) => handleDeviceChange(text.target.value, 'device')}
            name="device-id"
          />
          <Form.Control.Feedback tooltip type="invalid">
            {errors.device}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="position-relative" as={Col} xs={6} controlId="country">
          <Form.Label className="fs-7 ">Country</Form.Label>
          <SearchableDropdown
            multiple
            collapseGroup
            onSelect={(data) => setState({ country: data })}
            selected={state.country}
            options={abTestCountries}
            disableSearch
          />
        </Form.Group>
        <>
          <CohortDateSelect
            errors={{}}
            startDate={state.startDate.format('lll')}
            endDate={state.endDate.format('lll')}
            onDateChange={handleDateChange}
          />

          <div className="card px-3 py-2 mt-2 rounded  bg-sidebar-color">
            <p className="text-nowrap m-0 me-1 text-gray-blue fs-7">
              Total user percentage for this Test <span className="text-danger fs-7">*</span>
            </p>

            <div className="d-flex align-items-center my-1">
              <div className="flex-grow-1 me-3 rounded justify-content-center">
                <Form.Range
                  value={state.totalP}
                  onChange={(e) => handleTotalPChange(e, setState)}
                  aria-labelledby="input-slider"
                  max={100}
                  min={0}
                  steps={1}
                  variant="primary"
                  className="slider pt-1"
                />
              </div>
              <div>
                <Form.Group
                  onClick={(e) => e.stopPropagation()}
                  className="position-relative me-2 flex-grow-0"
                  controlId={`totalP`}
                >
                  <Form.Control
                    size="sm"
                    value={state.totalP}
                    onChange={(e) => handleTotalPChange(e, setState)}
                    type="number"
                    isInvalid={errors.totalP}
                    max={100}
                    min={0}
                    name="total-p"
                  />
                </Form.Group>
              </div>
            </div>
            <CohortList data={state} setData={setState} eligible={eligible} />
            <p className="fs-7 mb-0 mt-1 text-gray-blue">
              Each cohort will receive{' '}
              {state.cohorts.length ? Math.round(100 / (state.cohorts.length + (eligible ? 1 : 0))) : 100}% of Test
              users. (
              {state.cohorts.length
                ? Math.round((state.totalP * (100 / (state.cohorts.length + (eligible ? 1 : 0)))) / 100)
                : state.totalP}
              % of total)
            </p>
            {errors?.cohorts && (
              <Col xs={12}>
                <Alert className="fs-8 p-2" variant="danger">
                  {errors.cohorts}
                </Alert>
              </Col>
            )}
          </div>
        </>
      </Row>
    </Form>
  );
}

export default AbtestForm;
