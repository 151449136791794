import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  topBarTitle: {
    title: 'Games',
    back: '',
    search: false,
  },
};
export const topBarTitleSelector = (state) => state.global.topBarTitle;

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setTopBarTitle: (state, action) => {
      state.topBarTitle = action.payload;
    },
  },
});

export const { setTopBarTitle } = globalSlice.actions;

export default globalSlice.reducer;
