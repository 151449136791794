import Form from 'react-bootstrap/Form';

import { useSetState } from '../../../utils/customHooks';
import { Button } from 'react-bootstrap';
import InfoIcon from '../../../Components/Icons/InfoIcon';
import CheckmarkIcon from '../../../Components/Icons/CheckmarkIcon';
import { useResetPasswordMutation } from '../../../data/api/userApi';

function ForgotPasswordForm() {
  const [state, setState] = useSetState({
    email: '',
    error: '',
    success: '',
  });
  const [resetPass, { isLoading }] = useResetPasswordMutation();

  /** State Manipulation */
  const handleTextChange = (text, field) => {
    return setState({ [field]: text, error: '', success: '' });
  };

  const handleSendEmail = (e) => {
    e.preventDefault();

    resetPass(state.email)
      .unwrap()
      .then((response) => {
        setState({
          error: '',
          email: '',
          success: 'Check your emails, a message has been sent!',
        });
      })
      .catch((err) => {
        setState({
          error: err?.data?.message || err?.message || 'Something went wrong',
          success: '',
        });
      });
  };

  return (
    <Form onSubmit={handleSendEmail}>
      {state.error && (
        <div className="alert alert-danger mb-3 d-flex align-items-center fs-7" role="alert">
          <div className=" me-3">
            <InfoIcon />
          </div>
          <div>{state.error}</div>
        </div>
      )}
      {state.success && (
        <div className="alert alert-success mb-3 d-flex align-items-center fs-7" role="alert">
          <div className=" me-3">
            <CheckmarkIcon size={16} />
          </div>
          <div>{state.success}</div>
        </div>
      )}
      <Form.Group className="mb-2">
        <Form.Control
          type="email"
          id="email"
          className="form-control"
          aria-describedby="Email"
          placeholder="Email"
          value={state.email}
          onChange={(text) => handleTextChange(text.target.value, 'email')}
        />
      </Form.Group>

      <Button className="w-100 fs-6" size="lg" type="submit" disabled={isLoading}>
        Send
      </Button>
    </Form>
  );
}

export default ForgotPasswordForm;
