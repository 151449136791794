import React, { useState, useEffect } from 'react';
import { useImageGenerationMutation } from '../../data/api/openAiApi';
import Loader from '../../Components/Loader';
import { Button, Card, Form, InputGroup } from 'react-bootstrap';
import SendIcon from '../../Components/Icons/SendIcon';
import { Link } from 'react-router-dom';
import { getImageSizeOptions } from './chatUtils';

const ImageGenerator = () => {
  const [input, setInput] = useState('');
  const [number, setNumber] = useState(3);
  const [size, setSize] = useState('256x256');
  const [messages, setMessages] = useState([]);
  const [sendMessage, { isLoading, error }] = useImageGenerationMutation();
  const [model, setModel] = useState('DALL-E-2');

  useEffect(() => {
    if (model === 'DALL-E-3') {
      setNumber(1);
      setSize('1024x1024');
    } else {
      setSize('256x256');
    }
  }, [model]);

  const handleSend = () => {
    if (!input) return false;
    let msgs = { role: 'user', content: input, num: number, size };
    setMessages([msgs, ...messages]);
    sendMessage({ model, message: input, nbImages: number, imageSize: size })
      .unwrap()
      .then((resp) => {
        setMessages((val) => [{ urls: resp.map((el) => el.url), role: 'assistant' }, ...val]);
      })
      .catch();
    setInput('');
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend(input);
    }
  };
  const handleTextChange = (e) => {
    if (model === 'DALL-E-2' && e.target.value.length >= 400) return;
    setInput(e.target.value);
  };

  return (
    <div className="d-flex flex-basis-0 overflow-hidden flex-column flex-grow-1 mb-4">
      <div className="d-flex flex-column-reverse flex-grow-1 overflow-auto mb-3 px-3 bg-white shadow-sm">
        {isLoading && (
          <Card className={`my-2 fs-7 bg-pale-white`}>
            <Card.Body className="d-flex fs-7 py-2">
              <Loader parentStyle="me-2" size={20} color={'#3F96C7'} />
              Loading...
            </Card.Body>
          </Card>
        )}
        {messages.map((el, index) => (
          <Card key={index} className={`my-2 fs-7 ${el?.role === 'assistant' ? 'bg-primary-100' : 'bg-pale-white'}`}>
            <Card.Body className="py-2">
              {el?.urls && (
                <div className="d-flex flex-wrap">
                  {el.urls.map((url, index) => (
                    <Link
                      key={index}
                      className="text-decoration-none rounded m-2"
                      to={{ pathname: url }}
                      target="_blank"
                    >
                      <img src={url} style={{ maxWidth: 700 }} className="rounded" alt="" />
                    </Link>
                  ))}
                </div>
              )}
              <pre>{el?.content}</pre>
              <p className="m-0 text-secondary fs-8 mt-1">{el?.size}</p>
            </Card.Body>
          </Card>
        ))}
        <Card className={`my-2 bg-primary-100 fs-7`}>
          <Card.Body className="py-2">
            <pre>Need help generating images?</pre>
          </Card.Body>
        </Card>
      </div>
      <Form className="px-3">
        {error && !isLoading && (
          <div className="alert alert-danger p-2 mb-2 fs-7" role="alert">
            {error?.data?.message || error?.error || 'Something went wrong.'}
          </div>
        )}
        <div className="d-flex">
          <Form.Group className="mb-3 me-3">
            <Form.Label className="">Image Model</Form.Label>
            <Form.Select className="form-select" value={model} onChange={(event) => setModel(event.target.value)}>
              {['DALL-E-2', 'DALL-E-3'].map((value, index) => (
                <option value={value} key={index}>
                  {value}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3 me-3">
            <Form.Label className="">Image size</Form.Label>
            <Form.Select className="form-select" value={size} onChange={(event) => setSize(event.target.value)}>
              {getImageSizeOptions(model).map((value, index) => (
                <option value={value} key={index}>
                  {value}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          {model === 'DALL-E-2' && (
            <Form.Group className="mb-3 me-3">
              <Form.Label className="">Number of images</Form.Label>
              <Form.Select className="form-select" value={number} onChange={(event) => setNumber(event.target.value)}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value, index) => (
                  <option value={value} key={index}>
                    {value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
        </div>
        <InputGroup className="mb-3">
          <Form.Control
            as="textarea"
            rows={4}
            value={input}
            onChange={handleTextChange}
            onKeyDown={handleEnter}
            className="no-resize"
            placeholder="Send a message"
            aria-label="Send a message"
            disabled={isLoading}
          />
          <Button variant="outline-light" onClick={handleSend}>
            <SendIcon />
          </Button>
        </InputGroup>
      </Form>
    </div>
  );
};

export default ImageGenerator;
