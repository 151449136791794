import React, { useEffect, useState } from 'react';
import MetricsTable from '../../../../../Components/JsonMetrics/MetricsTable';
import MetricsTableChart from '../../../../../Components/JsonMetrics/MetricsTableChart';
import { getChartDataCell, getDataCell } from '../../../../../utils/jsonMetricsUtils';

function RetentionMetrics({ apiData }) {
  const [chart, setChart] = useState('Retention D1');
  const [data, setData] = useState(null);

  const handleChangeRetention = (e) => {
    setChart(e.target.value);
  };

  const selectedChar = () => {
    if (!data) return [];
    switch (chart) {
      case 'Retention D2':
        return data.retD2;
      case 'Retention D3':
        return data.retD3;
      case 'Retention D4':
        return data.retD4;
      case 'Retention D7':
        return data.retD7;
      default:
        return data.retD1;
    }
  };

  useEffect(() => {
    if (!apiData?.summary?.length && !apiData?.dates) return;

    const newData = {
      retD1: [],
      retD2: [],
      retD3: [],
      retD4: [],
      retD7: [],
      retLegendList: [],
      retOverview: {
        header: [
          { value: 'Cohort' },
          { value: 'Installs' },
          { value: 'RR D1' },
          { value: 'RR D2' },
          { value: 'RR D3' },
          { value: 'RR D4' },
          { value: 'RR D7' },
        ],
        data: [],
        title: 'Summary',
      },
    };

    apiData.summary.forEach((el) => {
      const isBaseCohort = el.user_cohort?.toLowerCase() === 'basecohort';
      newData.retLegendList.push(el.user_cohort);

      newData.retOverview.data.push([
        { value: el.user_cohort, className: isBaseCohort ? 'bg-light-400' : '' },
        { value: el.installs || '', className: isBaseCohort ? 'bg-light-400' : '' },
        getDataCell(el.ret_d1, el.meta, 'ret_d1', isBaseCohort, 'percentage'),
        getDataCell(el.ret_d2, el.meta, 'ret_d2', isBaseCohort, 'percentage'),
        getDataCell(el.ret_d3, el.meta, 'ret_d3', isBaseCohort, 'percentage'),
        getDataCell(el.ret_d4, el.meta, 'ret_d4', isBaseCohort, 'percentage'),
        getDataCell(el.ret_d7, el.meta, 'ret_d7', isBaseCohort, 'percentage'),
      ]);
    });

    Object.keys(apiData.dates).forEach((date, index) => {
      newData.retD1.push({ date });
      newData.retD2.push({ date });
      newData.retD3.push({ date });
      newData.retD4.push({ date });
      newData.retD7.push({ date });

      const d = apiData.dates[date];

      Object.keys(d).forEach((item) => {
        const obj = d[item];
        const isBaseCohort = obj.user_cohort?.toLowerCase() === 'basecohort';

        newData.retD1[index][item] = getChartDataCell(obj.ret_d1, obj.meta, 'ret_d1', isBaseCohort);
        newData.retD2[index][item] = getChartDataCell(obj.ret_d2, obj.meta, 'ret_d2', isBaseCohort);
        newData.retD3[index][item] = getChartDataCell(obj.ret_d3, obj.meta, 'ret_d3', isBaseCohort);
        newData.retD4[index][item] = getChartDataCell(obj.ret_d4, obj.meta, 'ret_d4', isBaseCohort);
        newData.retD7[index][item] = getChartDataCell(obj.ret_d7, obj.meta, 'ret_d7', isBaseCohort);
      });
    });

    setData(newData);
  }, [apiData]);

  return (
    <>
      {data && (
        <div className="bg-white rounded">
          <MetricsTable
            data={data.retOverview.data}
            title={data.retOverview.title}
            header={data.retOverview.header}
            downloadCsv
            csvDiffFromBase
          />
          <div className="d-flex text-nowrap align-items-center gap-2 p-2 ms-3">
            <label htmlFor="retSelect">Select Retention</label>
            <div className="w-25">
              <select
                className="form-select cursor-pointer"
                onChange={handleChangeRetention}
                value={chart}
                id="retSelect"
              >
                <option value="Retention D1">Day 1</option>
                <option value="Retention D2">Day 2</option>
                <option value="Retention D3">Day 3</option>
                <option value="Retention D4">Day 4</option>
                <option value="Retention D7">Day 7</option>
              </select>
            </div>
          </div>
          <MetricsTableChart data={selectedChar()} legendList={data.retLegendList} />
        </div>
      )}
    </>
  );
}

export default RetentionMetrics;
