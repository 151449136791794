import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import SignUpForm from './signUpForm';

/** Import Utils **/
import { getCookie, delCookie } from '../../../utils/common';
import { setUser } from '../../../data/slices/user';
import { useDispatch } from 'react-redux';
import { R_LOGIN, R_TERMS_CONDITIONS } from '../../../constants/routes';
import AuthLayout from '../../../Layouts/AuthLayout/AuthLayout';

function SignUp() {
  const dispatch = useDispatch();
  useEffect(() => {
    if (getCookie('user-id') !== null) {
      delCookie('user-id');
      dispatch(setUser({}));
    }
  }, []);

  return (
    <AuthLayout>
      <div className="login-form">
        <h1>Hello!</h1>
        <p className="text-muted">
          Welcome to the user creation page! Please fill the form and send it to create a new account
        </p>
        <SignUpForm />
        <div className="d-flex my-2 fs-7 text-gray-blue">
          Already have an account?
          <Link to={R_LOGIN} className="ms-2 text-decoration-none">
            Login here
          </Link>
        </div>

        <Link to={R_TERMS_CONDITIONS} className="fs-8">
          Terms & Conditions
        </Link>
      </div>
    </AuthLayout>
  );
}

export default SignUp;
