import { Button, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { useChangeABTestGroupMutation } from '../../../../data/api/abTestsApi';
import Loader from '../../../../Components/Loader';
import { useState } from 'react';
import { useUserPermission } from '../../../../Components/permissions/UserPermissions';
import { useSelector } from 'react-redux';
import { getGameByBundleId } from '../../../../data/slices/abTests';

const AbTestQualityDropdown = ({ data, type, placement = 'auto-start' }) => {
  const [show, setShow] = useState(false);
  const foundGame = useSelector((state) => getGameByBundleId(state, data.bundleId));
  const editable = useUserPermission('quality.update') && foundGame?.status !== 'ARCHIVED';
  const options = {
    '': { title: 'Undefined', color: 'light-400' },
    Positive: { title: 'Positive', color: 'success' },
    Negative: { title: 'Negative', color: 'warning' },
    Inconclusive: { title: 'Inconclusive', color: 'dark-300' },
  };
  let value = data?.quality || '';
  const [changeGroupQuality, { isLoading: isStatusUpdating }] = useChangeABTestGroupMutation();
  const handleChangeStatus = (newValue) => {
    if (newValue === value) {
      return;
    }
    changeGroupQuality({
      studioId: foundGame?.studioId,
      name: data.name, // name is needed for email sending
      type: type || 'ABTEST', // type is needed for email sending
      bundleId: data.bundleId, // name is needed for email sending
      quality: newValue,
      id: data.id,
    });
  };
  const popover = (
    <Popover>
      <ListGroup variant="flush" className="rounded ">
        {Object.keys(options).map((key) => (
          <ListGroup.Item
            key={key}
            action
            onClick={() => {
              handleChangeStatus(key);
              setShow(false);
            }}
            className="d-flex align-items-center"
          >
            <span className={`p-1 me-2 bg-${options[key].color} rounded-circle`} /> {options[key].title}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Popover>
  );
  return (
    <OverlayTrigger
      rootClose
      rootCloseEvent="mousedown"
      show={show}
      onToggle={(isClosed) => {
        if (!isClosed) setShow(false);
      }}
      trigger={editable ? 'click' : 'none'}
      placement={placement}
      overlay={popover}
    >
      <div>
        {isStatusUpdating ? (
          <div className="d-flex justify-content-center ">
            <Loader parentStyle="loader" size={38} color={'#3F96C7'} />
          </div>
        ) : (
          <Button
            onClick={() => {
              setShow(!show);
            }}
            variant="outline-light"
            disabled={!editable}
            className={`p-2 w-100 text-nowrap border-${options[value]?.color}`}
          >
            <div className="d-flex align-items-center justify-content-center">
              <span className={` p-2 bg-${options[value]?.color}  rounded-circle`} />
              <span className={`fs-7 ms-2 text-${options[value]?.color}`}> {options[value]?.title}</span>
            </div>
          </Button>
        )}
      </div>
    </OverlayTrigger>
  );
};

export default AbTestQualityDropdown;
