import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { hmac256 } from '../../utils/common';
const baseUrl = process.env.REACT_APP_MAIL_API;

export const sendEmailApi = createApi({
  reducerPath: 'sendEmailApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['textMessages'],
  endpoints: (builder) => ({
    sendTextMessage: builder.mutation({
      query: ({ subject, to, from, html }) => ({
        url: '/send',
        method: 'POST',
        body: {
          subject,
          to,
          from,
          html,
        },
        headers: {
          Authorization: hmac256({
            subject,
            to,
            from,
            html,
          }),
        },
      }),
    }),
  }),
});

export const { useSendTextMessageMutation } = sendEmailApi;
