import * as PropTypes from 'prop-types';

const AdIcon = ({ size, color }) => {
  return (
    <svg fill={color} height={size} width={size} viewBox="0 0 16 16">
      <path d="M11.35 8.337c0-.699-.42-1.138-1.001-1.138-.584 0-.954.444-.954 1.239v.453c0 .8.374 1.248.972 1.248.588 0 .984-.44.984-1.2v-.602zm-5.413.237-.734-2.426H5.15l-.734 2.426h1.52z" />
      <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm6.209 6.32c0-1.28.694-2.044 1.753-2.044.655 0 1.156.294 1.336.769h.053v-2.36h1.16V11h-1.138v-.747h-.057c-.145.474-.69.804-1.367.804-1.055 0-1.74-.764-1.74-2.043v-.695zm-4.04 1.138L3.7 11H2.5l2.013-5.999H5.9L7.905 11H6.644l-.47-1.542H4.17z" />
    </svg>
  );
};
AdIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

AdIcon.defaultProps = {
  size: 24,
  color: 'currentColor',
};

export default AdIcon;
