import moment from 'moment/moment';
import TapNationLogo from '../../../assets/logoTapNation/LogoTapNation40.png';

const MetricsTable = ({ headers, report, totals }) => {
  return (
    <div className="table-responsive">
      <table className="fs-7 table table-hover align-middle bg-white rounded overflow-hidden">
        <thead>
          <tr className="bg-light-400">
            {headers.map((head) => (
              <th key={head.key}>{head.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {report.map((el, index) => (
            <tr className="" key={index}>
              {headers.map((head) => {
                let d = head.key;
                if (d === 'month') el.month = el.date;
                if (typeof el[d] === 'undefined' || el[d] === null) return <td>-</td>;
                switch (d) {
                  case 'date':
                    return <td key={d + index}>{moment(el.date).format('YYYY-MM-DD')}</td>;
                  case 'month':
                    return <td key={d + index}>{moment(el.date).format('YYYY-MM')}</td>;
                  case 'country':
                    return (
                      <td key={d + index}>
                        <img
                          alt={el['country_name']}
                          width={34}
                          height={24}
                          className="overflow-hidden img-fit-contain me-1"
                          src={`//storage.googleapis.com/tn_icons/countries/${el?.['country'].toLowerCase()}.png`}
                        />
                        {el['country_name']}
                      </td>
                    );
                  case 'application':
                    return (
                      <td key={d + index}>
                        <img
                          width={34}
                          height={34}
                          alt="application"
                          className="overflow-hidden img-fit-contain me-1"
                          src={`//storage.googleapis.com/tn_icons/apps/${el?.['store_id']?.toLowerCase()}.png`}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = TapNationLogo;
                          }}
                        />
                        {el[d] || '-'}
                      </td>
                    );
                  default:
                    return <td key={d + index}>{el[d]}</td>;
                }
              })}
            </tr>
          ))}
        </tbody>

        <tfoot className="border-top">
          <tr className="bg-light-400">
            {headers.map((head, index) =>
              !index ? (
                <th key={head.key}>Total/Avg:</th>
              ) : totals?.[head.key] ? (
                <td key={head.key}>{totals[head.key]}</td>
              ) : (
                <td></td>
              ),
            )}
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default MetricsTable;
