import React from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedTemplate, selectTemplates, setSelectedTemplate } from '../../../data/slices/gameConfigSlice';

const ConfigsDropdown = () => {
  const dispatch = useDispatch();
  const handleTemplateChange = (event) => {
    dispatch(setSelectedTemplate(event.target.value));
  };
  const selectedTemplate = useSelector(selectSelectedTemplate);
  const templates = useSelector(selectTemplates);
  return (
    <div className="d-flex align-items-center">
      <Form.Select onChange={handleTemplateChange} value={selectedTemplate}>
        <option value="">Select a template</option>
        {templates?.map((template) => (
          <option key={template.value} value={template.value}>
            {template.label}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

export default ConfigsDropdown;
