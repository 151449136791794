import { Button, Card, Form, InputGroup } from 'react-bootstrap';
import SendIcon from '../../../Components/Icons/SendIcon';
import { useState } from 'react';
import {
  useDeleteChatHistoryMutation,
  useGetChatHistoryListQuery,
  useGetChatHistoryMutation,
} from '../../../data/api/openAiApi';
import Loader from '../../../Components/Loader';
import moment from 'moment';
import EditIcon from '../../../Components/Icons/EditIcon';
import DeleteIcon from '../../../Components/Icons/DeleteIcon';
import { confirmAlert } from '../../../Components/ConfirmModal';
import EditTitleModal from './EditTitleModal';
import MessageStream from './MessageStream';

const ChatGptStream = () => {
  const [aiModel, setAiModel] = useState('gpt-4o');
  const [selected, setSelected] = useState('');
  const [edit, setEdit] = useState('');
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);

  const { data: historyList, isFetching: isLoadingHistory, error: historyError } = useGetChatHistoryListQuery(aiModel);
  const [getHistory, { isLoading: isLoadingChat, error: errorChat }] = useGetChatHistoryMutation();
  const [deleteHistory, { isFetching: isDeleting, error: errorDelete }] = useDeleteChatHistoryMutation();

  const handleSend = () => {
    if (!input) return false;
    let msg = { role: 'user', time: Date.now(), content: input };
    let aiMsg = {
      time: Date.now() + 5,
      prompt: input,
      role: 'assistant',
    };
    let title = selected;
    if (!selected) {
      title = input.trim().substring(0, 15) + '-' + (moment().utc().valueOf() % 1_000_000);
      setSelected(title);
      aiMsg.clearHistory = true;
    }

    setMessages((val) => [aiMsg, msg, ...val]);
    setInput('');
  };
  const handleEnter = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend(input);
    }
  };
  const newChat = () => {
    setMessages([]);
    setSelected('');
    setInput('');
  };
  const selectChat = (title) => {
    if (selected === title) return false;
    setMessages([]);
    setSelected(title);
    getHistory({ title, model: aiModel })
      .unwrap()
      .then((resp) => {
        setMessages(resp);
      });
  };
  const editTitle = (title) => {
    setEdit(title);
  };
  const deleteChat = (title) => {
    confirmAlert({
      title: 'Are you sure?',
      desc: 'Are you sure you want to delete chat history?',
      variant: 'warning',
    })
      .then(() => {
        deleteHistory({ title, model: aiModel })
          .unwrap()
          .then((resp) => {
            if (selected === title) newChat();
          });
      })
      .catch((err) => {});
  };
  const handleModelChange = (event) => {
    setAiModel(event.target.value);
    newChat();
  };
  return (
    <div className="d-flex flex-basis-0 overflow-hidden flex-column flex-grow-1  mb-4">
      {edit && (
        <EditTitleModal
          title={edit}
          titles={historyList}
          model={aiModel}
          onSubmit={(newTitle) => {
            if (edit === selected) setSelected(newTitle);
            setEdit('');
          }}
        />
      )}
      <div className=" overflow-hidden bg-white shadow-sm d-flex flex-grow-1">
        <div className="d-flex px-3 flex-column-reverse flex-grow-1 overflow-auto ">
          {isLoadingChat && (
            <Card className={`my-2 bg-pale-white`}>
              <Card.Body className="d-flex fs-7 py-2">
                <Loader parentStyle="me-2" size={20} color={'#3F96C7'} />
                Loading...
              </Card.Body>
            </Card>
          )}
          {messages?.map((el, index) => (
            <Card
              key={el.time}
              className={`my-2 fs-7 ${el?.role === 'assistant' ? 'bg-primary-100' : 'bg-pale-white'}`}
            >
              <Card.Body className="py-2">
                <pre>
                  {/*<div>{formatMessage(el?.content)}</div>*/}
                  <MessageStream aiModel={aiModel} title={selected} message={el} />
                </pre>
              </Card.Body>
            </Card>
          ))}
          <Card className={`bg-primary-100 fs-7 my-2`}>
            <Card.Body className="py-2">
              <pre>Hello! How can I help you today?</pre>
            </Card.Body>
          </Card>
        </div>
        {/** Chat History */}
        <div className="d-flex flex-column p-2 flex-shrink-0 border-start " style={{ width: '20%' }}>
          <Button variant="info" size="sm" className="w-100 mb-1" onClick={newChat}>
            New Chat
          </Button>
          <div className="overflow-scroll flex-column d-flex flex-grow-1  text-wrap">
            {(historyError || errorDelete || errorChat) && !isLoadingHistory && (
              <div className="alert alert-danger p-2 mb-2 fs-7 " role="alert">
                {errorDelete?.data?.message ||
                  errorChat?.data?.message ||
                  historyError?.data?.message ||
                  'Something went wrong.'}
              </div>
            )}
            {isLoadingHistory || isDeleting ? (
              <div className="d-flex justify-content-center">
                <Loader parentStyle="me-2" size={20} color={'#3F96C7'} />
              </div>
            ) : (
              !!historyList?.length &&
              historyList?.map((el, index) => (
                <Card
                  key={index}
                  className={`my-2 ${selected === el ? 'bg-primary-100' : 'bg-pale-white'} cursor-pointer`}
                  onClick={() => selectChat(el)}
                >
                  <Card.Body className="d-flex px-2 py-1 align-items-center">
                    <span className="text-truncate fs-7 ">{el} </span>
                    <Button
                      size="sm"
                      className="p-1 ms-auto me-1"
                      variant="outline-light"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        editTitle(el);
                      }}
                    >
                      <EditIcon className="me-1 cursor-pointer" size={14} />
                    </Button>
                    <Button
                      size="sm"
                      className="p-1"
                      variant="outline-light"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        deleteChat(el);
                      }}
                    >
                      <DeleteIcon size={14} className="cursor-pointer" />
                    </Button>
                  </Card.Body>
                </Card>
              ))
            )}
          </div>
        </div>
      </div>
      <Form className="px-3">
        <Form.Group className="my-3 d-flex align-items-center">
          <Form.Label className="m-0">Ai Model</Form.Label>
          <div className="ms-2 col-sm-3 col-xl-1 col-md-2">
            <Form.Select value={aiModel} onChange={handleModelChange}>
              {['gpt-4o', 'gpt-4', 'gpt-3.5-turbo'].map((value, index) => (
                <option value={value} key={index}>
                  {value}
                </option>
              ))}
            </Form.Select>
          </div>
        </Form.Group>
        <InputGroup className="mb-2">
          <Form.Control
            as="textarea"
            rows={4}
            resize="none"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleEnter}
            className="no-resize"
            placeholder="Send a message"
            aria-label="Send a message"
            disabled={isLoadingChat}
          />
          <Button variant="outline-light send-button" onClick={handleSend}>
            <SendIcon />
          </Button>
        </InputGroup>

        <p className="fs-8 m-0  text-secondary text-end">
          Any chat session history will be deleted after 7 days of inactivity
        </p>
      </Form>
    </div>
  );
};

export default ChatGptStream;
