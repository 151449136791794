import { Button } from 'react-bootstrap';
import { confirmAlert } from '../ConfirmModal';

const ConfirmAlertDemo = () => {
  const handleClick = () => {
    confirmAlert({
      title: 'Are you sure?',
      desc: 'Are you sure???????',
      variant: 'warning',
    }).catch((err) => {});
  };
  return (
    <div>
      <Button onClick={handleClick}>Click</Button>
    </div>
  );
};

export default ConfirmAlertDemo;
