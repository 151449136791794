import React from 'react';
import ArrowStickDown from '../Icons/ArrowStickDown';

const MetricVariation = ({ confidence, diffFromBase }) => {
  return (
    <div className={`cursor-pointer p-1 rounded ${confidence > 0.95 ? 'bg-light-400' : ''}`}>
      <div className={diffFromBase > 0 ? 'text-success' : diffFromBase < 0 ? 'text-danger' : ''}>
        {Math.sign(diffFromBase) !== 0 && (
          <ArrowStickDown style={{ marginRight: 2 }} size={8} rotate={Math.sign(diffFromBase) > 0 ? 'up' : ''} />
        )}
        {Math.abs(Number(diffFromBase)).toFixed(1)}%
      </div>
    </div>
  );
};

export default MetricVariation;
