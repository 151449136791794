import * as PropTypes from 'prop-types';

const SendIcon = ({ size, color }) => {
  return (
    <svg
      fill="none"
      height={size}
      width={size}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" stroke="none" />
      <line x1="10" x2="21" y1="14" y2="3" />
      <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5" />
    </svg>
  );
};
SendIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

SendIcon.defaultProps = {
  size: 24,
  color: 'currentColor',
};

export default SendIcon;
