import React from 'react';
import { Modal } from 'react-bootstrap';
import { useListUsersQuery } from '../../../data/api/userApi';
import UserTable from '../userTable';

const UserModal = ({ onHide, data }) => {
  const { data: user } = useListUsersQuery(data.studioId);

  return (
    <Modal centered size={'xl'} show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>users</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mt-3">
          <UserTable data={user} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserModal;
