import axios from 'axios';
import { hmac256 } from '../../utils/common';

export const CreateTrelloCard = (gameName, appId, videosUrls) => {
  const url = process.env.REACT_APP_TRELLO_API + '/create_ticket_videos_crea';

  let os = 'iOS';
  if (isNaN(appId)) {
    os = 'Android';
  }

  const postInfos = {
    gameName: gameName,
    os: os,
    videosUrls: videosUrls,
    link: 'https://publishing-dashboard.tap-nation.io' + window?.location?.pathname,
  };

  return axios.post(url, postInfos, {
    headers: {
      Authorization: hmac256(JSON.stringify(postInfos)),
      'Content-Type': 'application/json',
    },
  });
};
