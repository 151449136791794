import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import AddIcon from '../../../Components/Icons/AddIcon';
import infosViewSchema from './Steps/validationSchemas/infosViewSchema';
import { useDispatch, useSelector } from 'react-redux';
import { studioSelectedSelector, userSelector } from '../../../data/slices/user';
import { studioApi, useCreateDraftGameMutation } from '../../../data/api/studioApi';
import { useSetState } from '../../../utils/customHooks';
import { GetLogo } from '../../../data/api/Querys';
import Loader from '../../../Components/Loader';
import InfosView from './Steps/InfosView';
import SDKView from './Steps/SDKView';
import { formatJoiErrorsArray } from '../../../utils/validation';
import { camelToSnakeCaseObj } from '../../../utils/converters';
import { generatePath, useHistory } from 'react-router-dom';
import { R_GAME_DRAFT } from '../../../constants/routes';
import { Restricted, useUserPermission } from '../../../Components/permissions/UserPermissions';

const CreateGameModal = () => {
  const history = useHistory();
  const user = useSelector(userSelector);
  const studioSelected = useSelector(studioSelectedSelector);
  const selectStudioPermission = useUserPermission('select.studio');
  const dispatch = useDispatch();
  const [createDraftGame] = useCreateDraftGameMutation();

  const [showForm, setShowForm] = useState(false);
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const infosDefaultVal = {
    studioId: selectStudioPermission
      ? studioSelected?.studioId || ''
      : user?.studios && Object.keys(user?.studios)?.[0],
    appName: '',
    bundleId: '',
    store: 'google',
    appId: '',
    bizdevEmail: '',
    category: '',
    tags: [],
    todoList: [],
    logoUrl: '',
    advertisingSdk: '',
  };
  const [infos, setInfos] = useSetState(infosDefaultVal);
  useEffect(() => {
    if (Object.keys(errors).length) {
      setErrors({});
    }
  }, [infos]);

  const handleSubmitGame = () => {
    setLoading(true);

    createDraftGame({ ...camelToSnakeCaseObj(infos) })
      .unwrap()
      .then((response) => {
        dispatch(studioApi.util.invalidateTags(['draftGames']));

        history.push(generatePath(R_GAME_DRAFT, { id: response?.id }));
        handleClose();
      })
      .catch((error) => {
        setLoading(false);
        setErrors({
          error: error?.data?.message || 'Something went wrong',
        });
      });
  };

  const handlePrev = () => {
    setStep(step - 1);
    setErrors({});
  };
  const handleNext = () => {
    if (step === 1) {
      let tmp = infos;
      if (tmp.store === 'google') {
        tmp.appId = infos.bundleId;
      }
      const { error } = infosViewSchema.validate(camelToSnakeCaseObj(tmp));
      if (error) {
        setErrors(formatJoiErrorsArray(error.details));
        return false;
      }
      setInfos(tmp);
      getLogoFromStore(tmp.appId);
      return setStep(2);
    }
    if (step === 2 && infos.todoList.length < 4) {
      setErrors({ error: 'All fields are mandatory.' });
      return false;
    }
    return handleSubmitGame();
  };
  const getLogoFromStore = (appId) => {
    GetLogo(appId)
      .then((res) => {
        let logoUrl = '';
        if (res.data.logo_url) {
          logoUrl = res.data.logo_url;
        }
        setInfos({ logoUrl });
      })
      .catch(() => {
        setLoading(false);
        setErrors({
          error: "Please check your Store Id, app couldn't be found on the store.",
        });
      });
  };
  const handleClose = () => {
    setStep(1);
    setInfos(infosDefaultVal);
    setErrors({});
    setLoading(false);
    setShowForm(false);
  };
  return (
    <>
      <Restricted permission="draftGame.create">
        <Button type="button" variant="success" className="d-flex" onClick={() => setShowForm(true)} id="create-game">
          <AddIcon size={20} className="me-1" /> Submit a Game
        </Button>
      </Restricted>
      <Modal backdrop="static" centered size={'md'} show={showForm} onHide={handleClose} id="create-game-modal">
        <Modal.Header closeButton>
          <Modal.Title>Create new Game</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors?.error && !loading && (
            <div className="alert alert-danger" role="alert">
              {errors?.error}
            </div>
          )}
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : step === 1 ? (
            <InfosView onNewGame errors={errors} data={infos} setData={setInfos} />
          ) : (
            <SDKView data={infos} setData={setInfos} />
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <div className="text-muted fs-7">Step {step} of 2</div>
          <div className="ms-auto">
            {step > 1 && (
              <Button
                disabled={loading}
                variant="light"
                className="me-3 back-button"
                type="button"
                onClick={handlePrev}
              >
                Back
              </Button>
            )}
            <Button disabled={loading} variant="primary" type="button" onClick={handleNext} id="create-game-button">
              {step === 2 ? 'Create' : 'Next'}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateGameModal;
