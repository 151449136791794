import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import DashboardCard from './DashboardCard';
import {
  R_ANALYTICS_ACQUISITION,
  R_ANALYTICS_MONETIZATION,
  R_GAMES,
  R_MARKET_INSIGHT,
  R_SDK,
  R_TOP_APPS,
} from '../../constants/routes';
import LogoPng from '../../assets/logoTapNation/dashbanner2.jpg';
import CreateGameModal from '../Game/CreateGame/CreateGameModal';
import DashboardTable from './DashboardTable';
import { useUserPermission } from '../../Components/permissions/UserPermissions';

function Dashboard() {
  const seeRunningGames = useUserPermission('show.running.tests');
  const addGame = useUserPermission('game.create');

  const infoArray = [
    seeRunningGames && {
      title: `A/B tests`,
      component: <DashboardTable />,
    },
    addGame && {
      title: 'Game Submission',
      subtitle: "Just submit your game, we'll take care of the rest!",
      buttonTitle: 'Submit A Game',
      component: <CreateGameModal />,
    },
    {
      title: 'Top Games Chart',
      subtitle: 'Check top performing games in US',
      buttonTitle: 'Top Games',
      link: R_TOP_APPS,
    },
    {
      title: 'FunGame SDK',
      subtitle: 'Integrate FunGame SDK smoothly',
      buttonTitle: 'Integration Guide',
      link: R_SDK,
    },
    {
      title: 'Weekly Market Insights',
      subtitle: 'Check our weekly market insights to catch trends',
      buttonTitle: 'Market Insights',
      link: R_MARKET_INSIGHT,
    },
    {
      title: 'Your Games',
      subtitle: 'Check the performances of your games',
      buttonTitle: 'Games',
      link: R_GAMES.replace('/:filter?', ''),
    },
    {
      title: 'Monetization Analytics',
      subtitle: 'Leverage advanced reporting of game revenue',
      buttonTitle: 'Monetization Analytics',
      link: R_ANALYTICS_MONETIZATION,
    },
    {
      title: 'UA Analytics',
      subtitle: 'Gain powerful insights about UA efforts of your games',
      buttonTitle: 'UA Analytics',
      link: R_ANALYTICS_ACQUISITION,
    },
  ].filter(Boolean);
  const handleClick = () => {
    window.location.href = 'https://www.tap-nation.io/blog/';
  };

  return (
    <div>
      <div className="container-fluid p-0" style={{ backgroundColor: '#02081C' }}>
        <div
          className="d-flex px-3 py-5 text-sh flex-column justify-content-center"
          style={{
            margin: '0 auto',
            maxWidth: 1140,
            backgroundImage: `url(${LogoPng})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'right',
            color: 'white',
            textShadow: '0 0 4px #000',
          }}
        >
          <div>
            <h1>Publishing Platform</h1>
            <h4>Let's make the best games together!</h4>
            <h6>Get insights, trends, and strategies to power up your game.</h6>
            <div className="btn btn-primary" onClick={handleClick}>
              Learn More
            </div>
          </div>
        </div>
      </div>
      <Container className="mt-3">
        <Row>
          {infoArray.map((info, index) => (
            <Col key={index} lg={6} xs={12} className="mb-3">
              <DashboardCard {...info} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Dashboard;
