import React from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import { R_AB_TEST } from '../../../constants/routes';
import { useSetState } from '../../../utils/customHooks';
import { useGetGamesQuery } from '../../../data/api/studioApi';
import TestsTable from './AbTests/TestsTable';
import Loader from '../../../Components/Loader';
import GameInfoCard from '../../../Components/GameInfoCard';
import ConfigTable from './Configs/ConfigTable';
import './gameABTestList.scss';
import AbTestCreateBtns from '../CreateAbTestModal/AbTestCreateBtns';
import GameAbTestListFilters from './_Components/GameAbTestListFilters';
import { useSelector } from 'react-redux';
import { getGameById } from '../../../data/slices/studio';

function GameAbtestList({ type: propType, disableTopBar }) {
  const { id, type: urlType } = useParams();
  let type = (propType || urlType || 'ABTEST').toUpperCase();
  const [state, setState] = useSetState({
    type,
    id,
    search: '',
    status: ['APPROVED', 'PENDING'],
    os: 'all',
    deviceId: [],
    country: [],
    store: [],
    build: '',
  });

  const { isLoading: isLoadingGames } = useGetGamesQuery();
  const foundGame = useSelector(getGameById(id));

  return (
    <div className="container-fluid" id="ab-test-page">
      {!disableTopBar && (
        <div className="row top-bar mb-4 px-md-2 bg-white">
          <div className="col-12 d-flex align-items-center">
            <div className="top-bar-tab-btns">
              <Link className={`btn ${type === 'ABTEST' ? 'active' : ''}`} to={generatePath(R_AB_TEST, { id: id })}>
                A/B Tests
              </Link>
              <Link
                className={`btn ${type === 'CONFIG' ? 'active' : ''}`}
                to={generatePath(R_AB_TEST, { id: id, type: 'CONFIG' })}
                id="config"
              >
                Configs
              </Link>
            </div>
            <div className="ms-auto d-flex">
              {foundGame?.bundleId && foundGame?.status !== 'ARCHIVED' && (
                <div className="d-flex gap-2">
                  <AbTestCreateBtns state={state} type={type} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isLoadingGames ? (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '64px' }}>
          <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
        </div>
      ) : (
        <div className="row mb-4">
          <div className="col-12 col-md-6 col-xl-4">
            <GameInfoCard id={id} />
          </div>
        </div>
      )}
      <div className="row g-3 ">
        <div className="col-xl-12 ">
          <GameAbTestListFilters state={state} setState={setState} type={type} />
        </div>
        <div className="col-12">
          {type === 'ABTEST' ? <TestsTable type={type} data={state} /> : <ConfigTable type={type} data={state} />}
        </div>
      </div>
    </div>
  );
}

export default GameAbtestList;
