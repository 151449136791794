const sortingGames = (type, games) => {
  const types = {
    idUp: 'id',
    idDown: 'id',

    nameUp: 'appName',
    nameDown: 'appName',

    cpiUp: 'avgCpi',
    cpiDown: 'avgCpi',

    d1Up: 'avgRD1',
    d1Down: 'avgRD1',

    d7Up: 'avgRD7',
    d7Down: 'avgRD7',

    dateUp: 'updateTime',
    dateDown: 'updateTime',

    sdkUp: 'todolist',
    sdkDown: 'todolist',
  };

  const sortProperty = types[type];
  let sorted;

  if (type.includes('sdk')) {
    if (type.includes('Up')) {
      sorted = [...games].sort((a, b) => a['todoList'].split(',').length - b['todoList'].split(',').length);
    } else {
      sorted = [...games].sort((a, b) => b['todoList'].split(',').length - a['todoList'].split(',').length);
    }
  } else if (type.includes('date')) {
    if (type.includes('Up')) {
      sorted = [...games].sort((a, b) => new Date(a[sortProperty]) - new Date(b[sortProperty]));
    } else {
      sorted = [...games].sort((a, b) => new Date(b[sortProperty]) - new Date(a[sortProperty]));
    }
  } else if (type.includes('name')) {
    if (type.includes('Up')) {
      sorted = [...games].sort((a, b) => a[sortProperty].localeCompare(b[sortProperty]));
    } else {
      sorted = [...games].sort((a, b) => b[sortProperty].localeCompare(a[sortProperty]));
    }
  } else {
    if (type.includes('Up')) {
      sorted = [...games].sort((a, b) => a[sortProperty] - b[sortProperty]);
    } else {
      sorted = [...games].sort((a, b) => b[sortProperty] - a[sortProperty]);
    }
  }

  return sorted;
};

export default sortingGames;
