import AddIcon from '../Icons/AddIcon';
import InfoIcon from '../Icons/InfoIcon';
import CardIcon from '../Icons/CardIcon';
import AbTestIcon from '../Icons/AbTestIcon';
import AccountProfileIcon from '../Icons/AccountProfileIcon';
import AppleLogo from '../Icons/adjustLogo';
import AndroidIcon from '../Icons/AndroidIcon';
import AppleIcon from '../Icons/AppleIcon';
import ArrowDown from '../Icons/ArrowDown';
import ArrowUpFull from '../Icons/ArrowUpFull';
import BlockIcon from '../Icons/BlockIcon';
import CalendarIcon from '../Icons/CalendarIcon';
import CancelIcon from '../Icons/CancelIcon';
import CheckmarkIcon from '../Icons/CheckmarkIcon';
import ChevronRightIcon from '../Icons/ChevronRightIcon';
import CloudDownlaod from '../Icons/CloudDownLoad';
import DeleteIcon from '../Icons/DeleteIcon';
import Document from '../Icons/Document';
import DownloadArrow from '../Icons/DownloadArrow';
import EditIcon from '../Icons/EditIcon';
import EmptyHourGlassIcon from '../Icons/EmptyHourGlassIcon';
import GaLogo from '../Icons/GaLogo';
import GamesIcon from '../Icons/GamesIcon';
import CommentIcon from '../Icons/CommentIcon';
import ChevronLeftIcon from '../Icons/ChevronLeftIcon';
import MarketInsightIcon from '../Icons/MarketInsightIcon';
import Logout from '../Icons/Logout';
import Publishing from '../Icons/Publishing';
import SearchIcon from '../Icons/SearchIcon';
import UploadIcon from '../Icons/UploadIcon';
import SettingsIcon from '../Icons/SettingsIcon';
import PeopleGroupIcon from '../Icons/PeopleGroupIcon';
import DocumentDownloadIcon from '../Icons/DocumentDownloadIcon';
import ArrowStickDown from '../Icons/ArrowStickDown';
import PlayIcon from '../Icons/PlayIcon';
import EearthIcon from '../Icons/EearthIcon';
import FolderIcon from '../Icons/FolderIcon';
import HomeIcon from '../Icons/HomeIcon';
import AiIcon from '../Icons/AiIcon';
import SendIcon from '../Icons/SendIcon';
import AdIcon from '../Icons/AdIcon';
import AnalyticsIcon from '../Icons/AnalyticsIcon';
import StudioIcon from '../Icons/StudioIcon';
import AppStoreBadgeIcon from '../Icons/AppStoreBadgeIcon';
import ArchiveIcon from '../Icons/ArchiveIcon';
import CopyIcon from '../Icons/CopyIcon';
import EyeSlashIcon from '../Icons/EyeSlashIcon';
import EyeVisibleIcon from '../Icons/EyeVisibleIcon';
import MoonIcon from '../Icons/MoonIcon';
import PlayStoreBadgeIcon from '../Icons/PlayStoreBadgeIcon';
import SunIcon from '../Icons/SunIcon';
import SyncIcon from '../Icons/SyncIcon';
import TopAppsIconIcon from '../Icons/TopAppsIconIcon';
const Icons = () => {
  return (
    <div>
      AbTestIcon:
      <AbTestIcon />
      AccountProfile:
      <AccountProfileIcon />
      AddIcon:
      <AddIcon />
      AdIcon:
      <AdIcon />
      Adjust AppleLogo:
      <AppleLogo />
      AiIcon:
      <AiIcon size={20} />
      AnalyticsIcon:
      <AnalyticsIcon />
      AndroidIcon:
      <AndroidIcon />
      AppleIcon:
      <AppleIcon />
      AppStoreBadgeIcon:
      <AppStoreBadgeIcon />
      ArchiveIcon:
      <ArchiveIcon />
      ArrowDown:
      <ArrowDown />
      ArrowStickDown:
      <ArrowStickDown />
      ArrowUpFull:
      <ArrowUpFull />
      BlockIcon:
      <BlockIcon />
      CalendarIcon:
      <CalendarIcon />
      CancelIcon:
      <CancelIcon />
      CardIcon:
      <CardIcon />
      CheckmarkIcon:
      <CheckmarkIcon />
      ChevronLeftIcon:
      <ChevronLeftIcon />
      ChevronRightIcon:
      <ChevronRightIcon />
      CloudDownlaod:
      <CloudDownlaod />
      CommentIcon:
      <CommentIcon />
      CopyIcon:
      <CopyIcon />
      DeleteIcon:
      <DeleteIcon />
      Document:
      <Document />
      DocumentDownloadIcon:
      <DocumentDownloadIcon />
      DownloadArrow:
      <DownloadArrow />
      Edit:
      <EditIcon />
      EearthIcon:
      <EearthIcon />
      EmptyHourGlassIcon:
      <EmptyHourGlassIcon />
      EyeSlashIcon:
      <EyeSlashIcon />
      EyeVisibleIcon:
      <EyeVisibleIcon />
      FolderIcon:
      <FolderIcon />
      GaLogo:
      <GaLogo />
      GamesIcon:
      <GamesIcon />
      InfoIcon:
      <InfoIcon />
      HomeIcon:
      <HomeIcon />
      InfoIcon:
      <InfoIcon />
      Logout:
      <Logout />
      MarketInsightIcon:
      <MarketInsightIcon />
      MoonIcon:
      <MoonIcon />
      PeopleGroupIcon:
      <PeopleGroupIcon />
      PlayIcon:
      <PlayIcon />
      PlayStoreBadgeIcon:
      <PlayStoreBadgeIcon />
      Publishing:
      <Publishing />
      SearchIcon:
      <SearchIcon />
      SendIcon:
      <SendIcon />
      SettingsIcon:
      <SettingsIcon />
      studioIcon:
      <StudioIcon />
      SunIcon:
      <SunIcon />
      SyncIcon:
      <SyncIcon />
      TopAppsIconIcon:
      <TopAppsIconIcon />
      UploadIcon:
      <UploadIcon />
    </div>
  );
};

export default Icons;
