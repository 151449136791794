import { useState } from 'react';
import { Accordion, Button, Col, Form, FormControl } from 'react-bootstrap';
import DeleteIcon from '../../../../Components/Icons/DeleteIcon';
import ArrowDown from '../../../../Components/Icons/ArrowDown';
import { useSetState } from '../../../../utils/customHooks';
import Params from '../_Components/Params';
import { formatJoiErrorsArray } from '../../../../utils/validation';
import cohortParamsSchema from '../_validationSchemas/cohortParamsSchema';
import cohortSchema from '../_validationSchemas/cohortSchema';

const CohortList = ({ data, setData, eligible }) => {
  /** COHORTS FUNCTIONS */
  const [activeKey, setActiveKey] = useState(null);
  const [errors, setErrors] = useState({});
  const [state, setState] = useSetState({
    name: '',
    cohort: 0,
    params: [{ name: '', value: '' }],
  });

  const handleAddCohort = () => {
    setData({
      cohorts: [
        ...data.cohorts,
        {
          name: '',
          cohort: 0,
          params: [{ name: '', value: '' }],
        },
      ],
    });
    setState({
      name: '',
      cohort: 0,
      params: [{ name: '', value: '' }],
    });
    toggleAccordion(data.cohorts.length);
  };

  const handleDeleteCohort = (index) => {
    let newArray = [...data.cohorts];

    if (newArray.length > 1) {
      newArray.splice(index, 1);
      setData({ cohorts: newArray });
      setErrors({});
    }
  };

  const handleTextChange = (txt, field) => {
    let text = txt.match(/([a-zA-Z0-9_-])/g)?.join('') || '';
    if (text || text === '') {
      setErrors({});
      return setState({ [field]: text.split(' ')?.join('') || '' });
    } else {
      return null;
    }
  };

  /** PARAMS FUNCTIONS */

  const handleParamTextChange = (text, field, index) => {
    let newArray = [...state.params];
    newArray[index][field] = text?.split(' ')?.join('');
    setState({
      params: newArray,
    });
    setErrors({});
  };

  const handleAddParam = () => {
    let newArray = [...state.params, { name: 'valid', value: 'length' }];
    const { error } = cohortParamsSchema.validate(newArray);
    if (error) {
      setErrors(formatJoiErrorsArray(error.details));
      return false;
    }
    setState({
      params: [...state.params, { name: '', value: '' }],
    });
  };

  const handleDeleteParam = (index) => {
    const tmp = [...state.params];
    tmp.splice(index, 1);
    setState({
      params: tmp,
    });
  };

  const toggleAccordion = (index) => {
    if (index === activeKey) {
      setActiveKey(null);
      setState({
        name: '',
        cohort: 0,
        params: [{ name: '', value: '' }],
      });
      setErrors({});
      return;
    }
    if (data?.cohorts[index])
      setState({ ...data?.cohorts[index], params: data?.cohorts[index]['params'].map((el) => ({ ...el })) });
    setActiveKey(index);
  };

  const handleSaveCohort = (e) => {
    e.stopPropagation();
    let params = state.params; //.filter(el=> el.name && el.value)
    let newArray = { ...state, cohort: data.totalP / data.cohorts.length, params };

    const { error } = cohortSchema.validate(newArray);
    if (error) {
      setErrors(formatJoiErrorsArray(error.details));
      return false;
    }
    setData({ cohorts: data.cohorts.map((elem, index) => (activeKey === index ? newArray : elem)) });
    toggleAccordion(activeKey);
  };
  return (
    <>
      <Col xs={12}>
        <p className="fs-7 text-gray-blue m-0">
          Cohort list<span className="text-danger fs-7">*</span>
        </p>
        {eligible && (
          <div className="card py-2 px-3 mb-1 bg-light-400">
            <span className="fs-7 text-gray-blue">BaseCohort</span>
          </div>
        )}
        <Accordion activeKey={[activeKey]}>
          {!!data.cohorts?.length &&
            data.cohorts.map((elem, index) => {
              return (
                <div key={index} className={` card mb-1`}>
                  <div
                    className={`${
                      index === activeKey ? 'border-bottom align-items-center' : 'align-items-start'
                    } cursor-pointer  py-2 px-3 d-flex`}
                    onClick={() => toggleAccordion(index)}
                  >
                    {activeKey === index ? (
                      <Form.Group
                        onClick={(e) => e.stopPropagation()}
                        className="position-relative me-2 flex-grow-1"
                        controlId={`cohortName${index}`}
                      >
                        <FormControl
                          type="name"
                          placeholder="Type cohort name..."
                          value={state.name}
                          isInvalid={errors.name}
                          className="fs-7"
                          onChange={(text) => handleTextChange(text.target.value, 'name')}
                          name="cohort-name"
                        />
                        <Form.Control.Feedback tooltip type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    ) : (
                      <div className="pe-2 overflow-hidden">
                        <p className="fs-7 text-gray-blue m-0 text-truncate">{elem.name || 'New Cohort'}</p>
                      </div>
                    )}
                    <div className={`ms-auto flex-shrink-0`}>
                      {activeKey === index && data.cohorts.length > 1 && (
                        <Button
                          variant="danger"
                          size="sm"
                          className="me-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteCohort(index);
                          }}
                        >
                          <DeleteIcon size={14} color="#fff" />
                        </Button>
                      )}
                      {activeKey === index && (
                        <div className="d-inline-flex" onClick={(e) => e.stopPropagation()}>
                          <Button
                            disabled={
                              !state.name ||
                              (state.params.length === 1 && (!state.params[0]?.['name'] || !state.params[0]?.['value']))
                            }
                            variant="success"
                            size="sm"
                            className="me-2"
                            onClick={handleSaveCohort}
                          >
                            Save
                          </Button>
                        </div>
                      )}
                      <ArrowDown rotate={index === activeKey ? 'up' : 'down'} size={14} />
                    </div>
                  </div>
                  {activeKey !== index && elem.params.length && elem.params[0]['name'] && (
                    <div className="card p-1 bg-light-400 m-2 mt-0">
                      <div className="table-responsive w-100">
                        <table className=" table table-sm fs-8 table-border-dashed m-0">
                          {
                            <tbody>
                              {elem.params.map((el, index) => (
                                <tr key={index}>
                                  <td width="50%" className="text-truncate">
                                    {el.name}
                                  </td>
                                  <td width="50%" className="text-truncate">
                                    {' '}
                                    {el.value}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          }
                        </table>
                      </div>
                    </div>
                  )}
                  <Accordion.Collapse eventKey={index}>
                    <div className="px-3 pt-2">
                      <Params
                        errors={errors}
                        paramsArray={state.params}
                        handleParamTextChange={handleParamTextChange}
                        handleAddParam={handleAddParam}
                        handleDeleteParam={handleDeleteParam}
                      />
                    </div>
                  </Accordion.Collapse>
                </div>
              );
            })}
        </Accordion>
      </Col>
      {(!data.cohorts.length || data.cohorts[data.cohorts.length - 1].name) && data.cohorts.length < 50 ? (
        <Button className="" variant="success" size="" onClick={handleAddCohort} id="add-cohort">
          + Add New Cohort
        </Button>
      ) : null}
    </>
  );
};

export default CohortList;
