import { useEffect, useState } from 'react';
import { useSetState } from '../../../utils/customHooks';
import { useChangeABTestGroupMutation } from '../../../data/api/abTestsApi';
import { Button, Modal } from 'react-bootstrap';
import Params from '../CreateAbTestModal/_Components/Params';
import Loader from '../../../Components/Loader';
import cohortParamsSchema from '../CreateAbTestModal/_validationSchemas/cohortParamsSchema';
import { formatJoiErrorsArray } from '../../../utils/validation';
import EditIcon from '../../../Components/Icons/EditIcon';
import { useSelector } from 'react-redux';
import { getGameByBundleId } from '../../../data/slices/abTests';

function UpdateCohortParamsModal({ abTest, editable, onClose }) {
  const [showModal, setShowModal] = useState(false);
  /** State */
  const foundGame = useSelector((state) => getGameByBundleId(state, abTest.bundleId));
  const [state, setState] = useSetState({
    studioId: '',
    error: '',
    paramsDeleted: [],
  });
  const [paramsArray, setParamsArray] = useState([]);

  useEffect(() => {
    if (abTest?.name && showModal) {
      setParamsArray(abTest?.params.length ? JSON.parse(JSON.stringify(abTest?.params)) : []);
    }
  }, [abTest, showModal]);

  const handleClose = () => {
    if (onClose) onClose();
    else setShowModal(false);
  };
  /** PARAMS FUNCTIONS */

  const handleDeleteParam = (index) => {
    let newArray = [...paramsArray];
    if (newArray.length > 1) {
      let deletedItem = newArray.splice(index, 1)[0];
      setParamsArray(newArray);
      setState({
        paramsDeleted: [...state.paramsDeleted, deletedItem],
      });
    }
  };

  const handleUndo = () => {
    const lastDeletedItem = state.paramsDeleted[state.paramsDeleted.length - 1];
    setParamsArray((newArray) => [...newArray, lastDeletedItem]);
    setState({
      paramsDeleted: state.paramsDeleted.slice(0, -1),
    });
  };

  const handleParamTextChange = (text, field, index) => {
    setState({ error: '' });
    setParamsArray((old) =>
      old.map((el, ind) => (ind === index ? { ...el, [field]: text?.split(' ')?.join(''), modified: true } : el)),
    );
  };

  const handleAddParam = () => {
    let newArray = [...paramsArray, { name: 'valid', value: 'length' }];
    const { error } = cohortParamsSchema.validate(newArray);
    if (error) {
      setState({ error: formatJoiErrorsArray(error.details) });
      return false;
    }
    setParamsArray((oldArray) => [...oldArray, { name: '', value: '' }]);
  };

  /** CHANGE CONFIG FUNCTIONS */
  const [changeGroupStatus, { isLoading }] = useChangeABTestGroupMutation();
  const handleUpdate = () => {
    const { error } = cohortParamsSchema.validate(paramsArray);
    if (error) {
      setState({ error: formatJoiErrorsArray(error.details) });
      return false;
    }
    const modifiedParams = paramsArray
      .filter((param) => param.modified || !param.id)
      .map((value) => {
        return { name: value.name, value: value.value };
      });

    let deleteParams = state.paramsDeleted.map((value) => value.id).filter(Boolean);

    const postInfos = {
      studioId: foundGame?.studioId,
      params: modifiedParams,
      paramsDeleted: deleteParams,
      id: abTest.id,
      testId: abTest.testId,
      name: abTest.name, // name is needed for email sending
      type: 'COHORT', // type is needed for email sending
      bundleId: abTest.bundleId,
      deviceId: abTest.deviceId,
    };

    changeGroupStatus(postInfos)
      .unwrap()
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        setState({ error: error?.data?.message || 'something went wrong' });
      });
  };

  return !editable ? null : (
    <>
      {!onClose && (
        <div className="btn btn-sm btn-success d-flex gap-1" onClick={() => setShowModal(true)}>
          <EditIcon size="17" color="#fff" />
          Params
        </div>
      )}
      <Modal centered dialogClassName="modal-lg" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Params</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: 400 }}>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : (
            abTest && (
              <>
                <div className="config-container position-relative">
                  <Params
                    errors={state.error}
                    paramsArray={paramsArray}
                    handleParamTextChange={handleParamTextChange}
                    handleAddParam={handleAddParam}
                    handleDeleteParam={handleDeleteParam}
                  />
                  {state.paramsDeleted.length > 0 && (
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      className="position-absolute top-0 end-0"
                      onClick={handleUndo}
                    >
                      Undo
                    </Button>
                  )}
                </div>

                {state.error && typeof state.error === 'string' && (
                  <div className="alert alert-danger">{state.error || 'Validation failed'}</div>
                )}
              </>
            )
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Abort
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateCohortParamsModal;
