import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';
import { studioApi } from '../api/studioApi';

const initialState = {};
const emptyResponse = undefined;
const gamesBundleIdSelector = studioApi.endpoints.getGames.select(undefined);

export const abTestsSlice = createSlice({
  name: 'abTests',
  initialState,
  reducers: {},
});

export const gameAbTestByIdSelector = createSelector(
  [(items) => items, (_, id) => id],
  (items, id) => items?.data?.find((el) => el.id === id) || null
);

export const getGameByBundleId = createSelector(
  [(state) => gamesBundleIdSelector(state), (_, bundleId) => bundleId],
  (data, bundleId) => {
    if (data?.data) {
      return data?.data?.find((el) => el.bundleId === bundleId);
    }
    return emptyResponse;
  }
);

export default abTestsSlice.reducer;
