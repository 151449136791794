import React from 'react';
import { Modal, Button, Col } from 'react-bootstrap';
import moment from 'moment';
import { useSetState } from '../../../utils/customHooks';
import { useChangeABTestGroupMutation } from '../../../data/api/abTestsApi';
import Loader from '../../../Components/Loader';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import { getGameByBundleId } from '../../../data/slices/abTests';

function UpdateDeviceModal({ show, onClose, abTest }) {
  /** State */
  const foundGame = useSelector((state) => getGameByBundleId(state, abTest.bundleId));
  const [state, setState] = useSetState({
    deviceId: abTest.deviceId,
    studioId: '',
  });
  /** CHANGE CONFIG FUNCTIONS */
  const [changeGroupStatus, { isLoading }] = useChangeABTestGroupMutation();
  const handleUpdate = () => {
    const postInfos = {
      studioId: foundGame?.studioId,
      deviceId: state.deviceId,
      id: abTest.id,
      name: abTest.name, // name is needed for email sending
      type: 'ABTEST', // type is needed for email sending
      bundleId: abTest.bundleId, // name is needed for email sending
    };

    changeGroupStatus(postInfos)
      .unwrap()
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.log('error', error);
        setState({ error: error?.data?.message || 'something went wrong' });
      });
  };
  const isDisabled =
    abTest.type === 'CONFIG' ? false : abTest?.status === 'CANCELED' || moment().utc(false).isAfter(abTest?.endTs); // finished test

  const handleDeviceChange = (e) => {
    let text = e.target.value;
    if (text.match(/([0-9a-fA-F-])$/g) || text === '') {
      return setState({ deviceId: text.split(' ').join('') });
    } else {
      return null;
    }
  };
  return isDisabled ? null : (
    <Modal centered ClassName="modal-lg" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Device Id</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
            <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
          </div>
        ) : (
          <div className="row mb-4 g-3">
            <Form.Group className="position-relative cursor-pointer" as={Col} xs={12} controlId="deviceId">
              <Form.Label className="fs-7">
                Device Id <span className="text-muted fs-7"> (testing on one device)</span>
              </Form.Label>
              <Form.Control
                type="text"
                className=" cursor-pointer"
                aria-describedby=""
                placeholder="Type Device Id"
                onChange={handleDeviceChange}
                value={state.deviceId}
              />
            </Form.Group>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Abort
        </Button>
        <Button variant="primary" onClick={handleUpdate}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdateDeviceModal;
