import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import MetricVariation from './MetricVariation';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { gameAbTestByIdSelector } from '../../data/slices/abTests';
import { getGameById } from '../../data/slices/studio';
import { useQuery } from '../../utils/customHooks';
import { abTestsApi } from '../../data/api/abTestsApi';
const MetricsTable = ({ title, header, data, downloadCsv, csvDiffFromBase }) => {
  const [csvData, setCsvData] = useState([]);
  const query = useQuery();
  const { gameId, testId } = useParams();

  const gameSelected = useSelector(getGameById(gameId));
  const abTestSelected = useSelector((state) =>
    gameAbTestByIdSelector(
      abTestsApi.endpoints.getGameAbtests.select({
        bundleId: gameSelected?.bundleId,
        store: gameSelected?.store,
      })(state),
      testId
    )
  );
  const headers = csvDiffFromBase
    ? header.flatMap((el, index) => (index === 0 ? [el.value] : [el.value, '']))
    : header.map((el) => el.value);

  const renderPopup = (popup, content) => {
    return (
      <div>
        <OverlayTrigger
          placement="top-start"
          delay={{ show: 150, hide: 200 }}
          overlay={(props) => (
            <Tooltip {...props}>
              <div className="text-start">
                {!!popup?.ARPU && (
                  <>
                    ARPU: {popup?.ARPU} <br />
                  </>
                )}
                {!!popup?.diffFromBase && (
                  <>
                    ARPU Change: {popup?.diffFromBase} <br />
                  </>
                )}
                {!!popup?.confidence && (
                  <>
                    Certainty: {popup?.pValue > 1 ? 0 : (popup?.confidence * 100).toFixed(2) || 'N/A'}
                    %
                    <br />
                  </>
                )}
                {!!popup?.pValue && typeof popup?.pValue === 'number' && <>P-value: {popup?.pValue.toFixed(4)}</>}
              </div>
            </Tooltip>
          )}
        >
          <div className={` ms-1 `}>{content}</div>
        </OverlayTrigger>
      </div>
    );
  };

  const renderWithPopup = (cell) => {
    if (cell?.popup) {
      if (typeof cell?.diffFromBase !== 'undefined') {
        //popup on diff
        return (
          <div className="d-flex align-items-center flex-nowrap">
            <div className="py-1">{cell.value}</div>
            {renderPopup(
              cell.popup,
              cell.diffFromBase ? (
                <MetricVariation confidence={cell?.popup?.confidence} diffFromBase={cell?.diffFromBase} />
              ) : null
            )}
          </div>
        );
      } else {
        //popup on value
        return <div>{renderPopup(cell?.popup, <div>{cell.value}</div>)}</div>;
      }
    } else {
      //value
      return <div className="py-1">{cell.value}</div>;
    }
  };

  return (
    <div className="bg-white rounded my-3 p-3">
      <div className="d-flex mb-2 ms-2">
        <div className="fw-bold d-flex justify-content-between align-items-center w-100">
          {title}
          {downloadCsv && (
            <CSVLink
              className="btn btn-sm bg-light"
              data={csvData}
              asyncOnClick={true}
              onClick={(e, done) => {
                setCsvData(
                  data?.length
                    ? csvDiffFromBase
                      ? data.map((row) => {
                          const rowData = [];
                          row.forEach((column, index) => {
                            const value = column.value || '-';
                            const diffFromBase = column.diffFromBase
                              ? String(`${column.diffFromBase >= 0 ? ' +' : ''} ${column.diffFromBase.toFixed(1)}%`)
                              : '';

                            rowData.push(value);
                            if (index > 0) {
                              rowData.push(diffFromBase);
                            }
                          });
                          return rowData;
                        })
                      : data.map((row) => row.map((column) => column.value || '-'))
                    : []
                );
                done(true);
              }}
              headers={headers}
              filename={
                gameSelected?.app_name +
                '_' +
                abTestSelected?.name +
                '_' +
                title +
                '_' +
                query?.toString() +
                '_' +
                moment().unix() +
                '.csv'
              }
            >
              Download CSV
            </CSVLink>
          )}
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-hover table-border-dashed bg-white sticky-first-column m-0 fs-7 rounded overflow-hidden">
          <thead>
            <tr className="border-bottom border-top">
              {' '}
              {/* აქ იყო ჩასხმული ჰედერის ფერი */}
              {header.map((cell, index) => (
                <th key={index}>
                  {cell?.popup ? (
                    renderPopup(cell?.popup, <div>{cell?.value}</div>)
                  ) : (
                    <div className="p-1">{cell?.value}</div>
                  )}{' '}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex} className="border-bottom">
                {row.map((cell, cellIndex) => (
                  <td className={`text-nowrap ${cell.className}`} key={cellIndex}>
                    <div className="d-flex">
                      <div className="d-flex gap-2">{renderWithPopup(cell)}</div>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

MetricsTable.propTypes = {
  title: PropTypes.string,
  header: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      popup: PropTypes.shape({
        confidence: PropTypes.number.isRequired,
        pValue: PropTypes.number.isRequired,
        tTest: PropTypes.number.isRequired,
      }),
    })
  ),
  footer: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        popup: PropTypes.shape({
          confidence: PropTypes.number.isRequired,
          pValue: PropTypes.number.isRequired,
          tTest: PropTypes.number.isRequired,
        }),
      })
    )
  ),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      diffFromBase: PropTypes.number.isRequired,
      className: PropTypes.string,
      rest: PropTypes.object,
      popup: PropTypes.shape({
        name: PropTypes.string,
        confidence: PropTypes.number.isRequired,
        diffFromBase: PropTypes.number.isRequired,
        p_value: PropTypes.number.isRequired,
        t_test: PropTypes.number.isRequired,
      }).isRequired,
    })
  ).isRequired,
};

export default MetricsTable;
