import AnalyticsChart from '../_Components/AnalyticsChart';
import Pagination from '../../../Components/Pagination';
import { capitalizeFirstLetter } from '../../../utils/converters';
import { countries } from '../../../constants/global';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import MetricsTable from '../_Components/MetricsTable';
import MaxLimitWarning from '../_Components/MaxLimitWarning';
import { useHistory } from 'react-router-dom';

const MonetizationReportTable = ({ report: reportData, trigger: triggerData, filter, setFilter }) => {
  const perPage = 25;
  const history = useHistory();

  const [totals, setTotals] = useState({});
  const [formattedReport, setformattedReports] = useState([]);
  const [headers, setHeaders] = useState([]);
  let report = reportData?.data || [];
  let hash = reportData?.hash || '';
  let withFilters = reportData?.filters;
  let trigger = triggerData || withFilters;
  useEffect(() => {
    if (report) {
      if (withFilters) {
        // set filters from hash
        let tmp = {};
        if (withFilters.platform)
          tmp[withFilters.platform?.neg ? 'exPlatform' : 'platform'] = withFilters.platform.list;
        if (withFilters.network) tmp[withFilters.network?.neg ? 'exNetwork' : 'network'] = withFilters.network.list;
        if (withFilters.country) tmp[withFilters.country?.neg ? 'exCountry' : 'country'] = withFilters.country.list;
        if (withFilters.application) tmp[withFilters.application?.neg ? 'exApp' : 'app'] = withFilters.application.list;
        if (withFilters.adType) tmp[withFilters.adType?.neg ? 'exAdType' : 'adType'] = withFilters.adType.list;
        if (withFilters.idfa) tmp['idfa'] = withFilters.idfa;
        if (withFilters.totals) tmp['advanced'] = withFilters.totals;
        if (withFilters.from) tmp['from'] = withFilters.from;
        if (withFilters.to) tmp['to'] = withFilters.to;
        if (withFilters.dimensions) tmp['dimension'] = withFilters.dimensions.list;
        tmp['page'] = 1;
        setFilter({ ...filter, ...tmp });
      }
      history.push(history.location.pathname + '?hash=' + hash);

      let totals = {
        impressions: 0,
        revenue: 0,
        eCPM: 0,
      };
      let formatted = []; // format data
      let headers = [
        ...trigger?.dimensions?.list?.map((el) => ({ label: capitalizeFirstLetter(el), key: el })),
        { label: 'Impressions', key: 'impressions' },
        { label: 'Revenue', key: 'revenue' },
        { label: 'eCPM', key: 'eCPM' },
      ];

      report.forEach((el) => {
        // calculate total sum
        totals.impressions += el.impressions || 0;
        totals.revenue += el.revenue || 0;
        totals.eCPM += el.eCPM || 0;

        // generate formatted report
        formatted.push({
          ...el,
          country_name: el.country ? countries.find((c) => c.code === el['country'])?.name || '-' : null,
          hasIdfa: el.hasIdfa === 1 ? 'Yes' : el.hasIdfa === 0 ? 'No' : '-',
          impressions: el.impressions ? Number(el.impressions).toLocaleString('en') : '-',
          revenue: el.revenue ? '$' + parseFloat(Number(el.revenue).toFixed(2)).toLocaleString('en') : '-',
          eCPM: el.eCPM ? '$' + parseFloat(Number(el.eCPM).toFixed(2)).toLocaleString('en') : '-',
        });
      });
      totals.eCPM /= report.length;

      setformattedReports(formatted);
      setHeaders(headers);
      setTotals({
        impressions: totals.impressions ? Number(totals.impressions).toLocaleString('en') : '-',
        revenue: totals.revenue ? '$' + parseFloat(Number(totals.revenue).toFixed(2)).toLocaleString('en') : '-',
        eCPM: totals.eCPM ? '$' + parseFloat(Number(totals.eCPM).toFixed(2)).toLocaleString('en') : '-',
      });
    }
  }, [report]);
  return formattedReport.length ? (
    <div className="">
      {report.length + 2 > 20000 ? (
        <MaxLimitWarning
          onClick={() => {
            setFilter({ advanced: true, dimension: filter.dimension.slice(0.2) });
          }}
        />
      ) : (
        <AnalyticsChart xDataKeys={trigger?.dimensions?.list} data={report} />
      )}

      <div className="d-flex mb-2">
        <CSVLink
          filename={`TN_${trigger?.from}_${trigger?.to}_monetization.csv`}
          data={[
            ...formattedReport,
            {
              [headers?.[0]?.['key']]: 'Total/Avg:',
              impressions: totals.impressions,
              revenue: totals.revenue,
              eCPM: totals.eCPM,
            },
          ]}
          headers={headers}
        >
          <div className="btn  btn-sm bg-white">Download CSV</div>
        </CSVLink>
        <div className="ms-auto d-flex align-items-center position-relative z-0">
          {formattedReport?.length > perPage && (
            <Pagination
              pushHistory={false}
              onChange={(page) => {
                setFilter({ page });
              }}
              total={report?.length || 0}
              perPage={perPage}
              page={filter.page}
            />
          )}
        </div>
      </div>
      <MetricsTable
        totals={totals}
        headers={headers}
        report={formattedReport.slice((filter.page - 1) * perPage, filter.page * perPage)}
      />
    </div>
  ) : null;
};

export default MonetizationReportTable;
