import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../data/slices/user';
import SideMenuLayout from '../Layouts/SideMenuLayout/SideMenuLayout';
import { setTopBarTitle } from '../data/slices/global';
import { R_LOGIN } from '../constants/routes';

const GuardedRoute = ({
  permission,
  component: Component,
  layout: Layout,
  title,
  back,
  search,
  skipHeader,
  ...rest
}) => {
  const user = useSelector(userSelector);
  const hasPermission = { permission };
  const location = useLocation();
  const dispatch = useDispatch();
  if (title) {
    document.title = 'TapNation -' + title;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!skipHeader) {
          dispatch(
            setTopBarTitle({
              title,
              back,
              search,
            })
          );
        }
        return user?.id && (permission ? hasPermission : true) ? (
          <Layout {...rest}>
            <Component {...rest} {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: R_LOGIN,
              state: { redirectUrl: location?.pathname + location?.search || null },
            }}
          />
        );
      }}
    />
  );
};
GuardedRoute.defaultProps = {
  layout: SideMenuLayout,
  admin: false,
  footer: true,
  title: '',
  back: '',
  search: false,
};
export default GuardedRoute;
