import React from 'react';

function ArrowUpFull({ size = 24, color = '#B4B8CD', rotate, ...rest }) {
  return (
    <svg
      style={
        rotate === 'left'
          ? { transform: 'rotate(90deg)' }
          : rotate === 'right'
          ? { transform: 'rotate(-90deg)' }
          : rotate === 'down'
          ? { transform: 'rotate(180deg)' }
          : {}
      }
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      {...rest}
      viewBox="0 0 13 8"
    >
      <path d="M12.2109 6.90625C12.2109 7.01042 12.1914 7.10807 12.1523 7.19922C12.1133 7.29036 12.0547 7.36849 11.9766 7.43359C11.9766 7.43359 11.931 7.45312 11.8398 7.49219C11.7487 7.53125 11.6185 7.58984 11.4492 7.66797L0.804688 7.66797C0.700521 7.66797 0.602865 7.64844 0.511719 7.60938C0.433594 7.57031 0.355469 7.51172 0.277344 7.43359C0.277344 7.43359 0.257813 7.39453 0.21875 7.31641C0.179688 7.22526 0.121094 7.08854 0.0429688 6.90625C0.0429688 6.90625 0.0625 6.86068 0.101563 6.76953C0.140625 6.67839 0.199219 6.54818 0.277344 6.37891L5.58984 1.06641C5.66797 0.988281 5.7526 0.929687 5.84375 0.890625C5.9349 0.851562 6.02604 0.832031 6.11719 0.832031C6.11719 0.832031 6.16276 0.851562 6.25391 0.890625C6.34505 0.929687 6.48177 0.988281 6.66406 1.06641L11.9766 6.37891C12.0547 6.45703 12.1133 6.54167 12.1523 6.63281C12.1914 6.71094 12.2109 6.80208 12.2109 6.90625Z" />
    </svg>
  );
}

export default ArrowUpFull;
