import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';

class AppleLogo extends PureComponent {
  static propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
  };

  static defaultProps = {
    size: 50,
    color: '#000000',
  };

  render() {
    const { size, color } = this.props;

    return (
      <svg height={size} width={size} viewBox="0 0 185.2 185.2">
        <path
          fill={color}
          d="m127.4 113.9-6.5-4.7c-13.1-9.5-25-17.5-35.6-23.8L83 83.8l15.4-34.7Zm9.2 23 10.1-10.1-40.5-90.5H91L72.8 78.2l-2-1c-10.9-5.4-19.3-8-25.6-8-8.2 0-11.9 4.5-13.4 7.1-4.5 8-1.9 19.2 7.8 34.2 3.6 5.5 7.4 10.6 10.2 14.1h14.6l6-13.6-6.3-14.2-7.4 16.2-2.5-3.2c-1.6-2.2-3.2-4.4-4.5-6.5-8-12.2-9.2-18.5-7.4-20.8 1.7-2.1 11.7.7 22.7 6.1a117 117 0 0 1 18.3 10.3q6.6 4 13.8 9c17.7 11.9 33.6 24.4 39.5 29m36.7-44.3a80.7 80.7 0 1 1-80.7-80.8 80.87 80.87 0 0 1 80.7 80.8m11.9 0a92.6 92.6 0 1 0-92.6 92.6 92.72 92.72 0 0 0 92.6-92.6"
        />
      </svg>
    );
  }
}

export default AppleLogo;
