import { useState, useRef } from 'react';
import { useImageVariationsMutation } from '../../data/api/openAiApi';
import Loader from '../../Components/Loader';
import { Button, Card, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SendIcon from '../../Components/Icons/SendIcon';
import UploadIcon from '../../Components/Icons/UploadIcon';
import { confirmAlert } from '../../Components/ConfirmModal';

const ImageVariations = () => {
  const [hover, setHover] = useState(false);
  const [input, setInput] = useState('');
  const [number, setNumber] = useState(3);
  const [size, setSize] = useState('256x256');
  const [messages, setMessages] = useState([]);
  const [sendMessage, { isLoading, error }] = useImageVariationsMutation();
  const inputRef = useRef();
  const clearInput = () => {
    setInput('');
    inputRef.current.value = '';
    return true;
  };
  const handleSend = () => {
    if (!input) return false;
    let msgs = { role: 'user', file: input, num: number, size };
    setMessages([msgs, ...messages]);
    sendMessage({ file: input, number, size })
      .unwrap()
      .then((resp) => {
        if (resp?.message) {
          setMessages((val) => [{ content: resp?.message, role: 'assistant' }, ...val]);
        } else {
          setMessages((val) => [{ urls: resp.map((el) => el.url), role: 'assistant' }, ...val]);
        }
      });
    clearInput();
  };
  const setFile = (file) => {
    if (file.type !== 'image/png' && file.type !== 'image/vnd.sealed.png') {
      confirmAlert({
        title: 'Only PNG files are allowed',
        variant: 'warning',
        confirmBtn: false,
        cancelText: 'Ok',
      }).catch((err) => {});
      return clearInput();
    }
    if (file.size > 4194304) {
      confirmAlert({
        title: 'Maximum 4MB image is allowed',
        variant: 'warning',
        confirmBtn: false,
        cancelText: 'Ok',
      }).catch((err) => {});
      return clearInput();
    }
    setInput(file);
  };
  const onDropHandler = (ev) => {
    ev.preventDefault();
    setHover(false);

    let file = '';
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      file = [...ev.dataTransfer.items].find((item) => item.kind === 'file').getAsFile();
    } else {
      // Use DataTransfer interface to access the file(s)
      file = ev.dataTransfer.files[0];
    }
    setFile(file);
  };
  const onDragOver = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  };
  return (
    <div className="d-flex flex-basis-0 overflow-hidden flex-column flex-grow-1  mb-4 ">
      <div className="d-flex flex-column-reverse flex-grow-1 overflow-auto mb-3 px-3 bg-white shadow-sm">
        {isLoading && (
          <Card className={`my-2 bg-pale-white`}>
            <Card.Body className="d-flex fs-7 my-2">
              <Loader parentStyle="me-2" size={20} color={'#3F96C7'} />
              Loading...
            </Card.Body>
          </Card>
        )}
        {messages.map((el, index) => (
          <Card key={index} className={`my-2 fs-7 ${el?.role === 'assistant' ? 'bg-primary-100' : 'bg-pale-white'}`}>
            <Card.Body>
              {el?.urls && (
                <div className="d-flex flex-wrap">
                  {el.urls.map((url, index) => (
                    <Link
                      className="text-decoration-none rounded m-2"
                      to={{
                        pathname: url,
                      }}
                      target="_blank"
                    >
                      <img src={url} style={{ maxWidth: 700 }} className="rounded " alt="" />
                    </Link>
                  ))}
                </div>
              )}
              {el?.content && <pre>{el?.content}</pre>}
              {el?.file && (
                <div
                  className=" me-3 rounded bg-light-200 border d-flex align-items-center justify-content-center overflow-hidden "
                  style={{
                    height: 198,
                    width: 198,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundImage: `url('${URL.createObjectURL(el?.file)}')`,
                  }}
                ></div>
              )}
              <p className="m-0 text-secondary fs-7">{el?.size}</p>
            </Card.Body>
          </Card>
        ))}
        <Card className={`my-2 bg-primary-100 fs-7`}>
          <Card.Body className="py-2">
            <pre>Generate similar images</pre>
          </Card.Body>
        </Card>
      </div>
      <Form className="px-3">
        {error && !isLoading && (
          <div className="alert alert-danger p-2 mb-2 fs-7 " role="alert">
            {error?.data?.message || error?.error || 'Something went wrong.'}
          </div>
        )}
        <Form className="d-flex flex-wrap">
          <Form.Group className="mb-3 me-3">
            <Form.Label className="">Image size</Form.Label>
            <Form.Select className="form-select" value={size} onChange={(event) => setSize(event.target.value)}>
              {['256x256', '512x512', '1024x1024'].map((value, index) => (
                <option value={value} key={index}>
                  {value}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3 me-3">
            <Form.Label className="">Number of images</Form.Label>
            <Form.Select className="form-select" value={number} onChange={(event) => setNumber(event.target.value)}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value, index) => (
                <option value={value} key={index}>
                  {value}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Form>
        <div className="d-flex mb-3">
          <div
            className="position-relative flex-grow-1 d-flex me-3 rounded border bg-white overflow-hidden"
            id="drop_zone"
            onDragEnter={() => {
              setHover(true);
            }}
          >
            {hover && (
              <div
                className="position-absolute w-100 h-100 top-0 start-0 bg-dark opacity-25 "
                onDrop={onDropHandler}
                onDragOver={onDragOver}
                onDragLeave={() => {
                  setHover(false);
                }}
              ></div>
            )}
            <Form.Label
              htmlFor={'file_picker'}
              className={
                'm-0  cursor-pointer text-muted  fs-7 text-center d-flex flex-column  justify-content-center  h-100 w-100'
              }
            >
              <div className="mb-1">
                <UploadIcon />
              </div>
              Drag & Drop PNG Image <br />
              or <span className="btn-link text-primary d-inline">Click here</span>
            </Form.Label>
            <input
              id="file_picker"
              type="file"
              ref={inputRef}
              accept="image/vnd.sealed.png,image/png"
              onChange={(ev) => {
                setFile(ev.target.files[0]);
              }}
              style={{ display: 'none' }}
            />
          </div>

          <div
            className=" me-3 rounded bg-light-200 border d-flex align-items-center justify-content-center overflow-hidden "
            style={{
              height: 98,
              width: 98,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundImage: `url('${input ? URL.createObjectURL(input) : ''}')`,
            }}
          ></div>

          <Button type="submit" variant="outline-light" onClick={handleSend} disabled={!input}>
            <SendIcon />
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ImageVariations;
