import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Col } from 'react-bootstrap';
import TapNationPng from '../../../../assets/logoTapNation/LogoTapNation40.png';
import AndroidIcon from '../../../../Components/Icons/AndroidIcon';
import AppleIcon from '../../../../Components/Icons/AppleIcon';
import AbTestGameList from './AbTestGameList';
import { useSelector } from 'react-redux';
import { getGameById } from '../../../../data/slices/studio';

const SelectAbTestGame = ({ gameId, onSelect, errors, selected }) => {
  const [search, setSearch] = useState('');
  const foundGame = useSelector(getGameById(gameId));
  const selectedGame = useSelector(getGameById(foundGame?.id));

  return selected ? (
    <div className="d-flex align-items-center cursor-pointer mb-2">
      <div className={`d-flex align-items-center mb-1 w-100 rounded bg-light-400 p-2 border-primary`}>
        <div className="overflow-hidden text-nowrap me-2 flex-shrink-0" style={{ width: 40 }}>
          <img
            width={40}
            height={40}
            className="rounded img-fit-contain"
            src={selectedGame?.logoUrl === '' ? TapNationPng : selectedGame?.logoUrl}
            alt={selectedGame?.appName}
          />
        </div>
        <div className="d-flex flex-column w-100">
          <div className="d-flex align-items-center justify-content-between">
            {selectedGame?.appName}
            <span className="me-1 ">
              {selectedGame?.multipleOs ? (
                <div className="d-flex gap-1">
                  <AndroidIcon size={15} />
                  <AppleIcon size={15} />
                </div>
              ) : selectedGame?.os === 'android' ? (
                <AndroidIcon size={15} />
              ) : (
                <AppleIcon size={15} />
              )}
            </span>
          </div>
          <p className="text-muted m-0 fs-8 text-truncate overflow-hidden w-75">{selectedGame?.bundleId}</p>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <Form.Group className="position-relative mb-2" as={Col} controlId="gameName" onClick={(e) => e.stopPropagation()}>
        <Form.Label>
          Select a Game <span className="text-danger fs-8">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          className="form-control search"
          aria-describedby=""
          placeholder="Search for game..."
          value={search}
          required
          isInvalid={errors?.bundleId}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Form.Control.Feedback tooltip type="invalid">
          {errors?.bundleId}
        </Form.Control.Feedback>
      </Form.Group>
      <AbTestGameList
        color="bg-light-400"
        showMoreButton={false}
        search={search}
        onSelect={(data) => {
          onSelect(data);
          setSearch('');
        }}
        gameId={gameId}
        activeOnly
      />
    </div>
  );
};

export default SelectAbTestGame;
