const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white fs-7 px-2 py-1 rounded border border-secondary ">
        <p className="m-0">
          {`${payload[0]?.payload?.title}`}: {`${payload[0]?.value}`}
        </p>
      </div>
    );
  }

  return null;
};
export default CustomTooltip;
