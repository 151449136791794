import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import CustomTooltip from './ChartCustomTooltip';

function AbTestGraph(props) {
  let data = [];

  setDataForGraph();

  function setDataForGraph() {
    let dataRows = props.data;
    let firstRow = props.header;

    let newArray = Array.from(dataRows);
    if (newArray.length > 0 && firstRow.length > 0) {
      newArray.forEach((key, index) => {
        if (key.includes('Summary')) return;

        let temp = key.map((v, i) => {
          let k = v;
          if (!isNaN(v) && v % 1 > 0) k = Number(k).toFixed(4);
          if (firstRow[i] !== 'D') {
            return { [firstRow[i]]: isNaN(k) ? k : +k };
          } else {
            return { [firstRow[i]]: k, title: firstRow[index] };
          }
        });
        data.push(Object.assign({}, ...temp));
        return data;
      });
    }
  }
  function getAverageAndSummury() {
    let averageArray = [];

    let dataRows = props.data;
    let firstRow = props.header;

    let newArray = Array.from(dataRows);

    if (newArray.length > 0 && firstRow.length > 0) {
      newArray.forEach((key, index) => {
        if (key[0] === 'Average' || key[0] === 'Summary') {
          let newObjForRechart = key.slice(1).map((item, index) => {
            let k = item;
            if (!isNaN(item) && item % 1 > 0) k = Number(item).toFixed(4);
            return {
              Average: +k,
              title: props.header[index + 1],
            };
          });
          averageArray.push(newObjForRechart);
        }
      });
    }
    return averageArray[0];
  }
  return (
    <div className="col-lg-4">
      <div className={`game_show ${props.bgColor}`}>
        <div className="abtest_graph">
          <div className="Titre-graphique">
            <h6 className="text-truncate ">
              {props.header[1] === 'v_BaseCohort' || ['Impressions', 'Rewarded/Users'].includes(props.csvName)
                ? 'Average'
                : props.selectedHeader === ''
                ? props.header[1]
                : props.selectedHeader}
            </h6>
          </div>
          <div className="blue-chart">
            {/*{['Overview', 'SessionTime', 'CohortedRevenue', 'LtvIapPivot'].includes(props.csvName) ? (*/}
            {!['Interstitials/User', 'Rewarded/Users', 'Impressions'].includes(props.csvName) ? (
              <ResponsiveContainer width="100%" height={160}>
                <BarChart
                  width={'100%'}
                  height={160}
                  // data={data}
                  data={data.map((d, index) => ({
                    ...d,
                    title: d.cohort || d.Cohort || d.install_date || d['Install date'],
                    ARPU: props.meta?.[index]?.total?.toFixed(4) || 0,
                    avg_sessions: +d?.avg_sessions,
                    v_BaseCohort: +d?.v_BaseCohort,
                    v__75_percents: +d?.v__75_percents,
                    v__80_percents: +d?.v__80_percents,
                    v__85_percents: +d?.v__85_percents,
                    v__90_percents: +d?.v__90_percents,
                    v__95_percents: +d?.v__95_percents,
                  }))}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis
                    allowDataOverflow
                    tickSize={8}
                    dataKey={props.selectedHeader === '' ? props.header[1] : props.selectedHeader}
                  />
                  <YAxis width={25} dataKey={props.selectedHeader === '' ? props.header[1] : props.selectedHeader} />

                  <Tooltip
                    content={<CustomTooltip />}
                    itemStyle={{ color: 'black' }}
                    cursor={{ fill: 'transparent', color: 'black' }}
                  />
                  {/*<Legend />*/}

                  <Bar
                    type="monotone"
                    // dataKey="installs"
                    dataKey={props.selectedHeader === '' ? props.header[1] : props.selectedHeader}
                    fill={props.bgColor === 'game_show_blue' ? '#FFFFFF' : '#4444FF'}
                    className="barchart"
                  />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <ResponsiveContainer width="95%" height={160}>
                <BarChart
                  width={'100%'}
                  height={160}
                  data={getAverageAndSummury()}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey="Average" />
                  <YAxis />

                  <Tooltip
                    content={<CustomTooltip />}
                    itemStyle={{ color: 'black' }}
                    cursor={{ fill: 'transparent', color: 'black' }}
                  />
                  {/*<Legend />*/}

                  <Bar
                    type="monotone"
                    dataKey="Average"
                    fill={props.bgColor === 'game_show_blue' ? '#FFFFFF' : '#4444FF'}
                    className="barchart"
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AbTestGraph;

/*
<ResponsiveContainer width="95%" height={160}>
  <LineChart
    width={'100%'}
    height={160}
    data={data.map((d) => ({
      ...d,
      title: d.install_date, // for tooltip
      installs: +d.installs,
      v_BaseCohort: +d.v_BaseCohort,
      v__75_percents: +d.v__75_percents,
      v__80_percents: +d.v__80_percents,
      v__85_percents: +d.v__85_percents,
      v__90_percents: +d.v__90_percents,
      v__95_percents: +d.v__95_percents,
    }))}
    margin={{
      top: 5,
      right: 30,
      left: 20,
      bottom: 5,
    }}
  >
    {props.header[0] === 'D' ? (
      <>
        <XAxis dataKey={props.header[0]} />
        <YAxis dataKey={props.selectedHeader === '' ? props.header[1] : props.selectedHeader} />
      </>
    ) : (
      <XAxis dataKey={props.selectedHeader === '' ? props.header[1] : props.selectedHeader} />
    )}
    <CartesianGrid stroke="#DEDEDE" vertical={false} strokeDasharray="5 5" />
    <Tooltip content={<CustomTooltip />} />
    {/!*<Legend />*!/}

    <Line
      type="monotone"
      dataKey={props.selectedHeader === '' ? props.header[1] : props.selectedHeader}
      stroke="#000"
      activeDot={{ r: 8 }}
    />
  </LineChart>
</ResponsiveContainer>*/
