export const convertDateToUtc = (date) => {
  return Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  );
};

export const snakeCaseToCamel = (str) => {
  return str.replace(/[^a-zA-Z0-9.]+(.)/g, (m, chr) => chr.toUpperCase());
};

export const snakeCaseToCamelObj = (obj) => {
  let newObject = {};
  Object.keys(obj).forEach(function (key) {
    newObject[snakeCaseToCamel(key)] = obj[key];
  });
  return newObject;
};

export const camelToSnakeCase = (str) => {
  return str.replace(/([A-Z])/g, (letter) => `_${letter.toLowerCase()}`).toLowerCase();
};

export const camelToSnakeCaseObj = (obj) => {
  let newObject = {};
  Object.keys(obj).forEach(function (key) {
    newObject[camelToSnakeCase(key)] = obj[key];
  });
  return newObject;
};

export const capitalizeFirstLetter = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};

export const snakeToCamelRec = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => snakeToCamelRec(item));
  } else if (typeof data === 'object' && data !== null) {
    let newData = {};
    Object.keys(data).forEach((key) => {
      newData[snakeCaseToCamel(key)] = snakeToCamelRec(data[key]);
    });
    return newData;
  } else {
    return data;
  }
};
