import React from 'react';

function ArrowStickDown({ size, color, rotate, style, ...rest }) {
  return (
    <svg
      style={{
        ...style,
        ...(rotate === 'left'
          ? { transform: 'rotate(90deg)' }
          : rotate === 'right'
          ? { transform: 'rotate(-90deg)' }
          : rotate === 'up'
          ? { transform: 'rotate(180deg)' }
          : {}),
      }}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      {...rest}
      viewBox="0 0 10 10"
    >
      <path d="M0.246295 5.59399L4.40953 9.76239C4.48872 9.83829 4.5821 9.89778 4.6843 9.93746C4.88702 10.0208 5.1144 10.0208 5.31712 9.93746C5.41932 9.89778 5.5127 9.83829 5.59189 9.76239L9.75513 5.59399C9.83276 5.51626 9.89434 5.42398 9.93636 5.32242C9.97837 5.22086 10 5.11201 10 5.00208C10 4.78007 9.91192 4.56715 9.75513 4.41017C9.59834 4.25318 9.38568 4.16499 9.16395 4.16499C8.94221 4.16499 8.72956 4.25318 8.57277 4.41017L5.83336 7.16131L5.83336 0.833681C5.83336 0.612575 5.74563 0.400525 5.58948 0.244179C5.43333 0.087834 5.22154 5.35928e-07 5.00071 5.16623e-07C4.77988 4.97317e-07 4.56809 0.0878339 4.41194 0.244179C4.25579 0.400525 4.16806 0.612575 4.16806 0.833681L4.16806 7.16131L1.42865 4.41017C1.35125 4.33203 1.25916 4.27001 1.15769 4.22768C1.05623 4.18536 0.947394 4.16356 0.837474 4.16356C0.727556 4.16356 0.618723 4.18536 0.517258 4.22768C0.415792 4.27001 0.323701 4.33203 0.246295 4.41017C0.168253 4.48767 0.106308 4.57987 0.0640349 4.68146C0.0217624 4.78306 -5.07121e-07 4.89202 -5.16742e-07 5.00208C-5.26364e-07 5.11213 0.0217623 5.2211 0.0640349 5.32269C0.106307 5.42428 0.168253 5.51649 0.246295 5.59399Z" />
    </svg>
  );
}
ArrowStickDown.defaultProps = {
  rotate: '',
  size: 24,
  color: 'inherit',
};
export default ArrowStickDown;
