import Form from 'react-bootstrap/Form';
import GameInfoCard from '../../../../Components/GameInfoCard';

const MetricsHeader = ({ data, os, changeOs }) => {
  return (
    <div className="row g-3 g-md-4 mb-3">
      <div className="col-sm-12 col-md-6 col-xl-4">{data && <GameInfoCard os={os} id={data.id} />}</div>
      <div className="col-sm-12 col-md-6 col-xl-8 d-flex">
        {data.multipleOs && (
          <div className="d-flex ms-auto mt-auto">
            <div
              className="d-flex align-items-center cursor-pointer"
              onClick={() => changeOs(os === 'android' ? 'ios' : 'android')}
            >
              <div className="fs-7 text-muted me-1">Android</div>
              <Form>
                <Form.Check type="switch" checked={os === 'ios'} id="custom-switch" />
              </Form>
              <div className="fs-7 text-muted" id="parot-ios-icon">
                IOS
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MetricsHeader;
