import React from 'react';
import EmailSendModal from '../../Modules/EmailSendModal';

const SendEmailDemo = () => {
  const sendText = 'Send Email';
  const to = 'fortsilo@gmail.com';

  return (
    <div>
      <EmailSendModal sendText={sendText} to={to} />
    </div>
  );
};

export default SendEmailDemo;
