import React, { useEffect, useState } from 'react';
import MetricsTable from '../../../../../Components/JsonMetrics/MetricsTable';
import { getDataCell } from '../../../../../utils/jsonMetricsUtils';

function OverviewMetrics({ apiData }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!apiData?.summary?.length) return;

    const newData = {
      overview: {
        header: [
          { value: 'Cohort' },
          { value: 'Installs' },
          { value: 'Total ARPU' },
          { value: 'Ad ARPU' },
          { value: 'RR D1' },
          { value: 'Avg IS impr / user' },
          { value: 'Avg RV impr / user' },
          { value: 'IAP ARPU' },
          { value: 'Avg Playtime' },
          { value: '# of Paying Users' },
        ],
        data: [],
        title: 'Summary',
      },
    };

    apiData.summary.forEach((el) => {
      const isBaseCohort = el.user_cohort?.toLowerCase() === 'basecohort';

      newData.overview.data.push([
        { value: el.user_cohort, className: isBaseCohort ? 'bg-light-400' : '' },
        { value: el.installs || '', className: isBaseCohort ? 'bg-light-400' : '' },
        getDataCell(el.arpu_total?.toFixed(4), el.meta, 'arpu_total', isBaseCohort),
        getDataCell(el.ltv_total?.toFixed(4), el.meta, 'ltv_total', isBaseCohort),
        getDataCell(el.ret_d1 ? (el.ret_d1 * 100).toFixed(2) : null, el.meta, 'ret_d1', isBaseCohort),
        getDataCell(el.inter_impressions?.toFixed(2), el.meta, 'inter_impressions', isBaseCohort),
        getDataCell(el.reward_impressions?.toFixed(2), el.meta, 'reward_impressions', isBaseCohort),
        getDataCell(el.iap_total?.toFixed(4), el.meta, 'iap_total', isBaseCohort),
        getDataCell(el.playtime?.toFixed(0), el.meta, 'playtime', isBaseCohort),
        getDataCell(el.iap_paying_users_total || '-', el.meta, 'iap_paying_users_total', isBaseCohort),
      ]);
    });

    setData(newData);
  }, [apiData]);

  return (
    <>
      {!!data?.overview.data?.length && (
        <MetricsTable
          data={data.overview.data}
          title={data.overview.title}
          header={data.overview.header}
          downloadCsv
          csvDiffFromBase
        />
      )}
    </>
  );
}

export default OverviewMetrics;
