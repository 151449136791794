import { Link } from 'react-router-dom';
import { R_HOME } from '../../constants/routes';
import LogoTapNation from '../../assets/logoTapNation/LogoTapNation42.png';
import './AuthLayout.scss';
import screenshotImg from '../../assets/images/portal-screen.png';

const AuthLayout = ({ children }) => {
  return (
    <div className="auth-layout-container">
      <div className=" img-container p-2">
        <Link to={R_HOME} className="position-absolute top-0 start-0 m-4 d-inline-block">
          <img src={LogoTapNation} alt="logo" width={56} />
        </Link>
        <img className="screenshot" src={screenshotImg} alt="screen" />
        <div className="text-white" style={{ marginTop: -150 }}>
          <h2>Designed for individuals</h2>
          <p className="opacity-75">
            See detailed analytics and grow your games <br /> remotely, from anywhere!
          </p>
        </div>
      </div>
      <div className="auth-layout-content bg-white">{children}</div>
    </div>
  );
};

export default AuthLayout;
