import { useState, useEffect } from 'react';
import { useGetGameConfigsQuery } from '../../../../data/api/abTestsApi';
import { useSelector } from 'react-redux';
import { getGameById } from '../../../../data/slices/studio';
import { buildVersionCompare } from '../../../../utils/common';

function useFilteredConfigs({ filter, gameId }) {
  const [configs, setConfigs] = useState({});
  const { search, status, os, type, deviceId, country, store, build } = filter;

  const foundGame = useSelector(getGameById(gameId));
  const { data: config, isLoading: isLoadingConfig } = useGetGameConfigsQuery(
    { bundleId: foundGame?.bundleId, store: foundGame?.store === 'amazon' ? 'amazon' : 'google,apple' },
    {
      skip: !foundGame?.bundleId,
    },
  );

  useEffect(() => {
    if (config && foundGame) {
      let newSort = [];
      if (foundGame.multipleOs) {
        newSort = [...config];
      } else if (foundGame.os === 'android') {
        newSort = config.filter((el) => el.os === 'android' || el.os === '');
      } else if (foundGame.os === 'ios') {
        newSort = config.filter((el) => el.os === 'ios' || el.os === '');
      } else {
        newSort = [...config];
      }

      if (search) newSort = newSort.filter((el) => el.name.toLowerCase().indexOf(search) > -1);
      if (status) newSort = newSort.filter((el) => status.includes(el.status));

      if (deviceId?.length) newSort = newSort.filter((el) => deviceId.includes(el.deviceId || ''));

      if (build) newSort = newSort.filter((el) => !el.build || (el.build && buildVersionCompare(build, el.build) >= 0));
      //
      // if (store?.length > 0)
      //   newSort = newSort.filter((el) => el.store === '' || store.some((s) => el.store.includes(s) || ''));
      if (store.length > 0) newSort = newSort.filter((el) => store.includes(el.store || ''));

      if (country?.length)
        newSort = newSort.filter((el) =>
          country.some((i) => (i && el.country ? el.country?.includes(i) : !!i === !!el.country)),
        );

      if (os !== 'all') newSort = newSort.filter((el) => os === el.os || el.os === '');

      // place configs with countries last
      let sortedWithCountry = newSort.filter((item) => item.country);
      let sortedWithoutCountry = newSort.filter((item) => !item.country);
      sortedWithCountry = sortedWithCountry.sort((a, b) => a.name.localeCompare(b.name));
      sortedWithoutCountry = sortedWithoutCountry.sort((a, b) => a.name.localeCompare(b.name));

      newSort = sortedWithoutCountry.concat(sortedWithCountry);

      newSort = newSort.reduce((old, curr) => {
        let build = curr.build || 'Global';
        let deviceId = curr.deviceId;

        if (!old[build]) {
          old[build] = {
            _: [],
            [deviceId]: [],
          };
        }

        if (deviceId) {
          if (!old[build][deviceId]) old[build][deviceId] = [];
          old[build][deviceId].push(curr);
        } else {
          old[build]['_'].push(curr);
        }

        return old;
      }, {});

      setConfigs(newSort);
    }
  }, [config, foundGame, search, status, os, type, deviceId, country, store, build]);

  let headers = Object.keys(configs);
  if (headers.length) {
    headers.sort(buildVersionCompare);
    if (headers.includes('Global')) {
      headers.splice(headers.indexOf('Global'), 1);
      headers.unshift('Global');
    }
  }

  return {
    configs,
    isLoadingConfig,
    headers,
  };
}

export default useFilteredConfigs;
