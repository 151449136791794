import { useEffect } from 'react';

/** Import Externals **/
import Modal from 'react-bootstrap/Modal';
import { Button, Form } from 'react-bootstrap';

/** Import Utils **/
import { useSetState } from '../../../../utils/customHooks';
import { BIZ_DEV_EMAIL_LIST } from '../../../../constants/global';
import { useUpdateEmailMutation } from '../../../../data/api/studioApi';
import { confirmAlert } from '../../../../Components/ConfirmModal';
import EditIcon from '../../../../Components/Icons/EditIcon';
import Loader from '../../../../Components/Loader';
import { useRouteMatch } from 'react-router-dom';
import { R_GAME_DRAFT } from '../../../../constants/routes';

function InfosPublisherModal({ infos }) {
  const isDraft = useRouteMatch(R_GAME_DRAFT)?.isExact;
  const [updateEmail, { isLoading, error }] = useUpdateEmailMutation();
  const [state, setState] = useSetState({
    loading: false,
    showModal: false,
    bizDevEmail: '',
  });

  useEffect(() => {
    if (state.showModal && infos) {
      setState({
        bizDevEmail: infos.bizdevEmail || '',
      });
    }
  }, [state.showModal, infos]);

  const handleTextChange = (text, field) => {
    return setState({ [field]: text });
  };

  const handleSave = (e) => {
    e.stopPropagation();
    if (!state.bizDevEmail) {
      confirmAlert({
        variant: 'danger',
        title: 'Required fields are empty',
        confirmBtn: false,
        cancelText: 'Ok',
      }).catch(() => {});
      return;
    }
    setState({ showModal: false });
    updateEmail({
      // app_id: infos.appId,
      bizdev_email: state.bizDevEmail,
      studio_id: infos.studioId,
      bundle_id: infos.bundleId,
      store: infos.store === 'amazon' ? ['amazon'] : [],
    })
      .unwrap()
      .then(() => {
        confirmAlert({
          variant: 'success',
          title: 'Email was successfully updated',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
      })
      .catch((err) => {
        confirmAlert({
          variant: 'danger',
          title: err?.data?.message || 'Something went wrong during email update',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
      });
  };

  const handleClose = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    setState({ showModal: false });
  };

  return (
    <>
      {!isDraft && (
        <Button
          variant="outline-light"
          size="sm"
          className=" p-1 text-nowrap"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setState({ showModal: true });
          }}
          id="emai-modal-open-button"
        >
          <EditIcon color="currentColor" size={18} />
        </Button>
      )}
      {infos && (
        <Modal centered onHide={handleClose} show={state.showModal} size="lg" id="infos-publisher-modal">
          <Modal.Header closeButton>
            <Modal.Title>{infos.appName} - Informations</Modal.Title>
          </Modal.Header>

          <Form>
            <Modal.Body>
              {isLoading ? (
                <div className="d-flex justify-content-center my-3">
                  <Loader parentStyle="me-2" size={60} color={'#3F96C7'} />
                </div>
              ) : (
                <div className="row">
                  {error && (
                    <div className="alert alert-danger p-2 mb-2 fs-7 " role="alert">
                      {error?.data?.message || 'Something went wrong.'}
                    </div>
                  )}
                  <Form.Group className="form-group" controlId="formBasicSelect">
                    <Form.Label className="col-form-label">Contact Email</Form.Label>
                    <Form.Select
                      className="form-select"
                      as="select"
                      value={state.bizDevEmail}
                      onChange={(text) => handleTextChange(text.target.value, 'bizDevEmail')}
                      name="biz-dev-email"
                    >
                      {BIZ_DEV_EMAIL_LIST.map((value, index) => (
                        <option value={value} key={index}>
                          {value}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <button type="button" onClick={handleClose} className="btn btn-txt" data-bs-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSave}
                className="btn btn-success"
                data-bs-dismiss="modal"
                id="email-update"
              >
                Update
              </button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </>
  );
}

export default InfosPublisherModal;
