export const getDataCell = (value, meta, field, isBaseCohort, formatType) => {
  let formattedValue = '-';
  let popup = null;

  if (value != null) {
    if (formatType === 'percentage') {
      formattedValue = (value * 100).toFixed(2);
    } else if (formatType === 'decimal') {
      formattedValue = value > 0.1 ? value.toFixed(1) : '-';
    } else {
      formattedValue = value;
    }
  }

  if (!isBaseCohort && meta?.[field]) {
    popup = {
      confidence: meta[field]?.confidence,
      pValue: meta[field]?.p_value,
    };
  }

  return {
    value: formattedValue,
    diffFromBase: meta?.[field]?.diffFromBase || null,
    className: isBaseCohort ? 'bg-light-400' : '',
    popup: popup,
  };
};

export const getChartDataCell = (value, meta, field, isBaseCohort) => ({
  value,
  diffFromBase: meta?.[field]?.diffFromBase || null,
  className: isBaseCohort ? 'bg-light-400' : '',
  popup: !isBaseCohort
    ? {
        confidence: meta?.[field]?.confidence,
        pValue: meta?.[field]?.p_value,
      }
    : null,
});
