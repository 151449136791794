import { useSelector } from 'react-redux';
import { userPermissionsSelector } from '../../data/slices/user';

export const useUserPermission = (permission) => {
  const userPermissions = useSelector(userPermissionsSelector);
  return userPermissions?.includes(permission);
};

export const Restricted = ({ permission, children, fallback }) => {
  const isAllowedTo = useUserPermission(permission);
  return isAllowedTo ? children : fallback || null;
};
