import ArrowUpFull from '../../../../Components/Icons/ArrowUpFull';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../../../utils/customHooks';
import { Restricted } from '../../../../Components/permissions/UserPermissions';

function DraftSortBar() {
  const history = useHistory();
  const query = useQuery();
  const sort = query.get('sort') || 'dateDown';
  const handleSortChange = (name) => {
    let value;
    if (sort === name + 'Down') {
      value = name + 'Up';
    } else {
      value = name + 'Down';
    }
    query.set('sort', value);
    history.push(history.location.pathname + '?' + query.toString());
  };
  const RenderArrow = ({ name }) => {
    return (
      <span className="ms-2">
        {sort === name + 'Down' ? (
          <ArrowUpFull rotate="down" color="#2d3363" size={12} />
        ) : sort === name + 'Up' ? (
          <ArrowUpFull color="#2d3363" size={12} />
        ) : (
          <ArrowUpFull rotate="down" size={12} />
        )}
      </span>
    );
  };
  return (
    <>
      <tr className="draftSortBar">
        <th scope="col">
          <span className="text-nowrap cursor-pointer fs-7" onClick={() => handleSortChange('name')}>
            Name
            <RenderArrow name="name" />
          </span>
        </th>

        <th scope="col">
          <span className="text-nowrap cursor-pointer fs-7" onClick={() => handleSortChange('date')}>
            Last Update <RenderArrow name="date" />
          </span>
        </th>
        <Restricted permission={'select.studio'}>
          <th scope="col" className="fs-7">
            Studio
          </th>
        </Restricted>
        <Restricted permission={'draftGame.update'}>
          <th scope="col" className="fs-7">
            Bs Dev
          </th>
        </Restricted>
        <th scope="col" width={115} />
        <th scope="col" width={90} className="text-center fs-7">
          Infos
        </th>

        <th scope="col" className="text-center fs-7" width={72}>
          SDK
        </th>
        <Restricted permission="show.comment.modal">
          <th width={50} />
        </Restricted>
      </tr>
    </>
  );
}

export default DraftSortBar;
