import React from 'react';
import Form from 'react-bootstrap/Form';
import { Nav } from 'react-bootstrap';

function FilterSelect({ label, options, value, onChange }) {
  return (
    <Nav.Item className="dropdown">
      <Form.Group className="input-group select-drop">
        <Form.Select className="form-select bg-white" onChange={onChange} value={value}>
          <option value={''}>All {label}</option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </Nav.Item>
  );
}

export default FilterSelect;
