import Graph from './Graph';
import { useSetState } from '../../../../../utils/customHooks';
import { Button } from 'react-bootstrap';

const DetailsGraph = (props) => {
  /** State */
  const [state, setState] = useSetState({
    detailsValue: 'installs',
  });

  const handleChangeDetails = (value) => {
    setState({ detailsValue: value });
  };

  const options = [
    !!props.avg?.fbCpi ? { title: 'FB CPI', value: 'facebookCpi' } : null,
    !!props.avg?.scCpi ? { title: 'SC CPI', value: 'snapchatCpi' } : null,
    !!props.avg?.tkCpi ? { title: 'TK CPI', value: 'tiktokCpi' } : null,

    { title: 'CPM', value: 'CPM' },
    { title: 'Installs', value: 'installs' },
    { title: 'Ret D1', value: 'retD1' },
    { title: 'Ret D3', value: 'retD3' },
    { title: 'Ret D7', value: 'retD7' },
    { title: 'Ret D14', value: 'retD14' },
  ].filter(Boolean);

  return (
    <div className=" ">
      <div className="d-flex mb-5">
        <h6 className="fw-bold d-flex ms-2 fs-7">Global performance</h6>
        <div className="d-flex ms-auto flex-wrap">
          {options.map((el) => (
            <Button
              bsPrefix="btn btn-sm btn-text border-0 mx-1"
              key={el.value}
              active={el.value === state.detailsValue}
              onClick={() => {
                handleChangeDetails(el.value);
              }}
            >
              {el.title}
            </Button>
          ))}
        </div>
      </div>

      <Graph metrics={props.metrics} dataKey={state.detailsValue} />
    </div>
  );
};

export default DetailsGraph;
