import * as PropTypes from 'prop-types';

const ArchiveIcon = ({ size, color, ...rest }) => {
  return (
    <svg {...rest} fill={color} height={size} width={size} viewBox="0 0 24 24">
      <path d="M20 9v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2zm0-2V5H4v2h16zM6 9v10h12V9H6zm4 2h4a1 1 0 0 1 0 2h-4a1 1 0 0 1 0-2z" />
    </svg>
  );
};
ArchiveIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

ArchiveIcon.defaultProps = {
  size: 24,
  color: 'currentColor',
};

export default ArchiveIcon;
