import React from 'react';
import FilterSearch from './FilterSearch';
import FilterSelect from './FilterSelect';
import { Nav, Navbar } from 'react-bootstrap';

const TnTableFiltersContainer = ({ filters, setFilters }) => {
  const activeFilters = filters.some((filter) => filter.value && filter.value !== 'all');

  const handleFilterChange = (name, value) => {
    setFilters((items) => items.map((filter) => (filter.name === name ? { ...filter, value } : filter)));
  };

  const handleClearFilters = () => {
    setFilters((filters) =>
      filters.map((filter) => ({
        ...filter,
        value: '',
      }))
    );
  };

  return (
    <div className="data-filter-container w-100">
      <Navbar className="p-0" bg="bg-transparent" expand="lg">
        <div className="d-flex w-100 justify-content-between d-lg-none">
          <Navbar.Brand className="d-lg-none p-0" href="#home">
            Filters
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="align-items-center">
            {filters.map((filter) => (
              <React.Fragment key={filter.name}>
                {filter.type === 'search' ? (
                  <FilterSearch
                    value={filter.value}
                    onChange={(e) => handleFilterChange(filter.name, e.target.value)}
                    placeholder={filter.placeholder}
                  />
                ) : (
                  filter.type === 'select' && (
                    <FilterSelect
                      label={filter.label}
                      options={filter.options}
                      value={filter.value}
                      onChange={(e) => handleFilterChange(filter.name, e.target.value)}
                    />
                  )
                )}
              </React.Fragment>
            ))}
            {activeFilters && (
              <div
                className="btn btn-sm btn-dark d-flex justify-content-center align-items-center text-nowrap h-75 dropdown"
                onClick={handleClearFilters}
              >
                Clear Filters
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default TnTableFiltersContainer;
