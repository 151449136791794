import Form from 'react-bootstrap/Form';
import { useSetState } from '../../../utils/customHooks';
import { BIZ_DEV_EMAIL_LIST } from '../../../constants/global';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import CheckmarkIcon from '../../../Components/Icons/CheckmarkIcon';
import { useCreateStudioMutation } from '../../../data/api/studioApi';
import Loader from '../../../Components/Loader';
import React, { useState } from 'react';
import { formatJoiErrorsArray } from '../../../utils/validation';
import CreateStudioSchema from './_validationSchemas/createStudioSchema';
import GameAnalyticsStudioID from './GameAnalyticsStudioID';

function CreateStudioForm() {
  const [createStudio, { isLoading }] = useCreateStudioMutation();
  const [errors, setErrors] = useState({});
  /** State */
  const [state, setState] = useSetState({
    name: '',
    email: '',
    bizdevEmail: '',
    success: '',
    gameAnalyticsStudioID: '',
  });
  const handleTextChange = (text, field) => {
    setState({ [field]: text });
    if (errors && errors[field]) {
      setErrors({ ...errors, [field]: '' });
    }
  };

  const handleCreateStudio = (e) => {
    e.preventDefault();

    const formatCreateStudioValidation = (details) => {
      let err = details.map((error) => {
        return {
          path: error.path,
          message: error.message,
        };
      });
      return formatJoiErrorsArray(err);
    };

    const { error } = CreateStudioSchema.validate(state, { abortEarly: false });
    if (error) {
      return setErrors(formatCreateStudioValidation(error.details));
    }

    createStudio({
      name: state.name,
      email: state.email,
      bizdev_email: state.bizdevEmail,
      ga_studio_id: state.gameAnalyticsStudioID,
    })
      .unwrap()
      .then(() => {
        setState({
          name: '',
          email: '',
          bizdevEmail: '',
          success: 'Your studio has been created!',
          gameAnalyticsStudioID: '',
        });
      })
      .catch((err) => {
        if (err?.data?.message) {
          return setErrors([err.data]);
        }
        setState({
          error: err?.data?.message || 'Something went wrong',
          success: '',
        });
      });
  };

  return (
    <Row className="g-3">
      {isLoading ? (
        <div className="d-flex justify-content-center w-100">
          <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
        </div>
      ) : (
        <Form onSubmit={handleCreateStudio}>
          {errors && errors.length > 0 && (
            <div className="alert alert-danger mb-3 d-flex align-items-center fs-7" role="alert">
              <div>
                {errors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </div>
            </div>
          )}
          {state.success && (
            <div className="alert alert-success d-flex align-items-center fs-7" role="alert">
              <div className=" me-3">
                <CheckmarkIcon size={16} />
              </div>
              <div>{state.success}</div>
            </div>
          )}

          <Form.Group as={Col} xs={12} controlId="studioname">
            <Form.Label className="fs-7 ">Studio Name</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                className="form-control"
                aria-describedby="Type studio name"
                placeholder="Studio name"
                value={state.name || ''}
                onChange={(text) => handleTextChange(text.target.value, 'name')}
                isInvalid={!!errors.name}
                name="name"
              />
              <Form.Control.Feedback tooltip type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group as={Col} xs={12} controlId="studioemail">
            <Form.Label className="fs-7 ">Studio Email</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="email"
                className="form-control"
                aria-describedby="Type studio email"
                placeholder="Studio email"
                value={state.email || ''}
                onChange={(text) => handleTextChange(text.target.value, 'email')}
                isInvalid={!!errors.email}
                name="email"
              />
              <Form.Control.Feedback tooltip type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group as={Col} xs={12} controlId="contactemail">
            <Form.Label className="fs-7">Contact Email</Form.Label>
            <InputGroup hasValidation>
              <Form.Select
                className="form-select"
                value={state.bizdevEmail || ''}
                onChange={(e) => handleTextChange(e.target.value, 'bizdevEmail')}
                isInvalid={!!errors.bizdevEmail}
                name="contact-email"
              >
                <option value={''}>Contact Email</option>
                {BIZ_DEV_EMAIL_LIST.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback tooltip type="invalid">
                {errors.bizdevEmail}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <GameAnalyticsStudioID
            value={state.gameAnalyticsStudioID}
            onChange={(value) => handleTextChange(value, 'gameAnalyticsStudioID')}
            studioName={state.name}
          />

          <div className="d-flex">
            <Button variant="success" className=" ms-auto mt-2" type="submit" id="create-btn">
              Create
            </Button>
          </div>
        </Form>
      )}
    </Row>
  );
}

export default CreateStudioForm;
