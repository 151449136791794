import TapNationPng from '../assets/logoTapNation/LogoTapNation40.png';
import { Link } from 'react-router-dom';
import AndroidIcon from './Icons/AndroidIcon';
import AppleIcon from './Icons/AppleIcon';
import ArchiveIcon from './Icons/ArchiveIcon';
import { useSelector } from 'react-redux';
import { getGameById } from '../data/slices/studio';
import { useGetGamesQuery } from '../data/api/studioApi';
import Loader from './Loader';
import React from 'react';
import TapNationLogo from '../assets/logoTapNation/LogoTapNation40.png';

const GameInfoCard = ({ id, game, os }) => {
  const { isLoading } = useGetGamesQuery();
  let foundGame = useSelector(getGameById(id));
  if (game) foundGame = game;
  if (os && foundGame?.[os]) foundGame = { ...foundGame, ...foundGame[os], multipleOs: foundGame.multipleOs };
  return isLoading ? (
    <div className="d-flex align-items-center justify-content-center ">
      <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
    </div>
  ) : !foundGame ? null : (
    <div className="h-100 mw-100 bg-white rounded d-flex align-items-center p-3">
      <div className="position-relative flex-shrink-0 overflow-hidden" style={{ width: 40, height: 40 }}>
        {foundGame?.status === 'ARCHIVED' && (
          <div className="position-absolute top-0 start-0 w-100 h-100  d-flex align-items-center justify-content-center">
            <div className="position-absolute z-0 top-0 start-0 w-100 h-100 bg-dark-300 opacity-50 rounded "></div>
            <ArchiveIcon className={'z-2'} color="#fff" />
          </div>
        )}
        <img
          className="img-fluid img-fit-contain rounded"
          width={44}
          height={44}
          src={foundGame?.logoUrl || TapNationPng}
          alt={foundGame?.appName}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = TapNationLogo;
          }}
        />
      </div>
      <div className="ms-2 d-flex flex-column flex-grow-1 overflow-hidden">
        <div className="d-flex flex-grow-1">
          <h5 className="m-0 text-truncate">{foundGame?.appName}</h5>

          <div className="d-flex align-items-center ms-auto">
            {(foundGame?.os === 'android' || foundGame?.multipleOs) && (
              <Link
                className="mx-1"
                to={{
                  pathname: 'https://play.google.com/store/apps/details?gl=US&id=' + foundGame?.bundleId,
                }}
                target="_blank"
              >
                <AndroidIcon size={20} />
              </Link>
            )}
            {(foundGame?.os === 'ios' || foundGame?.multipleOs) && (
              <Link
                className="mx-1"
                to={{
                  pathname: 'https://apps.apple.com/us/app/' + foundGame?.appName + '/id' + foundGame?.appId,
                }}
                target="_blank"
              >
                <AppleIcon size={20} />
              </Link>
            )}
          </div>
        </div>
        <p className="m-0 text-secondary text-truncate overflow-hidden w-75">{foundGame.bundleId}</p>
      </div>
    </div>
  );
};
GameInfoCard.defaultProps = {
  bundleId: '',
};
export default GameInfoCard;
