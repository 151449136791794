import { NavLink } from 'react-router-dom';
import fileDownload from 'js-file-download';

/** Import Internals */
import Form from 'react-bootstrap/Form';
import DownloadArrow from '../../../../Components/Icons/DownloadArrow';

/** Import Querys */
import { CloudGetFile } from '../../../../data/api/Querys';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import GameInfoCard from '../../../../Components/GameInfoCard';
import { Button } from 'react-bootstrap';
import { R_SDK } from '../../../../constants/routes';
import Loader from '../../../../Components/Loader';
import { useState } from 'react';

function SDKView({ data, setData }) {
  const sdkSelector = ['No Ad', 'Max', 'Mopub'];
  const [loading, setLoading] = useState(false);

  const todoListValues = [
    'Publish the app on the app store and/or the android store',
    'Integrate Adjust SDK',
    'Integrate GA SDK / Share the access to the game',
    'Make sure the studio properly set up the store id within GA app settings',
  ];

  const handleCheckboxChange = (nb) => {
    if (data.todoList.find((e) => e === nb || e === nb.toString())) {
      setData({
        todoList: data.todoList.filter((e) => e !== nb && e !== nb.toString()),
      });
    } else {
      setData({ todoList: [...data.todoList, nb] });
    }
  };

  const handleSettingsSDK = (value) => {
    let selectedSdk;
    switch (value) {
      case 'No Ad':
        selectedSdk = 0;
        break;
      case 'Max':
        selectedSdk = 1;
        break;
      case 'Mopub':
        selectedSdk = 2;
        break;
      default:
        selectedSdk = 1;
    }

    if (value === '') selectedSdk = '';

    return setData({ advertisingSdk: String(selectedSdk) });
  };

  const handleDownloadSDK = async (e) => {
    e.preventDefault();
    const pathNameSDK = 'tapnation-unity-sdk/v2_7_Max/FunGamesSdk_2_7.unitypackage.gz';

    try {
      setLoading(true);

      const result = await CloudGetFile(pathNameSDK);
      fileDownload(result.data, 'FunGamesSdk-2-7.unitypackage.gz');
    } catch (error) {
      console.error('Error downloading SDK:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="border rounded mb-2">
        <GameInfoCard
          game={{
            logoUrl: data?.logoUrl,
            appName: data?.appName,
            os: isNaN(data?.appId) ? 'android' : 'ios',
            bundleId: data?.bundleId,
            appId: data?.appId,
          }}
        />
      </div>
      <Form.Group className="position-relative mb-3" controlId="gameName">
        <Form.Label>SDK Setting</Form.Label>
        <Form.Select
          className="form-select"
          value={sdkSelector[data.advertisingSdk]}
          onChange={(text) => handleSettingsSDK(text.target.value)}
        >
          <option value={''}>Select Setting</option>
          {sdkSelector.map((value, index) => (
            <option value={value} key={index} disabled={value === 'Max' ? false : true}>
              {value}
            </option>
          ))}
        </Form.Select>

        <Form.Control.Feedback tooltip type="invalid">
          Game Name Must not be empty.
        </Form.Control.Feedback>
      </Form.Group>

      <div className="alert alert-warning mb-3 d-flex align-items-center fs-7" role="alert">
        <div className=" me-3">
          <InfoIcon />
        </div>
        <div>
          Please read and use the SDK User Guide to complet choses steps
          <span>(Studios can only check the first step, others will be verified and checked by us)</span>
        </div>
      </div>

      <div className="d-flex align-items-center mb-3">
        <NavLink to={R_SDK} target="_blank" className="btn btn-secondary me-1 flex-grow-1">
          SDK User Guide
        </NavLink>

        <div className="ms-1 d-flex flex-grow-1 justify-content-center ">
          <Button
            className="w-100 text-center download-sdk d-flex justify-content-center"
            onClick={handleDownloadSDK}
            disabled={loading}
          >
            {loading ? <Loader parentStyle="loader" size={20} color={'#3F96C7'} /> : <DownloadArrow />}
            <span className="ms-2">{loading ? 'Downloading...' : 'Download SDK'}</span>
          </Button>
        </div>
      </div>

      <div className="check-box-label pt-0 multiple-game-perfomance-check">
        {todoListValues.map((value, index) => (
          <Form.Check
            key={index}
            className="mb-2"
            type={'checkbox'}
            id={`checkbox-${value}`}
            label={<span className="fs-7"> {value}</span>}
            checked={data.todoList.find((e) => e === index + 1 || e === (index + 1).toString()) ? true : false}
            onClick={() => handleCheckboxChange(index + 1)}
            name="check"
          />
        ))}
        <div className="p-3 px-4  fs-8 bg-light-400 rounded">
          Share the access to the game with the following email addresses:
          <br />
          analytics@tap-nation.io,
          <br />
          data@tap-nation.io
        </div>
      </div>
    </div>
  );
}

export default SDKView;
