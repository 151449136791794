import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useQuery } from '../../../utils/customHooks';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

const MetricsGroupBtns = ({ abTest }) => {
  const query = useQuery();
  const group = query.get('group') || 'overview';
  const history = useHistory();
  const handleChange = (name, value) => {
    if (!value) query.delete(name);
    else query.set(name, value);
    history.push(history.location.pathname + '?' + query.toString());
  };

  const chartGroups = ['Overview', 'Ads', 'Retention'];
  if (Number(abTest?.gen) === 1) chartGroups.push('Churn Rate');
  if (Number(abTest?.gen) === 2) {
    chartGroups.push('Playtime');
    chartGroups.push('IAP');
  }

  return (
    <>
      <div className="top-bar-tab-btns d-none d-lg-block">
        <ButtonGroup id="grouped_button" className="country_grouped_button" aria-label="Basic example">
          {chartGroups.map((btn, index) => (
            <button
              key={index}
              type="button"
              className={`btn ${btn.toLowerCase() === group.toLowerCase() ? 'active' : ''}`}
              onClick={() => handleChange('group', btn.toLowerCase())}
            >
              {btn}
            </button>
          ))}
        </ButtonGroup>
      </div>
      <div className="d-block d-lg-none">
        <Dropdown onSelect={(eventKey) => handleChange('group', eventKey.toLowerCase())}>
          <Dropdown.Toggle id="dropdown-basic">{group.charAt(0).toUpperCase() + group.slice(1)}</Dropdown.Toggle>
          <Dropdown.Menu>
            {chartGroups.map((btn, index) => (
              <Dropdown.Item key={index} eventKey={btn} active={btn.toLowerCase() === group.toLowerCase()}>
                {btn}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default MetricsGroupBtns;
