import { useMemo, useReducer } from 'react';
import { useLocation } from 'react-router-dom';

export const useSetState = (initialState) => {
  return useReducer(
    (previousState, nextState) => {
      return { ...previousState, ...nextState };
    },
    {
      ...initialState,
    }
  );
};
// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};
