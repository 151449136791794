import React from 'react';
import MetricsTable from '../JsonMetrics/MetricsTable';
import MetricsTableChart from '../JsonMetrics/MetricsTableChart';

const MetricsTableDemo = () => {
  const header = [
    {
      value: 'cohort',
      popup: {
        confidence: 0.3260262052285233,
        pValue: 0.6739737947714767,
        tTest: 0.4207019012076778,
      },
    },

    {
      value: 'install',
    },

    {
      value: 'ad arpu',
      popup: {
        confidence: 0.3260262052285233,
        pValue: 0.6739737947714767,
        tTest: 0.4207019012076778,
      },
    },
  ];
  const footer = ['Footer 1', 'Footer 2'];
  const data = [
    [
      {
        value: 'BaseCohort',
        className: 'bg-gray-200',
      },
      {
        value: '2',
        className: 'bg-gray-200',
      },
      {
        value: '3',
        className: 'bg-gray-200',
      },
    ],
    [
      {
        value: 'FakeCohort',
      },
      {
        value: '5',
        diffFromBase: 0.12,
        popup: {
          confidence: 0.3260262052285233,
          pValue: 0.6739737947714767,
          tTest: 0.4207019012076778,
        },
      },
      {
        value: '1',
        diffFromBase: -0.3,
        popup: {
          confidence: 0.3260262052285233,
          diffFromBase: -1.2034704729918744,
          pValue: 0.6739737947714767,
          tTest: 0.4207019012076778,
        },
      },
    ],
    [
      {
        value: 'AnotherCohort',
        popup: {
          confidence: 0.965233,
          diffFromBase: -1.2034704729918744,
          pValue: 0.6739737947714767,
          tTest: 0.4207019012076778,
        },
      },
      {
        value: '4',
        diffFromBase: 0.279,
        className: '',
        rest: {},
        popup: {
          confidence: 0.3260262052285233,
          diffFromBase: -1.2034704729918744,
          pValue: 0.6739737947714767,
          tTest: 0.4207019012076778,
        },
      },
      {
        value: '8',
        diffFromBase: -0.3678,
        className: '',
        rest: {},
        popup: {
          confidence: 0.3260262052285233,
          diffFromBase: -1.2034704729918744,
          pValue: 0.6739737947714767,
          tTest: 0.4207019012076778,
        },
      },
    ],
  ];

  const chartData = [
    {
      date: '2023-10-11',
      baseCohort: {
        value: '4',
        diffFromBase: -0.3678,
        className: '',
        rest: {},
        popup: {
          confidence: 0.3260262052285233,
          diffFromBase: -1.2034704729918744,
          pValue: 0.6739737947714767,
          tTest: 0.4207019012076778,
        },
      },
      inGameAds_: {
        value: '7',
        diffFromBase: -0.3678,
        className: '',
        rest: {},
        popup: {
          confidence: 0.3260262052285233,
          diffFromBase: -1.2034704729918744,
          pValue: 0.6739737947714767,
          tTest: 0.4207019012076778,
        },
      },
      isCompletion: {
        value: '5',
        diffFromBase: -0.3678,
        className: '',
        rest: {},
        popup: {
          confidence: 0.3260262052285233,
          diffFromBase: -1.2034704729918744,
          pValue: 0.6739737947714767,
          tTest: 0.4207019012076778,
        },
      },
    },
    {
      date: '2023-10-12',
      baseCohort: {
        value: '3',
        diffFromBase: -0.3678,
        className: '',
        rest: {},
        popup: {
          confidence: 0.3260262052285233,
          diffFromBase: -1.2034704729918744,
          pValue: 0.6739737947714767,
          tTest: 0.4207019012076778,
        },
      },
      inGameAds_: {
        value: '9',
        diffFromBase: -0.3678,
        className: '',
        rest: {},
        popup: {
          confidence: 0.3260262052285233,
          diffFromBase: -1.2034704729918744,
          pValue: 0.6739737947714767,
          tTest: 0.4207019012076778,
        },
      },
      isCompletion: {
        value: '3',
        diffFromBase: -0.3678,
        className: '',
        rest: {},
        popup: {
          confidence: 0.3260262052285233,
          diffFromBase: -1.2034704729918744,
          pValue: 0.6739737947714767,
          tTest: 0.4207019012076778,
        },
      },
    },
    {
      date: '2023-10-13',
      baseCohort: {
        value: '7',
        diffFromBase: -0.3678,
        className: '',
        rest: {},
        popup: {
          confidence: 0.3260262052285233,
          diffFromBase: -1.2034704729918744,
          pValue: 0.6739737947714767,
          tTest: 0.4207019012076778,
        },
      },
      inGameAds_: {
        value: '13',
        diffFromBase: -0.3678,
        className: '',
        rest: {},
        popup: {
          confidence: 0.3260262052285233,
          diffFromBase: -1.2034704729918744,
          pValue: 0.6739737947714767,
          tTest: 0.4207019012076778,
        },
      },
      isCompletion: {
        value: '4',
        diffFromBase: -0.3678,
        className: '',
        rest: {},
        popup: {
          confidence: 0.3260262052285233,
          diffFromBase: -1.2034704729918744,
          pValue: 0.6739737947714767,
          tTest: 0.4207019012076778,
        },
      },
    },
  ];

  const legendList = ['baseCohort', 'inGameAds_', 'isCompletion'];
  return (
    <div>
      <MetricsTable title="Title" header={header} footer={footer} data={data} downloadCsv csvDiffFromBase />
      <MetricsTableChart data={chartData} legendList={legendList} />
      <br />
    </div>
  );
};

export default MetricsTableDemo;
