import React, { useState } from 'react';
import { Accordion, Card, useAccordionButton } from 'react-bootstrap';
import ArrowDown from '../../../../Components/Icons/ArrowDown';
import LogoPng from '../../../../assets/logoTapNation/LogoTapNation40.png';

const CustomToggle = ({ children, eventKey, activeKey, onClick }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => onClick(eventKey));
  const isCurrent = eventKey === activeKey;
  return (
    <div className="d-flex justify-content-end m-3">
      <div
        className="btn bg-white d-flex border-0 justify-content-center align-items-center gap-2"
        onClick={decoratedOnClick}
      >
        {children}
        <ArrowDown rotate={isCurrent ? 'up' : 'down'} size={14} />
      </div>
    </div>
  );
};

const VideoTips = ({ defaultlyOpen }) => {
  const [activeKey, setActiveKey] = useState(defaultlyOpen ? '0' : null);

  const handleToggleClick = (eventKey) => {
    setActiveKey(eventKey === activeKey ? null : eventKey);
  };

  return (
    <Accordion activeKey={activeKey}>
      <CustomToggle eventKey="0" activeKey={activeKey} onClick={handleToggleClick}>
        How to make a great videos
      </CustomToggle>
      <Accordion.Collapse eventKey="0">
        <Card>
          <Card.Body>
            <>
              <div
                className="container-fluid bg-primary mt-3 py-4 rounded"
                style={{ backgroundImage: `url(${LogoPng})`, backgroundSize: 'cover' }}
              >
                <div className="container text-white p-3">
                  <h1>WHAT WE NEED</h1>
                  <h4>GAMEPLAY FOOTAGES</h4>
                </div>
              </div>
              <div className="container mt-4 pb-3">
                <p style={{ fontWeight: 'bold' }}>
                  Exploring different gameplay options to capture footage for winning creatives. These are the essential
                  elements we need:
                </p>
                <ul className="d-flex flex-column gap-3">
                  <li>Different backgrounds, characters or levels</li>
                  <li>SFX</li>
                  <li>Most Actionable Scenes</li>
                  <li>Custom(Motion) Gameplay Footages</li>
                </ul>
              </div>
              <div
                className="container-fluid bg-primary mt-3 py-4 rounded"
                style={{ backgroundImage: `url(${LogoPng})`, backgroundSize: 'cover' }}
              >
                <div className="container text-white p-3">
                  <h1>WHAT WE NEED</h1>
                  <h4>CUSTOM BUILD - APK</h4>
                </div>
              </div>
              <div className="container mt-4 pb-4">
                <p style={{ fontWeight: 'bold' }}>
                  To Record Gameplay, we require an APK file containing the following components:
                </p>
                <ul className="d-flex flex-column gap-3">
                  <li>Ads Free</li>
                  <li>Different characters, background or floors option</li>
                  <li>Different camera angles</li>
                  <li>Music/Sound option</li>
                </ul>
              </div>
              <div
                className="container-fluid bg-primary mt-3 py-4 rounded"
                style={{ backgroundImage: `url(${LogoPng})`, backgroundSize: 'cover' }}
              >
                <div className="container text-white p-3">
                  <h1>WHAT WE NEED</h1>
                  <h4>MATERIALS</h4>
                </div>
              </div>
              <div className="container mt-4 pb-4">
                <p style={{ fontWeight: 'bold' }}>
                  Materials can change depends on the game. To create Google/SDK Networks, generally we need:
                </p>
                <ul className="d-flex flex-column gap-3">
                  <li>Game's assets such as characters, items, textures, Ul, etc.</li>
                  <li>Icon, Logo</li>
                  <li>PNG or JPEG</li>
                  <li>All Access Link (Drive, Wetransfer, etc)</li>
                </ul>
              </div>
              <div
                className="container-fluid bg-primary mt-3 py-4 rounded"
                style={{ backgroundImage: `url(${LogoPng})`, backgroundSize: 'cover' }}
              >
                <div className="container text-white p-3">
                  <h1>WHAT WE NEED</h1>
                  <h4>UNITY PROJECT</h4>
                </div>
              </div>
              <div className="container mt-4">
                <p style={{ fontWeight: 'bold' }}>Have to add SrDebugger to inspector:</p>
                <ul className="d-flex flex-column gap-3">
                  <li>Unlock all maps; allowing us to select and start any level we want, including bonus levels.</li>
                  <li>
                    Unlock all items; if there are multiple weapons/special powers, start the game with the
                    weapon/special power of our choice in SrDebugger. There should be unlimited ammo/special time
                    settings, with the option to toggle them on and off.
                  </li>
                  <li>
                    Unlock all characters; if there are multiple characters, start the game with the character of our
                    choice
                  </li>
                  <li>If the game is an idle arcade, add unlimited money to the game</li>
                  <li>
                    We need to add a hand sprite. There should be approximately 6-7 types of hand images, but we need to
                    make this optional in Unity with a toggle option. Additionally, we need to manually adjust the scale
                    dimensions of the hands
                  </li>
                </ul>
              </div>
            </>
          </Card.Body>
        </Card>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default VideoTips;
