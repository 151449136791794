import CreateStudioForm from './CreateStudioForm';
import { Button, Modal } from 'react-bootstrap';
import AddIcon from '../../../Components/Icons/AddIcon';
import { useState } from 'react';

function AddAccount() {
  const [showForm, setShowForm] = useState(false);
  const handleClose = () => {
    setShowForm(false);
  };
  return (
    <>
      <Button
        type="button"
        variant="success"
        className="d-flex"
        onClick={() => setShowForm(true)}
        id="cteate-studio-modal-btn"
      >
        <AddIcon size={20} className="me-2" /> Studio
      </Button>
      <Modal centered scrollable dialogClassName="modal-md" show={showForm} onHide={handleClose} id="studio-modal">
        <Modal.Header closeButton>
          <Modal.Title>Create Studio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateStudioForm />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddAccount;
