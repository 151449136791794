import Form from 'react-bootstrap/Form';
import { Col, InputGroup } from 'react-bootstrap';
import CalendarIcon from '../../../Components/Icons/CalendarIcon';
import moment from 'moment';
import DatePicker from 'react-datepicker';

const CohortEndDate = ({ onDateChange, startDate, endDate }) => {
  const currentDate = moment();
  const startDateMoment = moment(startDate);
  const isStartDateEditable = currentDate.isBefore(startDateMoment, 'day');

  let maxDate = moment(startDate);
  maxDate.add(29, 'days');

  return (
    <>
      <Form.Group className={`cursor-pointer pe-0 position-relative`} as={Col} xs={6} controlId="startDate">
        <Form.Label className="fs-7">
          Start Date <span className="text-danger fs-7">*</span>
        </Form.Label>
        <InputGroup hasValidation={isStartDateEditable} className="d-flex flex-nowrap">
          <Form.Control
            type="text"
            aria-describedby=""
            placeholder="Start date"
            readOnly
            value={moment(startDate).format('ll')}
            disabled={!isStartDateEditable}
          />
          <InputGroup.Text>
            <CalendarIcon size={14} />
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
      <Form.Group className="position-relative cursor-pointer" as={Col} xs={6} controlId="endDate">
        <Form.Label className="fs-7">
          End Date <span className="text-danger fs-7">*</span>
        </Form.Label>
        <InputGroup hasValidation className="d-flex flex-nowrap">
          <DatePicker
            id="datepicker"
            className="form-control"
            selected={new Date(endDate)}
            onChange={(date) => onDateChange(startDate, date?.toDateString())}
            minDate={new Date(startDate)}
            maxDate={new Date(maxDate.format('ll'))}
            dateFormat="MMM d, yyyy"
          />
          <InputGroup.Text className="cursor-pointer">
            <CalendarIcon size={14} />
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
    </>
  );
};

export default CohortEndDate;
