import * as Joi from 'joi';
import cohortParamsSchema from './cohortParamsSchema';

export const cohortSchema = Joi.object({
  name: Joi.string().trim().required().messages({ 'string.empty': 'Name is required' }),
  cohort: Joi.number().greater(0).required(),
  params: cohortParamsSchema,
}).options({ abortEarly: false, allowUnknown: true });

export default cohortSchema;
