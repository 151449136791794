import React from 'react';
import * as PropTypes from 'prop-types';

function AccountProfileIcon(props) {
  const { color } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      color={color}
      viewBox="0 0 256 256"
    >
      <circle
        cx="128"
        cy="96"
        fill="none"
        r="64"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="22"
      />
      <path
        d="M31,216a112,112,0,0,1,194,0"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="22"
      />
    </svg>
  );
}
AccountProfileIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

AccountProfileIcon.defaultProps = {
  size: 24,
  color: '',
};

export default AccountProfileIcon;
