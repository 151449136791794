import * as Joi from 'joi';

export const cohortParamsSchema = Joi.array()
  .items(
    Joi.object({
      name: Joi.string().trim().max(500).trim().required().messages({ 'string.empty': 'Name is required' }),
      value: Joi.string().trim().max(1000).trim().required().messages({ 'string.empty': 'Value is required' }),
    })
  )
  .min(1)
  .max(100)
  .required()
  .messages({ 'array.min': 'Add at least one item' })
  .messages({ 'array.max': 'Reached maximum allowed items' })
  .options({ abortEarly: false, allowUnknown: true });

export default cohortParamsSchema;
