import * as Joi from 'joi';
import cohortSchema from './cohortSchema';
export const abTestSchema = Joi.object({
  startDate: Joi.date().raw().required(),
  endDate: Joi.date().raw().required(),
  bundleId: Joi.string().trim().required().messages({ 'string.empty': 'Bundle ID is required' }),
  os: Joi.valid('all', 'ios', 'android').required(),
  store: Joi.array().items(Joi.string().trim()),
  build: Joi.string().trim().required().messages({ 'string.empty': 'Build version is required' }),
  name: Joi.string()
    .trim()
    .required()
    .messages({ 'string.empty': 'Name is required', 'any.required': 'Name is required' }),
  device: Joi.string().allow(''),
  gen: Joi.number(),
  cohorts: Joi.array().items(cohortSchema).min(1).required(),
})
  .custom((values, helpers) => {
    const { device, startDate, endDate, cohorts } = values;
    if (!device && new Date(endDate).getTime() <= new Date(startDate).getTime() + 172800000) {
      return helpers.error('endDate');
    }
    if (!device && new Date(endDate).getTime() - new Date(startDate).getTime() > 30 * 86400 * 1000)
      return helpers.error('startDate');
    if (device && cohorts.length > 1) {
      return helpers.error('cohorts');
    }
    return values;
  })
  .messages({
    endDate: 'A/B tests need to be more than 2 days long for a report',
    startDate: 'A/B tests need to be less than 30 days long',
    cohorts: 'Only one cohort can be added with device Id',
  })
  .options({ abortEarly: false, allowUnknown: true });

export default abTestSchema;
