import { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useSetState } from '../../../utils/customHooks';
import { setCookie } from '../../../utils/common';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../data/slices/user';
import { Button, Col, InputGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import InfoIcon from '../../../Components/Icons/InfoIcon';
import CheckmarkIcon from '../../../Components/Icons/CheckmarkIcon';
import { useUpdateUserProfileMutation } from '../../../data/api/userApi';

function UpdateAccountForm() {
  const user = useSelector(userSelector);
  const [updateUser, { isLoading }] = useUpdateUserProfileMutation();

  const [state, setState] = useSetState({
    firstName: '',
    lastName: '',
    password: '',
    rePassword: '',
    error: '',
    success: '',
  });

  useEffect(() => {
    setState({
      firstName: user.firstName,
      lastName: user.lastName,
    });
  }, [user]);

  const handleTextChange = (text, field) => {
    return setState({ [field]: text, error: '', success: '' });
  };

  const handleUpdateAccount = (e) => {
    e.preventDefault();
    if (state.password === state.rePassword) {
      const post = {
        first_name: state.firstName,
        last_name: state.lastName,
        password: state.password,
        re_password: state.rePassword,
      };

      updateUser(post)
        .unwrap()
        .then((response) => {
          setCookie('user-id', JSON.stringify(response), 1);
          setState({
            password: '',
            rePassword: '',
            error: '',
            success: 'Your account has been updated!',
          });
        })
        .catch((err) => {
          setState({
            error: err?.data?.message || 'Something went wrong',
            success: '',
          });
        });
    } else {
      setState({
        error: 'Passwords not matching',
        success: '',
      });
    }
  };

  return (
    <Form onSubmit={handleUpdateAccount}>
      <Row className="g-3">
        {state.error && (
          <div className="alert alert-danger d-flex align-items-center fs-7" role="alert">
            <div className=" me-3">
              <InfoIcon />
            </div>
            <div>{state.error}</div>
          </div>
        )}
        {state.success && (
          <div className="alert alert-success d-flex align-items-center fs-7" role="alert">
            <div className=" me-3">
              <CheckmarkIcon size={16} />
            </div>
            <div>{state.success}</div>
          </div>
        )}
        <Form.Group as={Col} xs={12} controlId="studioid">
          <Form.Control type="email" aria-describedby="Email" placeholder="Email" disabled value={user.login || ''} />
        </Form.Group>
        <Form.Group as={Col} xs={12} controlId="token">
          <Form.Label className="fs-7 ">API token</Form.Label>
          <Form.Control type="text" placeholder="API token" disabled value={user.apiToken || ''} />
        </Form.Group>
        <Form.Group as={Col} sm={6} xs={12} controlId="firstname">
          <Form.Label className="fs-7 ">First Name</Form.Label>
          <Form.Control
            type="text"
            aria-describedby="Type name"
            placeholder="First Name"
            value={state.firstName}
            onChange={(text) => handleTextChange(text.target.value, 'firstName')}
          />
        </Form.Group>

        <Form.Group as={Col} sm={6} xs={12} controlId="lastname">
          <Form.Label className="fs-7 ">Last Name</Form.Label>
          <Form.Control
            type="text"
            aria-describedby="Type last name"
            placeholder="Last Name"
            value={state.lastName}
            onChange={(text) => handleTextChange(text.target.value, 'lastName')}
          />
        </Form.Group>

        <Form.Group as={Col} xs={12} controlId="password">
          <Form.Label className="fs-7 ">Password</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              type="password"
              aria-describedby="Password"
              placeholder="Type password"
              value={state.password || ''}
              onChange={(text) => handleTextChange(text.target.value, 'password')}
            />
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip {...props}>
                  Password should be at least 8 characters long, with an uppercase, a lowercase. <br />
                  Password should not contain any special characters \'!@#\$%\^&*\
                </Tooltip>
              )}
            >
              <InputGroup.Text className="cursor-pointer">
                <InfoIcon size={18} />
              </InputGroup.Text>
            </OverlayTrigger>
            <Form.Control.Feedback tooltip type="invalid">
              Bundle Id Must not be empty.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} xs={12} controlId="repassword">
          <Form.Label className="fs-7 ">Repeat Password</Form.Label>
          <Form.Control
            type="password"
            aria-describedby="Re-Password"
            placeholder="Re-Password"
            value={state.rePassword || ''}
            onChange={(text) => handleTextChange(text.target.value, 'rePassword')}
          />
        </Form.Group>
        <Button className=" ms-auto" type="submit" disabled={isLoading}>
          Update
        </Button>
      </Row>
    </Form>
  );
}

export default UpdateAccountForm;
