import { useEffect, useState } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import EditIcon from '../../Components/Icons/EditIcon';
import Loader from '../../Components/Loader';
import Form from 'react-bootstrap/Form';
import { useUpdateStudioMutation } from '../../data/api/studioApi';
import { useSetState } from '../../utils/customHooks';
import { confirmAlert } from '../../Components/ConfirmModal';
import InfoIcon from '../../Components/Icons/InfoIcon';
import CheckmarkIcon from '../../Components/Icons/CheckmarkIcon';
import { BIZ_DEV_EMAIL_LIST } from '../../constants/global';
import GameAnalyticsStudioID from './CreateStudio/GameAnalyticsStudioID';

function UpdateStudioModal({ data }) {
  const [update, { isLoading, error }] = useUpdateStudioMutation();

  const [showForm, setShowForm] = useState(false);
  const [state, setState] = useSetState({
    name: '',
    email: '',
    bizDevEmail: '',
    error: '',
    gameAnalyticsStudioId: '',
  });

  useEffect(() => {
    if (showForm && data) {
      setState({
        name: data.name,
        email: data.email,
        bizDevEmail: data.bizdevEmail,
        error: '',
        gameAnalyticsStudioId: data.gaStudioId,
      });
    }
  }, [data, showForm]);

  const handleClose = () => {
    setShowForm(false);
  };

  const handleTextChange = (text, field) => {
    return setState({ [field]: text, error: '' });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    update({
      id: data.studioId,
      name: state.name === data.name ? '' : state.name,
      email: state.email,
      bizdev_email: state.bizDevEmail,
      ga_studio_id: state.gameAnalyticsStudioId,
    })
      .unwrap()
      .then(() => {
        confirmAlert({
          title: 'Studio has been successfully updated',
          variant: 'success',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
        handleClose();
      })
      .catch((err) => {
        setState({
          error: err.data?.message || 'Something went wrong',
          success: '',
        });
      });
  };

  return (
    <>
      <Button type="button" variant="info" className="d-flex" onClick={() => setShowForm(true)} id="hi">
        <EditIcon size={20} />
      </Button>
      {showForm && (
        <Modal centered dialogClassName="modal-md" show={showForm} onHide={handleClose} id="update-studio">
          <Modal.Header closeButton>
            <Modal.Title>Update Studio</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              {isLoading ? (
                <div className="d-flex justify-content-center w-100">
                  <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
                </div>
              ) : (
                <>
                  {(state.error || error) && (
                    <div className="alert alert-danger d-flex align-items-center fs-7" role="alert">
                      <div className=" me-3">
                        <InfoIcon />
                      </div>
                      <div>{state.error || error?.data?.message}</div>
                    </div>
                  )}
                  {state.success && (
                    <div className="alert alert-success d-flex align-items-center fs-7" role="alert">
                      <div className=" me-3">
                        <CheckmarkIcon size={16} />
                      </div>
                      <div>{state.success}</div>
                    </div>
                  )}

                  <Form.Group as={Col} xs={12} controlId="studioname">
                    <Form.Label className="fs-7 ">Studio Name</Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      aria-describedby="Type studio name"
                      placeholder="Studio name"
                      value={state.name || ''}
                      onChange={(text) => handleTextChange(text.target.value, 'name')}
                      name="name"
                    />
                  </Form.Group>

                  <Form.Group as={Col} xs={12} controlId="studioemail">
                    <Form.Label className="fs-7 ">Studio Email</Form.Label>
                    <Form.Control
                      type="email"
                      className="form-control"
                      aria-describedby="Type studio email"
                      placeholder="Studio email"
                      value={state.email || ''}
                      onChange={(text) => handleTextChange(text.target.value, 'email')}
                      name="email"
                    />
                  </Form.Group>

                  <Form.Group as={Col} xs={12} controlId="contactemail">
                    <Form.Label className="fs-7 ">Contact Emai</Form.Label>
                    <Form.Select
                      className="form-select"
                      value={state.bizDevEmail || ''}
                      onChange={(text) => handleTextChange(text.target.value, 'bizDevEmail')}
                      name="contact-email"
                    >
                      <option value={''}>Contact Email</option>
                      {BIZ_DEV_EMAIL_LIST.map((value, index) => (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <GameAnalyticsStudioID
                    value={state.gameAnalyticsStudioId}
                    onChange={(value) => handleTextChange(value, 'gameAnalyticsStudioId')}
                    studioName={state.name}
                  />
                </>
              )}
            </>
          </Modal.Body>
          <Modal.Footer className="d-flex">
            <div className="ms-auto">
              <Button type="button" variant="outline-light" className=" ms-auto" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" variant="primary" className=" ms-3" onClick={handleUpdate} id="update-btn">
                Update
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default UpdateStudioModal;
