import { createSlice, createSelector } from '@reduxjs/toolkit';
import { produce } from 'immer';

// Selectors
const selectGameConfig = (state) => state.gameConfig;
export const selectJsonData = createSelector([selectGameConfig], (gameConfig) => gameConfig.jsonData);
export const selectTemplateData = createSelector([selectGameConfig], (gameConfig) => gameConfig.templateData);
export const selectTemplates = createSelector([selectGameConfig], (gameConfig) => gameConfig.templates);
export const selectSelectedTemplate = createSelector([selectGameConfig], (gameConfig) => gameConfig.selectedTemplate);
export const selectChangedFields = createSelector([selectGameConfig], (gameConfig) => gameConfig.changedFields);
export const selectAvailableVersions = createSelector([selectGameConfig], (gameConfig) => gameConfig.availableVersions);
export const selectErrors = createSelector([selectGameConfig], (gameConfig) => gameConfig.errors);

const recLookup = (obj, path) => {
  const parts = path.split('.');
  if (parts.length === 1) return obj[parts[0]];
  return recLookup(obj[parts[0]], parts.slice(1).join('.'));
};

const initialState = {
  selectedTemplate: '',
  jsonData: null,
  templateData: null,
  changedFields: {},
  errors: {},
  templates: [
    { value: 'cpi_test', label: 'CPI Test Config' },
    { value: 'soft_launch', label: 'Softlaunch Config' },
    { value: 'custom', label: 'Custom Config' },
  ],
  availableVersions: {},
};

const gameConfigSlice = createSlice({
  name: 'gameConfig',
  initialState,
  reducers: {
    setErrors(state, action) {
      return produce(state, (draft) => {
        draft.errors = action.payload;
      });
    },
    setSelectedTemplate(state, action) {
      return produce(state, (draft) => {
        draft.selectedTemplate = action.payload;
      });
    },
    setChangedFields(state, action) {
      return produce(state, (draft) => {
        draft.changedFields = action.payload;
      });
    },
    setConfigData(state, action) {
      return produce(state, (draft) => {
        draft.jsonData = action.payload;
      });
    },
    setAvailableVersions(state, action) {
      return produce(state, (draft) => {
        draft.availableVersions = action.payload;
      });
    },
    updateModule(state, action) {
      const { path, updates } = action.payload;
      return produce(state, (draft) => {
        draft.errors = {};
        if (draft.jsonData) {
          const module = recLookup(draft.jsonData, path);
          if (module) {
            Object.assign(module, updates);
            if (updates.is_active) {
              let pathParts = path.split('.');
              while (pathParts.length > 1) {
                pathParts = pathParts.slice(0, -2);
                const parentPath = pathParts.join('.');
                const parentModule = recLookup(draft.jsonData, parentPath);
                if (parentModule && !parentModule.is_active) {
                  parentModule.is_active = true;
                }
              }
            }
            if (typeof module.is_active !== 'undefined' && !module.is_active) {
              const deactivateSubmodules = (currentModule) => {
                if (currentModule.submodules) {
                  currentModule.submodules.forEach((subModule) => {
                    subModule.is_active = false;
                    deactivateSubmodules(subModule);
                  });
                }
              };
              deactivateSubmodules(module);
            }
          }
        }
      });
    },
  },
});

export const { setSelectedTemplate, setErrors, setAvailableVersions, setChangedFields, updateModule, setConfigData } =
  gameConfigSlice.actions;
export default gameConfigSlice.reducer;
