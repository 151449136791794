import { generatePath, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import AbTestQualityDropdown from '../_Components/AbTestQualityDropdown';
import AbTestStatusDropdown from '../_Components/AbTestStatusDropdown';
import TestInfoModal from '../../_Components/TestInfoModal';
import { useState } from 'react';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import { R_AB_TEST_METRICS } from '../../../../constants/routes';
import GameOsIcons from '../../_Components/GameOsIcons';
import { Restricted } from '../../../../Components/permissions/UserPermissions';

const TestTableRow = ({ data }) => {
  const [modal, setModal] = useState(false);
  let history = useHistory();
  let { id } = useParams();
  const handleSeeMetrics = (e) => {
    e.stopPropagation();
    let url = generatePath(R_AB_TEST_METRICS, { gameId: id, testId: data.id });
    if (Number(data.gen) === 2) url += '?ads=1';

    return history.push(url);
  };

  return (
    <tr className="cursor-pointer" onClick={handleSeeMetrics}>
      <td className=" ">
        <GameOsIcons item={data} width="40px" height="35px" />
      </td>
      <td>
        <span>{data.name || data.cohorts.map((el) => el.name).join(', ')}</span>
      </td>
      <td className="fs-7 text-dark-400">
        {moment(data.startTs).utc().format('ll')}
        <span className="text-muted fs-8 ms-1">{moment(data.startTs).utc().format('LT')}</span>
      </td>
      <td className="fs-7 text-dark-400">
        {moment(data.endTs).utc().format('ll')}
        <span className="text-muted fs-8 ms-1">{moment(data.endTs).utc().format('LT')}</span>
      </td>
      <td className="text-center text-dark-400">{data.cohort ? (data.cohort * 100).toFixed(0) + '%' : ''}</td>
      <td className="text-truncate">{data?.updatedBy}</td>
      <td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className="btn btn-outline-light btn-sm p-1"
          onClick={(e) => {
            e.stopPropagation();
            setModal(true);
          }}
        >
          <InfoIcon />
        </div>
        {modal && <TestInfoModal id={id} data={data} onClose={() => setModal(false)} />}
      </td>
      <td className="cursor-default" onClick={(event) => event.stopPropagation()}>
        {data.status && (
          <Form.Group className="select-dropdown">
            <AbTestStatusDropdown data={data} />
          </Form.Group>
        )}
      </td>
      <Restricted permission="quality.update">
        <td className=" " onClick={(event) => event.stopPropagation()}>
          <Form.Group className="select-dropdown">
            <AbTestQualityDropdown data={data} />
          </Form.Group>
        </td>
      </Restricted>
    </tr>
  );
};

export default TestTableRow;
