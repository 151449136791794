import Form from 'react-bootstrap/Form';
import { Button, Collapse, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  useGetAcquisitionNetworksQuery,
  useGetAvailableAppsQuery,
  useGetAvailablePlatformsQuery,
} from '../../../../data/api/analyticsApi';
import React, { useState } from 'react';
import SearchableDropdown from '../../../../Components/SearchableDropdown';
import { countries } from '../../../../constants/global';
import DateSelectPopover from '../../_Components/DateSelectPopover';
import Loader from '../../../../Components/Loader';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import { capitalizeFirstLetter } from '../../../../utils/converters';

const dimensions = ['date', 'application', 'country', 'network', 'platform'];

const FiltersBar = ({ data, setData, onSubmit }) => {
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const {
    data: platforms,
    isFetching,
    error: platformsError,
  } = useGetAvailablePlatformsQuery(undefined, { skip: !open });
  const {
    data: games,
    isFetching: isFetchingGames,
    error: gamesError,
  } = useGetAvailableAppsQuery(undefined, { skip: !open });
  const {
    data: networks,
    isFetching: isFetchingNetworks,
    error: networksError,
  } = useGetAcquisitionNetworksQuery({ from: data.from, to: data.to }, { skip: !open });

  const handleSubmit = () => {
    //validate
    if (!data?.dimension.length) return setError('Select at least one dimension');
    setOpen(false);
    onSubmit();
  };
  const handleChange = (name, value) => {
    if (error) setError('');
    setData({ [name]: value });
  };
  const handleDimensionChange = (value) => {
    let tmp = value;
    if (typeof value === 'string') {
      tmp = [...data.dimension];
      if (tmp.includes(value)) {
        tmp.splice(tmp.indexOf(value), 1);
      } else {
        if (tmp.length > 1) tmp.shift();
        tmp.push(value);
      }
    }
    //place date first
    if (tmp.includes('date') && tmp[0] !== 'date') {
      tmp.splice(tmp.indexOf('date'), 1);
      tmp.unshift('date');
    }
    handleChange('dimension', tmp);
  };
  return (
    <>
      <div className="col-12 d-flex align-items-center mb-2 flex-wrap">
        <DateSelectPopover from={data.from} to={data.to} onChange={(name, val) => setData({ [name]: val })} />
        <Button variant="primary" size="sm" onClick={handleSubmit} id="acquistion-report-btn">
          Run Report
        </Button>

        <div className="d-flex ms-auto me-2 align-items-center">
          <Form.Check
            size="sm"
            label="Advanced"
            type="switch"
            onChange={() => {
              handleChange('dimension', data.dimension.slice(0, 2));
              handleChange('advanced', !data.advanced);
            }}
            checked={data.advanced}
            id="custom-switch"
            className="me-2 mb-0 mt-1 fs-7"
          />
          <Button
            size="sm"
            variant="primary"
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
          >
            {open ? 'Hide' : 'Show'} Filters
          </Button>
        </div>
      </div>
      {data.advanced && (
        <div className="col-12">
          <div className="d-flex flex-wrap mb-2">
            {dimensions.map((el) => (
              <Button
                className="me-2 text-info"
                size="sm"
                variant="outline-primary"
                key={el}
                active={data.dimension.includes(el)}
                onClick={() => {
                  handleDimensionChange(el);
                }}
              >
                {capitalizeFirstLetter(el)}
              </Button>
            ))}
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => <Tooltip {...props}>Select one or two dimension per report.</Tooltip>}
            >
              <span className="cursor-pointer">
                <InfoIcon size={18} />
              </span>
            </OverlayTrigger>
          </div>
        </div>
      )}
      {error && (
        <div className="col-12">
          <div className="alert alert-danger px-2 py-1 m-0 fs-7 mb-2 d-flex">
            <div className=" me-2">
              <InfoIcon size={20} />
            </div>
            <div>
              <p className="mb-0">{error}</p>
            </div>
          </div>
        </div>
      )}
      <Collapse in={open}>
        <div className="col-12 mb-2">
          <div
            className="bg-white border-top mx-1 px-3 py-2 "
            style={{
              borderBottomRightRadius: 'var(--bs-border-radius) ',
              borderBottomLeftRadius: 'var(--bs-border-radius) ',
            }}
          >
            <Form.Group className="position-relative mb-2 d-flex align-items-center w-100" controlId="os">
              <Form.Label className="fs-7 me-3 mb-0" style={{ minWidth: 90 }}>
                Platform
              </Form.Label>
              {isFetching ? (
                <div className="d-flex justify-content-center">
                  <Loader parentStyle="loader" size={34} color={'#3F96C7'} />
                </div>
              ) : platformsError ? (
                <div className="alert alert-danger px-2 py-1 m-0 fs-7 " role="alert">
                  {error?.data?.message?.message || error?.data?.message || 'Something went wrong.'}
                </div>
              ) : (
                <div style={{ width: 'calc(100% - 106px)' }}>
                  <SearchableDropdown
                    writeSelected
                    parClass={'w-100'}
                    multiple
                    disableSearch
                    collapseGroup
                    onSelect={(data) => handleChange('platform', data)}
                    selected={data.platform}
                    onExclude={(data) => handleChange('exPlatform', data)}
                    excluded={data.exPlatform}
                    options={platforms ? platforms.map((el) => ({ value: el.platform, label: el.platform })) : []}
                  />
                </div>
              )}
            </Form.Group>
            <Form.Group className="position-relative mb-2 d-flex align-items-center w-100" controlId="app">
              <Form.Label className="fs-7 me-3 mb-0" style={{ minWidth: 90 }}>
                Application
              </Form.Label>
              {isFetchingGames ? (
                <div className="d-flex justify-content-center">
                  <Loader parentStyle="loader" size={34} color={'#3F96C7'} />
                </div>
              ) : gamesError ? (
                <div className="alert alert-danger px-2 py-1 m-0 fs-7" role="alert">
                  {gamesError?.data?.message?.message || gamesError?.data?.message || 'Something went wrong.'}
                </div>
              ) : (
                <div style={{ width: 'calc(100% - 106px)' }}>
                  <SearchableDropdown
                    parClass={'w-100'}
                    multiple
                    writeSelected
                    onExclude={(data) => handleChange('exApp', data)}
                    excluded={data.exApp}
                    selected={data.app}
                    onSelect={(data) => handleChange('app', data)}
                    options={
                      games
                        ? games.map((el) => ({
                            value: el.bundle_id,
                            label: el.app_name + ' - ' + el.platform.join('/'),
                          }))
                        : []
                    }
                  />
                </div>
              )}
            </Form.Group>
            <Form.Group className="position-relative mb-2 d-flex align-items-center w-100" controlId="network">
              <Form.Label className="fs-7 me-3 mb-0" style={{ minWidth: 90 }}>
                Network
              </Form.Label>
              {isFetchingNetworks ? (
                <div className="d-flex justify-content-center">
                  <Loader parentStyle="loader" size={34} color={'#3F96C7'} />
                </div>
              ) : networksError ? (
                <div className="alert alert-danger px-2 py-1 m-0 fs-7 " role="alert">
                  {networksError?.data?.message?.message || networksError?.data?.message || 'Something went wrong.'}
                </div>
              ) : (
                <div style={{ width: 'calc(100% - 106px)' }}>
                  <SearchableDropdown
                    writeSelected
                    parClass={'w-100'}
                    multiple
                    excluded={data.exNetwork}
                    collapseGroup
                    onExclude={(data) => handleChange('exNetwork', data)}
                    onSelect={(data) => handleChange('network', data)}
                    selected={data.network}
                    options={networks ? networks.map((el) => ({ value: el.network, label: el.network })) : []}
                  />
                </div>
              )}
            </Form.Group>
            <Form.Group className="position-relative mb-2 d-flex align-items-center w-100" controlId="country">
              <Form.Label className="fs-7 me-3 mb-0" style={{ minWidth: 90 }}>
                Country
              </Form.Label>
              <div style={{ width: 'calc(100% - 106px)' }}>
                <SearchableDropdown
                  writeSelected
                  parClass={'w-100'}
                  multiple
                  excluded={data.exCountry}
                  onExclude={(data) => handleChange('exCountry', data)}
                  onSelect={(data) => handleChange('country', data)}
                  selected={data.country}
                  options={countries.map((el) => ({ label: el.name, value: el.code }))}
                />
              </div>
            </Form.Group>
            <Form.Group className="position-relative mb-2 d-flex align-items-center w-100" controlId="type">
              <Form.Label className="fs-7 me-3 mb-0" style={{ minWidth: 90 }}>
                Type
              </Form.Label>
              <Form.Select
                value={data.type}
                className="form-select"
                onChange={(e) => handleChange('type', e.target.value)}
              >
                <option defaultValue value={''}>
                  Basic
                </option>
                <option value={'cohort'}>Cohort</option>
              </Form.Select>
            </Form.Group>
            {!data.advanced && (
              <Form.Group className="position-relative mb-2 d-flex align-items-center w-100" controlId="dimension">
                <Form.Label className="fs-7 me-3 mb-0" style={{ minWidth: 90 }}>
                  Dimensions <span className="text-danger fs-7">*</span>
                </Form.Label>
                <div style={{ width: 'calc(100% - 106px)' }}>
                  <SearchableDropdown
                    writeSelected
                    parClass={'w-100'}
                    multiple
                    onSelect={(data) => handleChange('dimension', data)}
                    selected={data.dimension}
                    options={dimensions.map((el) => ({
                      label: capitalizeFirstLetter(el),
                      value: el,
                    }))}
                  />
                </div>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip {...props}>Selecting more than 2 dimension may exceed allowed report size.</Tooltip>
                  )}
                >
                  <span className="cursor-pointer ms-2">
                    <InfoIcon size={18} />
                  </span>
                </OverlayTrigger>
              </Form.Group>
            )}
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default FiltersBar;
