import BsPagination from 'react-bootstrap/Pagination';
import { useQuery } from '../utils/customHooks';
import { useHistory } from 'react-router-dom';
import ArrowDown from './Icons/ArrowDown';

const Pagination = ({
  total,
  page,
  perPage,
  onChange,
  pushHistory = true,
  scrollTop = true,
  scrollTopBtn = false,
  label = true,
  loadMore = false,
  size = 'sm',
}) => {
  const query = useQuery();
  const history = useHistory();
  const handleChange = (page) => {
    if (pushHistory && !isNaN(page)) {
      if (page === 1) query.delete('page');
      else query.set('page', page);
      history.push(history.location.pathname + '?' + query.toString());
    }
    if (scrollTop && !isNaN(page)) {
      window.scrollTo({ top: 0 });
    }
    if (onChange) onChange(page);
  };
  const count = Math.ceil(total / perPage);
  return (
    <>
      {count > 1 && (
        <BsPagination className="m-0 bg-white d-flex gap-1" size={size}>
          {label && (
            <BsPagination.Item disabled>
              <span className="text-nowrap">
                {(page - 1) * perPage + 1}-{page * perPage} of {total}
              </span>
            </BsPagination.Item>
          )}
          <BsPagination.Prev
            onClick={() => {
              handleChange(page - 1);
            }}
            disabled={page === 1}
          />
          {page > 3 && (
            <>
              <BsPagination.Item
                onClick={() => {
                  handleChange(1);
                }}
              >
                1
              </BsPagination.Item>
            </>
          )}
          {page > 4 && <BsPagination.Ellipsis disabled />}
          {page > 2 && (
            <BsPagination.Item
              onClick={() => {
                handleChange(page - 2);
              }}
            >
              {page - 2}
            </BsPagination.Item>
          )}
          {page > 1 && (
            <BsPagination.Item
              onClick={() => {
                handleChange(page - 1);
              }}
            >
              {page - 1}
            </BsPagination.Item>
          )}
          <BsPagination.Item active>{page}</BsPagination.Item>
          {count >= page + 1 && (
            <BsPagination.Item
              onClick={() => {
                handleChange(page + 1);
              }}
            >
              {page + 1}
            </BsPagination.Item>
          )}
          {count >= page + 2 && (
            <>
              <BsPagination.Item
                onClick={() => {
                  handleChange(page + 2);
                }}
              >
                {page + 2}
              </BsPagination.Item>
            </>
          )}
          {count > page + 3 && <BsPagination.Ellipsis disabled />}
          {count >= page + 3 && (
            <BsPagination.Item
              onClick={() => {
                handleChange(count);
              }}
            >
              {count}
            </BsPagination.Item>
          )}
          <BsPagination.Next
            onClick={() => {
              handleChange(page + 1);
            }}
            disabled={page === count}
          />
          {loadMore && (
            <BsPagination.Item
              active
              disabled={page === count}
              className="ms-2 cursor-pointer"
              onClick={() => {
                handleChange('more');
              }}
            >
              Load More
            </BsPagination.Item>
          )}
          {scrollTopBtn && (
            <BsPagination.Item
              active
              disabled={page === count}
              className="ms-2 cursor-pointer"
              onClick={() => window.scrollTo({ top: 0 })}
            >
              <div aria-label="Scroll to Top" className=" rounded d-flex cursor-pointer">
                <ArrowDown rotate="up" size={24} color="#002cff" />
              </div>
            </BsPagination.Item>
          )}
        </BsPagination>
      )}
    </>
  );
};

export default Pagination;
