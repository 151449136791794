import GoogleDriveModal from '../../Modules/GoogleDrive/GoogleDriveModal';

const GoogleDriveModalDemo = () => {
  return (
    <div>
      <GoogleDriveModal />
    </div>
  );
};

export default GoogleDriveModalDemo;
