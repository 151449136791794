import { useState } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

function PdfViewer({ pdf }) {
  const [loadedPdf, setLoadedPdf] = useState({});
  //const { data: file, isLoading: isLoadingGames, error } = useGetPdfQuery(pdf?.url, { skip: !pdf.url });

  function onDocumentLoadSuccess(e) {
    setLoadedPdf({ numPage: e?._pdfInfo?.numPages });
    window.scrollTo({ top: 0 });
  }

  return pdf ? (
    <div className="pdf-view-container">
      <Document
        file={pdf?.url || pdf || ''}
        onLoadSuccess={(e) => onDocumentLoadSuccess(e, pdf?.name)}
        externalLinkTarget="_blank"
      >
        {loadedPdf?.numPage &&
          [...Array(loadedPdf.numPage)].map((page, index) => {
            return <Page pageNumber={index + 1} key={index + 1} className="pdf-active" />;
          })}
      </Document>
    </div>
  ) : null;
}

export default PdfViewer;
