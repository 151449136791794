/** Import Externals */
import React, { useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Brush } from 'recharts';

/** Import Utils */
import { useSetState } from '../../../../../utils/customHooks';

function ChartSessions(props) {
  const [state, setState] = useSetState({
    loading: false,
    arrayToDisplay: [],
    width: (window.innerWidth * 70) / 100,
    height: 250,
    endIndex: '',
  });

  useEffect(() => {
    if (props.data) {
      let returnArray = [];
      props.data.map((e) => {
        let actualArray = [];
        let i = 2;
        while (i < e.length) {
          actualArray.push({ level: e[i], percent: e[i + 1] });
          i = i + 2;
        }
        //splice array to 30 values
        let res = actualArray;
        if (actualArray.length > 30) {
          res = actualArray.slice(0, 30);
        }
        return returnArray.push(res);
      });
      setState({
        arrayToDisplay: returnArray,
      });
    }
  }, [props]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 800) {
        setState({
          width: (window.innerWidth * 100) / 100,
          endIndex: 10,
        });
      }
      if (window.innerWidth > 765 && window.innerWidth < 1000) {
        setState({
          width: (window.innerWidth * 80) / 100,
          endIndex: 15,
        });
      }
    }

    window.addEventListener('resize', handleResize());
  }, []);

  const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return <text x={x + width / 2} y={y} fill="#666" fontSize={10} textAnchor="middle" dy={-6}>{`${value}`}</text>;
  };

  const renderLineChart = (data, index) => {
    if (!data.length) return null;
    return (
      <ResponsiveContainer key={'session' + index}>
        <div key={'session' + index} className="bg-white rounded my-3 p-2">
          <BarChart
            className="chart-level-chart"
            width={state.width}
            height={state.height}
            data={data.map((el) => ({ ...el, title: el.level }))}
            margin={{
              top: 30,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="level" />
            <YAxis domain={[0, 100]} />
            <Tooltip />
            <Bar dataKey="percent" fill="#3F96C7" label={renderCustomBarLabel} />
            <Brush dataKey="level" height={30} stroke="#8884d8" endIndex={state.endIndex} />
          </BarChart>
          <p className="chart-level-text">{props.cohorts[index]} - Number of players / sessions played</p>
        </div>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="chart-sessions-container">
      <p className="chart-level-title">Distribution of played sessions number per user</p>
      {state.arrayToDisplay.map((e, index) => {
        return renderLineChart(e, index);
      })}
    </div>
  );
}

export default ChartSessions;
