import CryptoJS from 'crypto-js';

export function getCookie(name) {
  var c = document.cookie || '';
  if (c.indexOf(';') > 0) c = c.split(';');
  else c = [c];
  if (c) {
    for (var i in c) {
      if (c[i].indexOf(name) > -1) {
        return c[i].split('=')[1].length ? decodeURIComponent(c[i].split('=')[1]) : null;
      }
    }
  }
  return null;
}

export function setCookie(name, value, ttl) {
  var d = new Date();
  if (ttl > 0) {
    d.setDate(d.getDate() + ttl);
    var expires = 'expires=' + d.toUTCString();
  } else {
    expires = 0;
  }
  document.cookie = name + '=' + value + ';' + expires; // + ';path=' + path;
  return true;
}

export function delCookie(name) {
  document.cookie = name + '= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
  return true;
}

export function hmac256(msg) {
  const HCode = process.env.REACT_APP_HMAC_CODE.split(',').map(function (item) {
    return parseInt(item, 10);
  });
  let message = msg;
  if (typeof message === 'object') {
    message = JSON.stringify(message);
  }
  var hash = CryptoJS.HmacSHA256(
    message,
    String.fromCharCode(
      HCode[0],
      HCode[1],
      HCode[2],
      HCode[3],
      HCode[4],
      HCode[5],
      HCode[6],
      HCode[7],
      HCode[8],
      HCode[9],
      HCode[10],
      HCode[11],
      HCode[12],
      HCode[13],
      HCode[14],
      HCode[15],
    ),
  );
  var hashInBase64 = CryptoJS.enc.Base64.stringify(hash).replace(/=/g, '');
  var cookie = getCookie('user-id') || '';
  var __API_TOKEN__ = cookie.indexOf('{') === 0 ? JSON.parse(cookie)['apiToken'] : 'null';
  return 'hmac ' + __API_TOKEN__ + ' ' + hashInBase64;
}

export const buildVersionCompare = (a, b) => {
  const aParts = a.split('.').map((part) => parseInt(part, 10));
  const bParts = b.split('.').map((part) => parseInt(part, 10));
  const maxLength = Math.max(aParts.length, bParts.length);

  for (let i = 0; i < maxLength; i++) {
    const aPart = aParts[i] || 0;
    const bPart = bParts[i] || 0;
    if (aPart > bPart) return -1;
    if (aPart < bPart) return 1;
  }
  return 0;
};
