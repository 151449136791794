import React from 'react';
import { useGetAbTestGamesQuery } from '../../../data/api/abTestsApi';
import GameSearchFilter from '../../../Modules/GameSearchFilter';
import AbTestGameListTable from './AbTestGameListTable';
import Loader from '../../../Components/Loader';
import Pagination from '../../../Components/Pagination';
import { useQuery, useSetState } from '../../../utils/customHooks';
import AbTestCreateBtns from '../CreateAbTestModal/AbTestCreateBtns';

function AbTestGameList() {
  const { error: errorGames, isLoading: isLoadingGames } = useGetAbTestGamesQuery();
  const [state, setState] = useSetState({
    gamesToDisplay: [],
    total: 0,
    postsPerPage: 15,
  });
  const query = useQuery();

  return (
    <div className="container-fluid">
      <div className="row top-bar bg-white align-items-center px-md-2">
        <div className="col-12 d-flex">
          <div className="d-flex gap-2 ms-auto">
            <AbTestCreateBtns />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="flex-lg-row flex-column d-flex">
            <GameSearchFilter />
            <div className="ms-auto mt-2">
              <Pagination total={state.total} perPage={state.postsPerPage} page={Number(query.get('page')) || 1} />
            </div>
          </div>
          <div className="col-12 mt-2">
            {errorGames ? (
              <div className="d-flex justify-content-center">Something went wrong! please try again.</div>
            ) : isLoadingGames ? (
              <div className="d-flex justify-content-center">
                <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
              </div>
            ) : (
              <div id="test-table">
                <AbTestGameListTable data={state} setData={setState} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AbTestGameList;
