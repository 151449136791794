import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import moment from 'moment';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import ResultsTable from './ResultsTable';
import ChartLevels from './Charts/ChartLevels';
import ChartSessions from './Charts/ChartSessions';
import {
  abTestsApi,
  useGetCohortsQuery,
  useGetGameAbTestMetricsOldQuery,
  useGetGameAbTestMetricsQuery,
} from '../../../../data/api/abTestsApi';
import { useQuery, useSetState } from '../../../../utils/customHooks';
import { gameAbTestByIdSelector } from '../../../../data/slices/abTests';
import Loader from '../../../../Components/Loader';
import Form from 'react-bootstrap/Form';
import { getGameById } from '../../../../data/slices/studio';

function MetricsContainer() {
  const { gameId, testId } = useParams();

  const query = useQuery();
  const history = useHistory();
  const countryIso = query.get('country') || 'US';
  const abTestOs = query.get('os') || 'android';
  const activeTab = query.get('group')?.toLowerCase() || 'overview';

  const [state, setState] = useSetState({
    abTestResults: {},
    abTestCSV: {},
  });
  let foundGame = useSelector(getGameById(gameId));
  const abTestSelected = useSelector((state) =>
    gameAbTestByIdSelector(
      abTestsApi.endpoints.getGameAbtests.select({
        bundleId: foundGame?.bundleId,
        store: foundGame?.store === 'amazon' ? 'amazon' : 'google,apple',
      })(state),
      testId,
    ),
  );

  const { data: cohorts, isFetching: isLoadingCohorts } = useGetCohortsQuery(abTestSelected?.id, {
    skip: !abTestSelected?.id,
  });
  let startTs = abTestSelected?.startTs ? abTestSelected.startTs.split('T')[0] : null;
  let isBefore = startTs ? moment(startTs).isBefore('2022-09-12') : false;
  let cohortId = cohorts?.[0].id || null;

  const {
    data: metricsData,
    isFetching: isLoading,
    error: metricsError,
  } = useGetGameAbTestMetricsQuery(
    {
      bundleId: foundGame?.bundleId,
      cohortId,
      startTs,
      os: abTestOs,
      countryIso,
    },
    { skip: !cohortId },
  );
  // const {
  //   data: oldMetricsData,
  //   isFetching: isLoadingOld,
  //   error: oldMetricsError,
  // } = useGetGameAbTestMetricsOldQuery(
  //   {
  //     cohortId,
  //     os: abTestOs,
  //     countryIso,
  //   },
  //   { skip: !cohortId || !isBefore }
  // );
  useEffect(() => {
    if (metricsError) {
      setState({
        abTestResults: {},
        abTestCSV: {},
      });
    }
  }, [metricsError]);

  useEffect(() => {
    let res = metricsData;
    setState({
      abTestResults: {},
      abTestCSV: {},
    });
    if (res && Object.keys(res).length) {
      // ADD CSV ON CONTEXT FOR DL
      let csvRes = { ...res };
      // Parse Ret to split for csv
      let retentionCsv = [];
      if (res.retention) {
        const rets = res.retention.split('\n\n');
        rets.map((value) => {
          return retentionCsv.push(value);
        });
        csvRes.retention = retentionCsv;
      }

      //PARSE CSV TO ARRAYS
      let cohorts = [];
      if (res.cohorts) {
        cohorts = res.cohorts.split('\n').join(',').split(',');
      }

      let retention = [];
      if (res.retention) {
        const rets = res.retention.split('\n\n');
        rets.map((value) => {
          let ret = value.split('\n').join(',').split(',');
          if (ret[0] === '' && ret.length > 1) {
            ret.shift();
            return retention.push(ret);
          } else {
            return retention.push(ret);
          }
        });
      }
      let retentionCsvObj = {};
      if (cohorts && retention) {
        for (const i in cohorts) {
          retentionCsvObj[cohorts[i]] = retentionCsv[i];
        }
      }

      csvRes.retention = retentionCsvObj;

      let ltv_pivot = [];
      if (res.ltv_pivot) {
        ltv_pivot = res.ltv_pivot.split('\n').join(',').split(',');
      }

      let ltv_iap_pivot = [];
      if (res.ltv_iap_pivot) {
        ltv_iap_pivot = res.ltv_iap_pivot.split('\n').join(',').split(',');
      }

      let nb_rewarded_by_user = [];
      if (res.nb_rewarded_by_user) {
        nb_rewarded_by_user = res.nb_rewarded_by_user.split('\n').join(',').split(',');
      }

      let nb_rewarded_by_type = [];
      if (res.nb_rewarded_by_type) {
        nb_rewarded_by_type = res.nb_rewarded_by_type.split('\n').join(',').split(',');
      }

      let progression = [];
      if (res.progression) {
        const progres = res.progression.split('\n\n');
        progres.map((value) => {
          let prog = value.split('\n').join(',').split(',');
          if (prog[0] === '') {
            prog.shift();
            return progression.push(prog);
          } else {
            return progression.push(prog);
          }
        });
      }

      const progression_sessions_distribution_by_level = [];
      if (res.progression_sessions_distribution_by_level) {
        const prog_sess = res.progression_sessions_distribution_by_level.split('\n\n');
        prog_sess.map((value) => {
          let prog_s = value.split('\n').join(',').split(',');
          if (prog_s[0] === '') {
            prog_s.shift();
            return progression_sessions_distribution_by_level.push(prog_s);
          } else {
            return progression_sessions_distribution_by_level.push(prog_s);
          }
        });
      }

      let nb_inter_by_user = [];
      if (res.nb_inter_by_user) {
        nb_inter_by_user = res.nb_inter_by_user.split('\n').join(',').split(',');
      }
      let sessions = [];
      if (res.sessions) {
        sessions = res.sessions.split('\n').join(',').split(',');
      }
      setState({
        abTestCSV: csvRes,
        abTestResults: {
          cohorts,
          cohortedRevenue: ltv_pivot,
          ltv_iap_pivot: ltv_iap_pivot,
          nb_rewarded_by_user: nb_rewarded_by_user,
          nb_rewarded_by_type: nb_rewarded_by_type,
          progression: progression,
          retention,
          progression_sessions_distribution_by_level: progression_sessions_distribution_by_level,
          nb_inter_by_user: nb_inter_by_user,
          sessions: sessions,
        },
      });
    }
  }, [metricsData, countryIso, abTestOs]);

  const { abTestResults, abTestCSV } = state;
  const [data, setData] = useState({});

  const formatData = (data) => {
    if (data?.length) {
      let firstRow = [];
      // check all NaN to know the max column count
      let i = 0;
      while (isNaN(data[i]) && data.length > i) {
        i++;
      }
      if (i === data.length) return null;
      let nbCohort = i - 1; // number of columns
      let returnArray = [];
      let rowArray = [];
      // group cohorts in nested array
      data.forEach((value, index) => {
        rowArray.push(value);
        if (rowArray.length === nbCohort && data[0] !== 'Summary') {
          if (index === nbCohort - 1) {
            firstRow = [...rowArray];
          } else {
            returnArray.push([...rowArray]);
          }
          rowArray = [];
        }
      });

      // set Basecohort first
      if (
        returnArray.find((e) => e[0].toLowerCase() === 'basecohort') &&
        returnArray[0][0].toLowerCase() !== 'basecohort'
      ) {
        let tmp = returnArray[0];
        let baseCohort = returnArray.find((e) => e[0].toLowerCase() === 'basecohort');
        let index = returnArray.indexOf(baseCohort);
        returnArray[0] = returnArray[index];
        returnArray[index] = tmp;
      }

      return {
        data: returnArray,
        header: firstRow,
      };
    }
    return null;
  };

  useEffect(() => {
    // format data here!
    if (Object.keys(abTestResults).length) {
      const data = {};
      data.sessions = formatData(abTestResults?.sessions);

      data.cohortedRevenue = formatData(abTestResults?.cohortedRevenue);
      data.ltv_iap_pivot = formatData(abTestResults?.ltv_iap_pivot);
      const formattedLtv = {};
      formattedLtv['Ad Revenue'] = data?.cohortedRevenue?.data;
      formattedLtv['IAP Revenue'] = data?.ltv_iap_pivot?.data;
      data.retentions = [];
      const formattedRetention = {};
      abTestResults?.retention.forEach((value, index) => {
        if (!value) return null;
        if (value[0] !== '' && abTestResults?.cohorts[index] !== undefined) {
          let tmp = formatData(value);
          if (tmp?.data) {
            data.retentions.push(tmp);
            formattedRetention[abTestResults?.cohorts[index]] = tmp.data;
          }
        }
      });

      data.overview = overviewData(formattedLtv, formattedRetention);
      data.nb_inter_by_user = formatData(abTestResults?.nb_inter_by_user);
      data.nb_rewarded_by_user = formatData(abTestResults?.nb_rewarded_by_user);
      data.nb_rewarded_by_type = formatData(abTestResults?.nb_rewarded_by_type);
      setData(data);
    } else if (data) {
      setData({}); // clear if results comes empty
    }
  }, [abTestResults]);
  const overviewData = (formattedLtv, formattedRetentions) => {
    if (Object.keys(formattedLtv).length && Object.keys(formattedRetentions).length) {
      let formattedRetention = { ...formattedRetentions };
      const firstRow = ['cohort', 'LTV Ads & IAP D1', 'Retention D1', 'Install'];
      let ltv_pivot = {};
      formattedLtv['Ad Revenue']?.forEach((el) => {
        ltv_pivot[el[0]] = el[2];
      });
      let ltv_iap_pivot = {};
      formattedLtv['IAP Revenue']?.forEach((el) => {
        ltv_iap_pivot[el[0]] = el[2];
      });
      if (formattedRetention.hasOwnProperty('BaseCohort')) {
        let tmp = formattedRetention.BaseCohort;
        delete formattedRetention.BaseCohort;
        formattedRetention = { BaseCohort: tmp, ...formattedRetention };
      }
      let result = [];
      for (const key in formattedRetention) {
        let arr = formattedRetention[key];
        if (!arr.length) {
          result.push([key, Number(ltv_pivot[key]) + Number(ltv_iap_pivot[key]), null, null]);
          continue;
        }
        if (arr[arr.length - 1][0] === 'Summary') {
          /// cohord name, ltv+ltv_iap, ret D1, installs
          result.push([
            key,
            Number(ltv_pivot[key]) + Number(ltv_iap_pivot[key] || 0),
            arr[arr.length - 1][2],
            arr[arr.length - 1][1],
          ]);
        }
      }
      return { header: firstRow, data: result };
    }
    return null;
  };

  const handleChange = (name, value) => {
    if (!value) query.delete(name);
    else query.set(name, value);
    history.push(history.location.pathname + '?' + query.toString());
  };
  const countryIsos = ['US', 'WW', 'ROW', 'EUROPE'];
  return isLoading || isLoadingCohorts ? (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
      <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
    </div>
  ) : (
    <>
      <div className="d-flex align-items-center mb-3 d-print-none">
        <div className="data-filter-container  w-100">
          <Navbar className="p-0" bg="bg-transparent" expand="sm">
            <Navbar.Brand className="d-sm-none p-0" href="#home">
              Filters
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="align-items-center">
                {!isBefore && (
                  <Nav.Item>
                    <Form.Group className="input-group select-drop">
                      <Form.Select
                        className="form-select bg-white"
                        value={countryIso}
                        onChange={(e) => handleChange('country', e.target.value)}
                      >
                        <option defaultValue value={''}>
                          Country
                        </option>
                        {countryIsos.map((el) => (
                          <option key={el} value={el}>
                            {el}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Nav.Item>
                )}
                <Nav.Item>
                  <Form.Group className="input-group select-drop">
                    <Form.Select
                      className="form-select bg-white"
                      value={abTestOs}
                      onChange={(e) => handleChange('os', e.target.value)}
                    >
                      <option defaultValue value={''}>
                        Platform
                      </option>
                      <option value="android">Android</option>
                      <option value="ios">Ios</option>
                    </Form.Select>
                  </Form.Group>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
      {!Object.keys(abTestResults).length ? (
        <div className="alert alert-warning d-flex align-items-center">
          <div className=" me-3">
            <InfoIcon />
          </div>
          <div>
            <p className="mb-1">No Data available at the moment.</p>
            <span className="fs-7">
              Report will befilled a few days after the start of the test. If no data appears after +2 days, check the
              test settings.
            </span>
          </div>
        </div>
      ) : (
        <div>
          <div className={`${activeTab !== 'overview' && 'd-none'} d-print-block`}>
            {data.overview && (
              <ResultsTable
                tableVariant="compareHorizontal"
                data={data.overview}
                title={'Overview'}
                csvName={'Overview'}
              />
            )}

            {data.sessions && (
              <ResultsTable
                data={data.sessions}
                title="Session Time Statistics"
                csv={abTestCSV.sessions}
                tableVariant="compareHorizontal"
                csvName={'SessionTime'}
              />
            )}

            {data.cohortedRevenue && (
              <ResultsTable
                data={data.cohortedRevenue}
                title={'Ad Revenue'}
                tableVariant="compareHorizontal"
                csv={abTestCSV.ltv_pivot}
                csvName={'CohortedRevenue'}
              />
            )}
            {data.ltv_iap_pivot && (
              <ResultsTable
                data={data.ltv_iap_pivot}
                title={'IAP Revenue'}
                tableVariant="compareHorizontal"
                csv={abTestCSV.ltv_iap_pivot}
                csvName={'LtvIapPivot'}
              />
            )}

            {data.nb_inter_by_user && (
              <ResultsTable
                data={data.nb_inter_by_user}
                csv={abTestCSV.nb_inter_by_user}
                tableVariant="compareVertical"
                csvName={'Interstitials/User'}
                title={'Average number of Interstitial ads watched per user'}
              />
            )}

            {data.nb_rewarded_by_user && (
              <ResultsTable
                data={data.nb_rewarded_by_user}
                csv={abTestCSV.nb_rewarded_by_user}
                tableVariant="compareVertical"
                csvName={'Rewarded/Users'}
                title={'Average number of Rewarded ads watched per user'}
              />
            )}

            {data.nb_rewarded_by_type && (
              <>
                <ResultsTable
                  data={data.nb_rewarded_by_type}
                  csv={abTestCSV.nb_rewarded_by_type}
                  tableVariant="compareVertical"
                  csvName={'Percent/Rewarded'}
                  title={'Percentage of players who have seen this Rewarded'}
                />
              </>
            )}
          </div>

          <div className={`${activeTab !== 'retention' && 'd-none'} d-print-block`}>
            <h6>Retentions</h6>
            {data.retentions &&
              data.retentions.map((value, index) => {
                let cohort = abTestResults?.cohorts?.[index];
                if (!value || value?.[0] === '' || cohort === undefined) return null;

                return (
                  <div key={index}>
                    <ResultsTable
                      key={'retTable' + index}
                      data={value}
                      title={cohort}
                      csv={abTestCSV.retention[cohort]}
                      csvName={cohort}
                    />
                  </div>
                );
              })}
          </div>

          <div className={`${activeTab !== 'churn rate' && 'd-none'} d-print-block`}>
            {!!abTestResults?.progression?.length && (
              <ChartLevels data={abTestResults?.progression} cohorts={abTestResults?.cohorts} />
            )}

            {!!abTestResults?.progression_sessions_distribution_by_level?.length && (
              <ChartSessions
                data={abTestResults?.progression_sessions_distribution_by_level}
                cohorts={abTestResults?.cohorts}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default MetricsContainer;
