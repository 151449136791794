import { Button, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { useChangeABTestGroupMutation } from '../../../../data/api/abTestsApi';
import Loader from '../../../../Components/Loader';
import { useState } from 'react';
import { useUserPermission } from '../../../../Components/permissions/UserPermissions';
import { useSelector } from 'react-redux';
import { getGameByBundleId } from '../../../../data/slices/abTests';

const AbTestStatusDropdown = ({ data, type, placement = 'auto-start' }) => {
  const foundGame = useSelector((state) => getGameByBundleId(state, data.bundleId));
  const [show, setShow] = useState(false);
  const statusUpdateCheckPermission = useUserPermission('abTest.status.update.withDeviceId');
  const statusUpdatePermission = useUserPermission('abTest.status.update');

  const editable =
    foundGame?.status !== 'ARCHIVED' && (statusUpdatePermission || (statusUpdateCheckPermission && !!data?.deviceId));

  const options = {
    APPROVED: { title: 'Approve', color: 'success' },
    PENDING: { title: 'Pending', color: 'warning' },
    CANCELED: { title: 'Cancel', color: 'dark-300' },
  };
  let value = data?.status;
  const [changeGroupStatus, { isLoading: isStatusUpdating }] = useChangeABTestGroupMutation();
  const handleChangeStatus = (newValue) => {
    if (newValue === value) {
      return;
    }
    changeGroupStatus({
      studioId: foundGame?.studioId,
      name: data.name, // name is needed for email sending
      type: type || 'ABTEST', // type is needed for email sending
      bundleId: data.bundleId, // name is needed for email sending
      id: data.id,
      status: newValue,
    });
  };

  const popover = (
    <Popover>
      <ListGroup variant="flush" className="rounded ">
        {Object.keys(options).map((key) => (
          <ListGroup.Item
            key={key}
            action
            onClick={() => {
              handleChangeStatus(key);
              setShow(false);
            }}
            className="d-flex align-items-center"
          >
            <span className={`p-1 me-2 bg-${options[key].color} rounded-circle`} /> {options[key].title}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Popover>
  );

  return (
    <OverlayTrigger
      rootClose={true}
      show={show}
      onToggle={(isClosed) => {
        if (!isClosed) setShow(false);
      }}
      rootCloseEvent="mousedown"
      trigger={editable ? 'click' : 'none'}
      placement={placement}
      overlay={popover}
    >
      <div>
        {isStatusUpdating ? (
          <div className="d-flex justify-content-center">
            <Loader parentStyle="loader" size={38} color={'#3F96C7'} />
          </div>
        ) : (
          <Button
            onClick={() => {
              setShow(!show);
            }}
            variant="outline-light"
            disabled={!editable}
            className={`p-2 opacity-100 w-100 text-nowrap rounded-pill ${editable ? 'cursor-pointer border-' + options[value].color : 'border-0 outline-none cursor-default'} `}
          >
            <div className="d-flex align-items-center justify-content-center">
              <span className={` p-2 bg-${options[value].color}  rounded-circle`} />
              <span className={`fs-7 ms-2 text-${options[value].color}`}> {options[value].title}</span>
            </div>
          </Button>
        )}
      </div>
    </OverlayTrigger>
  );
};

export default AbTestStatusDropdown;
