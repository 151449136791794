import Form from 'react-bootstrap/Form';
import { GAME_PLATFORMS } from '../../constants/game';
import { InputGroup, Nav, Navbar } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CalendarIcon from '../../Components/Icons/CalendarIcon';
import moment from 'moment';
import { useQuery } from '../../utils/customHooks';
import { useHistory } from 'react-router-dom';
import { useGetAvailableDatesQuery } from '../../data/api/appMagikApi';
import React, { useEffect } from 'react';
import Loader from '../../Components/Loader';
import SearchIcon from '../../Components/Icons/SearchIcon';

export default function TopAppsFilterBar({ onChange }) {
  const { data: dates, isFetching, error } = useGetAvailableDatesQuery();
  const query = useQuery();
  const history = useHistory();
  let date = query.get('date');
  let os = query.get('os');
  let search = query.get('search');

  useEffect(() => {
    if (dates && !query.has('date')) {
      if (!query.has('os')) query.set('os', 'ios');
      query.set('date', moment(dates[0]).format('YYYY-MM-DD'));
      history.replace(history.location.pathname + '?' + query.toString());
    }
  }, [dates, query.has('date')]);

  const handleChange = (name, value) => {
    if (onChange) onChange(name, value);
    if (query.has('page')) query.delete('page');
    if (value) query.set(name, value);
    else query.delete(name);
    if (name === 'search' && query.has('search')) history.replace(history.location.pathname + '?' + query.toString());
    else history.push(history.location.pathname + '?' + query.toString());
  };
  return (
    <div className="data-filter-container w-100 mt-2">
      <Navbar className="p-0" bg="bg-transparent" expand="lg">
        <div className="d-flex w-100 justify-content-between d-lg-none">
          <Navbar.Brand className="d-lg-none p-0" href="#home">
            Filters
          </Navbar.Brand>
          <Navbar.Toggle size="sm" aria-controls="basic-navbar-nav" />
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="align-items-center">
            <Nav.Item className="dropdown">
              <Form.Group>
                <InputGroup>
                  <Form.Control
                    className="bg-white"
                    type="text"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => handleChange('search', e.target.value)}
                    autoComplete={'off'}
                  />
                  <InputGroup.Text className="bg-white">
                    <SearchIcon size={20} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Nav.Item>
            <Nav.Item className="dropdown">
              <Form.Group className="input-group select-drop">
                <Form.Select
                  className="form-select bg-white"
                  value={os}
                  onChange={(e) => handleChange('os', e.target.value)}
                >
                  {GAME_PLATFORMS.map((value, index) => (
                    <option value={value.toLowerCase()} key={index}>
                      {value}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Nav.Item>
            <Nav.Item className="dropdown">
              {isFetching ? (
                <div className="d-flex justify-content-center">
                  <Loader parentStyle="loader" size={32} color={'#3F96C7'} />
                </div>
              ) : error ? (
                <div className="alert alert-danger p-2 mb-2 fs-7 " role="alert">
                  {error?.data?.message?.message || error?.data?.message || 'Something went wrong.'}
                </div>
              ) : (
                <InputGroup className="bg-white rounded d-flex flex-nowrap">
                  <DatePicker
                    id="datepicker"
                    className="form-control border-0 cursor-pointer bg-white"
                    selected={new Date(date)}
                    onChange={(date) => handleChange('date', moment(date).format('YYYY-MM-DD'))}
                    includeDates={(dates || []).map((el) => new Date(moment(el).format('YYYY-MM-DD')))}
                    placeholderText="This only includes dates from 30 days ago"
                    dateFormat="MMM d, yyyy"
                  />
                  <InputGroup.Text className=" bg-white border-0">
                    <CalendarIcon size={14} />
                  </InputGroup.Text>
                </InputGroup>
              )}
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
