import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import AddIcon from '../../../Components/Icons/AddIcon';
import AccountForm from './AccountForm';
import Loader from '../../../Components/Loader';
import { useCreateUserMutation } from '../../../data/api/userApi';
import { useSetState } from '../../../utils/customHooks';
import { formatJoiErrorsArray } from '../../../utils/validation';
import { confirmAlert } from '../../../Components/ConfirmModal';
import Form from 'react-bootstrap/Form';
import createAccountSchema from './_validationSchemas/createAccountSchema';

function CreateAccountModal() {
  const [createUser, { isLoading }] = useCreateUserMutation();
  const [errors, setErrors] = useState({});
  const [showForm, setShowForm] = useState(false);

  const [state, setState] = useSetState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    role: 'USER',
    studioIds: [],
    status: 'status',
  });

  const handleTextChange = (text, field) => {
    setState({ [field]: text });
    if (errors && errors[field]) {
      setErrors({ ...errors, [field]: '' });
    }
  };

  const handleCreateAccount = () => {
    const formatCreateAccountValidation = (details) => {
      let err = details.map((error) => {
        return {
          path: error.path,
          message: error.message,
        };
      });
      return formatJoiErrorsArray(err);
    };

    const { error } = createAccountSchema.validate(state);
    if (error) {
      return setErrors(formatCreateAccountValidation(error.details));
    }

    createUser({
      email: state.email,
      password: state.password,
      first_name: state.firstName,
      last_name: state.lastName,
      role: state.role,
      studio_ids: state.studioIds,
      status: state.status,
    })
      .unwrap()
      .then(() => {
        confirmAlert({
          title: 'Account has been successfully Created',
          variant: 'success',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
        handleClose();
      })
      .catch((err) => {
        if (err?.data?.details) {
          return setErrors(formatCreateAccountValidation(err.data.details));
        } else {
          setErrors({ message: err?.data?.message });
        }
      });
  };

  const handleClose = () => {
    setState({
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      role: 'USER',
      studioIds: [],
      status: 'status',
    });
    setErrors({});
    setShowForm(false);
  };

  return (
    <>
      <Button
        type="button"
        variant="success"
        className="d-flex"
        onClick={() => setShowForm(true)}
        id="create-account-modal-btn"
      >
        <AddIcon size={20} className="me-2" /> Account
      </Button>
      {showForm && (
        <Form onSubmit={handleCreateAccount}>
          <Modal centered dialogClassName="modal-md" show={showForm} onHide={handleClose} id="account-modal">
            <Modal.Header closeButton>
              <Modal.Title>Create User Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isLoading ? (
                <div className="d-flex justify-content-center w-100">
                  <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
                </div>
              ) : (
                <AccountForm state={state} setState={setState} errors={errors} handleTextChange={handleTextChange} />
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleCreateAccount} id="create-btn">
                Create User
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </>
  );
}

export default CreateAccountModal;
