import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useSendTextMessageMutation } from '../data/api/sendEmailApi';
import { useSetState } from '../utils/customHooks';
import { confirmAlert } from '../Components/ConfirmModal';
import Loader from '../Components/Loader';
import { useSelector } from 'react-redux';
import { userSelector } from '../data/slices/user';

const EmailSendModal = ({ sendText = 'Send email to Studio', to }) => {
  const user = useSelector(userSelector);
  const [sendTextMessage, { isLoading, error }] = useSendTextMessageMutation();
  const [show, setShow] = useState(false);
  const [state, setState] = useSetState({
    subject: '',
    textarea: '',
  });

  const handleTextChange = (text, field) => {
    setState({ [field]: text, error: '' });
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
    setState({ subject: '', textarea: '' });
  };

  const handleSend = (e) => {
    e.preventDefault();
    sendTextMessage({
      subject: state.subject,
      to,
      from: user?.email,
      html: state.textarea,
    })
      .unwrap()
      .then((e) => {
        confirmAlert({
          title: 'Your email has been sent.',
          variant: 'success',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch((err) => {});
        handleClose();
      })
      .catch((err) => {});
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {sendText}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSend}>
          <Modal.Header closeButton>
            <Modal.Title>Send Mail</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error && !isLoading && (
              <p className="text-danger text-center">
                {error?.error || error?.data?.message || 'Something went wrong'}
              </p>
            )}
            {isLoading ? (
              <div className="d-flex align-items-center justify-content-center w-100" style={{ height: 261 }}>
                <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
              </div>
            ) : (
              <>
                <Form.Group className="mb-3" controlId="EmailFrom">
                  <Form.Control type="email" value={'from: ' + user?.email} disabled />
                </Form.Group>
                <Form.Group className="mb-3" controlId="Email">
                  <Form.Control type="email" value={'to: ' + to} disabled />
                </Form.Group>
                <Form.Group className="mb-3" controlId="text">
                  <Form.Control
                    type="text"
                    placeholder="Subject"
                    value={state.subject}
                    onChange={(e) => handleTextChange(e.target.value, 'subject')}
                  />
                </Form.Group>
                <Form.Group controlId="textarea">
                  <Form.Control
                    as="textarea"
                    rows={7}
                    value={state.textarea}
                    onChange={(e) => handleTextChange(e.target.value, 'textarea')}
                  />
                </Form.Group>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              disabled={isLoading || state.subject === '' || state.textarea === ''}
              type="submit"
              variant="primary"
            >
              send
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EmailSendModal;
