import React, { useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import { useSetState } from '../../../utils/customHooks';
import { useChangeABTestGroupMutation } from '../../../data/api/abTestsApi';
import CohortDateSelect from '../CreateAbTestModal/AbTest/cohortDateSelect';
import CohortEndDate from './CohortEndDate';
import Loader from '../../../Components/Loader';
import { useSelector } from 'react-redux';
import { getGameByBundleId } from '../../../data/slices/abTests';
function UpdateDateModal({ show, onClose, abTest }) {
  /** State */
  const foundGame = useSelector((state) => getGameByBundleId(state, abTest.bundleId));
  const [state, setState] = useSetState({
    studioId: '',
    startDate: moment.utc(abTest?.startTs),
    endDate: moment.utc(abTest?.endTs),
  });
  const isAfterNow = useMemo(() => {
    return moment.utc().isAfter(state.startDate);
  }, [state.startDate]);
  const handleDateChange = (startDate, endDate) => {
    setState({
      startDate: moment.utc(startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      endDate: moment.utc(endDate).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }),
    });
  };
  /** CHANGE CONFIG FUNCTIONS */
  const [changeGroupStatus, { isLoading }] = useChangeABTestGroupMutation();
  const handleUpdate = () => {
    let startTs = state.startDate.format('YYYY-MM-DD HH:mm:ss');
    let endTs = state.endDate.format('YYYY-MM-DD HH:mm:ss');

    const postInfos = {
      studioId: foundGame?.studioId,
      endTs: endTs,
      id: abTest.id,
      name: abTest.name, // name is needed for email sending
      type: 'ABTEST', // type is needed for email sending
      bundleId: abTest.bundleId, // name is needed for email sending
      deviceId: abTest.deviceId,
    };
    if (!isAfterNow) postInfos.startTs = startTs;

    changeGroupStatus(postInfos)
      .unwrap()
      .then(() => {
        onClose();
      })
      .catch((error) => {
        setState({ error: error?.data?.message || 'something went wrong' });
      });
  };
  const isDisabled =
    abTest.type === 'CONFIG' ? false : abTest?.status === 'CANCELED' || moment().utc(false).isAfter(abTest?.endTs); // finished test

  return isDisabled ? null : (
    <Modal centered ClassName="modal-lg" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Date</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
            <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
          </div>
        ) : (
          <div className="row mb-4 g-3">
            {isAfterNow ? (
              <CohortEndDate
                startDate={state.startDate.format('lll')}
                endDate={state.endDate.format('lll')}
                onDateChange={handleDateChange}
              />
            ) : (
              <CohortDateSelect
                errors={{}}
                startDate={state.startDate.format('lll')}
                endDate={state.endDate.format('lll')}
                onDateChange={handleDateChange}
              />
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Abort
        </Button>
        <Button variant="primary" onClick={handleUpdate}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdateDateModal;
