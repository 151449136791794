import React from 'react';

function StudioIcon({ size, color, strokeWidth, ...rest }) {
  return (
    <svg {...rest} xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={color} viewBox="0 0 256 256">
      <circle
        cx="128"
        cy="140"
        fill="none"
        r="40"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M196,116a59.8,59.8,0,0,1,48,24"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M12,140a59.8,59.8,0,0,1,48-24"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M70.4,216a64.1,64.1,0,0,1,115.2,0"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M60,116A32,32,0,1,1,91.4,78"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M164.6,78A32,32,0,1,1,196,116"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}

StudioIcon.defaultProps = {
  size: 24,
  color: '#4D6F9D',
  strokeWidth: 20,
};

export default StudioIcon;
