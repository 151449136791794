import * as Joi from 'joi';
import cohortParamsSchema from './cohortParamsSchema';

export const configSchema = Joi.object({
  bundleId: Joi.string().trim().required().messages({ 'string.empty': 'Bundle ID is required' }),
  os: Joi.valid('all', 'ios', 'android').required(),
  store: Joi.array().items(Joi.string().trim()),
  build: Joi.string().trim().allow(''),
  device: Joi.string().trim().allow(''),
  params: cohortParamsSchema,
  name: Joi.string()
    .trim()
    .required()
    .messages({ 'string.empty': 'Name is required', 'string.required': 'Name is required' }),
}).options({ abortEarly: false, allowUnknown: true });

export default configSchema;
