import { Link, Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { R_AI_CHAT, R_AI_GAME_PITCH, R_AI_IMAGE_GENERATOR, R_AI_IMAGE_VARIATIONS } from '../../constants/routes';
import GuardedRoute from '../../Modules/GuardedRoute';
import EmptyLayout from '../../Layouts/EmptyLayout';
import ChatGpt from './Chat/ChatGptStream';
import GamePitch from './Chat/GamePitch';
import ImageGenerator from './ImageGenerator';
import ImageVariations from './ImageVariations';

const AiTools = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row top-bar shadow-sm position-relative border-bottom px-md-2">
          <div className="col-12 d-flex align-items-center">
            <div className="top-bar-tab-btns">
              <Link className={`btn ${useRouteMatch(R_AI_CHAT)?.isExact ? 'active' : ''}`} to={R_AI_CHAT}>
                Chat
              </Link>
              <Link
                className={`btn ${useRouteMatch(R_AI_IMAGE_GENERATOR)?.isExact ? 'active' : ''}`}
                to={R_AI_IMAGE_GENERATOR}
              >
                Image Generation
              </Link>
              <Link
                className={`btn ${useRouteMatch(R_AI_IMAGE_VARIATIONS)?.isExact ? 'active' : ''}`}
                to={R_AI_IMAGE_VARIATIONS}
              >
                Image Variations
              </Link>
              <Link className={`btn ${useRouteMatch(R_AI_GAME_PITCH)?.isExact ? 'active' : ''}`} to={R_AI_GAME_PITCH}>
                Game Pitch
              </Link>
            </div>
            <div className="ms-auto me-2"></div>
          </div>
        </div>
      </div>
      <Switch>
        <GuardedRoute exact layout={EmptyLayout} title="OpenAi Chat" path={R_AI_CHAT} component={ChatGpt} />
        <GuardedRoute
          layout={EmptyLayout}
          title="Image Generator"
          path={R_AI_IMAGE_GENERATOR}
          component={ImageGenerator}
        />
        <GuardedRoute
          layout={EmptyLayout}
          title="Image Variations"
          path={R_AI_IMAGE_VARIATIONS}
          component={ImageVariations}
        />
        <GuardedRoute layout={EmptyLayout} title="Game Pitch" path={R_AI_GAME_PITCH} component={GamePitch} />
        <Redirect to={R_AI_CHAT} />
      </Switch>
    </>
  );
};

export default AiTools;
