import React from 'react';
import GameConfigs from '../../Pages/Game/GameConfigs/GameConfigs';

function GameConfigsDemo() {
  return (
    <>
      <h1>game Configs</h1>
      <GameConfigs studioId={'test'} bundleId={'bundle'} />
    </>
  );
}

export default GameConfigsDemo;
