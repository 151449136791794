import { createSelector } from '@reduxjs/toolkit';
import { studioApi } from '../api/studioApi';

const emptyResponse = undefined;
const gamesSelector = studioApi.endpoints.getGames.select(undefined);
const studioSelector = studioApi.endpoints.listStudios.select(undefined);
export const getGameById = (id) => (state) =>
  createSelector([gamesSelector, (_, id) => id], (data, id) => {
    if (data?.data) {
      let ind = data.data.findIndex((elem) => Number(elem.id) === Number(id) || Number(elem?.ios?.id) === Number(id));
      return data.data[ind];
    }
    return emptyResponse;
  })(state, id);

export const getGamesByBundleId = (bundleId) => (state) =>
  createSelector([gamesSelector, (_, bundleId) => bundleId], (data, bundleId) => {
    if (data?.data) {
      return data.data.filter((elem) => elem.bundleId === bundleId);
    }
    return emptyResponse;
  })(state, bundleId);

export const getStudioByStudioId = (studioId) => (state) =>
  createSelector(
    [(state) => studioSelector(state), (_, studioId) => studioId],
    (items, studioId) => items?.data?.find((el) => el.studioId === studioId) || emptyResponse,
  )(state, studioId);
