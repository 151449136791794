import { useEffect, useState } from 'react';
import { useGetImageMutation } from '../data/api/studioApi';

const ImageUrlApiProxy = ({ url, width, height, ...rest }) => {
  const [getImg] = useGetImageMutation();
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    getImg(url)
      .unwrap()
      .then((url) => {
        setImgSrc(url);
      });
  }, []);

  return (
    <div style={{ height, width }} className="overflow-hidden d-flex justify-content-center align-items-center">
      {imgSrc !== null ? (
        <img {...rest} alt="thumbnail" style={{ maxWidth: '100%', maxHeight: '220%' }} src={imgSrc} />
      ) : null}
    </div>
  );
};
ImageUrlApiProxy.defaultProps = {
  width: '100%',
  height: 90,
};
export default ImageUrlApiProxy;
