import { Form, Row, Col, Tooltip, OverlayTrigger, Badge, useAccordionButton, Accordion, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAvailableVersions,
  selectErrors,
  updateModule,
  selectSelectedTemplate,
} from '../../../data/slices/gameConfigSlice';
import InfoIcon from '../../../Components/Icons/InfoIcon';
import { buildVersionCompare } from '../../../utils/common';
import { useState } from 'react';

const colors = [
  '#ccd8ff',
  '#99b0ff',
  '#6685ff',
  '#3f64ff',
  '#0829c4',
  '#0021db',
  '#001193',
  '#202655',
  '#161a47',
  '#0e1139',
];

const ModuleItem = ({ module, path, isSubModule = 0 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const selectedTemplate = useSelector(selectSelectedTemplate);
  const availableVersionsData = useSelector(selectAvailableVersions);
  const errors = useSelector(selectErrors);

  const { id, name, module_version, submodules = [], is_active, is_mandatory, parameters = [] } = module;
  const availableVersions = availableVersionsData[id] || {};
  const currentVersionDetail = availableVersions[module_version];
  const isUpdateAvailable = Object.keys(availableVersions).some(
    (versionDetail) => buildVersionCompare(module_version, versionDetail) > 0,
  );

  const handleChange = (key, value) => {
    dispatch(updateModule({ path, updates: { [key]: value, hasChanged: true } }));
  };
  const handleParameterChange = (index, newValue) => {
    const updatedParameters = parameters.map((p, i) => (i === index ? { ...p, value: newValue, hasChanged: true } : p));
    dispatch(updateModule({ path, updates: { parameters: updatedParameters } }));
  };

  const renderTooltip = (message, color) => (
    <OverlayTrigger placement="top" overlay={<Tooltip>{message}</Tooltip>}>
      <span className={'ms-2 cursor-pointer ' + color}>
        <InfoIcon />
      </span>
    </OverlayTrigger>
  );

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey);
    return (
      <button
        type="button"
        style={{ backgroundColor: colors[isSubModule], border: 'none' }}
        onClick={() => {
          setIsOpen((prev) => !prev);
          decoratedOnClick();
        }}
      >
        {children}
      </button>
    );
  };

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header
          className="d-flex justify-content-between align-items-center p-0 px-2 overflow-hidden"
          style={{ backgroundColor: colors[isSubModule] }}
        >
          <CustomToggle eventKey="0">
            {!currentVersionDetail?.dependencies?.length && !parameters.length && !submodules.length ? (
              ''
            ) : (
              <span style={{ color: '#2d3363', fontSize: '25px' }}>{isOpen ? '+' : '-'}</span>
            )}
          </CustomToggle>
          <div className="d-flex justify-content-between align-items-center position-relative w-100 h-100">
            <div className="d-flex align-items-center">
              <strong className="text-dark">{name}</strong>
              <small className="fs-8 text-secondary ms-1">{id}</small>
            </div>
            <div className="d-flex align-items-center gap-1 my-1">
              {module_version && is_active && (
                <small className="px-1 d-flex align-items-center">
                  {currentVersionDetail?.isBeta && <span className="ms-2 badge bg-warning text-dark">Beta</span>}
                  {isUpdateAvailable && renderTooltip('A newer version is available.', 'text-secondary')}
                  {currentVersionDetail?.isDeprecated &&
                    renderTooltip('Current version is deprecated, please update.', 'text-danger')}
                  {currentVersionDetail?.hasIssue &&
                    renderTooltip('We detected issues with this version.', 'text-danger')}
                </small>
              )}
              {(selectedTemplate === 'custom' ||
                currentVersionDetail?.isDeprecated ||
                currentVersionDetail?.hasIssue) &&
              Object.keys(availableVersions).length > 1 ? (
                <Form.Group className="d-flex">
                  <Form.Select
                    isInvalid={!!errors[`${path}.version`]}
                    size="sm"
                    value={module_version}
                    onChange={(e) => handleChange('module_version', e.target.value)}
                    required={is_mandatory}
                  >
                    {Object.entries(availableVersions).map(([version, versionDetail], index) => (
                      <option key={index} value={version}>
                        {version} {versionDetail.isBeta ? '(Beta)' : ''}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              ) : (
                <span className={'text-dark'}>{module_version}</span>
              )}
              <Form.Group className="d-flex">
                <Form.Check
                  className={!!errors[`${path}.is_active`] ? 'is-invalid' : ''}
                  isInvalid={!!errors[`${path}.is_active`]}
                  type="checkbox"
                  checked={is_active}
                  disabled={is_mandatory}
                  onChange={(e) => handleChange('is_active', e.target.checked)}
                />
                {is_mandatory && <div className="text-secondary ms-1">*</div>}
              </Form.Group>
            </div>
          </div>
        </Card.Header>
        {errors[`${path}.is_active`] && (
          <div className="bg-danger rounded d-flex justify-content-end p-1">{errors[`${path}.is_active`]}</div>
        )}
        {errors[`${path}.version`] && (
          <div className="bg-danger rounded d-flex justify-content-end p-1">{errors[`${path}.version`]}</div>
        )}
        <Accordion.Collapse eventKey="0">
          <Card.Body className={'p-0'}>
            {currentVersionDetail?.dependencies?.length > 0 && (
              <div className="ps-2 py-1 d-flex flex-wrap gap-1">
                <small>Dependencies</small>
                {currentVersionDetail.dependencies.map((dep, versionIndex) => (
                  <Badge key={dep} bg={errors[`${path}.dependencies.${dep}`] ? 'danger' : 'secondary'}>
                    {errors[`${path}.dependencies.${dep}`] ? errors[`${path}.dependencies.${dep}`] : dep}
                  </Badge>
                ))}
              </div>
            )}
            {parameters.length > 0 && (
              <div className="bg-white p-2">
                {parameters.map((param, index) => (
                  <Form.Group as={Row} className="align-items-center " key={index}>
                    <Form.Label className="d-flex fs-7 gap-5 justify-content-between text-black" column xs={6}>
                      <div>{param.name}</div>
                      <div>{param.platform}</div>
                    </Form.Label>
                    <Col xs={6} className="d-flex position-relative">
                      <Form.Control
                        size="sm"
                        type="text"
                        placeholder={`Enter ${param.name}`}
                        value={param.value || ''}
                        onChange={(e) => handleParameterChange(index, e.target.value)}
                        isInvalid={!!errors[`${path}.parameters.${index}.value`]}
                      />
                      {param.is_mandatory && <div className="text-secondary ms-1">*</div>}
                      <Form.Control.Feedback tooltip type="invalid">
                        {errors[`${path}.parameters.${index}.value`]}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                ))}
              </div>
            )}
            {!!submodules.length && (
              <div className="ms-4 pt-2">
                <Accordion>
                  {submodules.map((subModule, index) => (
                    <ModuleItem
                      key={`${id}-${index}`}
                      module={subModule}
                      path={`${path}.submodules.${index}`}
                      isSubModule={isSubModule + 1}
                    />
                  ))}
                </Accordion>
              </div>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default ModuleItem;
