import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SunIcon from '../../Components/Icons/SunIcon';
import MoonIcon from '../../Components/Icons/MoonIcon';
const ColorModes = () => {
  const [darkMode, setDarkMode] = useState(getPreferredTheme());

  const toggleTheme = () => {
    let col = darkMode === 'light' ? 'dark' : 'light';
    setDarkMode(col);
    setTheme(col);
    setStoredTheme(col);
  };

  return (
    <div
      className="d-flex align-items-center border rounded py-1 cursor-pointer user-dropdown color-button"
      onClick={toggleTheme}
    >
      {darkMode === 'light' ? <SunIcon size="22" color="#a4a4a5" /> : <MoonIcon size="22" color="#a4a4a5" />}
      <div className="user-dropdown-tittle overflow-hidden ms-3">
        <div className="text-truncate color-text flex-lg-shrink-0">
          {darkMode === 'light' ? 'Light Theme' : 'Dark Theme'}
        </div>
      </div>
    </div>
  );
};

const getStoredTheme = () => localStorage.getItem('theme');
const setStoredTheme = (theme) => localStorage.setItem('theme', theme);

const getPreferredTheme = () => {
  const storedTheme = getStoredTheme();
  if (storedTheme) {
    return storedTheme;
  }
  return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
};

const setTheme = (theme) => {
  document.documentElement.setAttribute('data-bs-theme', theme);
};

export default ColorModes;
