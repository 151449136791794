const Sdk = () => {
  return (
    <div className="d-flex flex-grow-1">
      <iframe
        className="d-flex flex-grow-1"
        src="https://sdk-doc-static-website-dot-carbide-booth-229112.ew.r.appspot.com"
        title="Sdk-file"
        id="sdk-link"
      ></iframe>
    </div>
  );
};
export default Sdk;
