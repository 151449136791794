import InfoIcon from '../../../Components/Icons/InfoIcon';
import { Button } from 'react-bootstrap';

const MaxLimitWarning = ({ onClick }) => {
  return (
    <div className={`alert alert-info d-flex align-items-center`}>
      <div className=" me-3">
        <InfoIcon />
      </div>
      <div>
        <>
          <p className="mb-1">
            Report size exceeds our limits, to see report summery please use
            <Button size="sm" variant="outline-primary" className="ms-1" onClick={onClick}>
              advanced mode
            </Button>
          </p>
          <span className="fs-7">Or select different filters.</span>
        </>
      </div>
    </div>
  );
};

export default MaxLimitWarning;
