import { Button, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { useChangeGameStatusMutation } from '../../../../data/api/studioApi';
import Loader from '../../../../Components/Loader';
import { useUserPermission } from '../../../../Components/permissions/UserPermissions';

const GameStatusDropdown = ({ bundleId, studioId, store, value, onChange, placement = 'auto-start' }) => {
  const options = {
    LAUNCHED: { title: 'Live', color: 'success' },
    'PRE-LAUNCH': { title: 'Soft Launch', color: 'info' },
    WATCHED: { title: 'Testing', color: 'warning' },
    ARCHIVED: { title: 'Archived', color: 'dark-300' },
  };
  const editable = useUserPermission('game.status.update');

  const [changeGameStatus, { isLoading: isStatusUpdating }] = useChangeGameStatusMutation();
  const handleChangeStatus = (val) => {
    if (val === value) {
      return;
    }
    if (!editable) return false;
    // NOTE: add store dimension
    changeGameStatus({
      bundle_id: bundleId,
      status: val,
      store: store === 'amazon' ? ['amazon'] : ['google', 'apple'],
      studio_id: studioId,
    }).then(() => {
      if (onChange) {
        onChange(val);
      }
    });
  };
  const popover = (
    <Popover>
      <ListGroup variant="flush" className="rounded ">
        {Object.keys(options).map((key) => (
          <ListGroup.Item
            key={key}
            action
            onClick={() => handleChangeStatus(key)}
            className="d-flex align-items-center"
          >
            <span className={`p-1 me-2 bg-${options[key].color} rounded-circle`} /> {options[key].title}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Popover>
  );
  return isStatusUpdating ? (
    <div className="d-flex justify-content-center">
      <Loader parentStyle="loader" size={37} color={'#3F96C7'} />
    </div>
  ) : value ? (
    <OverlayTrigger
      rootClose
      rootCloseEvent="mousedown"
      trigger={editable ? 'click' : 'none'}
      placement={placement}
      overlay={popover}
    >
      <Button
        disabled={!editable}
        variant="outline-light"
        className={`p-2 pe-3 opacity-100 d-flex flex-grow-1 text-nowrap rounded-pill ${editable ? 'cursor-pointer border-' + options[value].color : 'border-0 outline-none cursor-default'} `}
      >
        <div className={`d-flex align-items-center`}>
          <span className={`p-2 bg-${options[value].color}  rounded-circle`} />
          <span className={`fs-7 ms-2 text-${options[value].color}`}> {options[value].title}</span>
        </div>
      </Button>
    </OverlayTrigger>
  ) : null;
};

export default GameStatusDropdown;
