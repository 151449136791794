import React, { useState } from 'react';
import ArrowUpFull from '../Icons/ArrowUpFull';

const TnTable = ({ header, data, onRowClick, page, perPage }) => {
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

  const sortData = (col) => {
    if (!col.sortable) return false;
    let key = col.key;
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = [...data].sort((a, b) => {
    let sortable = header.find((el) => el.key === sortConfig.key)?.sortable;
    if (typeof sortable === 'function') return sortable(a, b, sortConfig.direction);
    const valueA = typeof a[sortConfig.key]?.value !== 'undefined' ? a[sortConfig.key]?.value : a[sortConfig.key];
    const valueB = typeof b[sortConfig.key]?.value !== 'undefined' ? b[sortConfig.key]?.value : b[sortConfig.key];
    if (valueA !== undefined && valueB !== undefined) {
      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return sortConfig.direction === 'asc' ? valueA - valueB : valueB - valueA;
      } else {
        return sortConfig.direction === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }
    }
    return 0;
  });

  return (
    <table className="table table-hover align-middle bg-white rounded overflow-hidden">
      <thead>
        <tr>
          {header.map((column, index) => (
            <th
              key={index}
              onClick={() => sortData(column)}
              className={`${column.sortable ? 'cursor-pointer' : ''} text-nowrap`}
            >
              {column.label}
              {column.sortable && (
                <button className="btn btn-link btn-sm">
                  {sortConfig && sortConfig.key === column.key && sortConfig.direction === 'asc' ? (
                    <ArrowUpFull size={12} />
                  ) : (
                    <ArrowUpFull rotate={'down'} size={12} />
                  )}
                </button>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {(page ? sortedData.slice((page - 1) * perPage, page * perPage) : sortedData).map((data, index) => (
          <tr
            key={index}
            onClick={() => (onRowClick ? onRowClick(data) : false)}
            className={onRowClick ? 'cursor-pointer' : ''}
          >
            {header.map((column, columnIndex) => (
              <td key={columnIndex}>{data[column.key]?.label ? data[column.key].label : data[column.key]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TnTable;
