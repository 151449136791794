import React from 'react';
import AndroindIcon from '../../../Components/Icons/AndroidIcon';
import AppleIcon from '../../../Components/Icons/AppleIcon';

function GameOsIcons({ item, width, height, classname }) {
  return (
    <div
      className={`d-flex align-items-center justify-content-center bg-light-400 rounded ${classname}`}
      style={{ width: width, height: height }}
    >
      {item.os === 'android' ? (
        <div className="d-flex justify-content-center">
          <AndroindIcon size={18} />
        </div>
      ) : item.os === 'ios' ? (
        <div className="d-flex justify-content-center">
          <AppleIcon size={18} />
        </div>
      ) : (
        <>
          <div className="d-flex">
            <AndroindIcon size={18} />
            <AppleIcon size={18} />
          </div>
        </>
      )}
    </div>
  );
}

export default GameOsIcons;
