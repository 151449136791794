import { marked } from 'marked';

export const formatMessage = (str) => {
  marked.use({
    renderer: {
      code: (str) => {
        let txt = str
          .replace(/&/g, '&amp;')
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
          .replace(/"/g, '&quot;')
          .replace(/'/g, '&#039;');
        return `<pre class="bg-secondary rounded p-1 mb-2"><code>${formatMessage(txt)}</code></pre>`;
      },

      paragraph: (str) => {
        return `<p class="mb-1">${str}</p>`;
      },
      // codespan: (str) => {
      //   return `<p class="mb-1">${str}</p>`;
      // },
    },
  });
  return marked.parse(str.replace(/^[\u200B\u200C\u200D\u200E\u200F\uFEFF]/, ''));
};

export const imageSizeOptions = {
  'DALL-E-2': ['256x256', '512x512', '1024x1024'],
  'DALL-E-3': ['1024x1024', '1024x1792', '1792x1024'],
};

export const getImageSizeOptions = (model) => {
  return imageSizeOptions[model] || [];
};
