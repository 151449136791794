import React, { useEffect, useState } from 'react';
import TestTableRow from './TestTableRow';
import { useGetGameAbtestsQuery } from '../../../../data/api/abTestsApi';
import { useParams } from 'react-router-dom';
import Loader from '../../../../Components/Loader';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import TestsTableHeader from './TestsTableHeader';
import { useSelector } from 'react-redux';
import { getGameById } from '../../../../data/slices/studio';
import { buildVersionCompare } from '../../../../utils/common';

function TestsTable({ data: { search, status, os, deviceId, country, store, build }, type }) {
  const [tests, setTests] = useState(null);
  const { id } = useParams();
  const foundGame = useSelector(getGameById(id));
  const { data: abTests, isLoading: isLoadingAbTests } = useGetGameAbtestsQuery(
    { bundleId: foundGame?.bundleId, store: foundGame?.store === 'amazon' ? 'amazon' : 'google,apple' },
    { skip: !foundGame?.bundleId },
  );

  useEffect(() => {
    if (abTests && foundGame) {
      let newSort = [];
      if (foundGame.multipleOs) {
        newSort = [...abTests];
      } else if (foundGame.os === 'android') {
        newSort = abTests.filter((el) => {
          return el.os === 'android' || el.os === '';
        });
      } else if (foundGame.os === 'ios') {
        newSort = abTests.filter((el) => {
          return el.os === 'ios' || el.os === '';
        });
      } else {
        newSort = [...abTests];
      }

      if (search) newSort = newSort.filter((el) => el.name.toLowerCase().indexOf(search) > -1);
      if (status) newSort = newSort.filter((el) => status.includes(el.status));
      if (deviceId.length > 0) newSort = newSort.filter((el) => deviceId.includes(el.deviceId || ''));
      if (store.length > 0) newSort = newSort.filter((el) => store.includes(el.store || ''));
      if (build) newSort = newSort.filter((el) => !el.build || (el.build && buildVersionCompare(build, el.build) >= 0));
      if (country?.length) newSort = newSort.filter((el) => country.some((i) => el.country?.includes(i)));
      if (os !== 'all') newSort = newSort.filter((el) => os === el.os || el.os === '');

      setTests(newSort);
    }
  }, [abTests, foundGame, search, status, os, deviceId, country, type, store, build]);

  return (
    <div className="table-responsive btest-one-game">
      <table className="table table-hover align-middle bg-white rounded overflow-hidden">
        <thead>
          <TestsTableHeader type={type} />
        </thead>
        <tbody>
          {isLoadingAbTests || !foundGame ? (
            <tr>
              <td colSpan={9}>
                <div className="d-flex justify-content-center align-items-center">
                  <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
                </div>
              </td>
            </tr>
          ) : tests && tests.length ? (
            tests.map((value, index) => {
              return <TestTableRow key={index} data={value} />;
            })
          ) : (
            tests?.length === 0 && (
              <tr>
                <td colSpan={9}>
                  <div className="alert alert-warning mb-3 d-flex align-items-center fs-7" role="alert">
                    <div className=" me-3">
                      <InfoIcon />
                    </div>
                    <div>No Data found...!</div>
                  </div>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TestsTable;
