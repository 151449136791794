import { useEffect, useState } from 'react';
import VideoView from './VideoView';
import { Button } from 'react-bootstrap';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import Loader from '../../../../Components/Loader';
import { CreateTrelloCard } from '../../../../data/api/CommunicationsQuerys';
import {
  useCreateSqlVideosMutation,
  useDeleteSqlVideosMutation,
  useGetGameVideosQuery,
} from '../../../../data/api/studioApi';
import { useDeleteCloudVideoMutation, useUploadCloudVideoMutation } from '../../../../data/api/googleCloudApi';
import { confirmAlert } from '../../../../Components/ConfirmModal';
import { Restricted } from '../../../../Components/permissions/UserPermissions';

function Videos({ infosGame }) {
  const [uploadCloud] = useUploadCloudVideoMutation();
  const [deleteCloud] = useDeleteCloudVideoMutation();
  const [createSql] = useCreateSqlVideosMutation();
  const [deleteSql] = useDeleteSqlVideosMutation();
  /** State */
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { data: fetchedVideos, isFetching } = useGetGameVideosQuery(
    {
      appId: infosGame?.appId,
      studioId: infosGame?.studioId,
    },
    { skip: !infosGame?.appId },
  );
  useEffect(() => {
    if (fetchedVideos) {
      setVideos(fetchedVideos);
    }
  }, [fetchedVideos]);
  const hasChanged = () => {
    return fetchedVideos?.length !== videos?.length || videos?.find((el) => typeof el.file !== 'string');
  };
  const handleSubmit = async () => {
    const actVideos = [...videos];
    const fetchVideos = [...fetchedVideos];
    const studioId = infosGame.studioId;

    if (hasChanged()) {
      setLoading(true);
      let deleteVideoIds = [];
      // Delete removed videos from cloud
      let promises = fetchVideos.map((el) => {
        if (actVideos.find((e) => e.file === el?.file)) return false;
        if (el?.file !== '' && typeof el?.file === 'string') {
          const valArr = el?.file.split('/');
          if (studioId !== valArr[4]) return false;
          const fileUrl = `${valArr[3]}/${valArr[4]}/${valArr[5]}/${valArr[6]}`;
          deleteVideoIds.push(el.id);
          return deleteCloud(fileUrl).unwrap();
        }
        return false;
      });

      await Promise.all(promises)
        .then((response) => {})
        .catch((error) => {
          // file may be missing from cloud so ignore it and delete from sql
          setError(error?.data?.message || error?.message || error?.error || error);
        });
      // delete removed files
      if (deleteVideoIds?.length)
        await deleteSql({ ids: deleteVideoIds, appId: infosGame?.appId, studioId: infosGame?.studioId })
          .unwrap()
          .then((response) => {})
          .catch((err) => {
            confirmAlert({
              variant: 'danger',
              title: err?.data?.message || 'Something went wrong during video delete',
              confirmBtn: false,
              cancelText: 'Ok',
            }).catch((err) => {});
          });
      //Upload new videos to cloud
      promises = actVideos.map((el) => {
        if (el?.file?.name) {
          return uploadCloud({ appId: infosGame.appId, studioId: infosGame.studioId, file: el?.file }).unwrap();
        }
        return el?.file;
      });
      await Promise.all(promises).then(async (response) => {
        let links = [];
        if (response.length) {
          response.forEach((value) => {
            if (value.hasOwnProperty('link')) links.push(value.link);
          });
        }
        const newVideos = [];
        links.forEach((value) => {
          let split = value.split('/');
          let foundVideo = videos.find((e) => e?.file?.name === split[split.length - 1]);
          if (foundVideo && !newVideos.find((e) => e[0] === value)) {
            newVideos.push([value, foundVideo.type, infosGame.bundleId, infosGame.appId, infosGame.studioId]);
          }
        });
        if (newVideos?.length)
          createSql(newVideos)
            .unwrap()
            .then((response) => {
              if (newVideos.length)
                CreateTrelloCard(
                  infosGame.appName,
                  infosGame.appId,
                  newVideos.map((el) => ({ url: el[0], type: el[1] })),
                ).catch((e) => {});
            });
      });
      setLoading(false);
    }
  };

  return (
    <>
      {error && (
        <div className="alert alert-danger d-flex align-items-center fs-7" role="alert">
          <div className=" me-3">
            <InfoIcon />
          </div>
          <div>{error?.message || typeof error === 'string' ? error : 'Something went wrong'}</div>
        </div>
      )}
      {isFetching || loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '150px' }}>
          <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
        </div>
      ) : (
        infosGame && <VideoView videos={videos} setVideos={setVideos} />
      )}
      <div className="d-flex justify-content-end mt-3">
        <Restricted permission={'game.video.create'}>
          <Button disabled={loading || !hasChanged()} variant="success" onClick={handleSubmit}>
            Update
          </Button>
        </Restricted>
      </div>
    </>
  );
}

export default Videos;
