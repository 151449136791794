import React from 'react';
import { Button, InputGroup, Nav, Navbar } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import SearchIcon from '../../../../Components/Icons/SearchIcon';
import AppleIcon from '../../../../Components/Icons/AppleIcon';
import AndroidIcon from '../../../../Components/Icons/AndroidIcon';
import SearchableDropdown from '../../../../Components/SearchableDropdown';
import { abTestCountries } from '../../../../constants/abTestCountries';
import { useGetGameAbtestsQuery, useGetGameConfigsQuery } from '../../../../data/api/abTestsApi';
import { useSelector } from 'react-redux';
import { getGameById } from '../../../../data/slices/studio';
import { buildVersionCompare } from '../../../../utils/common';

const GameAbTestListFilters = ({ state, setState, type }) => {
  const foundGame = useSelector(getGameById(state.id));
  const { data: abTests } = useGetGameAbtestsQuery(
    { bundleId: foundGame?.bundleId, store: foundGame?.store === 'amazon' ? 'amazon' : 'google,apple' },
    {
      skip: type === 'CONFIG' || !foundGame?.bundleId,
    },
  );
  const { data: config } = useGetGameConfigsQuery(
    { bundleId: foundGame?.bundleId, store: foundGame?.store === 'amazon' ? 'amazon' : 'google,apple' },
    {
      skip: type === 'ABTEST' || !foundGame?.bundleId,
    },
  );

  const isFiltersEmpty = () => {
    return (
      !state.search &&
      !state.os &&
      !state.status?.length &&
      !state.deviceId?.length &&
      !state.country?.length &&
      !state.store?.length &&
      !state.build
    );
  };

  const clearFilters = () => {
    setState({
      os: 'all',
      search: '',
      status: [],
      deviceId: [],
      country: [],
      store: [],
      build: '',
    });
  };
  let deviceIds = [
    { label: 'Global', value: '' },
    ...((type === 'CONFIG' ? config : abTests)
      ?.filter((el) => el && el.deviceId)
      .filter((v, i, self) => i === self.findIndex((el) => el.deviceId === v.deviceId))
      .map((test) => ({ label: test?.deviceId, value: test?.deviceId })) || []),
  ];

  let stores = [
    { label: 'Global', value: '' },
    ...((type === 'CONFIG' ? config : abTests)
      ?.filter((el) => el && el.store)
      .filter((v, i, self) => i === self.findIndex((el) => el.store === v.store))
      .map((test) => ({ label: test?.store, value: test?.store })) || []),
  ];

  let builds = (type === 'CONFIG' ? config : abTests)
    ?.filter((el) => el && el.build)
    .map((el) => el.build)
    .filter((v, i, self) => i === self.indexOf(v))
    .sort(buildVersionCompare);

  return (
    <div className="data-filter-container">
      <Navbar className="p-0 " bg="bg-transparent" expand="lg">
        <div className="d-flex w-100 justify-content-between d-lg-none">
          <Navbar.Brand className="d-lg-none p-0" href="#home">
            Filters
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="align-items-center">
            <Nav.Item className="w-100">
              <Form.Group>
                <InputGroup>
                  <Form.Control
                    className="bg-white"
                    type="text"
                    aria-describedby=""
                    placeholder="Search..."
                    value={state.search}
                    autoComplete={'off'}
                    onChange={(e) => setState({ search: e.target.value })}
                  />
                  <InputGroup.Text className="bg-white">
                    <SearchIcon size={20} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Nav.Item>
            <Nav.Item className="w-100">
              <Form.Group className="input-group select-drop">
                <InputGroup>
                  <InputGroup.Text className="cursor-pointer pe-0 bg-white">
                    {state.os === 'ios' ? <AppleIcon size={14} /> : state.os === 'android' && <AndroidIcon size={14} />}
                  </InputGroup.Text>
                  <Form.Select
                    value={state.os || ''}
                    className="form-select bg-white"
                    onChange={(e) => setState({ os: e.target.value })}
                  >
                    <option value="all">Os</option>
                    <option value="android">Android</option>
                    <option value="ios">Ios</option>
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </Nav.Item>
            <Nav.Item className="w-100">
              <Form.Group className="input-group select-drop">
                <SearchableDropdown
                  parClass={'w-100'}
                  multiple
                  disableSearch
                  onSelect={(data) => setState({ status: data })}
                  selected={state.status}
                  placeholder={'Status'}
                  options={[
                    { label: 'Approved', value: 'APPROVED' },
                    { label: 'Pending', value: 'PENDING' },
                    { label: 'Canceled', value: 'CANCELED' },
                  ]}
                />
              </Form.Group>
            </Nav.Item>
            {deviceIds?.length > 1 && (
              <Nav.Item className="w-100">
                <Form.Group className="input-group select-drop">
                  <SearchableDropdown
                    parClass={'w-100'}
                    multiple
                    disableSearch
                    placeholder={'Device Id'}
                    onSelect={(data) => setState({ deviceId: data })}
                    selected={state?.deviceId}
                    options={deviceIds}
                  />
                </Form.Group>
              </Nav.Item>
            )}
            {stores?.length > 1 && (
              <Nav.Item className="w-100">
                <Form.Group className="input-group select-drop">
                  <SearchableDropdown
                    parClass={'w-100'}
                    multiple
                    disableSearch
                    placeholder={'Store'}
                    onSelect={(data) => setState({ store: data })}
                    selected={state?.store}
                    options={stores}
                  />
                </Form.Group>
              </Nav.Item>
            )}
            {builds?.length > 1 && (
              <Nav.Item className="w-100">
                <Form.Group className="input-group select-drop">
                  <InputGroup>
                    <Form.Select
                      value={state.build || ''}
                      className="form-select bg-white"
                      onChange={(e) => setState({ build: e.target.value })}
                    >
                      <option value="">Min Build:</option>
                      {builds.map((el) => (
                        <option key={el} value={el}>
                          {el}
                        </option>
                      ))}
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </Nav.Item>
            )}
            <Nav.Item className="w-100">
              <Form.Group className="input-group select-drop">
                <SearchableDropdown
                  multiple
                  collapseGroup
                  parClass={'w-100'}
                  placeholder={'Country'}
                  onSelect={(data) => setState({ country: data })}
                  selected={state?.country}
                  options={[{ label: 'Global', value: '' }, ...abTestCountries]}
                  disableSearch
                />
              </Form.Group>
            </Nav.Item>
            {!isFiltersEmpty() && (
              <Nav.Item className="w-100">
                <Button variant="dark" size="sm" onClick={clearFilters} className="fs-8 text-nowrap w-100">
                  Clear Filters
                </Button>
              </Nav.Item>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default GameAbTestListFilters;
