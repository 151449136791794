export const GAME_CATEGORIES = [
  'Arcade',
  'Arcade-idle',
  'ASMR',
  'Classic games',
  'Casual',
  'Idle',
  'IO',
  'Puzzle',
  'Racing',
  'Runner',
  'Simulation',
  'Shooter',
  'Strategy',
  'Trivia',
];
export const GAME_KEYWORDS = [
  'Tap',
  '3D',
  '2D',
  'Cartoon',
  'Realistic',
  'Timing',
  'Hold and Release',
  'Drag and Release',
  'Top view',
  'Stacking',
];
export const GAME_PLATFORMS = ['iOS', 'Android'];
