import TapNationPng from '../../assets/logoTapNation/LogoTapNation40.png';
import AppStoreBadgeIcon from '../../Components/Icons/AppStoreBadgeIcon';
import PlayStoreBadgeIcon from '../../Components/Icons/PlayStoreBadgeIcon';
import { Link } from 'react-router-dom';
import moment from 'moment';

const TopAppCard = ({ data, showScreenshots }) => {
  const appUrl =
    data?.os === 'android'
      ? 'https://play.google.com/store/apps/details?gl=US&id=' + data?.bundle_id
      : 'https://apps.apple.com/us/app/' + data?.application_name + '/id' + data?.bundle_id;
  return (
    <div className={`bg-white rounded p-3 mb-3 ${showScreenshots ? 'pb-0' : ''}`}>
      <div className="d-flex">
        <Link to={{ pathname: appUrl }} target="_blank">
          <div className="overflow-hidden position-relative text-nowrap me-3 flex-shrink-0" style={{ width: 106 }}>
            <img
              width={106}
              height={106}
              className="rounded img-fit-contain"
              src={data?.icon ? data?.icon : TapNationPng}
              alt={data?.application_name}
            />
            <div
              className="text-info position-absolute translate-middle top-50 start-50 fs-1 p-3 rounded d-flex align-items-center justify-content-center"
              style={{ background: '#00000099', width: 66, height: 66 }}
            >
              {data?.rank}
            </div>
          </div>
        </Link>
        <div>
          <Link
            className="text-decoration-none h5"
            to={{
              pathname: appUrl,
            }}
            target="_blank"
          >
            {data?.application_name}
          </Link>

          <p className="m-0 text-secondary">
            {data?.publisher_name} &middot; {data?.bundle_id}
          </p>
          <p className="mb-1 text-secondary">
            {data?.rating} <span className="star-rating" style={{ '--rating': data?.rating }}></span>
            Downloads: {data?.downloads}
          </p>
          <div className=" ">
            {data?.categories &&
              data?.categories.split(',').map((el) => (
                <span key={el} className="badge fs-7 me-1 mb-1 light-button px-3 py-2">
                  {el}
                </span>
              ))}
          </div>
        </div>
        <div className="ms-auto">
          <Link
            className="mx-1 mb-4 d-flex justify-content-end"
            to={{
              pathname: appUrl,
            }}
            target="_blank"
          >
            {data?.os === 'android' ? (
              <PlayStoreBadgeIcon size={36} />
            ) : (
              data?.os === 'ios' && <AppStoreBadgeIcon size={36} />
            )}
          </Link>
          <p className="text-secondary fs-7 mx-1 my-1">
            Released: {moment(data?.first_release_date?.value).format('ll')}
          </p>
          <p className="text-secondary text-jus fs-7 mx-1 mb-1">
            Updated: &nbsp;{moment(data?.latest_update_date?.value).format('ll')}
          </p>
        </div>
      </div>
      {showScreenshots && (
        <>
          <p className="my-1">Short description</p>

          <div className="text-secondary" dangerouslySetInnerHTML={{ __html: data?.short_description }}></div>
          {data?.screenshots && (
            <div className="slider d-flex overflow-scroll py-3 align-items-center">
              {data?.screenshots.split(',').map((el, index) => (
                <img
                  key={index}
                  style={{ maxHeight: 320 }}
                  width={'100%'}
                  height={'100%'}
                  className="rounded img-fit-contain me-2"
                  src={el}
                  alt={data?.application_name}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TopAppCard;
