import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { InputGroup } from 'react-bootstrap';
import SearchIcon from '../../../../Components/Icons/SearchIcon';
import AbTestGameList from '../../../../Pages/AbTest/CreateAbTestModal/_Components/AbTestGameList';
import { generatePath } from 'react-router-dom';
import { R_GAME_METRICS } from '../../../../constants/routes';

function SearchInput() {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [showSearchList, setShowSearchList] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target) && !event.target.closest('.search-list')) {
        setSearch('');
        setShowSearchList(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showSearchList, search]);

  const onSelect = (selectedGame) => {
    history.push(generatePath(R_GAME_METRICS, { id: selectedGame.id }));
    setSearch('');
    setShowSearchList(false);
  };

  return (
    <div className={`d-flex flex-column position-relative rounded px-2 py-1 ${search ? 'bg-light-400' : ''}`}>
      <InputGroup className="main-search bg-body rounded">
        <InputGroup.Text htmlFor="main-search" bsPrefix="input-group-text">
          <SearchIcon />
        </InputGroup.Text>
        <Form.Control
          ref={searchRef}
          placeholder="Search for Games..."
          aria-label="search "
          className="bg-body"
          autoComplete={'off'}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            setShowSearchList(true);
          }}
        />
      </InputGroup>
      {showSearchList && search && (
        <div
          className="position-absolute w-100 bg-light-400 px-2 py-1 overflow-scroll search-list"
          style={{
            top: '47px',
            right: 0,
            maxHeight: '70vh',
            borderBottomRightRadius: '6px',
            borderBottomLeftRadius: '6px',
          }}
        >
          <AbTestGameList search={search} setSearch={setSearch} showMoreButton onSelect={onSelect} />
        </div>
      )}
    </div>
  );
}

export default SearchInput;
