import { Link } from 'react-router-dom';
import { R_HOME } from '../constants/routes';
import TapNationLogo from '../assets/logoTapNation/LogoTapNation45.png';
import homeBack from '../assets/images/home.png';

function TermsAndConditons() {
  return (
    <div className="terms-and-conditions">
      <div className="d-flex w-100 mb-3">
        <Link to={R_HOME}>
          <img height={24} src={TapNationLogo} alt="Logo" />
        </Link>

        <Link to={R_HOME} className="home-link ms-auto">
          <img src={homeBack} alt="home-back-icon" width={24} />
        </Link>
      </div>
      <h1>
        <strong>General terms and conditions of use of the TAPNATION Platform</strong>
        <br />
      </h1>
      <div className="general">
        <strong>
          TAPNATION
          <br />
          General terms and conditions of use of the TAPNATION Platform – version dated July 29th, 2022
          <br />
          <br />
          1. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; OBJECT AND SCOPE OF APPLICATION
        </strong>
        <br />
        <br />
        <p>
          All provisions set out in these General Terms and Conditions of Use are binding and have to be unconditionally
          followed by each Person using the TAPNATION Platform. These General Terms and Conditions of Use apply whether
          the User is accessing the TAPNATION Platform via a wireless or mobile device, a personal computer or any other
          kind of technology or device.
        </p>
        <p>
          Before registering on the TAPNATION Platform, each User must read these General Terms and Conditions of Use,
          accept them and thereby commit to complying with them.
        </p>
        <p>
          To use the TAPNATION Platform, it is necessary for the User to: (i) have a device that allows him/her to
          access the Internet with a browser such as Firefox 7 or later, or Internet Explorer 9 or higher, or Chrome 15
          or later, or Safari 6 or later, technology to support Ajax, HTML5 and JavaScript, and SSL; and (ii) access to
          the Internet.
        </p>
        <p>
          The User confirms that he/she is aware that the use of the Services, as services provided electronically, are
          typically associated with threats to transmission of data over the Internet.
        </p>
        <p>
          No special condition or other general conditions stipulated by the User may prevail over these conditions,
          unless formally accepted by TAPNATION.
        </p>
        If there is a conflict between these General Terms and Conditions of Use and any Special Terms and Conditions of
        Use entered into with any User, the Special Terms and Conditions of Use shall prevail.
        <p>
          The User may contact TAPNATION at contact@tap-nation.io if it has any queries regarding its rights and
          obligations under these General Terms and Conditions of Use.
        </p>
        <h6>
          <strong>2. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; DEFINITIONS</strong>
        </h6>
        <p>
          As used in these General Terms and Conditions of Use, the following capitalised terms, whether used in the
          singular or plural, shall have the meaning set forth below:
        </p>
        2.1 &nbsp; &nbsp; &nbsp; Affiliate(s) means, in relation to any Party, any Person controlled by that Party, or
        which controls that Party, or which is controlled by a Person which also controls that Party, in each case,
        directly or indirectly, through one or more intermediaries.
        <p>
          2.2 &nbsp; &nbsp; &nbsp; Confidential Information means all information, data or material, whatever the medium
          thereof, which is exchanged between the Parties pursuant to these T&amp;Cs (including anything the receiving
          party creates which is derived from or based upon the information, data or materials disclosed to it by the
          disclosing party), including but not limited to any documents or information related to the Game Content,
          TAPNATION’ processes, products and technologies or the TAPNATION Platform. It shall not include any
          information or materials which:
        </p>
        <p>
          (a) &nbsp; &nbsp; &nbsp; &nbsp; is in or enters into the public domain (other than as a result of disclosure,
          by the receiving party or any third party to whom the receiving party disclosed such information, in breach of
          these T&amp;Cs);
        </p>
        <p>
          (b) &nbsp; &nbsp; &nbsp; &nbsp; were already in the lawful possession of the receiving party prior to the
          disclosure by the disclosing party;
        </p>
        <p>
          (c) &nbsp; &nbsp; &nbsp; &nbsp; are subsequently obtained by the receiving party from a third party who is
          free to disclose them to the receiving party;
        </p>
        <p>(d) &nbsp; &nbsp; &nbsp; &nbsp; are independently developed by the receiving party; or</p>
        <p>(e) &nbsp; &nbsp; &nbsp; &nbsp; are required to be disclosed by law or regulatory authority.</p>
        <p>
          2.3 &nbsp; &nbsp; &nbsp; Delivery Date means the date on which the User delivers to TAPNATION the Game Content
          in electronic format via data upload on the TAPNATION Platform, email, or any other electronic location as the
          Parties may agree to in writing.
        </p>
        <p>
          2.4 &nbsp; &nbsp; &nbsp; Development and Publishing Agreement means the agreement to be concluded between
          TAPNATION and the User whereby the User grants to TAPNATION an exclusive worldwide license on the Game Content
          for the purposes of publishing and marketing a video game based on the Game Content.
        </p>
        2.5 &nbsp; &nbsp; &nbsp; Exclusivity Period has the meaning set forth in Article 7.1.
        <p>
          2.6 &nbsp; &nbsp; &nbsp; TAPNATION means the French limited liability company registered with Trade and
          Companies Registry of Paris (France) under No. 845 232 974, whose registered office is located at 9 rue
          Anatole de la Forge, 75017 Paris, with EU VAT No. FR12 845 232 974.
        </p>
        <p>
          2.7 &nbsp; &nbsp; &nbsp; Intellectual Property Rights means all rights, whether registered or not, including
          but not limited to, patents, trademarks, registered designs, author rights, copyright and related rights,
          database rights and other sui generis rights, domain name registrations, rights in corporate names, trade
          names and commercial signs, rights related to know-how, industrial and trade secrets, or any equivalent form
          of protection which is in force anywhere in the world, together with any applications for registration, and
          the right to apply for registration, for any of these rights. &nbsp; &nbsp; &nbsp; &nbsp;
        </p>
        <p>2.8 &nbsp; &nbsp; &nbsp; License has the meaning set forth in Article 9.2.</p>
        <p>2.9 &nbsp; &nbsp; &nbsp; Option Right has the meaning set forth in Article 8.1.</p>
        <p>2.10 &nbsp; &nbsp; Option Exercise Notice has the meaning set forth in Article 8.1.</p>
        <p>
          2.11 &nbsp; &nbsp; TAPNATION Platform means the web platform available at the address
          https://publishing-dashboard.tap-nation.io/, and all associated websites linked to it, which are operated by
          TAPNATION, whether accessed on a computer, wireless or mobile device or via any other technology.
        </p>
        <p>2.12 &nbsp; &nbsp; Party means TAPNATION or the User, as the case may be.</p>
        <p>2.13 &nbsp; &nbsp; Parties means TAPNATION and the User collectively.</p>
        <p>
          2.14 &nbsp; &nbsp; Person means an individual, partnership, company, trust, association, joint venture or
          other similar entity or organisation, including a government or political subdivision, department or agency of
          a government.
        </p>
        <p>
          2.15 &nbsp; &nbsp; T&amp;Cs means the present General Terms and Conditions of Use, together with their
          appendices and all further amendments.
        </p>
        <p>
          2.16 &nbsp; &nbsp; User means any natural person who registers on the TAPNATION Platform and creates a User’s
          Account.
        </p>
        <p>
          2.17 &nbsp; &nbsp; User’s Account means the result of the User’s registration on the TAPNATION Platform, the
          account created containing personal data including data with regard to the use of the TAPNATION Platform.
        </p>
        <p>
          2.18 &nbsp; &nbsp; Game Content means any content posted by the User on the TAPNATION Platform. The
          Definitions form part of these T&amp;Cs and shall have the same force and effect as if set out in the body of
          these T&amp;Cs and any references to these T&amp;Cs shall include the Definitions.
        </p>
        <h6>
          <strong>3. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; MODIFICATIONS</strong>
        </h6>
        <p>
          TAPNATION reserves the right, in its entire discretion, to amend these T&amp;Cs from time to time. Users will
          be informed of any amendment to these T&amp;Cs by e-mail to the address given by the User during registration
          at least thirty (30) days before the amendment is effective. If the User expressly accepts the amended General
          Terms and Conditions of Use before the date specified in the preceding sentence, or if the User continues to
          use the TAPNATION Platform after the deadline specified in the preceding sentence, the User will be considered
          as having accepted the amendment.
        </p>
        <h6>
          <strong>4. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ELIGIBILITY</strong>
        </h6>
        <p>
          The use of the TAPNATION Platform is not intended for or directed at children under eighteen (18) years of
          age. By using the TAPNATION Platform, the User represents and warrants that: (i) he/she is eighteen (18) years
          of age or older; (ii) his/her use of the TAPNATION Platform does not violate any applicable law, rule or
          regulation; and (iii) all registration information the User submits is truthful and accurate and the User
          shall maintain and promptly update the accuracy of such information. Further, if the User provides information
          that is untrue, inaccurate, not current or incomplete, or TAPNATION suspects that such information is untrue,
          inaccurate, not current or incomplete, TAPNATION has the right to suspend or terminate the User’s Account (in
          whole or in part) and refuse any and all current or future use of the TAPNATION Platform, in TAPNATION’ sole
          discretion, without liability or obligation to the User or any third party.
        </p>
        <h6>
          <strong>5. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; CONFIDENTIALITY</strong>
        </h6>
        <p>
          5.1 &nbsp; &nbsp; &nbsp; The Parties shall use the highest degree of care to preserve the confidentiality of
          Confidential Information. Except as expressly authorised herein, the receiving party agrees that it shall (a)
          use the Confidential Information only to exercise its rights and fulfil its obligations pursuant to these
          T&amp;Cs (b) treat all Confidential Information of the disclosing party as secret and confidential; and (c)
          only disclose the Confidential Information to such Party’s Affiliates, its and their legal representatives,
          officers, directors, advisors, actual and potential investors and acquirers and any other third parties who
          need access to such Confidential Information for use in connection with exercising such Party’s rights under
          or performance of these T&amp;Cs and who are bound by appropriate confidentiality obligations.
        </p>
        <p>
          5.2 &nbsp; &nbsp; &nbsp; Each Party acknowledges that the other Party may suffer irreparable damage in the
          event of a breach by such Party of the terms of this section and that the other Party shall be entitled to
          seek injunctive relief in the event of any such breach.
        </p>
        <p>
          5.3 &nbsp; &nbsp; &nbsp; If the User, or one of its directors, affiliates or employees, acts in violation of
          the provisions of, or pursuant to this Article 5, the User shall, without any demand or notice of default
          being required, be liable to forfeit to TAPNATION an immediately due and payable penalty not eligible for
          setting-off of fifty thousand dollars ($50,000) per violation, without prejudice to the right of TAPNATION to
          – in addition to the penalty and all other rights accrued under these T&amp;Cs – claim full damages and profit
          gained and to demand that the User complies with its obligations under these T&amp;Cs.
        </p>
        <h6>
          <strong>6. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; DELIVERY OF THE GAME CONTENT</strong>
        </h6>
        <p>
          6.1 &nbsp; &nbsp; &nbsp; To upload Game Content on the TAPNATION Platform, Game Content must be in the
          following format: Google Play Store link Apple Store link, .ipa file or .apk file.
        </p>
        <p>
          6.2 &nbsp; &nbsp; &nbsp; User is entirely liable for all content that he/she may upload, send or otherwise
          make available via the TAPNATION Platform.
        </p>
        <p>
          6.3 &nbsp; &nbsp; &nbsp; User shall not upload, send or otherwise make available any content which: (i) is
          illegal, harmful, defamatory, obscene, discriminatory or otherwise punishable; (ii) violates any Person’s
          privacy; (iii) is likely to incite violence or racial or ethnic hatred; (iv) the User is not entitled to
          disclose pursuant to confidentiality or non-disclosure undertakings; (v) infringes any Intellectual Property
          Right or other right of any Person; (vi) includes any unrequested or unauthorised advertising or promotional
          material, or any other form of solicitation; or (vii) contains any computer virus or other computer code,
          files or programs designed to interrupt, damage or limit the operation of any software or hardware or
          telecommunication equipment.
        </p>
        <p>
          6.4 &nbsp; &nbsp; &nbsp; TAPNATION is under no obligation to store elements which may be sent via the
          TAPNATION Platform and shall not be bound to return elements sent by User. User hereby undertakes to take all
          necessary precautions accordingly.
        </p>
        <h6>
          <strong>7. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; EXCLUSIVITY</strong>
        </h6>
        <p>
          7.1 &nbsp; &nbsp; &nbsp; As of the Delivery Date and upon the earlier of the following: (i) three (3) months
          from the Delivery Date; (ii) the date the Development and Publishing Agreement with respect to the Game
          Content is signed by TAPNATION and the User; or (iii) the date TAPNATION notifies the User of its decision not
          to exercise its Option Right with respect to the Game Content (the “Exclusivity Period”), the User shall not,
          either alone or in conjunction with, or on behalf of any other Person, with respect to such particular Game
          Content, do any of the following in any country:
        </p>
        <p>
          - grant any rights on the Game Content to any third party to publish, promote, market or distribute the Game
          Content;
        </p>
        <p>
          - publish, promote, distribute or market a video game with a design, features, name, gameplay or other
          characteristics substantially similar to those of the Game Content;
        </p>
        <p>- sell or offer to sell the Game Content to a third party; or</p>
        <p>- assist any other person to do any of the above.</p>
        <p>
          7.2 &nbsp; &nbsp; &nbsp; During the Exclusivity Period, TAPNATION undertakes to perform a series of tests on
          the Game Content in order to determine the potential success of a video game (i.e. a mobile game with simple
          mechanisms that offer instant gameplay) based on such Game Content.
        </p>
        <p>
          7.3 &nbsp; &nbsp; &nbsp; If the tests performed by TAPNATION on the Game Content are not conclusive, then
          TAPNATION shall notify the User before the end of the Exclusivity Period of its decision not to exercise its
          Option Right with respect to the Game Content. In such a case, the Exclusivity Period ends immediately, and
          the User regains all its rights to freely dispose of such Game Content.
        </p>
        <p>
          7.4 &nbsp; &nbsp; &nbsp; For the avoidance of doubt, whether the Option Right is exercised or not in relation
          to a particular Game Content shall not affect in any way TAPNATION’ right to exercise the Option Right with
          respect to any other Game Content.
        </p>
        <h6>
          <strong>8. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; OPTION RIGHT</strong>
        </h6>
        <p>
          8.1 &nbsp; &nbsp; &nbsp; At any time during the Exclusivity Period applicable to any particular Game Content,
          TAPNATION shall have an option right (the “Option Right”) pursuant to which TAPNATION may send to the User a
          written notice (the “Option Exercise Notice”) whereby TAPNATION agrees to conclude the Development and
          Publishing Agreement with the User, under the terms and conditions set up in the Article 10.
        </p>
        <p>
          8.2 &nbsp; &nbsp; &nbsp; If, with respect to particular Game Content: (i) the Option Right is not exercised by
          TAPNATION; (ii) the Option Right is not notified during the Exclusivity Period; or (iii) TAPNATION notifies
          the User at any time during the Exclusivity Period that it does not wish to exercise the Option Right, then
          the Option Right will become null and void with respect to such Game Content without any indemnity being due
          by either Party to the other.
        </p>
        <h6>
          <strong>9. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; INTELLECTUAL PROPERTY</strong>
        </h6>
        <p>
          9.1 &nbsp; &nbsp; &nbsp; TAPNATION owns, solely and exclusively, all Intellectual Property Rights in the
          TAPNATION Platform, and all content contained and/or made available on, through or in connection therewith
          TAPNATION owns all Intellectual Property Rights of the Game Content within the period where TAPNATION
          exercises the Option Right in the Exclusivity Period.
        </p>
        <p>
          9.2 &nbsp; &nbsp; &nbsp; The User hereby grants to TAPNATION during the Exclusivity Period an exclusive,
          sublicensable, transferable, worldwide, royalty-free license to use, reproduce, distribute, transmit,
          translate, modify and store the Game Content and all Intellectual Property Rights therein.
        </p>
        <p>
          9.3 &nbsp; &nbsp; &nbsp; In this context, the User hereby grants to TAPNATION, worldwide, for the purpose of
          publishing the Game Content, and on any current or future format or medium , the following exclusive rights on
          the Game Content : (i) the right to reproduce or have reproduced the Game Content using any technique ; (ii)
          the right to represent, have represented, the Game Content by any means, totally or in part, under any form
          whatsoever and in any languages; (iii) the right to adapt and modify the Game Content, including by the
          addition of new material, the right to translate or have translated the Game Content into any written or
          spoken language, including computer language.
        </p>
        <p>
          9.4 &nbsp; &nbsp; &nbsp; TAPNATION values intellectual property above all and refuses to publish Game Content
          which may infringe the rights of a third party. No documents and information shared by or from TAPNATION shall
          be construed by any means by the User as an inducement to create and upload Game Content which may infringe
          the rights of a third party.
        </p>
        <h6>
          <strong>10. Development &amp; Publishing Agreement</strong>
        </h6>
        <p>
          The User agrees that if TAPNATION exercises its Option Right on a Game Content, then the terms and conditions
          for a Development &amp; Publishing Agreement shall be a royalty and a minimum guarantee, except if TAPNATION
          chooses an internal team or a third party to finish developing the Game Content. In that case, the User and
          TAPNATION will negotiate new terms and conditions for another Developing &amp; Publishing Agreement.
        </p>
      </div>

      <div className="warrenty">
        <h6>
          <strong>11. &nbsp; &nbsp; &nbsp; TERM</strong>
        </h6>

        <p>
          12.1 &nbsp; &nbsp; These T&amp;Cs, and any posted revision or modification thereto, shall remain in full force
          and effect while the User uses or is registered to the TAPNATION Platform.
        </p>
        <p>
          12.2 &nbsp; &nbsp; Obligations under Articles 5, 11, 12 of the T&amp;Cs respectively with regard to
          confidentiality, indemnity, warranty and liability shall survive after the expiry or termination of these
          T&amp;Cs for any cause whatsoever.
        </p>
        <p>
          12.3 &nbsp; If a User working under a Programming Services Agreement signed with TAPNATION is submitting a
          game on TAPNATION during the period of this agreement, the User and the Game Content submitted to TAPNATION
          under this agreement shall not be subjected to Article 9, Article 10 and Article 11. The PSA terms of the
          agreement signed only will be of use. The Effective Date of the Programming Service Agreement will serve as
          the date to delimit if the User is working under the agreement or not. The Creation Date of the Game on
          TAPNATION will also serve as a time marker to compare.
        </p>

        <h6>
          <strong>12. &nbsp; &nbsp; &nbsp; WARRANTY</strong>
        </h6>

        <p>The User represents and warrants to TAPNATION that as of the Delivery Date:</p>
        <p>
          (a) &nbsp; &nbsp; &nbsp; &nbsp; the User is the valid, full and sole owner of the Game Content, including the
          documentation and source code thereof;
        </p>
        <p>(b) &nbsp; &nbsp; &nbsp; &nbsp; the User has the power and authority to grant the License;</p>
        <p>
          (c) &nbsp; &nbsp; &nbsp; &nbsp; the Game Content provided to TAPNATION by the User is not the subject of any
          lien, encumbrance, claim, litigation or arbitration, whether pending, suspected or threatened;
        </p>
        <p>
          (d) &nbsp; &nbsp; &nbsp; &nbsp; the Game Content provided to TAPNATION by the User does not and will not
          violate any law, rules or regulations, or infringe any Intellectual Property Rights or other right of any
          third party, including without limitation any rights of publicity or privacy or other rights, or give rise to
          any legal claim by any third party;
        </p>
        <p>
          (e) &nbsp; &nbsp; &nbsp; &nbsp; the User has duly acquired, or has been legally vested with, all rights from
          any third party, including without limitation from its suppliers, past or present employees or consultants,
          agents and officers, and from directors or shareholders, that may have been involved in the creation or
          consolidation of the Game Content on a worldwide basis for the duration of such rights, and all amounts due in
          this regard have been duly paid; and
        </p>
        <p>
          (f) &nbsp; &nbsp; &nbsp; &nbsp; the Game Content is not subject to the terms of any open source or other
          similar license that provides for any source of such software to be disclosed, licensed, publicly distributed
          or dedicated to the public.
        </p>

        <h6>
          <strong>13. &nbsp; &nbsp; &nbsp; INDEMNITY AND LIABILITY</strong>
        </h6>

        <p>
          14.1 &nbsp; &nbsp; The User shall at all times defend, indemnify and hold TAPNATION harmless from any and all
          claims, liabilities, damages, losses, costs and expenses (including reasonable attorneys’ fees), arising in
          any way out of or in connection with: (i) his/her use of the TAPNATION Platform; (ii) his/her breach or
          violation of these T&amp;Cs or (iii) the Game Content, in particular in relation to any claim or allegation
          that TAPNATION’ exploitation of the Game Content within the scope of the License infringes any Intellectual
          Property Rights of a third party.
        </p>
        <p>
          14.2 &nbsp; &nbsp; TAPNATION reserves the right, at its own expense, to assume the exclusive defense and
          control of any matter otherwise subject to indemnification by the User and all negotiation for its settlement
          or compromise (as applicable), and in each such case, the User agrees to fully cooperate with TAPNATION upon
          its request.
        </p>
        <p>
          14.3 &nbsp; &nbsp; The TAPNATION Platform, together with all Intellectual Property Rights therein, are made
          available on an “as is” and “as available” basis, without any representation or warranty of any kind, express
          or implied, or any guaranty or assurance the TAPNATION Platform will be available for use, or that all
          features, functions, services or operations will be available or perform as described. TAPNATION is not
          responsible or liable for any malicious code, delays, inaccuracies, errors, or omissions arising out of the
          User’s use of the TAPNATION Platform.
        </p>
        <p>
          14.4 &nbsp; &nbsp; To the fullest extent permitted by applicable law, TAPNATION, its Affiliates, successors
          and assigns, officers, directors, employees, agents, representatives, licensors, operational service
          providers, advertisers and suppliers shall not be liable for any loss or damage, of any kind, direct or
          indirect, in connection with or arising from these T&amp;Cs, including, without limitation, compensatory,
          consequential, incidental, indirect, special or punitive damages.
        </p>

        <h6>
          <strong>14. &nbsp; &nbsp; &nbsp; DATA PROTECTION</strong>
        </h6>

        <p>
          15.1 &nbsp; &nbsp; TAPNATION respects the User’s privacy and the use and protection of the User’s personally
          identifiable information. In the course of his/her use of the TAPNATION Platform, the User may be asked to
          provide certain personal information to TAPNATION.
        </p>
        <p>
          15.2 &nbsp; &nbsp; TAPNATION’ information collection and use policies with respect to the privacy of such
          personal information are set forth in the Privacy Policy, available at the address
          https://www.tap-nation.io/legal-notice/.
        </p>

        <h6>
          <strong>15. &nbsp; &nbsp; &nbsp; GOVERNING LAW</strong>
        </h6>

        <p>
          These T&amp;Cs shall be governed by and construed in accordance with the laws of France, without reference to
          its conflict of laws principles.
        </p>

        <h6>
          <strong>16. &nbsp; &nbsp; &nbsp; DISPUTES</strong>
        </h6>

        <p>
          All disputes arising out of or in connection with these T&amp;Cs shall be subject to the jurisdiction of the
          Courts of Paris (France).
        </p>

        <h6>
          <strong>17. &nbsp; &nbsp; &nbsp; ASSIGNMENT</strong>
        </h6>

        <p>
          Neither Party may assign its rights or obligations under these T&amp;Cs absent the prior written consent of
          the other Party, except to any of TAPNATION’ Affiliates or in the context of a merger, acquisition, sale or
          other transaction involving all or substantially all of the assets pertaining to the subject matter of these
          T&amp;Cs, in which case TAPNATION in its sole discretion may assign its rights and obligations under these
          T&amp;Cs. Any permitted assignment shall be binding on the successors of the assigning Party.
        </p>

        <h6>
          <strong>18. &nbsp; &nbsp; &nbsp; INDEPENDENT CONTRACTOR</strong>
        </h6>

        <p>
          No employee or representative of either Party shall have any authority to bind or obligate the other Party to
          these T&amp;Cs for any sum or in any manner whatsoever or to create or impose any contractual or other
          liability on the other Party without said Party’s prior written approval. For all purposes, and
          notwithstanding any other provision of these T&amp;Cs to the contrary, the Parties’ legal relationship under
          these T&amp;Cs shall be that of independent contractors.
        </p>

        <h6>
          <strong>19. &nbsp; &nbsp; &nbsp; UNENFORCEABLE PROVISIONS AND SEVERABILITY</strong>
        </h6>

        <p>
          If any of the provisions of these T&amp;Cs are held to be void or unenforceable, then such void or
          unenforceable provisions shall be replaced by valid and enforceable provisions that will achieve as far as
          possible the economic business intentions of the Parties. However the remainder of these T&amp;Cs will remain
          in full force and effect, provided that the material interests of the Parties are not affected, i.e. the
          Parties would presumably have concluded these T&amp;Cs without the unenforceable provisions.
        </p>

        <h6>
          <strong>20. &nbsp; &nbsp; &nbsp; WAIVER</strong>
        </h6>

        <p>
          The failure by either Party to require strict performance and/or observance of any obligation, term, provision
          or condition under these T&amp;Cs will neither constitute a waiver thereof nor affect in any way the right of
          the respective Party to require such performance and/or observance. The waiver by either Party of a breach of
          any obligation, term, provision or condition hereunder shall not constitute a waiver of any subsequent breach
          thereof or of any other obligation, term, provision or condition.
        </p>
      </div>

      <footer className="footer py-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-center">© Tap Nation</div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default TermsAndConditons;
