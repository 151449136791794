import CohortParamsInfo from '../../_Components/CohortParamsInfo';
import React, { useMemo } from 'react';
import useFilteredConfigs from '../../GameAbtestList/Configs/useFilteredConfigs';
import Loader from '../../../../Components/Loader';

const ConfigList = ({ data }) => {
  const params = useMemo(
    () => ({
      deviceId: data?.deviceId ? [data.deviceId, ''] : [''],
      country: data?.country ? [...data.country, ''] : [''],
      status: ['APPROVED'],
    }),
    [data?.deviceId, data?.country],
  );
  const { configs, headers, isLoadingConfig } = useFilteredConfigs({
    filter: { ...data, ...params },
    gameId: data.id,
  });

  return isLoadingConfig ? (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
      <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
    </div>
  ) : (
    <div className="d-flex flex-column">
      {!!headers.length &&
        headers.map((build, index) => (
          <div className="rounded px-2 border mb-1">
            <p className="mb-0">Build: {build}</p>
            <div key={index} className="rounded  d-flex config-wrapper">
              <div className="flex-grow-1 d-flex flex-column ">
                {Object.entries(configs[build])?.map(([header, devices], deviceIndex) => (
                  <div key={deviceIndex}>
                    {devices.length > 0 && (
                      <>
                        <div className="mb-2 bg-white rounded">
                          {devices.map((item, itemIndex) => (
                            <div key={itemIndex} className="d-flex mb-2 pb-1 flex-column gap-2">
                              <div className="config-container">
                                <div className="d-flex flex-column gap-1">
                                  <div className="fs-7">
                                    {item.name}{' '}
                                    {header !== '_' && <span className="text-secondary fs-8">: {header}</span>}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="d-flex flex-column align-items-end w-100">
                                  <div className="bg-light-400 h-100 w-100 rounded px-2 d-flex flex-wrap flex-grow-1 overflow-auto">
                                    <CohortParamsInfo disableHeader cohort={item} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ConfigList;
