import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Button, Dropdown, FormCheck, InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import SearchIcon from './Icons/SearchIcon';
export const CustomToggle = React.forwardRef(({ children, onClick, isInvalid }, ref) => (
  <div
    ref={ref}
    className={`form-select d-flex align-items-center cursor-pointer ${isInvalid && 'is-invalid'}`}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));
const SearchableDropdown = ({
  isInvalid,
  writeSelected,
  selectAll,
  placeholder,
  options,
  onSelect,
  onExclude,
  excluded,
  selected,
  disabledItems,
  multiple,
  collapseGroup,
  disableSearch,
  parClass,
}) => {
  const [search, setSearch] = useState('');
  const [collapse, setCollapse] = useState([]);
  const handleSelectAll = (exclude = false) => {
    let tmp = [];
    options.forEach((el) => {
      if (el.options) el.options.map((el) => tmp.push(el.value));
      else tmp.push(el.value);
    });
    if (exclude) {
      onSelect && onSelect([]);
      onExclude(tmp);
    } else {
      onExclude && onExclude([]);
      onSelect(tmp);
    }
    setSearch('');
  };
  const isAllSelected = () => {
    let count = 0;
    options.forEach((el) => {
      if (el.options) count += el.options.length;
      else count++;
    });
    return count === selected.length;
  };
  const toggleSelect = (el) => {
    let tmp = [...selected];
    if (selected.includes(el.value)) {
      tmp.splice(selected.indexOf(el.value), 1);
    } else {
      tmp.push(el.value);
    }
    onSelect(tmp);
    setSearch('');
  };
  const toggleExclude = (el) => {
    let tmp = [...excluded];
    if (excluded.includes(el.value)) {
      tmp.splice(excluded.indexOf(el.value), 1);
    } else {
      tmp.push(el.value);
    }
    onExclude(tmp);
    setSearch('');
  };
  const toggleGroup = (options) => {
    let tmp = [...selected];
    if (isGroupSelected(options)) {
      //remove
      options.map((el) => {
        if (tmp.includes(el.value)) {
          tmp.splice(tmp.indexOf(el.value), 1);
        }
        return el;
      });
    } else {
      //add all
      tmp = tmp.concat(options.map((el) => el.value));
      tmp = tmp.filter((value, index, array) => array.indexOf(value) === index); //filter unique
    }
    onSelect(tmp);
  };
  const isGroupSelected = (options) => {
    for (const opt of options) {
      if (!selected.includes(opt.value)) return false;
    }
    return true;
  };
  const renderItem = (el, isSub = false) => {
    let isDisabled = disabledItems ? disabledItems?.includes(el.value) : false;
    return (
      <Dropdown.Item
        key={el?.value}
        className={`${isSub ? 'ms-2   fs-7 ' : ''}py-0 d-inline-block text-truncate`}
        active={!multiple && selected.includes(el?.value)} // fix this
        disabled={isDisabled}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (!isDisabled) {
            toggleSelect(el);
          }
        }}
      >
        {(multiple || excluded) && (
          <div className="d-inline-flex">
            <Form.Check
              bsPrefix="form-check-input opacity-100"
              className="me-2  opacity-100"
              type="checkbox"
              disabled /// doesn't work without it. (ui not updating on change)
              checked={selected.includes(el?.value)}
              defaultChecked={selected.includes(el?.value)}
            />
            {excluded && (
              <div
                className=""
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (!isDisabled) {
                    toggleExclude(el);
                  }
                }}
              >
                <Form.Check
                  className="me-2  opacity-1 checkbox-red"
                  type="checkbox"
                  disabled
                  checked={excluded.includes(el.value)}
                  defaultChecked={excluded.includes(el.value)}
                />
              </div>
            )}
          </div>
        )}

        {el?.label}
      </Dropdown.Item>
    );
  };
  return (
    <Dropdown className={parClass}>
      <Dropdown.Toggle as={CustomToggle} isInvalid={isInvalid}>
        <div className={`d-flex overflow-hidden`}>
          <p className="m-0 text-truncate ">
            {selected?.length
              ? selected.length + ' Selected'
              : excluded?.length
                ? excluded.length + ' Excluded'
                : placeholder}
            {writeSelected && ': '}
            {writeSelected &&
              (!!selected.length || !!excluded?.length) &&
              (selected.length ? selected : excluded).join(', ')}
          </p>
        </div>

        {!!selected.length && (
          <Button
            variant="dark"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              if (onSelect) onSelect([]);
              if (onExclude) onExclude([]);
            }}
            className="fs-8 ms-1 py-0"
          >
            Clear
          </Button>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu align={'start'} style={{ maxHeight: '60vh', overflowY: 'scroll' }}>
        {!disableSearch && (
          <Dropdown.Header className="d-flex align-items-center">
            <InputGroup>
              <Form.Control
                type="text"
                className="form-control"
                aria-describedby=""
                placeholder="Search..."
                value={search}
                autoComplete={'off'}
                //isInvalid={errors?.bundleId}
                onChange={(text) => setSearch(text.target.value)}
              />
              <InputGroup.Text>
                <SearchIcon />
              </InputGroup.Text>
            </InputGroup>
          </Dropdown.Header>
        )}

        {excluded && (
          <div className="d-flex ms-3 text-gray-blue">
            <FormCheck className="me-2 " type="checkbox" bsPrefix="opacity-100 form-check-input" disabled checked />{' '}
            <FormCheck
              className="me-2   checkbox-red"
              bsPrefix="opacity-100 form-check-input "
              type="checkbox"
              disabled
              checked
            />
            Include or exclude items
          </div>
        )}
        {selectAll && (
          <Dropdown.Item
            className="d-flex position-relative py-0 d-inline-block text-truncate"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleSelectAll();
            }}
          >
            <div>
              <FormCheck
                className="me-2 "
                type="checkbox"
                bsPrefix="opacity-100 form-check-input"
                disabled
                checked={isAllSelected()}
              />
            </div>
            {excluded && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleSelectAll(true);
                }}
              >
                <FormCheck
                  className="me-2   checkbox-red"
                  bsPrefix="opacity-100 form-check-input "
                  type="checkbox"
                  disabled
                  checked={options.length === excluded.length}
                />
              </div>
            )}
            Select All
          </Dropdown.Item>
        )}
        {options
          ?.filter((el) => (search ? el.label.toLowerCase().includes(search) : true))
          .map((el) =>
            el?.options ? (
              <Dropdown.ItemText
                as={'div'}
                key={el?.label}
                className="position-relative p-0"
                active={!multiple && selected.includes(el?.value)} // fix this
                onClick={() => toggleSelect(el)}
              >
                <Dropdown.Item
                  className="d-inline-block text-truncate "
                  active={!multiple && selected.includes(el?.value)} // fix this
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    toggleGroup(el.options);
                  }}
                >
                  {multiple && (
                    <>
                      <Form.Check
                        className="me-2 d-inline-block"
                        type="checkbox"
                        disabled
                        checked={isGroupSelected(el.options)}
                      />
                    </>
                  )}

                  {el.label}
                </Dropdown.Item>
                {collapseGroup && (
                  <div className="position-absolute top-0 end-0 mt-1 me-1">
                    {collapse.includes(el.label) ? (
                      <Button
                        variant="warning"
                        className="px-1 py-0"
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          let tmp = [...collapse];
                          tmp.splice(tmp.indexOf(el.label), 1);
                          setCollapse(tmp);
                        }}
                      >
                        -
                      </Button>
                    ) : (
                      <Button
                        variant="secondary"
                        className="px-1 py-0"
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCollapse([...collapse, el.label]);
                        }}
                      >
                        +
                      </Button>
                    )}
                  </div>
                )}
                {(collapseGroup ? collapse.includes(el.label) : true) && el.options.map((el) => renderItem(el, true))}
              </Dropdown.ItemText>
            ) : (
              renderItem(el)
            ),
          )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
SearchableDropdown.propTyoes = {
  isInvalid: PropTypes.bool,
  writeSelected: PropTypes.bool,
  selectAll: PropTypes.bool,
  ukey: PropTypes.string,
  placeholder: PropTypes.string,
  parClass: PropTypes.string,
  options: PropTypes.array,
  onSelect: PropTypes.func,
  onExclude: PropTypes.func,
  selected: PropTypes.array,
  disabledItems: PropTypes.array,
  multiple: PropTypes.bool,
  disableSearch: PropTypes.bool,
  collapseGroup: PropTypes.bool,
};
SearchableDropdown.defaultProps = {
  writeSelected: false,
  selectAll: false,
  placeholder: 'Select from list',
  parClass: '',
  multiple: false,
  selected: [],
  disableSearch: false,
  collapseGroup: false,
};
export default SearchableDropdown;
