import React from 'react';

function CheckmarkIcon({ size, color, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 24}
      height={size || 24}
      fill={color || '#00D48A'}
      {...rest}
      viewBox="0 0 48 36"
    >
      <path d="M46.8913 1.6002C45.5247 0.233529 43.4747 0.233529 42.108 1.6002L16.483 27.2252L5.89134 16.6335C4.52467 15.2669 2.47467 15.2669 1.10801 16.6335C-0.258659 18.0002 -0.258659 20.0502 1.10801 21.4169L14.0913 34.4002C14.7747 35.0835 15.458 35.4252 16.483 35.4252C17.508 35.4252 18.1913 35.0835 18.8747 34.4002L46.8913 6.38353C48.258 5.01686 48.258 2.96686 46.8913 1.6002Z" />
    </svg>
  );
}

export default CheckmarkIcon;
