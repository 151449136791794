import React from 'react';
import { Link } from 'react-router-dom';

function DashboardCard({ title, subtitle, buttonTitle, link, component }) {
  return (
    <div className="rounded px-3 py-2 shadow bg-white h-100" style={{ maxHeight: '150px' }}>
      <div className="ms-2">
        <h3>{title}</h3>
        <p className="text-gray-text">{subtitle}</p>
      </div>
      <div className="d-flex justify-content-end me-2 pb-2">
        {link && (
          <Link className={`btn btn-primary `} style={{ minWidth: 180 }} to={link}>
            {buttonTitle}
          </Link>
        )}
        {component && component}
      </div>
    </div>
  );
}

export default DashboardCard;
