import { Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';
import AddIcon from '../../Components/Icons/AddIcon';
import { useEffect, useState } from 'react';
import { useGetFilesQuery } from '../../data/api/googleDriveApi';
import Loader from '../../Components/Loader';
import Form from 'react-bootstrap/Form';
import SearchIcon from '../../Components/Icons/SearchIcon';
import * as PropTypes from 'prop-types';
import HomeIcon from '../../Components/Icons/HomeIcon';
import DriveFileCard from './DriveFileCard';

const GoogleDriveModal = ({
  onSelect,
  defaultType,
  typeEdit,
  prevSelected,
  searchTitles,
  btnText,
  disabled,
  ...rest
}) => {
  const [search, setSearch] = useState('');
  const [type, setType] = useState(defaultType || '');
  const [dir, setDir] = useState('');
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (open && prevSelected) {
      setSelected(prevSelected);
    }
    if (open && defaultType) {
      setType(defaultType);
    }
  }, [open]);

  const { data, isFetching: isLoading, error } = useGetFilesQuery(dir, { skip: !open });
  const handleClose = () => {
    setOpen(false);
    setDir('');
    setSelected([]);
    setType('');
    setSearch('');
  };
  const handleClick = (file) => {
    if (file.mimeType === 'application/vnd.google-apps.folder') {
      setDir(file.id);
      setSearch('');
    } else {
      if (isSelected(file)) {
        let tmp = [...selected];
        tmp.splice(
          tmp.findIndex((el) => el.id === file.id),
          1,
        );
        setSelected(tmp);
      } else {
        setSelected([...selected, file]);
      }
    }
  };
  const filterType = (el) => {
    if (typeof type === 'string') {
      return el.includes(type) || el.includes('folder');
    } else {
      return type.map((t) => el.includes(t)).filter(Boolean).length || el.includes('folder');
    }
  };
  const isSelected = (file) => {
    return selected.find((el) => el.id === file.id);
  };
  const inSearchTitles = (file) => {
    return searchTitles ? searchTitles.includes(file.name) : false;
  };
  const handleSelect = () => {
    onSelect(selected);
    setOpen(false);
    setDir('');
    setSelected([]);
  };
  return (
    <>
      <Button
        disabled={disabled}
        type="button"
        variant="info"
        onClick={() => {
          setOpen(1);
        }}
      >
        <AddIcon size={20} className="me-2" /> {btnText}
      </Button>
      <Modal scrollable centered dialogClassName="modal-xl" show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Google Drive</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className="g-2">
            <Col className="d-flex" xs={12}>
              <Button
                variant={dir ? 'light' : 'outline-light'}
                className="d-flex align-items-center"
                disabled={!dir}
                onClick={() => setDir('')}
              >
                <HomeIcon size={18} />
              </Button>

              <InputGroup className="d-flex flex-grow-1 ms-3">
                <Form.Control
                  type="text"
                  aria-describedby=""
                  placeholder="Search..."
                  value={search}
                  autoComplete={'off'}
                  //isInvalid={errors?.bundleId}
                  onChange={(text) => {
                    setSearch(text.target.value);
                  }}
                />
                <InputGroup.Text>
                  <SearchIcon size={20} />
                </InputGroup.Text>
              </InputGroup>
            </Col>
            <Col xs={6} className="">
              {typeEdit && (
                <Form.Select
                  className="form-select"
                  value={type}
                  onChange={(event) => {
                    setType(event.target.value);
                  }}
                >
                  <option defaultValue value="">
                    All File Types
                  </option>
                  <option value="image">Image</option>
                  <option value="video">Video</option>
                </Form.Select>
              )}
            </Col>
            <Col xs={6} className="d-flex align-items-center justify-content-end ">
              {selected.length} selected
              {!!selected.length && (
                <Button variant="dark" size="sm" onClick={() => setSelected([])} className="fs-8 ms-2">
                  Clear
                </Button>
              )}
            </Col>
            {isLoading ? (
              <div className="d-flex justify-content-center p-3">
                <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
              </div>
            ) : data?.files?.length ? (
              <>
                {data?.files
                  ?.filter((el) => (type ? filterType(el.mimeType) : true))
                  ?.filter((el) => (search ? el.name.toLowerCase().includes(search) : true))
                  ?.map((el) => (
                    <Col key={el.id} xs={3} onClick={() => handleClick(el)}>
                      <DriveFileCard
                        file={el}
                        border={isSelected(el) ? 'border-primary' : inSearchTitles(el) ? 'border-success' : ''}
                      />
                    </Col>
                  ))}
              </>
            ) : (
              <Col xs={12}>Empty directory</Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-block">
          {error && !isLoading && (
            <div className="alert alert-danger p-2 mb-2 fs-7 " role="alert">
              {error?.data?.message || error?.error || 'Something went wrong.'}
            </div>
          )}
          <div className="d-flex">
            <div className="ms-auto">
              <Button type="button" variant="outline-light" className=" ms-auto" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="button" variant="primary" className=" ms-3" onClick={handleSelect}>
                Select
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
GoogleDriveModal.propTypes = {
  onSelect: PropTypes.func,
  defaultType: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  btnText: PropTypes.string,
  disabled: PropTypes.bool,
  searchTitles: PropTypes.array,
};
GoogleDriveModal.defaultProps = {
  btnText: 'Google Drive',
  disabled: false,
  typeEdit: true,
};
export default GoogleDriveModal;
