/** Import Externals */
import React, { useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Brush, ResponsiveContainer } from 'recharts';

/** Import Utils */
import { useSetState } from '../../../../../utils/customHooks';
import CustomTooltip from './ChartCustomTooltip';

function ChartLevels(props) {
  const [state, setState] = useSetState({
    loading: false,
    arrayToDisplay: [],
    height: 250,
  });

  useEffect(() => {
    if (props.data) {
      let returnArray = [];
      props.data.map((e) => {
        let actualArray = [];
        let i = 2;
        while (i < e.length) {
          actualArray.push({ level: e[i], percent: e[i + 1] });
          i = i + 2;
        }
        //splice array to 30 values
        let res = actualArray;
        if (actualArray.length > 30) {
          res = actualArray.slice(0, 30);
        }
        return returnArray.push(res);
      });
      setState({
        arrayToDisplay: returnArray,
      });
    }
  }, [props]);

  const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return (
      <text x={x + width / 2} y={y} fill="#666" fontSize={10} textAnchor="middle" dy={-6}>
        {`${value}`}
      </text>
    );
  };

  const renderLineChart = (data, index) => {
    return data.length ? (
      <div key={'level' + index} className="bg-white rounded my-3 p-2">
        <ResponsiveContainer height={state.height}>
          <BarChart
            className="chart-level-chart"
            data={data.map((el) => ({ ...el, title: el.level }))}
            margin={{
              top: 30,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="level" />
            <YAxis domain={[0, 100]} />
            <Tooltip content={<CustomTooltip />} />
            <Brush dataKey="level" height={24} stroke="#8884d8" style={{ margin: 10 }} />
            <Bar dataKey="percent" fill="#3F96C7" label={renderCustomBarLabel} />
          </BarChart>
        </ResponsiveContainer>
        <p className="chart-level-text">{props.cohorts[index]}</p>
      </div>
    ) : null;
  };

  return (
    <div className="chart-level-container">
      <p className="chart-level-title">Churn rate (% of users per level)</p>
      {state.arrayToDisplay.map((e, index) => {
        return renderLineChart(e, index);
      })}
    </div>
  );
}

export default ChartLevels;
