import React, { useState } from 'react';
import { Col, Form, FormControl, InputGroup } from 'react-bootstrap';
import { useListStudiosQuery } from '../../../data/api/studioApi';
import EyeSlashIcon from '../../../Components/Icons/EyeSlashIcon';
import EyeVisibleIcon from '../../../Components/Icons/EyeVisibleIcon';
import { userRoles } from '../../../constants/permissions';
import SearchableDropdown from '../../../Components/SearchableDropdown';

function AccountForm({ state, errors, handleTextChange, setState, data, disabledStudios }) {
  const { data: studioList } = useListStudiosQuery();
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  let allOptions = studioList.map((studio) => ({ value: studio.studioId, label: studio.name }));
  if (disabledStudios) allOptions = allOptions.concat(disabledStudios);

  return (
    <>
      {errors && errors.message && (
        <div className="alert alert-danger mb-3 d-flex align-items-center fs-7" role="alert">
          <div>
            <div>{errors.message}</div>
          </div>
        </div>
      )}
      <Form.Group className="position-relative" as={Col} xs={12}>
        <Form.Label className="fs-7">
          Email <span className="text-danger fs-7">*</span>
        </Form.Label>
        <FormControl
          type="email"
          id="email"
          className="form-control"
          aria-describedby="Email"
          placeholder="Email"
          value={state.email}
          disabled={data?.id}
          onChange={(text) => handleTextChange(text.target.value, 'email')}
          isInvalid={!!errors.email}
          name="email"
        />
        <Form.Control.Feedback tooltip type="invalid">
          {errors.email}
        </Form.Control.Feedback>
      </Form.Group>

      {data?.id ? (
        <Form.Group className="position-relative" as={Col} xs={12}>
          <Form.Label className="fs-7">
            Status <span className="text-danger fs-7">*</span>
          </Form.Label>
          <Form.Select
            className="form-select"
            value={state.status}
            onChange={(e) => handleTextChange(e.target.value, 'status')}
            name="status"
          >
            <option value={1}>Active</option>
            <option value={0}>Inactive</option>
          </Form.Select>
          <Form.Control.Feedback tooltip type="invalid">
            {errors.status}
          </Form.Control.Feedback>
        </Form.Group>
      ) : (
        <Form.Group className="position-relative" as={Col} xs={12}>
          <Form.Label className="fs-7">
            Password <span className="text-danger fs-7">*</span>
          </Form.Label>
          <InputGroup hasValidation>
            <FormControl
              type={showPassword ? 'text' : 'password'}
              className="fs-6"
              size="lg"
              aria-describedby="Password"
              placeholder="Type password"
              value={state.password}
              onChange={(text) => handleTextChange(text.target.value, 'password')}
              isInvalid={!!errors.password}
              name="password"
            />
            <InputGroup.Text className="cursor-pointer">
              <div className="cursor-pointer d-flex align-items-center" onClick={toggleShowPassword}>
                {showPassword ? (
                  <EyeSlashIcon color="#829ABA" size={20} />
                ) : (
                  <EyeVisibleIcon color="#829ABA" size={20} />
                )}
              </div>
            </InputGroup.Text>
            <Form.Control.Feedback tooltip type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      )}

      <div className="d-flex gap-1">
        <Form.Group className="position-relative" as={Col} xs={6}>
          <Form.Label className="fs-7">
            First Name <span className="text-danger fs-7">*</span>
          </Form.Label>
          <FormControl
            required
            type="text"
            id="firstName"
            className="form-control"
            aria-describedby="First Name"
            placeholder="First Name"
            value={state.firstName}
            onChange={(text) => handleTextChange(text.target.value, 'firstName')}
            isInvalid={!!errors.firstName}
            name="name"
          />
          <Form.Control.Feedback tooltip type="invalid">
            {errors.firstName}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="position-relative" as={Col} xs={6}>
          <Form.Label className="fs-7">
            Last Name <span className="text-danger fs-7">*</span>
          </Form.Label>
          <FormControl
            required
            type="text"
            id="lastName"
            className="form-control"
            aria-describedby="Last Name"
            placeholder="Last Name"
            value={state.lastName}
            onChange={(text) => handleTextChange(text.target.value, 'lastName')}
            isInvalid={!!errors.lastName}
            name="last-name"
          />
          <Form.Control.Feedback tooltip type="invalid">
            {errors.lastName}
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      <Form.Group className="position-relative" as={Col} xs={12}>
        <Form.Label className="fs-7">
          Role <span className="text-danger fs-7">*</span>
        </Form.Label>
        <Form.Select
          className="form-select"
          value={state.role}
          onChange={(e) => handleTextChange(e.target.value, 'role')}
          isInvalid={!!errors.role}
          name="role"
        >
          {userRoles.map((role) => (
            <option value={role.value} key={role.value}>
              {role.label}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback tooltip type="invalid">
          {errors.role}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="position-relative" as={Col} xs={12} id="studio">
        <Form.Label className="fs-7">
          Studio <span className="text-danger fs-7">*</span>
        </Form.Label>
        <SearchableDropdown
          placeholder="Select Studio"
          isInvalid={errors.studioIds}
          parClass={errors.studioIds && 'is-invalid'}
          multiple
          collapseGroup
          onSelect={(data) => setState({ studioIds: data })}
          selected={state.studioIds}
          disabledItems={disabledStudios?.map((studioId) => studioId.value)}
          options={allOptions}
        />
        <Form.Control.Feedback tooltip type="invalid">
          {errors.studioIds}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
}

export default AccountForm;
