import { useState } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useSetState } from '../../../utils/customHooks';
import { useLoginMutation } from '../../../data/api/userApi';
import { R_FORGOT_PASSWORD, R_HOME, R_SIGN_UP } from '../../../constants/routes';
import { Button, InputGroup } from 'react-bootstrap';
import InfoIcon from '../../../Components/Icons/InfoIcon';
import EyeVisibleIcon from '../../../Components/Icons/EyeVisibleIcon';
import EyeSlashIcon from '../../../Components/Icons/EyeSlashIcon';

function LoginForm() {
  let history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [login, { isLoading }] = useLoginMutation();
  const [state, setState] = useSetState({
    email: '',
    password: '',
  });
  const handleTextChange = (text, field) => {
    return setState({ [field]: text });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (!state.email || !state.password) return false;
    login({
      login: state.email,
      password: state.password,
    })
      .unwrap()
      .then((response) => {
        if (response && Object.keys(response).length) {
          history.push(history?.location?.state?.redirectUrl || generatePath(R_HOME, { filter: null }));
        } else {
          setState({
            error: 'Something went wrong !',
          });
        }
      })
      .catch((e) => {
        setState({
          error: e.data.message || 'Something went wrong!',
        });
      });
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Form onSubmit={handleLogin}>
      {state.error && (
        <div className="alert alert-danger mb-3 d-flex align-items-center fs-7" role="alert">
          <div className=" me-3">
            <InfoIcon />
          </div>
          <div>{state.error}</div>
        </div>
      )}
      <Form.Group className=" mb-4" controlId="email">
        <Form.Label className="font fw-bold text-black">Email Address</Form.Label>
        <Form.Control
          size="lg"
          type="email"
          className="form-control fs-6 "
          aria-describedby="Email"
          placeholder="name@email.com"
          disabled={isLoading}
          value={state.email}
          onChange={(text) => handleTextChange(text.target.value, 'email')}
        />
      </Form.Group>

      <Form.Group className="" controlId="password">
        <Form.Label className="fw-bold text-black">Password</Form.Label>
        <InputGroup>
          <Form.Control
            type={showPassword ? 'text' : 'password'}
            size="lg"
            className="form-control fs-6"
            aria-describedby="Password"
            placeholder="Enter Password"
            disabled={isLoading}
            value={state.password}
            onChange={(text) => handleTextChange(text.target.value, 'password')}
          />
          <InputGroup.Text className="cursor-pointer login-input">
            <div className="cursor-pointer d-flex align-items-center" onClick={toggleShowPassword}>
              {showPassword ? <EyeSlashIcon color="#829ABA" /> : <EyeVisibleIcon color="#829ABA" />}
            </div>
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <div className="d-flex my-2">
        <Link to={R_FORGOT_PASSWORD} className="ms-auto fs-7 text-gray-blue text-decoration-none">
          Forgot password?
        </Link>
      </div>

      <Button className="w-100 fs-6" size="lg" variant="primary" type="submit" disabled={isLoading}>
        Login
      </Button>
      <div className="d-flex my-2 fs-7 text-gray-blue">
        Don't have an account?
        <Link to={R_SIGN_UP} className="ms-2 text-decoration-none">
          Sign Up
        </Link>
      </div>
    </Form>
  );
}

export default LoginForm;
