import moment from 'moment';
import { useEffect } from 'react';

const PdfList = ({ pdfs, selected, onSelect }) => {
  useEffect(() => {
    if (!selected && pdfs.length) {
      onSelect(pdfs[0]);
    }
  }, []);
  return (
    <div className="pdf-list">
      {pdfs.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => onSelect(item)}
            className={`${selected?.id === item.id ? 'active' : ''} pdf-list-item`}
          >
            <div className="item-title fw-bold text-white fs-5">{item.name}</div>
            <p className="position-absolute bottom-0 left-0 m-2 text-white fs-7 fw-bold ">
              {moment.utc(item.date).format('ll')}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default PdfList;
