import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import TestInfoModalDetails from './TestInfoModalDetails';
import CohortParamsInfo from './CohortParamsInfo';
import AbTestIcon from '../../../Components/Icons/AbTestIcon';
import Loader from '../../../Components/Loader';
import UpdateCohortParamsModal from '../UpdateAbTestModals/UpdateParamsModal';
import moment from 'moment';
import { Restricted, useUserPermission } from '../../../Components/permissions/UserPermissions';
import { useSelector } from 'react-redux';
import { getGameById } from '../../../data/slices/studio';
import { confirmAlert } from '../../../Components/ConfirmModal';
import { useGetCohortsQuery, useDisableCohortMutation } from '../../../data/api/abTestsApi';

const TestInfoModal = ({ onClose, data, id }) => {
  const { data: cohorts, isFetching, error } = useGetCohortsQuery(data.id);
  const [disableCohort, { isLoading: isStatusUpdating }] = useDisableCohortMutation();
  const foundGame = useSelector(getGameById(id));

  const elementEditByDeviceIdPermission = useUserPermission('abTest.update.withDeviceId');
  const elementEditPermission = useUserPermission('abTest.update');

  let editable =
    data?.status !== 'CANCELED' &&
    foundGame?.status !== 'ARCHIVED' &&
    !moment().utc(false).isAfter(data?.endTs) &&
    ((!!data?.deviceId && elementEditByDeviceIdPermission) || elementEditPermission);

  const handleDisableClick = (cohort) => {
    confirmAlert({
      title: `Are you sure you want to ${cohort.active === 0 ? 'Enable' : 'Disable'} this item?`,
      variant: 'warning',
      cancelText: 'Cancel',
      confirmText: `${cohort.active === 0 ? 'Enable' : 'Disable'}`,
      confirmBtn: true,
      cancelBtn: true,
    })
      .then(() => {
        disableCohort({
          testId: data.id,
          bundleId: data.bundleId,
          id: cohort.id,
          active: cohort.active === 1 ? 0 : 1,
        })
          .then(() => {
            console.log('Cohort change successfully');
          })
          .catch((error) => {
            console.error('Error disabling cohort:', error);
          });
      })
      .catch(() => {
        console.log('User cancelled');
      });
  };

  return (
    <Modal centered size="lg" show onHide={onClose}>
      <Modal.Header closeButton onClick={(e) => e.stopPropagation()}>
        <Modal.Title className="d-inline-block text-truncate">{data.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-1 overflow-scroll">
        <div className="d-flex">
          <TestInfoModalDetails abTest={data} editable={editable} />
          {isFetching ? (
            <div className="d-flex flex-grow-1 justify-content-center align-items-center">
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : error ? (
            <div className="alert alert-danger fs-7 align-self-start ">{error.message || 'Something went Wrong'}</div>
          ) : (
            <div className="w-100 d-flex flex-column gap-2 me-2 my-2">
              {cohorts &&
                cohorts.length &&
                cohorts.map((cohort, index) => (
                  <div key={index}>
                    <div className="border rounded">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-between align-items-center p-2">
                          <div className="d-flex align-items-center">
                            <AbTestIcon color="#4D6F9D" />
                            <div className="ms-2 ">
                              <div>{cohort.name}</div>
                              <div className="text-secondary fs-8">{cohort.id}</div>
                            </div>
                          </div>
                        </div>
                        <div className="me-2 d-flex gap-1">
                          {editable && cohort.name !== 'BaseCohort' && (
                            <UpdateCohortParamsModal
                              editable={editable}
                              abTest={{ ...cohort, testId: data.id, bundleId: data.bundleId, deviceId: data.deviceId }}
                            />
                          )}
                          {editable && cohort.name !== 'BaseCohort' && (
                            <Restricted permission="test.disable">
                              {isStatusUpdating ? (
                                <div className="d-flex justify-content-center">
                                  <Loader parentStyle="loader" size={28} color={'#3F96C7'} />
                                </div>
                              ) : (
                                <Button
                                  variant={cohort.active === 0 ? 'success' : 'danger'}
                                  size="sm"
                                  onClick={() => handleDisableClick(cohort)}
                                >
                                  {cohort.active === 0 ? 'Enable' : 'Disable'}
                                </Button>
                              )}
                            </Restricted>
                          )}
                        </div>
                      </div>
                      {cohort.name !== 'BaseCohort' && (
                        <div>
                          <div className="bg-light-400 h-100 rounded-bottom w-100 px-2 py-1 d-flex flex-wrap flex-grow-1 overflow-auto ">
                            <CohortParamsInfo cohort={cohort} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TestInfoModal;
