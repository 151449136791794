import { Badge, Dropdown } from 'react-bootstrap';
import AccountProfileIcon from '../../Components/Icons/AccountProfileIcon';
import { Link, useHistory } from 'react-router-dom';
import { R_ACCOUNT, R_LOGIN } from '../../constants/routes';
import Logout from '../../Components/Icons/Logout';
import EditIcon from '../../Components/Icons/EditIcon';
import { delCookie } from '../../utils/common';
import { setUser } from '../../data/slices/user';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../data/slices/user';
import { studioApi } from '../../data/api/studioApi';
import { abTestsApi } from '../../data/api/abTestsApi';

const UserAccount = () => {
  const user = useSelector(userSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLogOut = () => {
    delCookie('user-id');
    dispatch(setUser({}));
    dispatch(studioApi.util.resetApiState());
    dispatch(abTestsApi.util.resetApiState());
    history.push(R_LOGIN);
  };
  return (
    <Dropdown className="no-arrow border rounded user-dropdown color-button p-0" drop="up">
      <Dropdown.Toggle as={'div'}>
        <div
          className="d-flex rounded align-items-center cursor-pointer overflow-hidden gap-1 pt-1 pb-1 pe-2"
          style={{ paddingLeft: '8px' }}
        >
          <AccountProfileIcon size={28} color="#a4a4a5" />
          <div className="text-truncate color-text overflow-hidden user-dropdown-tittle p-0">
            <div className="text-truncate">
              {user.firstName} {user.lastName}
            </div>
            <div className="fs-7 text-secondary text-truncate">{user.email}</div>
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu rootCloseEvent="mousedown" popperConfig={{}}>
        <Dropdown.Item className="" as={Link} to={R_ACCOUNT}>
          <Badge bg="info">
            <EditIcon size="17" color="#fff" />
          </Badge>
          <span className="ms-2"> Edit Account</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={handleLogOut} className="">
          <Badge bg="danger">
            <Logout size="17" color="#fff" />
          </Badge>
          <span className="ms-2">Logout</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserAccount;
