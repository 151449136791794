import Graph from './components/Graph';
import GraphDoubleAxis from './components/GraphDoubleAxis';
import DetailsGraph from './components/DetailGraph';
import { useGetGameMetricsQuery } from '../../../../data/api/studioApi';
import Loader from '../../../../Components/Loader';

export default Metrics;

function Metrics({ game }) {
  const { data: infosGame, isFetching: isLoadingGames } = useGetGameMetricsQuery(game?.id, { skip: !game?.id });

  const avg = infosGame?.avg || {};

  const avPlaytimeMin = Math.floor(parseFloat(avg.playtime) / 60) || 0;
  const avPlaytimeSec = Math.floor(parseFloat(avg.playtime) - avPlaytimeMin * 60) || '00';
  const formattedMetrics =
    infosGame?.metrics?.map((item) => ({
      ...item,
      CPI: item?.CPI !== null ? parseFloat(item.CPI.toFixed(2)) : null,
    })) || [];

  return isLoadingGames ? (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
      <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
    </div>
  ) : infosGame ? (
    <div className="row g-4">
      <div className="col-xl-4 col-lg-6 col-12">
        <div className="bg-white rounded p-2 h-100">
          <div className="d-flex mb-4 px-2 py-1">
            <h6 className="fs-7">Global CPI</h6>
            <div className="ms-auto fs-7">
              <span className="text-muted">Average CPM:</span> {(parseFloat(avg.cpm) || 0).toFixed(2)}$
            </div>
          </div>
          <Graph metrics={formattedMetrics} dataKey={'CPI'} stroke={'#8884d8'} />
        </div>
      </div>
      <div className="col-xl-4 col-lg-6 col-12">
        <div className="bg-white rounded p-2 h-100">
          <div className="d-flex mb-4  px-2 py-1 ">
            <h6 className="fs-7">D1 retention / Installs</h6>
            <div className="ms-auto fs-7">
              <span className="text-muted">Average Install / Day:</span> {(parseFloat(avg.installs) || 0).toFixed(2)}
            </div>
          </div>

          <GraphDoubleAxis metrics={formattedMetrics} dataKey={'retD1'} dataKey2={'installs'} />
        </div>
      </div>
      <div className="col-xl-4 col-lg-4 col-sm-12">
        <div className="bg-white rounded p-2 h-100">
          <div className="d-flex mb-4 px-2 py-1">
            <h6 className="fs-7">Global D7 retention</h6>
            <div className="ms-auto fs-7">
              <span className="text-muted">Average Playtime Duration:</span> {avPlaytimeMin}m{avPlaytimeSec}s
            </div>
          </div>
          <Graph metrics={formattedMetrics} dataKey={'retD7'} stroke={'#8884d8'} />
        </div>
      </div>
      <div className="col-xl-12 col-lg-8 col-12">
        <div className="bg-white rounded px-4 py-3">
          <DetailsGraph avg={avg} metrics={formattedMetrics} stroke={'#8884d8'} />
        </div>
      </div>
    </div>
  ) : (
    <div>Game not available on this platform</div>
  );
}
