import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSetState } from '../../../utils/customHooks';
import GameListTable from '../../Game/GameList/GamesListTable/GameListTable';
import Pagination from '../../../Components/Pagination';
import { setStudioSelected } from '../../../data/slices/user';
import { useDispatch } from 'react-redux';

function AppsModal({ onHide }) {
  const [state, setState] = useSetState({
    sortedGames: [],
    error: '',
    postsPerPage: 15,
    total: 0,
    page: 1,
  });
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(setStudioSelected(''));
    onHide();
  };

  return (
    <Modal centered size={'xl'} show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Apps</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-hidden overflow-x-scroll">
        <table className="table table-hover align-middle rounded bg-white tap-n-table">
          <tbody>
            <GameListTable data={state} setData={setState} showArchived />
            <Pagination
              pushHistory={false}
              onChange={(page) => setState({ page })}
              total={state.total}
              perPage={state.postsPerPage}
              page={state.page}
            />
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
}

export default AppsModal;
