import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { hmac256 } from '../../utils/common';
const baseUrl = process.env.REACT_APP_ANALYTICS_API;

export const analyticsApi = createApi({
  reducerPath: 'analyticsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['platforms', 'overviewReport'],
  keepUnusedDataFor: 600, // keep for 10min
  endpoints: (builder) => ({
    getAvailablePlatforms: builder.query({
      query: () => ({
        url: '/platforms',
        headers: {
          Authorization: hmac256(baseUrl + '/platforms'),
        },
      }),
    }),
    getAvailableApps: builder.query({
      query: () => ({
        url: '/apps',
        headers: {
          Authorization: hmac256(baseUrl + '/apps'),
        },
      }),
      transformResponse: (response) => {
        const groupedGames = [];
        const bundleIds = {};
        let index = 0;
        response.forEach((game) => {
          if (!game.bundle_id) return;
          if (bundleIds[game.bundle_id.toLowerCase()] > -1) {
            groupedGames[bundleIds[game.bundle_id.toLowerCase()]].platform.push(game.platform);
          } else {
            groupedGames.push({
              ...game,
              platform: [game.platform],
            });
            bundleIds[game.bundle_id.toLowerCase()] = index;
            index++;
          }
        });
        return groupedGames;
      },
    }),
    getMediationAdTypes: builder.query({
      query: () => ({
        url: '/mediation/adtypes',
        headers: {
          Authorization: hmac256(baseUrl + '/mediation/adtypes'),
        },
      }),
    }),
    getMediationNetworks: builder.query({
      query: ({ from, to }) => ({
        url: `/mediation/networks/${from}/${to}`,
        headers: {
          Authorization: hmac256(baseUrl + `/mediation/networks/${from}/${to}`),
        },
      }),
    }),
    getMediationReport: builder.query({
      query: (data) => {
        return {
          url: '/mediation/report',
          method: 'post',
          body: data,
          headers: {
            Authorization: hmac256(data),
          },
        };
      },
    }),
    getMediationHashReport: builder.query({
      query: (hash) => ({
        url: '/mediation/report/' + hash,
        method: 'get',
        headers: {
          Authorization: hmac256(baseUrl + '/mediation/report/' + hash),
        },
      }),
    }),
    getOverviewReport: builder.query({
      query: (args) => ({
        url: `/overview/report`,
        method: 'POST',
        body: args,
        headers: {
          Authorization: hmac256(args),
        },
      }),
      providesTags: ['overviewReport'],
    }),
    getOverviewHashReport: builder.query({
      query: (hash) => ({
        url: '/overview/report/' + hash,
        method: 'get',
        headers: {
          Authorization: hmac256(baseUrl + '/overview/report/' + hash),
        },
      }),
    }),
    getAcquisitionNetworks: builder.query({
      query: ({ from, to }) => ({
        url: `/acquisition/networks/${from}/${to}`,
        headers: {
          Authorization: hmac256(baseUrl + `/acquisition/networks/${from}/${to}`),
        },
      }),
    }),
    getAcquisitionReport: builder.query({
      query: (data) => ({
        url: '/acquisition/report',
        method: 'post',
        body: data,
        headers: {
          Authorization: hmac256(data),
        },
      }),
    }),
    getAcquisitionHashReport: builder.query({
      query: (hash) => ({
        url: '/acquisition/report/' + hash,
        method: 'get',
        headers: {
          Authorization: hmac256(baseUrl + '/acquisition/report/' + hash),
        },
      }),
    }),
  }),
});

export const {
  useGetAvailablePlatformsQuery,
  useGetAvailableAppsQuery,
  useGetMediationAdTypesQuery,
  useGetMediationReportQuery,
  useGetMediationHashReportQuery,
  useGetMediationNetworksQuery,
  useGetAcquisitionNetworksQuery,
  useGetAcquisitionReportQuery,
  useGetAcquisitionHashReportQuery,
  useGetOverviewReportQuery,
  useGetOverviewHashReportQuery,
} = analyticsApi;
