import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { hmac256 } from '../../utils/common';
const baseUrl = process.env.REACT_APP_GOOGLE_DRIVE_API;

export const googleDriveApi = createApi({
  reducerPath: 'googleDriveApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  tagTypes: [],
  keepUnusedDataFor: 600, // keep for 10min
  endpoints: (builder) => ({
    getFiles: builder.query({
      query: (dir) => {
        let url = '/files';
        if (dir) url += '?dir=' + dir;
        return {
          url: url,
          headers: { Authorization: hmac256(baseUrl + url) },
        };
      },
    }),
  }),
});

export const { useGetFilesQuery } = googleDriveApi;
