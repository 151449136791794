import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import Pagination from '../../../Components/Pagination';
import MetricsTable from '../_Components/MetricsTable';
import { capitalizeFirstLetter } from '../../../utils/converters';
import MaxLimitWarning from '../_Components/MaxLimitWarning';
import OverviewCharts from './AnalyticsChart';
import { countries } from '../../../constants/global';

const OverviewReport = ({ report: reportData = {}, trigger: triggerData, filter, setFilter }) => {
  const perPage = 25;
  const history = useHistory();

  const [totals, setTotals] = useState({});
  const [formattedReport, setFormattedReport] = useState([]);
  const [headers, setHeaders] = useState([]);

  const report = reportData?.data || [];
  let hash = reportData?.hash || '';
  let withFilters = reportData?.filters;
  let trigger = triggerData || withFilters;

  useEffect(() => {
    if (Array.isArray(report) && report.length > 0) {
      if (withFilters) {
        let tmp = {};
        if (withFilters.platform)
          tmp[withFilters.platform?.neg ? 'exPlatform' : 'platform'] = withFilters.platform.list;
        if (withFilters.country) tmp[withFilters.country?.neg ? 'exCountry' : 'country'] = withFilters.country.list;
        if (withFilters.application) tmp[withFilters.application?.neg ? 'exApp' : 'app'] = withFilters.application.list;
        if (withFilters.totals) tmp['advanced'] = withFilters.totals;
        if (withFilters.from) tmp['from'] = withFilters.from;
        if (withFilters.to) tmp['to'] = withFilters.to;
        if (withFilters.dimensions) tmp['dimension'] = withFilters.dimensions.list;

        tmp['page'] = 1;
        setFilter({ ...filter, ...tmp });
      }
      history.push(history.location.pathname + '?hash=' + hash);

      let totals = { revenue: 0, spend: 0, margin: 0 };
      let formatted = [];
      let headers = [
        ...(trigger?.dimensions?.list?.map((el) => ({ label: capitalizeFirstLetter(el), key: el })) || []),
        { label: 'Revenue', key: 'revenue' },
        { label: 'Spend', key: 'spend' },
        { label: 'Margin', key: 'margin' },
      ];

      report.forEach((data) => {
        totals.revenue += data.revenue;
        totals.spend += data.spend;
        totals.margin += data.margin;

        formatted.push({
          ...data,
          country_name: data.country
            ? countries.find((c) => c.code === data['country'])?.name || data['country']
            : null,
          revenue: data.revenue ? '$' + parseFloat(Number(data.revenue).toFixed(2)).toLocaleString('en') : '-',
          spend: data.spend ? '$' + parseFloat(Number(data.spend).toFixed(2)).toLocaleString('en') : '-',
          margin: data.margin ? '$' + parseFloat(Number(data.margin).toFixed(2)).toLocaleString('en') : '-',
        });
      });

      setFormattedReport(formatted);
      setHeaders(headers);
      setTotals({
        revenue: totals.revenue ? '$' + parseFloat(Number(totals.revenue).toFixed(2)).toLocaleString('en') : '-',
        spend: totals.spend ? '$' + parseFloat(Number(totals.spend).toFixed(2)).toLocaleString('en') : '-',
        margin: totals.margin ? '$' + parseFloat(Number(totals.margin).toFixed(2)).toLocaleString('en') : '-',
      });
    } else {
      console.warn('No data available in report');
    }
  }, [report]);

  return formattedReport.length ? (
    <div>
      <div className="mb-3">
        {report.length + 2 > 20000 ? (
          <MaxLimitWarning
            onClick={() => {
              setFilter({ advanced: true, dimension: filter.dimension.slice(0.2) });
            }}
          />
        ) : (
          <OverviewCharts data={report} xDataKeys={trigger?.dimensions?.list} />
        )}
        <div className="d-flex mb-2">
          <CSVLink
            filename={`Overview_${trigger?.from}_${trigger?.to}.csv`}
            data={[
              ...formattedReport,
              {
                [headers?.[0]?.['key']]: 'Total:',
                revenue: totals.revenue,
                spend: totals.spend,
                margin: totals.margin,
              },
            ]}
            headers={headers}
          >
            <div className="btn btn-sm bg-white">Download CSV</div>
          </CSVLink>
          <div className="ms-auto d-flex align-items-center position-relative z-0">
            {formattedReport?.length > perPage && (
              <Pagination
                scrollTop={false}
                pushHistory={false}
                onChange={(page) => {
                  setFilter({ page });
                }}
                total={report?.length || 0}
                perPage={perPage}
                page={filter.page}
              />
            )}
          </div>
        </div>
        <MetricsTable
          totals={totals}
          headers={headers}
          report={formattedReport.slice((filter.page - 1) * perPage, filter.page * perPage)}
        />
      </div>
    </div>
  ) : null;
};

export default OverviewReport;
