import React, { useEffect } from 'react';
import { MONTHS } from '../../../constants/global';
/** Import Utils */
import { useSelector } from 'react-redux';
import { studioSelectedSelector } from '../../../data/slices/user';
import { abTestsApi } from '../../../data/api/abTestsApi';
import { useQuery } from '../../../utils/customHooks';
import TnTable from '../../../Components/Table/TnTable';
import TapNationPng from '../../../assets/logoTapNation/LogoTapNation40.png';
import TapNationLogo from '../../../assets/logoTapNation/LogoTapNation40.png';
import moment from 'moment/moment';
import { generatePath, useHistory } from 'react-router-dom';
import { R_AB_TEST } from '../../../constants/routes';
import { studioApi } from '../../../data/api/studioApi';
import ArchiveIcon from '../../../Components/Icons/ArchiveIcon';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import GameOsIcons from '../_Components/GameOsIcons';
import { useUserPermission } from '../../../Components/permissions/UserPermissions';

function AbTestGameListTable({ data, setData }) {
  const studioSelected = useSelector(studioSelectedSelector);
  let history = useHistory();
  const query = useQuery();
  const currentPage = Number(query.get('page')) || 1;
  const sort = query.get('sort') || 'dateDown';
  const { data: studioList } = studioApi.endpoints.listStudios.useQueryState();
  const { data: games } = abTestsApi.endpoints.getAbTestGames.useQueryState();
  const studioPermission = useUserPermission('select.studio');
  const handleSeeAbTests = (el) => {
    history.push(
      generatePath(R_AB_TEST, {
        id: el.id,
      }),
    );
  };
  useEffect(() => {
    if (games.length) {
      let parsedArray = games;

      const studioObject = studioList?.reduce((old, curr) => {
        old[curr.studioId] = curr;
        return old;
      }, {});

      if (Object.keys(studioSelected).length) {
        parsedArray = parsedArray.filter((el) => {
          return el.studioId === studioSelected.studioId;
        });
      }

      let searchGameFilter = parsedArray.filter((game) => {
        let filters = {
          appName: query.get('search') || '',
          bizdevEmail: query.get('email') || '',
          category: query.get('category') || '',
          pendingTests: query.get('status') || '',
          tags: query.get('tags') || '',
          year: query.get('year') || '',
          month: query.get('month') || '',
          os: query.get('os') || '',
        };
        return Object.keys(filters).every((key) => {
          if (!filters[key]) return true;
          if (key === 'pendingTests') {
            return game.pendingTests > 0 || game.pendingConfigs > 0;
          } else if (key === 'year') {
            return new Date(game.createdAt).getFullYear().toString() === filters.year ? true : false;
          } else if (key === 'month') {
            return MONTHS[new Date(game.createdAt).getMonth()] === filters.month ? true : false;
          } else if (key === 'appName' && game['appName']?.toLowerCase().includes(filters[key].toLowerCase())) {
            return true;
          } else if (game[key]?.includes(filters[key])) {
            return true;
          } else {
            return false;
          }
        });
      });
      const currentPost = searchGameFilter.map((el) => {
        const foundGame = games.find((g) => el.bundleId === g.bundleId);
        return {
          ...el,
          game: (
            <div className="d-flex align-items-center">
              <div
                className="overflow-hidden text-nowrap me-3 flex-shrink-0 position-relative"
                id="ab-test-game"
                style={{ width: 56 }}
              >
                {foundGame?.status === 'ARCHIVED' && (
                  <div className="position-absolute top-0 start-0 w-100 h-100  d-flex align-items-center justify-content-center">
                    <div className="position-absolute z-0 top-0 start-0 w-100 h-100 bg-dark-300 opacity-50 rounded "></div>
                    <ArchiveIcon className={'z-2'} color="#fff" />
                  </div>
                )}
                <img
                  width={56}
                  height={56}
                  className="rounded img-fit-contain"
                  src={el.logoUrl === null || el.logoUrl === '' ? TapNationPng : el.logoUrl}
                  alt={el.appName}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = TapNationLogo;
                  }}
                />
              </div>
              <div>
                <h6 className="game-title">{el.appName}</h6>
                <p className="m-0 fs-7">Creation: {moment(el.createdAt).format('ll')}</p>
              </div>
            </div>
          ),
          os: <GameOsIcons item={{ ...el.data, os: el.multipleOs ? '' : el.os }} width="40px" height="30px" />,
          pending: (
            <div className="d-flex  justify-content-center">
              {!!el.pendingTests && (
                <OverlayTrigger overlay={<Tooltip>Tests</Tooltip>} placement="top">
                  <div className="text-white fs-7 bg-warning py-1 px-2 m-1 rounded">
                    {Math.max(el.pendingTests, el?.ios?.pendingTests || 0)}
                  </div>
                </OverlayTrigger>
              )}
              {!!el.pendingConfigs && (
                <OverlayTrigger overlay={<Tooltip>Configs</Tooltip>} placement="top">
                  <div className="text-white bg-warning-300 fs-7 py-1 px-2 m-1 rounded">
                    {Math.max(el.pendingConfigs, el?.ios?.pendingConfigs || 0)}
                  </div>
                </OverlayTrigger>
              )}
            </div>
          ),
          date: moment(el.updateTime).format('ll'),
          studio: !!studioObject ? (studioObject[el.studioId] ? studioObject[el.studioId].name : '-') : null,
        };
      });
      setData({
        gamesToDisplay: currentPost,
        total: searchGameFilter.length,
      });
    }

    if (currentPage) {
      window.scrollTo({ top: 0 });
    }
  }, [games, sort, studioSelected, studioList, query]);

  const header = [
    {
      key: 'game',
      label: 'Game',
      sortable: (a, b, dir) =>
        dir === 'asc' ? a.appName.localeCompare(b.appName) : b.appName.localeCompare(a.appName),
    },
    {
      key: 'os',
      label: 'Os',
    },
    {
      key: 'date',
      label: 'Last Update',
      sortable: (a, b, dir) =>
        dir === 'asc'
          ? new Date(a['updateTime']).getTime() - new Date(b['updateTime']).getTime()
          : new Date(b['updateTime']).getTime() - new Date(a['updateTime']).getTime(),
    },
    studioPermission && { key: 'studio', label: 'Studio', sortable: true },
    { key: 'bizdevEmail', label: 'Bs Dev', sortable: true },
    { key: 'pending', label: 'Pending' },
  ];
  return (
    <>
      <TnTable
        page={currentPage}
        perPage={data.postsPerPage}
        onRowClick={handleSeeAbTests}
        header={header}
        data={data.gamesToDisplay}
      />
    </>
  );
}

export default AbTestGameListTable;
