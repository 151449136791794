import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Metrics from './Metrics';
import MetricsHeader from './MetricsHeader';
import { useSelector } from 'react-redux';
import { getGameById } from '../../../../data/slices/studio';
import Loader from '../../../../Components/Loader';
import { studioApi } from '../../../../data/api/studioApi';

function GameMetrics() {
  const { isLoading } = studioApi.endpoints.getGames.useQueryState();
  const [os, setOs] = useState('android');

  let { id } = useParams();
  let foundGame = useSelector(getGameById(id));

  return isLoading ? (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
      <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
    </div>
  ) : foundGame?.appName ? (
    <>
      <MetricsHeader data={foundGame} os={os} changeOs={setOs} />
      <Metrics game={os === 'ios' ? foundGame.ios : foundGame} />
    </>
  ) : (
    <div>Game not available on this platform</div>
  );
}

export default GameMetrics;
