import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import ConfigsDropdown from './ConfigsDropdown';
import ModuleItem from './ModuleItem';
import {
  selectAvailableVersions,
  selectChangedFields,
  selectErrors,
  setAvailableVersions,
  setChangedFields,
  setConfigData,
  setErrors,
  setSelectedTemplate,
} from '../../../data/slices/gameConfigSlice';
import { useCreateGameConfigMutation, useGetGameConfigQuery } from '../../../data/api/googleCloudApi';
import { selectJsonData, selectSelectedTemplate } from '../../../data/slices/gameConfigSlice';
import Loader from '../../../Components/Loader';
import { buildVersionCompare } from '../../../utils/common';

const GameConfigs = ({ studioId, bundleId, os = 'ALL' }) => {
  const dispatch = useDispatch();
  const versionObject = useSelector(selectAvailableVersions);
  const jsonData = useSelector(selectJsonData);
  const selectedTemplate = useSelector(selectSelectedTemplate);
  const changedFields = useSelector(selectChangedFields);
  const errors = useSelector(selectErrors);

  const [showModal, setShowModal] = useState(false);

  const [createGameConfig, { isLoading: isCreating }] = useCreateGameConfigMutation();

  const { data: configData, isLoading, error } = useGetGameConfigQuery({ studioId, bundleId }, { skip: !showModal });
  const {
    data: templateData,
    isLoading: isLoadingTemplate,
    error: templateError,
  } = useGetGameConfigQuery({ studioId: selectedTemplate, bundleId: 'config' }, { skip: !selectedTemplate });
  const {
    data: mainJson,
    isLoading: isLoadingMainJson,
    error: mainJsonError,
  } = useGetGameConfigQuery(
    {
      studioId: 'main',
      bundleId: 'modules',
    },
    { skip: !showModal },
  );
  const getChangedFields = (data, prefix) => {
    let fields = {};
    data.forEach((module) => {
      if (module.hasChanged)
        fields[`${prefix}.${module.id}`] = {
          is_active: module.is_active,
          module_version: module.module_version,
          id: module.id,
        };
      if (module.parameters)
        module.parameters.forEach((parameter) => {
          if (parameter.hasChanged)
            fields[`${prefix}.${module.id}.parameters.${parameter.id}`] = { value: parameter.value };
        });

      if (module.submodules)
        fields = { ...fields, ...getChangedFields(module.submodules, `${prefix}.${module.id}.submodules`) };
    });
    return fields;
  };

  const processGameConfigData = (confData, prefix) => {
    let data = [...confData];
    data.forEach((module, index) => {
      const availableVersions = versionObject[module.id] || {};
      if (changedFields[`${prefix}.${module.id}`])
        data[index] = {
          ...data[index],
          hasChanged: true,
          is_active: changedFields[`${prefix}.${module.id}`].is_active,
          module_version: changedFields[`${prefix}.${module.id}`].module_version,
        };

      if (module.is_mandatory && !module.is_active) data[index] = { ...data[index], is_active: true, hasChanged: true };

      if (module.parameters) {
        let params = [...module.parameters];
        module.parameters.forEach((parameter, pIndex) => {
          if (changedFields[`${prefix}.${module.id}.parameters.${parameter.id}`]) {
            params[pIndex] = {
              ...params[pIndex],
              hasChanged: true,
              value: changedFields[`${prefix}.${module.id}.parameters.${parameter.id}`].value,
            };
          }
        });
        data[index] = {
          ...data[index],
          parameters: params,
        };
      }

      if (module.submodules)
        data[index] = {
          ...data[index],
          submodules: processGameConfigData(data[index].submodules, `${prefix}.${module.id}.submodules`),
        };

      if (
        (!module.module_version || (selectedTemplate === 'custom' && !configData?.id)) &&
        Object.keys(availableVersions)?.length
      ) {
        const highestVersion = Object.keys(availableVersions)[0];
        data[index] = {
          ...data[index],
          hasChanged: true,
          module_version: highestVersion,
        };
      }
      if (selectedTemplate !== 'custom' && module.module_version) {
        data[index] = {
          ...data[index],
          module_version: module.module_version,
        };
      }
    });
    return data;
  };

  useEffect(() => {
    if (mainJson) {
      const versionObject = {};
      mainJson.VersionsDetails.forEach((moduleDetail) => {
        const moduleId = moduleDetail.Id;
        versionObject[moduleId] = {};
        moduleDetail.ModuleInfos.forEach((moduleInfo) => {
          const version = moduleInfo.Version;
          versionObject[moduleId][version] = {
            name: moduleInfo.Name,
            packageUrl: moduleInfo.PackageUrl,
            isDeprecated: moduleInfo.IsDeprecated,
            hasIssue: moduleInfo.HasIssue,
            isBeta: moduleInfo.IsBeta,
            dependencies: moduleInfo.Dependencies,
          };
        });
        versionObject[moduleId] = Object.keys(versionObject[moduleId])
          .sort(buildVersionCompare)
          .reduce((sortedVersions, version) => {
            sortedVersions[version] = versionObject[moduleId][version];
            return sortedVersions;
          }, {});
      });
      dispatch(setAvailableVersions(versionObject));
    }
  }, [mainJson, dispatch]);

  useEffect(() => {
    if (configData && showModal) {
      const changedFields = getChangedFields(configData.modules, 'modules');
      dispatch(setChangedFields(changedFields));
      dispatch(setSelectedTemplate(configData.id));
    }
  }, [configData, showModal, dispatch]);

  useEffect(() => {
    if (mainJson && templateData && templateData.id === selectedTemplate) {
      let configData = processGameConfigData(templateData.modules, 'modules');
      dispatch(setConfigData({ ...templateData, modules: configData }));
    }
  }, [templateData, selectedTemplate, mainJson]);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => {
    dispatch(setErrors({}));
    dispatch(setConfigData({}));
    dispatch(setChangedFields({}));
    dispatch(setSelectedTemplate(''));
    setShowModal(false);
  };

  const validateParameter = (param, value) => {
    const regex = new RegExp(param.regex);
    return regex.test(value.trim());
  };
  const validateGameConfigData = (configData, prefix, selectedModules = {}) => {
    let validationErrors = {};

    configData.forEach((module, index) => {
      const availableVersions = versionObject[module.id] || {};
      const isSelectedVersionValid = () => {
        const selectedVersionDetail = availableVersions[module.module_version];
        return !(selectedVersionDetail?.isDeprecated || selectedVersionDetail?.hasIssue);
      };

      if (module.is_mandatory && !module.is_active)
        validationErrors[`${prefix}.${index}.is_active`] = 'Mandatory module must be active';

      if (module.is_active) {
        if (!isSelectedVersionValid(module.module_version)) {
          validationErrors[`${prefix}.${index}.version`] =
            'The selected version is either deprecated or has issues. Please select a valid version';
        }
        //check dependencies
        const currentVersionDetail = availableVersions[module.module_version];
        if (currentVersionDetail?.dependencies?.length) {
          currentVersionDetail.dependencies.forEach((dep) => {
            let tmp = dep.split('-');
            if (!selectedModules[tmp[0]]) {
              validationErrors[`${prefix}.${index}.dependencies.${dep}`] = `${dep} required to be selected`;
            }
            if (selectedModules[tmp[0]] && buildVersionCompare(selectedModules[tmp[0]], tmp[1]) > 0) {
              validationErrors[`${prefix}.${index}.dependencies.${dep}`] = `Higher version is required for ${dep}`;
            }
          });
        }
        module.parameters.forEach((param, pIndex) => {
          if (
            param.is_mandatory &&
            !param.value.trim() &&
            (os.includes(param.platform.toLowerCase()) || param.platform.toLowerCase() === 'all')
          ) {
            validationErrors[`${prefix}.${index}.parameters.${pIndex}.value`] = 'Parameter value is required';
          }
          if (param.value.trim() && !validateParameter(param, param.value))
            validationErrors[`${prefix}.${index}.parameters.${pIndex}.value`] =
              `Incorrect value (regex: ${param.regex})`;
        });

        if (module.submodules) {
          const submoduleErrors = validateGameConfigData(
            module.submodules,
            `${prefix}.${index}.submodules`,
            selectedModules,
          );
          validationErrors = { ...validationErrors, ...submoduleErrors };
        }
      } else {
        if (module.submodules) {
          module.submodules.forEach((submodule, subIndex) => {
            if (submodule.is_active) {
              validationErrors[`${prefix}.${index}.submodules.${subIndex}.is_active`] =
                'Active submodule found while parent module is inactive';
            }
            const nestedSubmoduleErrors = validateGameConfigData(
              [submodule],
              `${prefix}.${index}.submodules.${subIndex}`,
              selectedModules,
            );
            validationErrors = { ...validationErrors, ...nestedSubmoduleErrors };
          });
        }
      }
    });
    return validationErrors;
  };
  const handleCreateConfig = () => {
    let selectedModules = {};
    const getSelectedModules = (modules) => {
      modules.forEach((module) => {
        if (module.is_active) selectedModules[module.id] = module.module_version;
        if (module.submodules) getSelectedModules(module.submodules);
      });
    };
    getSelectedModules(jsonData.modules);
    let validation = validateGameConfigData(jsonData.modules, 'modules', selectedModules);
    if (!Object.keys(validation).length) {
      createGameConfig({
        studioId,
        bundleId,
        configData: jsonData,
      })
        .unwrap()
        .then((response) => {
          console.log('Create config response:', response);
          handleCloseModal();
        })
        .catch((error) => {
          console.error('Failed to create config:', error);
        });
    } else {
      dispatch(setErrors(validation));
    }
  };

  return (
    <div>
      <Button variant="primary" onClick={handleOpenModal}>
        SDK Config
      </Button>

      <Modal show={showModal} onHide={handleCloseModal} size="lg" scrollable backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Select SDK Config</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ConfigsDropdown />
          {(templateError || mainJsonError) && (
            <div className="alert alert-danger p-2 mb-2 fs-7 " role="alert">
              {(error || templateError || mainJsonError)?.data?.message || 'Something went wrong.'}
            </div>
          )}
          {isLoading || isLoadingTemplate || isLoadingMainJson ? (
            <div className="d-flex align-items-center justify-content-center w-100" style={{ height: 261 }}>
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : jsonData ? (
            <div style={{ marginTop: '20px' }}>
              {jsonData.modules && jsonData.modules.length > 0 ? (
                jsonData.modules.map((module, index) => {
                  return <ModuleItem key={module.id + index} module={module} path={`modules.${index}`} />;
                })
              ) : (
                <p>No modules available</p>
              )}
            </div>
          ) : (
            <p className="mt-2">No data available. Select a template.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!!Object.keys(errors).length && (
            <div className="text-danger p-2 mb-2 fs-7" role="alert">
              Please fix the errors before creating the config.
            </div>
          )}
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateConfig} disabled={!selectedTemplate || isCreating}>
            {isCreating ? (
              <div className="d-flex align-items-center justify-content-center" style={{ width: 44 }}>
                <Loader parentStyle="loader" size={40} color={'#3F96C7'} />
              </div>
            ) : (
              'Create'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GameConfigs;
