import * as PropTypes from 'prop-types';

const AppStoreBadgeIcon = ({ size, color }) => {
  return (
    <svg height={size} viewBox="0 0 120 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M110.444 0.00013H9.56145C9.19372 0.00013 8.8304 0.000129999 8.46364 0.00212999C8.15663 0.00412999 7.85207 0.00993997 7.54211 0.01483C6.86874 0.0227317 6.19695 0.081806 5.53259 0.191539C4.86915 0.303662 4.2265 0.515044 3.62637 0.818537C3.02697 1.1246 2.47929 1.52229 2.00318 1.99719C1.52457 2.47076 1.12561 3.01814 0.82165 3.61828C0.516847 4.21723 0.305496 4.85905 0.194896 5.52159C0.083244 6.18329 0.0231632 6.85263 0.0151925 7.52359C0.00588648 7.83018 0.00490373 8.1378 0 8.44444V31.5586C0.00490373 31.8691 0.00588648 32.1699 0.0151925 32.4805C0.0231657 33.1514 0.0832464 33.8207 0.194896 34.4824C0.30519 35.1453 0.516554 35.7875 0.82165 36.3867C1.12548 36.9849 1.52449 37.5301 2.00318 38.001C2.47748 38.478 3.02552 38.876 3.62637 39.1797C4.2265 39.484 4.86908 39.6967 5.53259 39.8105C6.19707 39.9194 6.86879 39.9785 7.54211 39.9873C7.85207 39.9941 8.15663 39.998 8.46364 39.998C8.83039 40 9.19374 40 9.56145 40H110.444C110.804 40 111.171 40 111.531 39.998C111.837 39.998 112.15 39.9941 112.455 39.9873C113.128 39.9789 113.798 39.9198 114.461 39.8105C115.127 39.6958 115.772 39.4833 116.375 39.1797C116.975 38.8758 117.522 38.4779 117.996 38.001C118.474 37.5282 118.874 36.9835 119.181 36.3867C119.484 35.7871 119.693 35.145 119.802 34.4824C119.914 33.8207 119.976 33.1515 119.988 32.4805C119.992 32.1699 119.992 31.8691 119.992 31.5586C120 31.1953 120 30.834 120 30.4648V9.53623C120 9.17002 120 8.80674 119.992 8.44444C119.992 8.1378 119.992 7.83018 119.988 7.52355C119.976 6.85253 119.914 6.18335 119.802 5.52155C119.693 4.8594 119.484 4.21762 119.181 3.61824C118.562 2.41532 117.581 1.43615 116.375 0.818447C115.772 0.515696 115.127 0.304369 114.461 0.191449C113.798 0.0812325 113.128 0.0221377 112.455 0.01469C112.15 0.00980997 111.837 0.00394999 111.531 0.00199999C111.171 0 110.804 0 110.444 0V0.00013Z"
        fill="#A6A6A6"
      />
      <path
        d="M8.57708 38.998C8.27219 38.998 7.97466 38.9941 7.67216 38.9874C7.04551 38.9793 6.42032 38.9251 5.80172 38.8254C5.22491 38.7267 4.66614 38.5433 4.14383 38.2811C3.62632 38.021 3.1543 37.68 2.74586 37.2712C2.33151 36.8671 1.98655 36.3985 1.72464 35.8839C1.45985 35.3659 1.27659 34.8107 1.18127 34.2376C1.07832 33.6218 1.02262 32.9991 1.01465 32.3749C1.00831 32.1654 1 31.4679 1 31.4679V8.51947C1 8.51947 1.00885 7.83262 1.0147 7.63083C1.02233 7.00764 1.07771 6.38594 1.18035 5.77109C1.27585 5.19642 1.45925 4.63961 1.72418 4.11994C1.98513 3.60566 2.32818 3.13669 2.74003 2.7312C3.15142 2.32186 3.62494 1.97911 4.14334 1.71547C4.66445 1.45408 5.22217 1.27193 5.79781 1.17511C6.41844 1.07434 7.0458 1.01986 7.67462 1.01213L8.57757 1H111.418L112.331 1.01262C112.954 1.01997 113.576 1.07396 114.191 1.17414C114.772 1.27217 115.336 1.45559 115.863 1.71838C116.902 2.2497 117.747 3.0903 118.28 4.12236C118.54 4.63845 118.721 5.19072 118.815 5.76042C118.919 6.38031 118.977 7.0069 118.989 7.6352C118.992 7.91653 118.992 8.21873 118.992 8.51947C119 8.892 119 9.24658 119 9.60407V30.3949C119 30.7558 119 31.1079 118.992 31.463C118.992 31.786 118.992 32.082 118.988 32.3866C118.977 33.0036 118.919 33.619 118.817 34.2278C118.724 34.8051 118.542 35.3646 118.277 35.8868C118.013 36.3959 117.67 36.8605 117.26 37.2634C116.852 37.6745 116.379 38.0175 115.86 38.2792C115.335 38.5434 114.772 38.7275 114.191 38.8254C113.572 38.9257 112.947 38.9798 112.32 38.9874C112.027 38.9941 111.72 38.998 111.422 38.998L110.338 39L8.57708 38.998Z"
        fill="black"
      />
      <path
        d="M24.7689 20.3008C24.7907 18.6043 25.6801 17.0373 27.1254 16.1488C26.2095 14.8406 24.7304 14.041 23.1343 13.9911C21.455 13.8148 19.8271 14.9959 18.9714 14.9959C18.0991 14.9959 16.7816 14.0086 15.3629 14.0378C13.4962 14.0981 11.7981 15.1337 10.89 16.7656C8.95595 20.114 10.3985 25.0351 12.2512 27.7417C13.1781 29.0671 14.2613 30.5475 15.6788 30.495C17.0658 30.4375 17.5839 29.6105 19.2582 29.6105C20.9169 29.6105 21.403 30.495 22.8492 30.4616C24.3376 30.4375 25.2753 29.1304 26.1697 27.7925C26.8357 26.8481 27.3481 25.8044 27.6881 24.7C25.9204 23.9523 24.7709 22.2202 24.7689 20.3008Z"
        fill="white"
      />
      <path
        d="M22.0372 12.211C22.8488 11.2368 23.2486 9.98463 23.1518 8.7204C21.9119 8.85062 20.7667 9.44318 19.9442 10.38C19.132 11.3043 18.7206 12.5136 18.8005 13.7414C20.0569 13.7543 21.2499 13.1903 22.0372 12.211Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.5689 27.1398H42.3023L43.438 30.4962H45.4771L40.9937 18.0782H38.9107L34.4273 30.4962H36.4321L37.5689 27.1398ZM41.8111 25.5909H38.0591L39.9097 20.1437H39.9615L41.8111 25.5909Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.3814 30.591C53.6538 30.591 55.1597 28.7833 55.1597 25.9699C55.1597 23.1642 53.645 21.3478 51.3472 21.3478C50.1648 21.3097 49.0572 21.9246 48.4644 22.9484H48.4302V21.4425H46.6313V33.4913H48.4897V29.007H48.5327C49.1003 30.0372 50.2068 30.6524 51.3814 30.591ZM50.8569 22.9318C52.3023 22.9318 53.2495 24.1369 53.2495 25.9699C53.2495 27.8195 52.3023 29.0158 50.8569 29.0158C49.437 29.0158 48.4819 27.7941 48.4819 25.9699C48.4819 24.1622 49.437 22.9318 50.8569 22.9318Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.3462 30.591C63.6187 30.591 65.1245 28.7833 65.1245 25.9699C65.1245 23.1642 63.6099 21.3478 61.312 21.3478C60.1296 21.3097 59.022 21.9246 58.4292 22.9484H58.395V21.4425H56.5962V33.4913H58.4546V29.007H58.4976C59.0652 30.0372 60.1716 30.6524 61.3462 30.591ZM60.8218 22.9318C62.2671 22.9318 63.2144 24.1369 63.2144 25.9699H63.2144C63.2144 27.8195 62.2671 29.0158 60.8218 29.0158C59.4019 29.0158 58.4468 27.7941 58.4468 25.9699C58.4468 24.1622 59.4019 22.9318 60.8218 22.9318Z"
        fill="white"
      />
      <path
        d="M71.7105 27.0363C71.8482 28.2677 73.0445 29.0763 74.6792 29.0763C76.2456 29.0763 77.3726 28.2677 77.3726 27.1573C77.3726 26.1934 76.6929 25.6163 75.0835 25.2208L73.4742 24.8331C71.1939 24.2823 70.1353 23.2159 70.1353 21.4854C70.1353 19.3428 72.0025 17.8712 74.6538 17.8712C77.2778 17.8712 79.0767 19.3428 79.1372 21.4854H77.2612C77.1489 20.2462 76.1245 19.4981 74.6274 19.4981C73.1304 19.4981 72.106 20.255 72.106 21.3565C72.106 22.2345 72.7603 22.7511 74.3608 23.1465L75.729 23.4825C78.2769 24.085 79.3355 25.1085 79.3355 26.9249C79.3355 29.2481 77.4849 30.7032 74.5415 30.7032C71.7876 30.7032 69.9282 29.2823 69.8081 27.0362L71.7105 27.0363Z"
        fill="white"
      />
      <path
        d="M83.3462 19.2999V21.4425H85.0679V22.9142H83.3462V27.9054C83.3462 28.6808 83.6909 29.0421 84.4478 29.0421C84.6522 29.0386 84.8562 29.0242 85.0591 28.9991V30.462C84.7188 30.5256 84.373 30.5544 84.0269 30.5479C82.1939 30.5479 81.479 29.8595 81.479 28.1036V22.9142H80.1626V21.4425H81.479V19.2999H83.3462Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.359 21.3312C87.7427 21.3312 86.065 23.1212 86.065 25.9699C86.065 28.8263 87.7261 30.6085 90.359 30.6085C92.9927 30.6085 94.6539 28.8263 94.6539 25.9699C94.6539 23.1212 92.984 21.3312 90.359 21.3312ZM90.3589 22.8624C91.8648 22.8624 92.7603 24.0158 92.7603 25.9699H92.7603C92.7603 27.9318 91.8648 29.0763 90.3589 29.0763C88.8531 29.0763 87.9585 27.9318 87.9585 25.9699C87.9585 24.0246 88.8531 22.8624 90.3589 22.8624Z"
        fill="white"
      />
      <path
        d="M96.1861 21.4425H97.9585V22.9835H98.0015C98.2487 21.9915 99.1577 21.3088 100.179 21.3478C100.393 21.347 100.607 21.3703 100.816 21.4171V23.1554C100.546 23.0728 100.264 23.0348 99.981 23.0431C99.4346 23.0209 98.9059 23.2387 98.5336 23.6391C98.1613 24.0396 97.9826 24.5828 98.0445 25.1261V30.4962H96.1861L96.1861 21.4425Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.486 30.6085C107.534 30.6085 109.134 29.4806 109.384 27.837L107.62 27.837C107.281 28.6771 106.431 29.195 105.529 29.1105C104.853 29.1354 104.198 28.8685 103.732 28.3779C103.266 27.8872 103.034 27.22 103.093 26.546V26.4337H109.488V25.797C109.488 23.0519 107.913 21.3312 105.408 21.3312C102.861 21.3312 101.217 23.173 101.217 26.0128C101.217 28.8439 102.852 30.6085 105.486 30.6085ZM107.628 25.1349L103.102 25.1349C103.1 24.5236 103.343 23.9371 103.776 23.5056C104.209 23.0741 104.796 22.8334 105.408 22.837C106.014 22.824 106.599 23.0646 107.02 23.5008C107.442 23.937 107.662 24.5292 107.628 25.1349Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.5834 9.16242C39.0333 8.57109 38.2516 8.26204 37.4535 8.32034H35.2733V14.4638H37.4535C39.2514 14.4638 40.2936 13.3356 40.2936 11.3729C40.3942 10.565 40.1336 9.75374 39.5834 9.16242ZM37.3488 13.595H36.2107V9.1885H37.3488C37.9161 9.15646 38.4678 9.38414 38.853 9.80928C39.2382 10.2344 39.4165 10.8125 39.3393 11.3855C39.4226 11.9608 39.2468 12.5437 38.8606 12.9723C38.4745 13.401 37.9191 13.6297 37.3488 13.595Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.3629 10.0682C41.666 10.5084 41.2745 11.3126 41.3526 12.1436C41.273 12.9755 41.6641 13.7813 42.3616 14.2224C43.0592 14.6635 43.9422 14.6635 44.6397 14.2224C45.3372 13.7813 45.7284 12.9755 45.6488 12.1436C45.7269 11.3126 45.3354 10.5084 44.6385 10.0682C43.9416 9.62802 43.0598 9.62802 42.3629 10.0682ZM43.5022 10.5509C44.2806 10.5509 44.7241 11.1386 44.7241 12.1436H44.7241C44.7241 13.1526 44.2806 13.7398 43.5022 13.7398C42.7208 13.7398 42.2812 13.1566 42.2812 12.1436C42.2812 11.1386 42.7208 10.5509 43.5022 10.5509Z"
        fill="white"
      />
      <path
        d="M51.3596 14.4637H50.4271L49.4857 11.0491H49.4145L48.477 14.4637H47.5534L46.2978 9.82748H47.2096L48.0256 13.3652H48.0928L49.0293 9.82748H49.8917L50.8282 13.3652H50.8993L51.7113 9.82748H52.6103L51.3596 14.4637Z"
        fill="white"
      />
      <path
        d="M53.6663 9.82749H54.5316V10.564H54.5988C54.8324 10.0217 55.3783 9.69002 55.9581 9.738C56.4071 9.70364 56.847 9.87894 57.1537 10.2144C57.4604 10.5499 57.6006 11.0091 57.5347 11.4624V14.4637H56.6358V11.6921C56.6358 10.9471 56.3177 10.5766 55.6529 10.5766C55.3484 10.5621 55.0528 10.6838 54.8438 10.9096C54.6347 11.1354 54.533 11.4429 54.5652 11.7515V14.4637H53.6663L53.6663 9.82749Z"
        fill="white"
      />
      <path d="M58.9671 8.01763H59.8661V14.4637H58.9671V8.01763Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.1262 10.0681C61.4293 10.5083 61.0377 11.3125 61.1157 12.1436C61.0363 12.9755 61.4275 13.7813 62.125 14.2224C62.8226 14.6635 63.7056 14.6635 64.4032 14.2224C65.1007 13.7813 65.4919 12.9755 65.4125 12.1436C65.4905 11.3125 65.0989 10.5083 64.402 10.0681C63.705 9.62792 62.8232 9.62792 62.1262 10.0681ZM63.2653 10.5509C64.0438 10.5509 64.4873 11.1386 64.4873 12.1436H64.4873C64.4873 13.1526 64.0438 13.7398 63.2653 13.7398C62.484 13.7398 62.0444 13.1566 62.0444 12.1436C62.0444 11.1386 62.4839 10.5509 63.2653 10.5509Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.053 11.7686C66.9693 11.8369 66.3588 12.318 66.3588 13.1526C66.3603 13.5483 66.5263 13.9249 66.8155 14.1892C67.1048 14.4536 67.4905 14.581 67.8772 14.5401C68.4288 14.5661 68.9514 14.288 69.2454 13.8122H69.3165V14.4637H70.1819V11.296C70.1819 10.3167 69.5378 9.73801 68.3958 9.73801C67.3625 9.73801 66.6265 10.2488 66.5347 11.0451H67.405C67.5048 10.7173 67.8525 10.5298 68.3543 10.5298C68.9688 10.5298 69.2868 10.8063 69.2868 11.296V11.6962L68.053 11.7686ZM69.2868 12.3688V12.7564C69.2672 13.0519 69.1305 13.3266 68.9082 13.5177C68.6859 13.7087 68.3969 13.7998 68.1074 13.7699C67.6183 13.7699 67.2627 13.5271 67.2627 13.1098C67.2627 12.7011 67.5472 12.4839 68.1745 12.4412L69.2868 12.3688Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.2541 9.75057C72.1032 9.75057 71.3633 10.6786 71.3633 12.1436C71.3633 13.6121 72.0953 14.5402 73.2541 14.5401C73.8416 14.5621 74.3926 14.2506 74.6845 13.7312H74.7556V14.4637H75.6171V8.01763H74.7181V10.564H74.6509C74.3795 10.0403 73.8352 9.72332 73.2541 9.75057ZM73.509 13.7187C72.7474 13.7187 72.2919 13.1269 72.2919 12.1436H72.2919C72.2919 11.1683 72.7523 10.5725 73.509 10.5725C74.2617 10.5725 74.7349 11.1814 74.7349 12.1476C74.7349 13.1184 74.2667 13.7187 73.509 13.7187Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.3467 10.0682C79.6498 10.5084 79.2583 11.3126 79.3364 12.1436C79.2568 12.9755 79.6479 13.7813 80.3454 14.2224C81.043 14.6635 81.926 14.6635 82.6235 14.2224C83.321 13.7813 83.7122 12.9755 83.6326 12.1436C83.7107 11.3126 83.3192 10.5084 82.6223 10.0682C81.9254 9.62802 81.0436 9.62802 80.3467 10.0682ZM81.4859 10.5509C82.2644 10.5509 82.7079 11.1386 82.7079 12.1436C82.7079 13.1526 82.2644 13.7398 81.4859 13.7398C80.7046 13.7398 80.265 13.1566 80.265 12.1436C80.265 11.1386 80.7046 10.5509 81.4859 10.5509Z"
        fill="white"
      />
      <path
        d="M84.8387 9.82749H85.7041V10.564H85.7713C86.0049 10.0217 86.5508 9.69002 87.1306 9.738C87.5795 9.70364 88.0195 9.87894 88.3262 10.2144C88.6329 10.5499 88.7731 11.0091 88.7072 11.4624V14.4637H87.8083V11.6921C87.8083 10.9471 87.4902 10.5766 86.8253 10.5766C86.5208 10.5621 86.2253 10.6838 86.0162 10.9096C85.8072 11.1354 85.7054 11.4429 85.7377 11.7515V14.4637H84.8387V9.82749Z"
        fill="white"
      />
      <path
        d="M93.7868 8.67321V9.8486H94.7737V10.6193H93.7868V13.0033C93.7868 13.4889 93.9834 13.7016 94.4309 13.7016C94.5455 13.7012 94.6599 13.6941 94.7737 13.6804V14.4426C94.6123 14.472 94.4487 14.4876 94.2847 14.4893C93.285 14.4893 92.8869 14.1314 92.8869 13.2375V10.6193H92.1638V9.84856H92.8869V8.67321H93.7868Z"
        fill="white"
      />
      <path
        d="M96.0016 8.01763H96.8926V10.5725H96.9638C97.2086 10.0255 97.764 9.6934 98.3526 9.74202C98.7991 9.71729 99.233 9.89645 99.5364 10.2307C99.8398 10.5649 99.9809 11.0194 99.9214 11.4704V14.4637H99.0215V11.6961C99.0215 10.9556 98.6826 10.5806 98.0475 10.5806C97.7347 10.5545 97.4266 10.6702 97.2055 10.8968C96.9844 11.1234 96.8728 11.4378 96.9006 11.756V14.4637H96.0016L96.0016 8.01763Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.189 14.5532C104.079 14.6515 104.911 14.0864 105.163 13.2119L104.298 13.2119C104.082 13.6056 103.654 13.8277 103.214 13.7739C102.875 13.7778 102.55 13.6358 102.319 13.3829C102.088 13.1301 101.972 12.7898 102.001 12.4457V12.3945H105.217V12.0752C105.217 10.6193 104.453 9.738 103.185 9.738C102.576 9.73272 101.995 9.99662 101.592 10.4613C101.189 10.9261 101.004 11.5459 101.085 12.1602C101.002 12.7728 101.188 13.3916 101.593 13.8524C101.998 14.3133 102.582 14.5695 103.189 14.5532ZM104.302 11.7178H102.001C101.997 11.3981 102.12 11.0902 102.342 10.8641C102.564 10.638 102.866 10.5127 103.18 10.5167C103.49 10.5095 103.789 10.6358 104.002 10.8645C104.216 11.0933 104.324 11.4031 104.302 11.7178Z"
        fill="white"
      />
    </svg>
  );
};
AppStoreBadgeIcon.propTypes = {
  size: PropTypes.number,
};

AppStoreBadgeIcon.defaultProps = {
  size: 50,
};

export default AppStoreBadgeIcon;
