import { useEffect } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { abTestsApi, useGetGameAbtestsQuery } from '../../../data/api/abTestsApi';
import { useGetGamesQuery } from '../../../data/api/studioApi';
import { gameAbTestByIdSelector } from '../../../data/slices/abTests';
import { getGameById } from '../../../data/slices/studio';
import { Button } from 'react-bootstrap';
import Loader from '../../../Components/Loader';
import DocumentDownloadIcon from '../../../Components/Icons/DocumentDownloadIcon';
import AbTestStatusDropdown from '../GameAbtestList/_Components/AbTestStatusDropdown';
import MetricsHeader from './MetricsHeader';
import MetricsContainer from './Metrics/MetricsContainer';
import MetricsGroupBtns from './MetricsGroupBtns';
import JsonMetricsContainer from './JsonMetrics/JsonMetricsContainer';
import { setTopBarTitle } from '../../../data/slices/global';
import { R_AB_TEST } from '../../../constants/routes';
import InfoIcon from '../../../Components/Icons/InfoIcon';
function AbTestMetrics() {
  const { gameId, testId } = useParams();
  const dispatch = useDispatch();

  const { isLoading: isLoadingGames } = useGetGamesQuery();
  const foundGame = useSelector(getGameById(gameId));

  const { isLoading: isLoadingAbTests } = useGetGameAbtestsQuery(
    { bundleId: foundGame?.bundleId, store: foundGame?.store === 'amazon' ? 'amazon' : 'google,apple' },
    { skip: !foundGame?.bundleId },
  );
  const abTestSelected = useSelector((state) =>
    gameAbTestByIdSelector(
      abTestsApi.endpoints.getGameAbtests.select({
        bundleId: foundGame?.bundleId,
        store: foundGame?.store === 'amazon' ? 'amazon' : 'google,apple',
      })(state),
      testId,
    ),
  );

  useEffect(() => {
    if (abTestSelected) {
      document.title = 'TapNation - ' + abTestSelected.name;
      dispatch(
        setTopBarTitle({
          title: 'A/B Test Metrics',
          back: generatePath(R_AB_TEST, {
            id: gameId,
          }),
          search: false,
        }),
      );
    }
  }, [abTestSelected]);
  const handleSavePdf = () => {
    window.print();
  };

  return (
    <div className="container-fluid game-abtest-metrics">
      <div className="row top-bar align-items-center d-print-none px-md-2 bg-white">
        <div className="col-12 d-flex align-items-center">
          <MetricsGroupBtns abTest={abTestSelected} />

          <div className="ms-auto d-flex">
            {abTestSelected && (
              <>
                <div className="me-2">
                  <AbTestStatusDropdown data={abTestSelected} />
                </div>
              </>
            )}
            <Button className="ms-2" variant={'primary'} onClick={handleSavePdf}>
              <DocumentDownloadIcon />
              PDF report
            </Button>
          </div>
        </div>
      </div>
      {isLoadingAbTests || isLoadingGames ? (
        <div className="d-flex justify-content-center my-2 p-4">
          <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
        </div>
      ) : (
        <>
          <MetricsHeader />
          {isLoadingAbTests ? (
            <div className="d-flex justify-content-center my-2 p-4">
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : abTestSelected?.status === 'PENDING' ? (
            <div className="alert alert-warning d-flex align-items-center">
              <div className=" me-3">
                <InfoIcon />
              </div>
              <div>
                <p className="mb-1">This Abtest has not yet been approved.</p>
                <span className="fs-7">
                  Report will befilled a few days after the start of the test. If no data appears after +2 days, check
                  the test settings.
                </span>
              </div>
            </div>
          ) : Number(abTestSelected?.gen) === 2 ? (
            <JsonMetricsContainer />
          ) : (
            <MetricsContainer />
          )}
        </>
      )}
    </div>
  );
}

export default AbTestMetrics;
