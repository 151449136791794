import * as PropTypes from 'prop-types';

const FolderIcon = ({ size, color }) => {
  return (
    <svg height={size} width={size} fill={color} viewBox="0 0 32 32">
      <path d="M4,28a3,3,0,0,1-3-3V7A3,3,0,0,1,4,4h7a1,1,0,0,1,.77.36L14.8,8H27a1,1,0,0,1,0,2H14.33a1,1,0,0,1-.76-.36L10.53,6H4A1,1,0,0,0,3,7V25a1,1,0,0,0,1,1,1,1,0,0,1,0,2Z" />
      <path d="M25.38,28H4a1,1,0,0,1-1-1.21l3-14A1,1,0,0,1,7,12H30a1,1,0,0,1,1,1.21L28.32,25.63A3,3,0,0,1,25.38,28ZM5.24,26H25.38a1,1,0,0,0,1-.79L28.76,14h-21Z" />
    </svg>
  );
};
FolderIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

FolderIcon.defaultProps = {
  size: 24,
  color: '#4D6F9D',
};

export default FolderIcon;
