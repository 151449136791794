import React from 'react';
import SearchInput from '../../Pages/AbTest/CreateAbTestModal/_Components/SearchInput';
import { InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import CalendarIcon from '../Icons/CalendarIcon';
import SearchIcon from '../Icons/SearchIcon';

function SearchInputDemo() {
  return (
    <>
      <SearchInput />
      <Form.Group className={`cursor-pointer pe-0 position-relative`} controlId="startDate">
        <Form.Label className="fs-7">
          Start Date <span className="text-danger fs-7">*</span>
        </Form.Label>
        <Form.Control isInvalid type="text" aria-describedby="" placeholder="Start date" />
      </Form.Group>{' '}
      <Form.Group className={`cursor-pointer pe-0 position-relative`} controlId="startDate">
        <Form.Label className="fs-7">
          Start Date <span className="text-danger fs-7">*</span>
        </Form.Label>
        <InputGroup hasValidation className="">
          <Form.Control isInvalid type="text" aria-describedby="" placeholder="Start date" />
          <InputGroup.Text>
            <CalendarIcon size={14} />
          </InputGroup.Text>
          <Form.Control.Feedback tooltip type="invalid">
            Please choose a username.
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group className={`cursor-pointer pe-0 position-relative`} controlId="startDate">
        <Form.Label className="fs-7">
          Start Date <span className="text-danger fs-7">*</span>
        </Form.Label>
        <InputGroup className="bg-white">
          <InputGroup.Text className="bg-white">
            <CalendarIcon size={14} />
          </InputGroup.Text>
          <Form.Control className=" bg-white" type="text" aria-describedby="" placeholder="Start date" />
        </InputGroup>
      </Form.Group>{' '}
      <Form.Group className={`cursor-pointer pe-0 position-relative`} controlId="startkDate">
        <Form.Label className="fs-7">
          Start Date <span className="text-danger fs-7">*</span>
        </Form.Label>

        <InputGroup>
          <Form.Control type="text" aria-describedby="" placeholder="Start date" readOnly value={9999} disabled />
          <InputGroup.Text>
            <CalendarIcon size={14} />
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>{' '}
      <Form.Group className={`cursor-pointer mb-2 pe-0 position-relative`} controlId="startkDate">
        <Form.Label className="fs-7">
          Start Date <span className="text-danger fs-7 border-start-0">*</span>
        </Form.Label>
        <InputGroup hasValidation>
          <InputGroup.Text className="">
            <CalendarIcon size={14} />
          </InputGroup.Text>
          <Form.Control
            type="text"
            bsPrefix="form-control border-start-0 "
            aria-describedby=""
            placeholder="Start date"
            readOnly
            value={9999}
            disabled
          />
        </InputGroup>
      </Form.Group>
      <div className="d-flex">
        <Form.Group className="input-group select-drop">
          <div className="d-flex flex-column">
            <Form.Label className="fs-7">
              able <span className="text-danger fs-7">*</span>
            </Form.Label>
            <Form.Select className="form-select  ">
              <option defaultValue value={''}>
                Year
              </option>
              <option value={2024}>2024</option>
              <option value={2023}>2023</option>
              <option value={2022}>2022</option>
              <option value={2021}>2021</option>
              <option value={2020}>2020</option>
            </Form.Select>
          </div>
        </Form.Group>
        <Form.Group className="input-group select-drop">
          <div className="d-flex flex-column">
            <Form.Label className="fs-7">
              disable <span className="text-danger fs-7">*</span>
            </Form.Label>
            <Form.Select className="form-select  " disabled>
              <option defaultValue value={''}>
                Year
              </option>
              <option value={2024}>2024</option>
              <option value={2023}>2023</option>
              <option value={2022}>2022</option>
              <option value={2021}>2021</option>
              <option value={2020}>2020</option>
            </Form.Select>
          </div>
        </Form.Group>
      </div>
      <br />
      <Form.Group>
        <InputGroup>
          <Form.Control className=" " type="text" aria-describedby="" placeholder="able..." autoComplete={'off'} />
          <InputGroup.Text className=" ">
            <SearchIcon size={20} />
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
      <br />
      <Form.Group>
        <InputGroup>
          <Form.Control
            className=" "
            type="text"
            aria-describedby=""
            placeholder="disable..."
            autoComplete={'off'}
            disabled
          />
          <InputGroup.Text className=" ">
            <SearchIcon size={20} />
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>{' '}
      <br />
      <Form.Group>
        <InputGroup>
          <Form.Control
            className=" bg-white"
            type="text"
            aria-describedby=""
            placeholder="disable..."
            autoComplete={'off'}
          />
          <InputGroup.Text className=" bg-white">
            <SearchIcon size={20} />
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
    </>
  );
}

export default SearchInputDemo;
