import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { hmac256 } from '../../utils/common';
const baseUrl = process.env.REACT_APP_APP_MAGIK_API;

export const appMagikApi = createApi({
  reducerPath: 'appMagikApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['topApps', 'topAppDates'],
  keepUnusedDataFor: 600, // keep for 10min
  endpoints: (builder) => ({
    listTopApps: builder.query({
      query: ({ os, date, search, page }) => {
        const params = [];
        if (page) params.push('page=' + page);
        if (search) params.push('search=' + encodeURIComponent(search));
        const url = `/apps/${os}/${date}${params.length ? '?' + params.join('&') : ''}`;
        return {
          url,
          headers: {
            Authorization: hmac256(baseUrl + url),
          },
        };
      },
      providesTags: ['topApps'],
    }),
    getAvailableDates: builder.query({
      query: () => ({
        url: '/apps/dates',
        headers: {
          Authorization: hmac256(baseUrl + '/apps/dates'),
        },
      }),
      providesTags: (result, error, args) => (result ? ['topAppDates'] : []),
    }),
  }),
});

export const { useListTopAppsQuery, useGetAvailableDatesQuery } = appMagikApi;
