import React, { useEffect, useState } from 'react';
import MetricsTable from '../../../../../Components/JsonMetrics/MetricsTable';
import MetricsTableChart from '../../../../../Components/JsonMetrics/MetricsTableChart';
import { getChartDataCell, getDataCell } from '../../../../../utils/jsonMetricsUtils';

function AdsMetrics({ apiData }) {
  const [selectedChart, setSelectedChart] = useState('Interstitial');
  const [data, setData] = useState(null);

  const handleChange = (e) => {
    setSelectedChart(e.target.value);
  };

  const selectedChartValue = () => {
    if (!data) return [];
    switch (selectedChart) {
      case 'Interstitial':
        return data.interstitialChart;
      case 'Rewarded':
        return data.rewardedChart;
      default:
        return data.bannerChart;
    }
  };

  useEffect(() => {
    if (!apiData?.summary?.length && !apiData?.dates) return;

    const newData = {
      bannerChart: [],
      interstitialChart: [],
      rewardedChart: [],
      legendList: [],
      retD1: [],
      retD2: [],
      retD3: [],
      retD4: [],
      retD7: [],
      ltv: {
        header: [
          { value: 'Cohort' },
          { value: 'ltv 0' },
          { value: 'ltv 1' },
          { value: 'ltv 2' },
          { value: 'ltv 3' },
          { value: 'ltv 4' },
          { value: 'ltv 5' },
          { value: 'ltv 6' },
          { value: 'ltv 7' },
        ],
        data: [],
        title: 'Ad Revenue',
      },
      averages: {
        header: [{ value: 'Cohort' }, { value: 'Interstitial' }, { value: 'Rewarded' }, { value: 'Banner' }],
        data: [],
        title: 'Average number of ads watched per user',
      },
    };

    apiData.summary.forEach((el) => {
      const isBaseCohort = el.user_cohort?.toLowerCase() === 'basecohort';
      newData.legendList.push(el.user_cohort);

      newData.averages.data.push([
        { value: el.user_cohort, className: isBaseCohort ? 'bg-light-400' : '' },
        getDataCell(el.inter_impressions, el.meta, 'inter_impressions', isBaseCohort),
        getDataCell(el.reward_impressions, el.meta, 'reward_impressions', isBaseCohort),
        getDataCell(el.banner_impressions, el.meta, 'banner_impressions', isBaseCohort),
      ]);

      newData.ltv.data.push([
        { value: el.user_cohort, className: isBaseCohort ? 'bg-light-400' : '' },
        getDataCell(el.ltv0?.toFixed(4), el.meta, 'ltv0', isBaseCohort),
        getDataCell(el.ltv1?.toFixed(4), el.meta, 'ltv1', isBaseCohort),
        getDataCell(el.ltv2?.toFixed(4), el.meta, 'ltv2', isBaseCohort),
        getDataCell(el.ltv3?.toFixed(4), el.meta, 'ltv3', isBaseCohort),
        getDataCell(el.ltv4?.toFixed(4), el.meta, 'ltv4', isBaseCohort),
        getDataCell(el.ltv5?.toFixed(4), el.meta, 'ltv5', isBaseCohort),
        getDataCell(el.ltv6?.toFixed(4), el.meta, 'ltv6', isBaseCohort),
        getDataCell(el.ltv7?.toFixed(4), el.meta, 'ltv7', isBaseCohort),
      ]);
    });

    Object.keys(apiData.dates).forEach((date, index) => {
      newData.bannerChart.push({ date });
      newData.interstitialChart.push({ date });
      newData.rewardedChart.push({ date });
      newData.retD1.push({ date });
      newData.retD2.push({ date });
      newData.retD3.push({ date });
      newData.retD4.push({ date });
      newData.retD7.push({ date });

      const d = apiData.dates[date];

      Object.keys(d).forEach((item) => {
        const obj = d[item];
        const isBaseCohort = obj.user_cohort?.toLowerCase() === 'basecohort';

        newData.interstitialChart[index][item] = getChartDataCell(
          obj.inter_impressions,
          obj.meta,
          'inter_impressions',
          isBaseCohort,
        );
        newData.bannerChart[index][item] = getChartDataCell(
          obj.banner_impressions,
          obj.meta,
          'banner_impressions',
          isBaseCohort,
        );
        newData.rewardedChart[index][item] = getChartDataCell(
          obj.reward_impressions,
          obj.meta,
          'reward_impressions',
          isBaseCohort,
        );
      });
    });

    setData(newData);
  }, [apiData]);

  return (
    <>
      {!!data?.ltv.data?.length && (
        <MetricsTable data={data.ltv.data} title={data.ltv.title} header={data.ltv.header} downloadCsv />
      )}

      <div className="bg-white rounded d-flex flex-column">
        {!!data?.averages.data?.length && (
          <MetricsTable
            data={data.averages.data}
            title={data.averages.title}
            header={data.averages.header}
            downloadCsv
            csvDiffFromBase
          />
        )}
        <div className="p-3 d-flex text-nowrap align-items-center gap-2">
          <label htmlFor="select">Select Ad Type:</label>
          <div className="w-25">
            <select className="form-select cursor-pointer" id="select" onChange={handleChange} value={selectedChart}>
              <option value="Interstitial">Interstitial</option>
              <option value="Rewarded">Rewarded</option>
              <option value="Banner">Banner</option>
            </select>
          </div>
        </div>
        {!!data?.legendList?.length && <MetricsTableChart data={selectedChartValue()} legendList={data.legendList} />}
      </div>
    </>
  );
}

export default AdsMetrics;
