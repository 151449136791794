import { Button, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import CheckmarkIcon from './Icons/CheckmarkIcon';
import InfoIcon from './Icons/InfoIcon';
import { createRoot } from 'react-dom/client';

const target = document.getElementById('portalsRoot');

let root = null; // createRoot(container!) if you use TypeScript

function removeElementReconfirm() {
  if (root) {
    root.unmount();
  }
}

export function confirmAlert(properties) {
  root = createRoot(target);
  return new Promise((resolve, reject) => {
    root.render(<ConfirmModal {...properties} {...{ resolve, reject }} />);
  });
}

const ConfirmModal = ({ resolve, reject, title, desc, variant, cancelText, cancelBtn, confirmText, confirmBtn }) => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    if (!show) {
      removeElementReconfirm();
    }
  }, [show]);
  const handleConfirm = () => {
    resolve();
    setShow(false);
  };
  const handleClose = () => {
    setShow(false);
    reject();
  };
  return (
    <Modal centered show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton className="border-0" />

      <Modal.Body className="d-flex  flex-column align-items-center ">
        {variant === 'success' && (
          <div className="rounded-circle p-5 mb-4  bg-success-300">
            <CheckmarkIcon size={48} />
          </div>
        )}
        {variant === 'warning' && (
          <div className="rounded-circle p-5 mb-4  bg-warning-300 text-warning">
            <InfoIcon color="inherit" size={48} />
          </div>
        )}
        {variant === 'danger' && (
          <div className="rounded-circle p-5 mb-4  bg-danger-300">
            <InfoIcon size={48} />
          </div>
        )}
        <h5 className="text-center">{title}</h5>
        <div className="text-center text-muted">{desc}</div>
      </Modal.Body>
      {(cancelBtn || confirmBtn) && (
        <Modal.Footer>
          <Button
            variant="light"
            className="d-flex justify-content-center flex-grow-1 mx-1"
            onClick={() => handleClose()}
          >
            {cancelText}
          </Button>
          {confirmBtn && (
            <Button
              variant="success"
              className="d-flex justify-content-center flex-grow-1 mx-1"
              onClick={() => handleConfirm()}
            >
              {confirmText}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

ConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  variant: PropTypes.oneOf(['success', 'danger', 'warning']),
  cancelText: PropTypes.string,
  cancelBtn: PropTypes.bool,
  confirmText: PropTypes.string,
  confirmBtn: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  title: 'Confirm',
  variant: 'success',
  cancelText: 'Cancel',
  confirmText: 'Confirm',
  confirmBtn: true,
  cancelBtn: true,
};
