import * as PropTypes from 'prop-types';

const HomeIcon = ({ size, color }) => {
  return (
    <svg height={size} width={size} fill={color} viewBox="0 0 24 24">
      <path d="M12,3c0,0-6.186,5.34-9.643,8.232C2.154,11.416,2,11.684,2,12c0,0.553,0.447,1,1,1h2v7c0,0.553,0.447,1,1,1h3  c0.553,0,1-0.448,1-1v-4h4v4c0,0.552,0.447,1,1,1h3c0.553,0,1-0.447,1-1v-7h2c0.553,0,1-0.447,1-1c0-0.316-0.154-0.584-0.383-0.768  C18.184,8.34,12,3,12,3z" />
    </svg>
  );
};
HomeIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

HomeIcon.defaultProps = {
  size: 24,
  color: '#000',
};

export default HomeIcon;
