import React from 'react';
import ConfigRow from './ConfigRow';
import Loader from '../../../../Components/Loader';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import useFilteredConfigs from './useFilteredConfigs';
import { useSelector } from 'react-redux';
import { getGameById } from '../../../../data/slices/studio';

function ConfigTable(props) {
  const { configs, headers, isLoadingConfig } = useFilteredConfigs({
    filter: props.data,
    gameId: props.data.id,
  });
  const foundGame = useSelector(getGameById(props.data.id));

  return (
    <>
      {isLoadingConfig || !foundGame ? (
        <div className="d-flex align-items-center">
          <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
        </div>
      ) : headers.length ? (
        headers.map((build, index) => (
          <div key={index} className="rounded py-2 d-flex config-wrapper">
            <h4 className="me-2 px-3 mb-0 pe-2 border-end text-end" style={{ flexBasis: 124 }}>
              {build}
            </h4>
            <div className="flex-grow-1 d-flex flex-column mb-1">
              {Object.entries(configs[build])?.map(([header, devices], deviceIndex) => (
                <div key={deviceIndex}>
                  {devices.length > 0 && (
                    <>
                      <p className="mb-1 text-gray-blue">
                        {header === '_' ? (
                          'Configs'
                        ) : (
                          <>
                            <span className="text-secondary">Device ID: </span> {header}
                          </>
                        )}
                      </p>
                      <div className="mb-2 bg-white rounded">
                        {devices.map((item, itemIndex) => (
                          <ConfigRow item={item} itemIndex={itemIndex} key={itemIndex} />
                        ))}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <div className="alert alert-warning mb-3 d-flex align-items-center fs-7" role="alert">
          <div className="me-3">
            <InfoIcon />
          </div>
          <div>No data found...!</div>
        </div>
      )}
    </>
  );
}

export default ConfigTable;
