import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useUpdateChatTitleMutation } from '../../../data/api/openAiApi';
import Loader from '../../../Components/Loader';

function EditTitleModal({ title, titles, model, onSubmit }) {
  const [showForm, setShowForm] = useState(false);
  const [input, setInput] = useState(title);
  const [update, { isLoading, error }] = useUpdateChatTitleMutation();
  useEffect(() => {
    if (title && !showForm) {
      setShowForm(true);
    }
  }, [title]);
  const handleClose = () => {
    setShowForm(false);
    setInput('');
  };
  const handleSubmt = (e) => {
    e.preventDefault();
    if (titles.includes(input) || input === title) return false;

    update({ title, new: input, model })
      .unwrap()
      .then((resp) => {
        onSubmit(resp?.title);
        handleClose();
      });
  };

  return (
    <>
      <Modal centered scrollable dialogClassName="modal-md" show={showForm} onHide={handleClose}>
        <Form onSubmit={handleSubmt}>
          <Modal.Header closeButton>
            <Modal.Title>Rename: {title}</Modal.Title>
          </Modal.Header>
          {isLoading ? (
            <div className="d-flex justify-content-center my-3">
              <Loader parentStyle="me-2" size={60} color={'#3F96C7'} />
            </div>
          ) : (
            <Modal.Body>
              {error && (
                <div className="alert alert-danger p-2 mb-2 fs-7 " role="alert">
                  {error?.data?.message || 'Something went wrong.'}
                </div>
              )}
              {input !== title && titles.includes(input) && (
                <div className="alert alert-danger p-2 mb-2 fs-7 " role="alert">
                  This title already exists.
                </div>
              )}
              <Form.Group className="form-group" controlId="formBasicSelect">
                <Form.Label className="col-form-label">New title</Form.Label>
                <Form.Control
                  value={input}
                  placeholder="Type new title here..."
                  onChange={(e) => setInput(e.target.value)}
                />
              </Form.Group>
            </Modal.Body>
          )}
          <Modal.Footer className="d-flex">
            <div className="ms-auto">
              <Button type="button" variant="outline-light" className=" ms-auto" onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={isLoading} type="submit" variant="primary" className=" ms-3">
                Rename
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default EditTitleModal;
