import React, { useEffect, useState } from 'react';
import { useSendMessage2Query } from '../../../data/api/aiAgentApi';
import Loader from '../../../Components/Loader';
import { useImageGenerationMutation } from '../../../data/api/openAiApi';
import { formatMessage, imageSizeOptions } from '../chatUtils';
import { Button } from 'react-bootstrap';

const PitchMessage = ({ message }) => {
  const {
    data: response,
    isLoading,
    error,
  } = useSendMessage2Query({ request: message.prompt }, { skip: !message?.prompt });

  const [sendImage, { isLoading: isLoadingImage, error: errorImage, isUninitialized, reset }] =
    useImageGenerationMutation();
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (response?.text && !isLoadingImage && !error && isUninitialized) {
      getImage();
    }
  }, [response, isLoadingImage]);

  const getImage = () => {
    sendImage({
      model: 'DALL-E-3',
      message: response.text,
      imageSize: imageSizeOptions['DALL-E-3'][1],
    })
      .unwrap()
      .then((imageResponse) => {
        setImages((val) => [...val, imageResponse[0]?.url]);
      })
      .catch((error) => {
        console.error('Error generating image:', error);
      });
  };
  const generateNewImage = () => {
    reset();
    getImage();
  };
  return (
    <>
      {isLoading && (
        <div className="d-flex">
          <Loader parentStyle="me-2" size={20} color={'#3F96C7'} />
          Loading...
        </div>
      )}
      {(message?.content || response) && (
        <div dangerouslySetInnerHTML={{ __html: formatMessage(message.content || response.text) }}></div>
      )}
      {error && (
        <div className="alert alert-danger my-1 fs-7" role="alert">
          Error: {error?.data?.message || error?.error || 'Something went wrong.'}
        </div>
      )}
      {!!images.length && (
        <div className="d-flex flex-wrap m-1">
          {images.map((url, index) => (
            <a key={index} href={url} target="_blank" rel="noopener noreferrer">
              <img src={url} alt={`Generated visual ${index + 1}`} style={{ maxWidth: 600, margin: '5px 0' }} />
            </a>
          ))}
        </div>
      )}
      {isLoadingImage ? (
        <div className="d-flex">
          <Loader parentStyle="me-2" size={20} color={'#3F96C7'} />
          Loading Image...
        </div>
      ) : (
        !!images.length && <Button onClick={generateNewImage}>Generate another image</Button>
      )}
      {errorImage && (
        <div className="alert alert-danger my-1 mt-3 fs-7" role="alert">
          Error: {errorImage?.data?.message || errorImage?.error || 'Something went wrong.'}
        </div>
      )}
    </>
  );
};

export default PitchMessage;
