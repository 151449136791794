const HeaderInfoCard = ({ title, children, icon, classname }) => {
  return (
    <div className={`d-flex align-items-center ${classname}`}>
      {icon && <div className="bg-light p-2 me-2 rounded d-flex">{icon}</div>}
      <div className="fs-7 overflow-hidden mw-100 flex-grow-1">
        {title && <p className="fs-8 text-muted m-0">{title}</p>}
        <p className="m-0 text-wrap text-break">{children}</p>
      </div>
    </div>
  );
};

export default HeaderInfoCard;
