import { useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useSetState } from '../../../../utils/customHooks';
import Form from 'react-bootstrap/Form';
import { Button, Col, InputGroup, Modal } from 'react-bootstrap';
import CalendarIcon from '../../../../Components/Icons/CalendarIcon';
import moment from 'moment';

const CohortDateSelect = ({ onDateChange, errors, startDate, endDate }) => {
  const [show, setShow] = useState(false);
  const [state, setState] = useSetState({
    minDate: (() => {
      let minDate = moment().add(1, 'day');
      return minDate.format();
    })(),
    maxDate: moment(),
    selectionRange: {},
  });
  const handleChange = (ranges) => {
    setState({ selectionRange: ranges.selection });
    setMaxDate(ranges.selection.startDate);
  };

  const handleSubmit = () => {
    onDateChange(state.selectionRange.startDate?.toDateString(), state.selectionRange.endDate?.toDateString());
    handleClose();
  };

  const setMaxDate = (startDate) => {
    let maxDate = startDate ? moment(startDate) : moment();
    maxDate.add(29, 'days');
    setState({
      maxDate: maxDate.format(),
    });
  };

  const handleOpen = () => {
    setState({
      selectionRange: {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: 'selection',
      },
    });
    setMaxDate(startDate);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <Form.Group
        onClick={handleOpen}
        className="position-relative cursor-pointer"
        as={Col}
        xs={6}
        controlId="startDate"
      >
        <Form.Label className="fs-7">
          Start Date <span className="text-danger fs-7">*</span>
        </Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type="text"
            className=" cursor-pointer"
            aria-describedby=""
            placeholder="Start date"
            readOnly
            value={moment(startDate).format('ll')}
            isInvalid={errors?.startDate}
          />
          <InputGroup.Text className="cursor-pointer">
            <CalendarIcon size={14} />
          </InputGroup.Text>

          <Form.Control.Feedback tooltip type="invalid">
            {errors.startDate}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group onClick={handleOpen} className="position-relative cursor-pointer" as={Col} xs={6} controlId="endDate">
        <Form.Label className="fs-7">
          End Date <span className="text-danger fs-7">*</span>
        </Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type="text"
            className=" cursor-pointer"
            aria-describedby=""
            readOnly
            value={moment(endDate).format('ll')}
            placeholder="End date"
            isInvalid={errors?.endDate}
          />
          <InputGroup.Text className="cursor-pointer">
            <CalendarIcon size={14} />
          </InputGroup.Text>
          <Form.Control.Feedback tooltip type="invalid">
            {errors.endDate}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Modal centered dialogClassName="modal-md" show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="d-flex justify-content-around w-100">
            <p className="fs-7 m-0">Start date</p>
            <p className="fs-7 m-0">End date</p>
          </div>
          {state.selectionRange?.startDate && (
            <DateRange
              className="w-100"
              ranges={[state.selectionRange]}
              onChange={handleChange}
              minDate={new Date(state.minDate)}
              maxDate={new Date(state.maxDate)}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <div className="ms-auto">
            <Button type="button" variant="outline-light" onClick={handleClose} className=" ms-auto">
              Cancel
            </Button>
            <Button type="button" variant="primary" className=" ms-3" onClick={handleSubmit}>
              Apply
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CohortDateSelect;
