import Form from 'react-bootstrap/Form';
import { Button, InputGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import moment from 'moment';
import CalendarIcon from '../../../Components/Icons/CalendarIcon';
import DatePicker from 'react-datepicker';

const DateSelectPopover = ({ from, to, onChange }) => {
  const handleDateRange = (range) => {
    switch (range) {
      case 'yesterday':
        onChange('from', moment().subtract(1, 'day').format('YYYY-MM-DD'));
        onChange('to', moment().subtract(1, 'day').format('YYYY-MM-DD'));
        break;
      case 'pastm':
        onChange('from', moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
        onChange('to', moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        break;
      default:
        onChange('from', moment().subtract(range, 'days').format('YYYY-MM-DD'));
        onChange('to', moment().format('YYYY-MM-DD'));
        break;
    }
  };
  const popover = (
    <Popover id="popover-basic" style={{ maxWidth: 690 }}>
      <Popover.Body className="pb-0 ">
        <div className="d-flex w-100">
          <div style={{ width: 120 }} className="me-1 fs-7 text-secondary"></div>
          <p className="fs-7 flex-grow-1 text-center m-0">Start date</p>
          <p className="fs-7 flex-grow-1 text-center m-0">End date</p>
        </div>
        <div className="d-flex flex-wrap justify-content-between">
          <div style={{ width: 120 }} className="me-1">
            <Button
              variant="outline-secondary"
              className="w-100 mb-2"
              size="sm"
              onClick={() => handleDateRange('yesterday')}
            >
              Yesterday
            </Button>
            <Button variant="outline-secondary" className="w-100 mb-2" size="sm" onClick={() => handleDateRange(7)}>
              Last 7 days
            </Button>
            <Button variant="outline-secondary" className="w-100 mb-2" size="sm" onClick={() => handleDateRange(14)}>
              Last 14 days
            </Button>
            <Button variant="outline-secondary" className="w-100 mb-2" size="sm" onClick={() => handleDateRange(30)}>
              Last 30 days
            </Button>
            <Button
              variant="outline-secondary"
              className="w-100 mb-2"
              size="sm"
              onClick={() => handleDateRange('pastm')}
            >
              Past month
            </Button>
            <Button variant="outline-secondary" className="w-100 mb-2" size="sm" onClick={() => handleDateRange(60)}>
              Last 60 days
            </Button>
          </div>
          <div className="me-1 mb-2">
            <DatePicker
              disabledKeyboardNavigation
              id="datepickerfrom"
              className="form-control border-0 cursor-pointer"
              selected={new Date(from)}
              maxDate={new Date()}
              onChange={(date) => {
                onChange('from', moment(date).format('YYYY-MM-DD'));
                if (moment(to).diff(moment(date), 'days') >= 180) {
                  onChange('to', moment(date).add(180, 'days').format('YYYY-MM-DD'));
                }
                if (moment(to).isBefore(moment(date), 'days')) {
                  onChange('to', moment(date).format('YYYY-MM-DD'));
                }
              }}
              placeholderText="This only includes dates from 30 days ago"
              dateFormat="MMM d, yyyy"
              inline
            />
          </div>
          <div className="ms-1 mb-2">
            <DatePicker
              disabledKeyboardNavigation
              id="datepickerto"
              className="form-control border-0 cursor-pointer "
              selected={new Date(to)}
              minDate={new Date(from)}
              maxDate={(() => {
                if (moment().diff(moment(from), 'days') > 180) {
                  return new Date(moment(from).add(180, 'days').format('YYYY-MM-DD'));
                } else {
                  return new Date();
                }
              })()}
              onChange={(date) => onChange('to', moment(date).format('YYYY-MM-DD'))}
              placeholderText="This only includes dates from 30 days ago"
              dateFormat="MMM d, yyyy"
              inline
            />
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <div>
      <OverlayTrigger
        onToggle={(open) => {
          if (!open) {
          }
        }}
        rootClose
        trigger="click"
        placement="bottom-start"
        overlay={popover}
      >
        <Form.Group className="position-relative cursor-pointer me-2">
          <InputGroup>
            <Form.Control
              style={{ minWidth: 180 }}
              type="text"
              className="fs-8 cursor-pointer bg-white"
              aria-describedby=""
              readOnly
              value={moment(from).format('YYYY-MM-DD') + ' | ' + moment(to).format('YYYY-MM-DD')}
              placeholder="End date"
            />
            <InputGroup.Text className="cursor-pointer bg-white">
              <CalendarIcon size={14} />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </OverlayTrigger>
    </div>
  );
};

export default DateSelectPopover;
