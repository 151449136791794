import * as PropTypes from 'prop-types';
import { CartesianGrid, LineChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis, Bar, BarChart } from 'recharts';
import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../../../utils/converters';
import moment from 'moment';
import SearchableDropdown from '../../../Components/SearchableDropdown';
const colors = [
  '#002cff',
  '#009eff',
  '#4d6f9d',
  '#6685ff',
  '#00b688',
  '#ff9232',
  '#b75219',
  '#ff6f77',
  '#b71f53',
  '#3f64ff',
  '#2d3363',
  '#001193',
  '#161a47',
];

const hexCharacters = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F'];

function getCharacter(index) {
  return hexCharacters[index];
}

function generateNewColor() {
  let hexColorRep = '#';

  for (let index = 0; index < 6; index++) {
    const randomPosition = Math.floor(Math.random() * hexCharacters.length);
    hexColorRep += getCharacter(randomPosition);
  }

  return hexColorRep;
}
const AnalyticsChart = ({ data: initialData, options, defaultDataKey, xDataKeys: initialXDataKeys }) => {
  /** State */
  const hasDate = initialXDataKeys.includes('date');
  const hasMonth = initialXDataKeys.includes('month');

  const xDataKeys = [...initialXDataKeys].sort();
  //place date first
  if (hasDate && xDataKeys[0] !== 'date') {
    xDataKeys.splice(xDataKeys.indexOf('date'), 1);
    xDataKeys.unshift('date');
  }
  if (hasMonth && xDataKeys[0] !== 'month') {
    xDataKeys.splice(xDataKeys.indexOf('month'), 1);
    xDataKeys.unshift('month');
  }

  const [data, setData] = useState({ data: [], select: [] });
  const [selected, setSelected] = useState([]);
  const [dataKey, setDataKey] = useState(defaultDataKey);
  const [xDataKey, setXDataKey] = useState(hasDate ? 'date' : xDataKeys[0]);

  useEffect(() => {
    let data = [];
    let select = {}; // helper for select
    let sortedSelect = []; //select options ['name', 'value']
    let pointer = 0; // cursor/pointer for grouping
    let indexes = {}; // save pointer on each group
    initialData.forEach((el) => {
      if (
        !hasMonth &&
        (typeof el[dataKey] === 'undefined' ||
          typeof el[xDataKey] === 'undefined' ||
          el[dataKey] === null ||
          el[xDataKey] === null)
      )
        return null;
      let xKey = hasDate || hasMonth ? 'date' : xDataKey; // group by date if it exists in report
      let index = indexes[el[xKey]]; // get pointer for group
      if (typeof index === 'undefined') {
        // create new group and pointer
        data.push({});
        index = pointer;
        indexes[el[xKey]] = pointer; // save pointer for group
        pointer++;
        data[index][xKey] = xKey === 'date' ? moment(el.date).format('YYYY-MM-DD') : el[xDataKey]; // format date
      }
      data[index][dataKey] = (data[index][dataKey] || 0) + el[dataKey]; // sum up data per group
      if (dataKey !== 'revenue') data[index]['revenue'] = (data[index]['revenue'] || 0) + el['revenue']; // sum up data per group
      if (dataKey !== 'spend') data[index]['spend'] = (data[index]['spend'] || 0) + el['spend']; // sum up data per group
      if (dataKey !== 'margin') data[index]['margin'] = (data[index]['margin'] || 0) + el['margin']; // sum up data per group

      data[index][dataKey + '_count'] = (data[index][dataKey + '_count'] || 0) + 1; // add count for calculating averages

      if ((hasDate || hasMonth) && xDataKey !== 'date') {
        // if date exists create sub-group within each date for multiple lines
        data[index][el[xDataKey]] = (data[index][el[xDataKey]] || 0) + el[dataKey];
        data[index][el[xDataKey] + '_count'] = (data[index][el[xDataKey] + '_count'] || 0) + 1;

        select[el[xDataKey]] = (select[el[xDataKey]] || 0) + el[dataKey]; // add total sum for select dropdown
      }
    });
    // calculate averages
    /* if (['eCPM', 'CPI'].includes(dataKey) || dataKey.includes('ret') || dataKey.includes('ltv')) {
      data = data.map((el) => {
        let tmp = { ...el };
        Object.keys(el).forEach((key) => {
          if (el[key + '_count']) {
            if (dataKey.includes('ret')) {
              tmp[key] = (el[key] * 100) / el[key + '_count'];
            } // if ret need %
            else tmp[key] = el[key] / el[key + '_count'];
          }
        });
        return tmp;
      });
    }*/
    if (Object.keys(select).length) {
      // sort and set select options
      sortedSelect = Object.entries(select)
        .sort(([, a], [, b]) => b - a)
        .filter((el) => el[0] && el[1]);
      sortedSelect.unshift([dataKey, 'All']);
      if (!selected.length) setSelected(sortedSelect.slice(0, 8).map((el) => el[0]));
    }
    // reverse order for chart
    data.reverse();
    setData({ data, select: sortedSelect });
  }, [initialData, xDataKey, dataKey]);

  const formatter = (value) => {
    return `$${parseFloat(Number(value).toFixed(2)).toLocaleString('en')}`;
  };

  return data?.data ? (
    <div className="bg-white px-3 py-3 mb-3 rounded position-relative z-1">
      <div className="d-flex mb-5">
        {xDataKeys.length > 1 && (
          <div className="d-flex flex-wrap">
            {xDataKeys.map((el) => (
              <Button
                bsPrefix="btn btn-sm btn-text border-0 mx-1"
                key={el}
                active={el === xDataKey}
                onClick={() => {
                  setXDataKey(el);
                  setSelected([]);
                }}
              >
                {capitalizeFirstLetter(el)}
              </Button>
            ))}
          </div>
        )}
        <div className="d-flex ms-auto flex-wrap">
          {xDataKey !== 'month' &&
            xDataKey !== 'date' &&
            options.map((el) => (
              <Button
                bsPrefix="btn btn-sm btn-text border-0 mx-1"
                key={el.value}
                active={el.value === dataKey}
                onClick={() => {
                  setDataKey(el.value);
                  setSelected([]);
                }}
              >
                {el.title}
              </Button>
            ))}
        </div>
      </div>
      <div style={{ position: 'relative', zIndex: 9999 }}>
        <ResponsiveContainer width="95%" height={250}>
          {hasDate || hasMonth ? (
            <LineChart data={data?.data} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
              <CartesianGrid stroke="#DEDEDE" vertical={false} strokeDasharray="5 5" />
              <XAxis
                dataKey={hasDate || hasMonth ? 'date' : xDataKey}
                angle={16}
                height={60}
                width={16}
                fontSize={12}
                dy={8}
                dx={8}
              />
              <YAxis
                tickFormatter={formatter}
                width={90}
                fontSize={12}
                yAxisId="left"
                padding={{ bottom: 5, top: 5 }}
              />
              {xDataKey === 'date' || xDataKey === 'month' ? (
                <>
                  <Line type="monotone" dataKey={'revenue'} stroke={'#002CFF'} strokeWidth={2} yAxisId="left" />
                  <Line type="monotone" dataKey={'spend'} stroke={'#4d6f9d'} strokeWidth={2} yAxisId="left" />
                  <Line type="monotone" dataKey={'margin'} stroke={'#00b688'} strokeWidth={2} yAxisId="left" />
                </>
              ) : (
                selected.map((el, index) => (
                  <Line
                    key={el}
                    type="monotone"
                    dataKey={el}
                    stroke={colors[index] || generateNewColor()}
                    strokeWidth={2}
                    yAxisId="left"
                  />
                ))
              )}
              <Tooltip
                contentStyle={{
                  borderRadius: 4,
                  padding: '4px 8px',
                  fontSize: 14,
                }}
                formatter={formatter}
              />
            </LineChart>
          ) : (
            <BarChart
              className="chart-level-chart"
              data={data?.data}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <Tooltip
                contentStyle={{
                  borderRadius: 4,
                  padding: '4px 8px',
                  fontSize: 14,
                }}
                formatter={formatter}
              />
              <XAxis
                tickFormatter={(val) => {
                  if (xDataKey === 'hasIdfa') return val === 1 ? 'Yes' : val === 0 ? 'No' : '-';
                  return val;
                }}
                dataKey={xDataKey === 'month'}
                angle={16}
                height={60}
                width={16}
                fontSize={12}
                dy={8}
                dx={8}
              />
              <YAxis
                tickFormatter={formatter}
                width={90}
                fontSize={12}
                yAxisId="left"
                padding={{ bottom: 5, top: 5 }}
              />
              <Bar yAxisId="left" dataKey={dataKey} fill="#3F96C7" />
            </BarChart>
          )}
        </ResponsiveContainer>
      </div>
      {(hasDate || hasMonth) && xDataKey !== 'date' && xDataKey !== 'month' && (
        <SearchableDropdown
          writeSelected
          parClass={'w-100 '}
          multiple
          ukey={'app'}
          selected={selected}
          onSelect={(data) => setSelected(data)}
          options={data.select.map((el) => ({ value: el[0], label: el[0] + ' - ' + el[1] }))}
        />
      )}
    </div>
  ) : null;
};

AnalyticsChart.propTypes = {
  options: PropTypes.array,
  data: PropTypes.array,
  defaultDataKey: PropTypes.string,
};

AnalyticsChart.defaultProps = {
  options: [
    { title: 'Revenue', value: 'revenue' },
    { title: 'Spend', value: 'spend' },
    { title: 'Margin', value: 'margin' },
  ],
  defaultDataKey: 'revenue',
  xDataKeys: ['date'],
};

export default AnalyticsChart;
