import Sidebar from './Sidebar';
import TopBar from './TopBar';
import './sideMenuLayout.scss';
import UserConsentModal from './UserConsentModal';
const SideMenuLayout = ({ children }) => {
  return (
    <>
      <Sidebar />
      <UserConsentModal />
      <TopBar />
      <div className="main-container">{children}</div>
    </>
  );
};

export default SideMenuLayout;
