import AnalyticsChart from '../_Components/AnalyticsChart';
import Pagination from '../../../Components/Pagination';
import { capitalizeFirstLetter } from '../../../utils/converters';
import { countries } from '../../../constants/global';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import MetricsTable from '../_Components/MetricsTable';
import MaxLimitWarning from '../_Components/MaxLimitWarning';
import { useHistory } from 'react-router-dom';

const AcquisitionReportTable = ({ report: reportData, trigger: triggerData, filter, setFilter }) => {
  const perPage = 25;
  const history = useHistory();
  const [totals, setTotals] = useState({});
  const [formattedReport, setformattedReports] = useState([]);
  const [headers, setHeaders] = useState([]);
  let report = reportData?.data || [];
  let hash = reportData?.hash || '';
  let withFilters = reportData?.filters;
  let trigger = triggerData || withFilters;
  const dataKeys = [
    { title: 'Installs', value: 'installs' },
    { title: 'CPI', value: 'CPI' },
    { title: 'Spend', value: 'spend' },
    ...(trigger?.type === 'cohort'
      ? [
          { title: 'Ret D1', value: 'ret_d1' },
          { title: 'Ret D3', value: 'ret_d3' },
          { title: 'Ret D7', value: 'ret_d7' },
          { title: 'Ret D14', value: 'ret_d14' },
          { title: 'Ret D90', value: 'ret_d90' },
          { title: 'Ltv D0', value: 'ltv0' },
          { title: 'Ltv D1', value: 'ltv1' },
          { title: 'Ltv D3', value: 'ltv3' },
          { title: 'Ltv D7', value: 'ltv7' },
          { title: 'Ltv D14', value: 'ltv14' },
          { title: 'Ltv D30', value: 'ltv30' },
          { title: 'Ltv D60', value: 'ltv60' },
        ]
      : []),
  ];

  useEffect(() => {
    if (withFilters) {
      // set filters from hash
      let tmp = {};
      if (withFilters.platform) tmp[withFilters.platform?.neg ? 'exPlatform' : 'platform'] = withFilters.platform.list;
      if (withFilters.network) tmp[withFilters.network?.neg ? 'exNetwork' : 'network'] = withFilters.network.list;
      if (withFilters.country) tmp[withFilters.country?.neg ? 'exCountry' : 'country'] = withFilters.country.list;
      if (withFilters.application) tmp[withFilters.application?.neg ? 'exApp' : 'app'] = withFilters.application.list;
      if (withFilters.type) tmp['type'] = withFilters.type;
      if (withFilters.totals) tmp['advanced'] = withFilters.totals;
      if (withFilters.from) tmp['from'] = withFilters.from;
      if (withFilters.to) tmp['to'] = withFilters.to;
      if (withFilters.dimensions) tmp['dimension'] = withFilters.dimensions.list;
      tmp['page'] = 1;
      setFilter({ ...filter, ...tmp });
    }
    history.push(history.location.pathname + '?hash=' + hash);
    if (report) {
      let totals = {};
      let counts = {};
      dataKeys.forEach((k) => {
        totals[k.value] = 0;
        counts[k.value] = 0;
      });
      let formatted = []; // format data
      let headers = [
        ...(trigger?.dimensions?.list || []).map((el) => ({ label: capitalizeFirstLetter(el), key: el })),
        ...dataKeys.map((el) => ({ label: el.title, key: el.value })),
      ];
      report.forEach((el) => {
        // calculate total sum

        dataKeys.forEach((k) => {
          if (typeof el[k.value] === 'number') {
            totals[k.value] += el[k.value];
            counts[k.value] += 1;
          }
        });
        // generate formatted report
        formatted.push({
          ...el,
          country_name: el.country ? countries.find((c) => c.code === el['country'])?.name || '-' : null,
          installs: el.installs ? Number(el.installs).toLocaleString('en') : '-',
          CPI: el.CPI ? '$' + parseFloat(Number(el.CPI).toFixed(2)).toLocaleString('en') : '-',
          spend: el.spend ? '$' + parseFloat(Number(el.spend).toFixed(2)).toLocaleString('en') : '-',
          ...(trigger?.type === 'cohort'
            ? {
                ret_d1: typeof el.ret_d1 === 'number' ? (el.ret_d1 * 100).toFixed(2) + '%' : '-',
                ret_d3: typeof el.ret_d3 === 'number' ? (el.ret_d3 * 100).toFixed(2) + '%' : '-',
                ret_d7: typeof el.ret_d7 === 'number' ? (el.ret_d7 * 100).toFixed(2) + '%' : '-',
                ret_d14: typeof el.ret_d14 === 'number' ? (el.ret_d14 * 100).toFixed(2) + '%' : '-',
                ret_d90: typeof el.ret_d90 === 'number' ? (el.ret_d90 * 100).toFixed(2) + '%' : '-',
                ltv0: typeof el?.ltv0 === 'number' ? '$' + el.ltv0.toFixed(2) : '-',
                ltv1: typeof el?.ltv1 === 'number' ? '$' + el.ltv1.toFixed(2) : '-',
                ltv3: typeof el?.ltv3 === 'number' ? '$' + el.ltv3.toFixed(2) : '-',
                ltv7: typeof el?.ltv7 === 'number' ? '$' + el.ltv7.toFixed(2) : '-',
                ltv14: typeof el?.ltv14 === 'number' ? '$' + el.ltv14.toFixed(2) : '-',
                ltv30: typeof el?.ltv30 === 'number' ? '$' + el.ltv30.toFixed(2) : '-',
                ltv60: typeof el?.ltv60 === 'number' ? '$' + el.ltv60.toFixed(2) : '-',
              }
            : {}),
        });
      });

      totals.CPI /= counts.CPI;
      if (trigger.type === 'cohort') {
        totals.ret_d1 /= counts.ret_d1;
        totals.ret_d3 /= counts.ret_d3;
        totals.ret_d7 /= counts.ret_d7;
        totals.ret_d14 /= counts.ret_d14;
        totals.ret_d90 /= counts.ret_d90;
        totals.ltv0 /= counts.ltv0;
        totals.ltv1 /= counts.ltv1;
        totals.ltv3 /= counts.ltv3;
        totals.ltv7 /= counts.ltv7;
        totals.ltv14 /= counts.ltv14;
        totals.ltv30 /= counts.ltv30;
        totals.ltv60 /= counts.ltv60;
      }

      setformattedReports(formatted);
      setHeaders(headers);
      setTotals({
        installs: totals.installs ? Number(totals.installs).toLocaleString('en') : '-',
        CPI: totals.CPI ? '$' + parseFloat(Number(totals.CPI).toFixed(2)).toLocaleString('en') : '-',
        spend: totals.spend ? '$' + parseFloat(Number(totals.spend).toFixed(2)).toLocaleString('en') : '-',
        ret_d1: totals?.ret_d1 ? (totals.ret_d1 * 100).toFixed(2) + '%' : '-',
        ret_d3: totals?.ret_d3 ? (totals.ret_d3 * 100).toFixed(2) + '%' : '-',
        ret_d7: totals?.ret_d7 ? (totals.ret_d7 * 100).toFixed(2) + '%' : '-',
        ret_d14: totals?.ret_d14 ? (totals.ret_d14 * 100).toFixed(2) + '%' : '-',
        ret_d90: totals?.ret_d90 ? (totals.ret_d90 * 100).toFixed(2) + '%' : '-',
        ltv0: totals?.ltv0 ? '$' + totals.ltv0.toFixed(2) : '-',
        ltv1: totals?.ltv1 ? '$' + totals.ltv1.toFixed(2) : '-',
        ltv3: totals?.ltv3 ? '$' + totals.ltv3.toFixed(2) : '-',
        ltv7: totals?.ltv7 ? '$' + totals.ltv7.toFixed(2) : '-',
        ltv14: totals?.ltv14 ? '$' + totals.ltv14.toFixed(2) : '-',
        ltv30: totals?.ltv30 ? '$' + totals.ltv30.toFixed(2) : '-',
        ltv60: totals?.ltv60 ? '$' + totals.ltv60.toFixed(2) : '-',
      });
    }
  }, [reportData]);

  return formattedReport.length ? (
    <div className="">
      {formattedReport.length + 2 > 20000 ? (
        <MaxLimitWarning
          onClick={() => {
            setFilter({ advanced: true, dimension: filter.dimension.slice(0.2) });
          }}
        />
      ) : (
        <AnalyticsChart
          options={dataKeys}
          defaultDataKey="installs"
          xDataKeys={trigger?.dimensions?.list}
          data={reportData?.data || []}
        />
      )}
      <div className="d-flex mb-2">
        <CSVLink
          className="ms-2"
          filename={`TN_${trigger?.from}_${trigger?.to}_Acquisition.csv`}
          data={[
            ...formattedReport,
            {
              [headers?.[0]?.['key']]: 'Total/Avg:',
              ...totals,
            },
          ]}
          headers={headers}
        >
          <div className="btn  btn-sm bg-white">Download CSV</div>
        </CSVLink>
        <div className="ms-auto d-flex align-items-center position-relative z-0">
          {formattedReport?.length > perPage && (
            <Pagination
              pushHistory={false}
              onChange={(page) => {
                setFilter({ page });
              }}
              total={formattedReport?.length || 0}
              perPage={perPage}
              page={filter.page}
            />
          )}
        </div>
      </div>
      <MetricsTable
        report={formattedReport.slice((filter.page - 1) * perPage, filter.page * perPage)}
        totals={totals}
        headers={headers}
      />
    </div>
  ) : null;
};

export default AcquisitionReportTable;
