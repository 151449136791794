import { useQuery, useSetState } from '../../../utils/customHooks';
import moment from 'moment';
import { useGetAcquisitionHashReportQuery, useGetAcquisitionReportQuery } from '../../../data/api/analyticsApi';
import FiltersBar from './Filters/FiltersBar';
import Loader from '../../../Components/Loader';
import InfoIcon from '../../../Components/Icons/InfoIcon';
import { useState } from 'react';
import AcquisitionReportTable from './AcquisitionReportTable';

const Acquisition = () => {
  const [filter, setFilter] = useSetState({
    page: 1,
    platform: [],
    exPlatform: [],
    network: [],
    exNetwork: [],
    country: [],
    exCountry: [],
    app: [],
    exApp: [],
    advanced: false,
    type: '',
    dimension: ['date'],
    from: (() => {
      let t = moment().subtract(7, 'day');
      return t.format('YYYY-MM-DD');
    })(),
    to: (() => {
      let t = moment();
      return t.format('YYYY-MM-DD');
    })(),
  });
  const [trigger, setTrigger] = useState(null);
  const runReport = () => {
    let tmp = {};
    if (filter.exPlatform.length) tmp.platform = { list: filter.exPlatform, neg: true };
    if (filter.platform.length) tmp.platform = { list: filter.platform };

    if (filter.exNetwork.length) tmp.adNetwork = { list: filter.exNetwork, neg: true };
    if (filter.network.length) tmp.adNetwork = { list: filter.network };

    if (filter.exCountry.length) tmp.country = { list: filter.exCountry, neg: true };
    if (filter.country.length) tmp.country = { list: filter.country };

    if (filter.exApp.length) tmp.application = { list: filter.exApp, neg: true };
    if (filter.app.length) tmp.application = { list: filter.app };

    if (filter.type) tmp.type = filter.type;

    if (filter.advanced) tmp.totals = true;
    tmp.from = filter.from;
    tmp.to = filter.to;
    tmp.dimensions = { list: filter.dimension };
    setFilter({ page: 1 });
    setTrigger(tmp);
  };
  const {
    data: report,
    isFetching,
    status,
    error,
  } = useGetAcquisitionReportQuery(trigger, {
    skip: !trigger,
  });
  const query = useQuery();
  const hash = query.get('hash');
  const {
    data: hashReport,
    isFetching: isFetchingHash,
    error: errorHash,
  } = useGetAcquisitionHashReportQuery(hash, {
    skip: !hash,
  });

  return (
    <div className="container-fluid mb-4">
      <div className="row mt-3 mb-1 px-md-2">
        <FiltersBar data={filter} setData={setFilter} onSubmit={runReport} />
      </div>
      <div className="row px-md-2">
        <div className="col-12">
          {isFetching || isFetchingHash ? (
            <div className="d-flex justify-content-center my-3">
              <Loader parentStyle="me-2" size={60} color={'#3F96C7'} />
            </div>
          ) : !report && (error || errorHash) ? (
            <div className="alert alert-danger p-2 mb-2 fs-7 " role="alert">
              {error?.data?.message?.message ||
                error?.data?.message ||
                errorHash?.data?.message?.message ||
                errorHash?.data?.message ||
                'Something went wrong.'}
            </div>
          ) : report || hashReport ? (
            <AcquisitionReportTable
              report={report || hashReport}
              filter={filter}
              setFilter={setFilter}
              trigger={trigger}
            />
          ) : (
            <div
              className={`alert ${
                status === 'uninitialized' ? 'alert-info' : 'alert-warning'
              } d-flex align-items-center`}
            >
              <div className=" me-3">
                <InfoIcon />
              </div>
              <div>
                {status === 'uninitialized' ? (
                  <p className="mb-1">Run the report with appropriate filters.</p>
                ) : (
                  <>
                    <p className="mb-1">This report does not have any data.</p>
                    <span className="fs-7">Please select different filters.</span>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Acquisition;
