import { Restricted } from '../../../../Components/permissions/UserPermissions';

function TestsTableHeader() {
  return (
    <>
      <tr>
        <th scope="col" width={50} className="fs-7 text-center">
          Os
        </th>
        <th scope="col" className="fs-7 ">
          A/B test name
        </th>
        <th scope="col" className="fs-7 ">
          Start date
        </th>
        <th scope="col" className="fs-7 ">
          End date
        </th>
        <th scope="col" width={40} className="fs-7 ">
          Cohort
        </th>
        <th className="fs-7 text-nowrap">Updated By</th>
        <th>Actions</th>
        <th scope="col" width={120} className="text-center fs-7 ">
          Status
        </th>
        <Restricted permission="quality.update">
          <th width={140} className="text-center fs-7 " scope="col">
            Quality
          </th>
        </Restricted>
      </tr>
    </>
  );
}

export default TestsTableHeader;
