const Publishing = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill={props.color}
      className="bi bi-pie-chart"
      viewBox="0 0 24 24"
    >
      <path d="M12 0.333496C5.58337 0.333496 0.333374 5.5835 0.333374 12.0002C0.333374 18.4168 5.58337 23.6668 12 23.6668C18.4167 23.6668 23.6667 18.4168 23.6667 12.0002C23.6667 5.5835 18.4167 0.333496 12 0.333496ZM12 2.66683C16.6667 2.66683 20.6334 6.16683 21.2167 10.8335H12V2.66683ZM16.6667 20.0502L12.7 13.1668H21.3334C20.8667 16.0835 19.2334 18.6502 16.6667 20.0502Z" />
    </svg>
  );
};

export default Publishing;
