import React from 'react';

function AndroidIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill={props.color || '#95CF00'}
      className="bi bi-clipboard2-data"
      viewBox="0 0 128 128"
    >
      <rect fill="none" height="128" width="128" />
      <path
        clipRule="evenodd"
        d="M112.368,40.466c-4.222,0-7.64,3.326-7.64,7.428    v31.797c0,4.102,3.418,7.428,7.64,7.428c4.214,0,7.632-3.326,7.632-7.428V47.894C120,43.793,116.582,40.466,112.368,40.466z     M15.64,40.466c-4.222,0-7.64,3.326-7.64,7.428v31.797c0,4.102,3.418,7.428,7.64,7.428c4.214,0,7.632-3.326,7.632-7.428V47.894    C23.272,43.793,19.854,40.466,15.64,40.466z M28.917,40.466h-0.549v54.503c0,4.327,3.611,7.85,8.065,7.85h5.815    c-0.201,0.655-0.309,1.34-0.309,2.062v15.692c0,4.102,3.426,7.428,7.64,7.428c4.214,0,7.64-3.326,7.64-7.428V104.88    c0-0.722-0.116-1.407-0.309-2.062h14.182c-0.193,0.655-0.302,1.34-0.302,2.062v15.692c0,4.102,3.418,7.428,7.632,7.428    c4.222,0,7.648-3.326,7.648-7.428V104.88c0-0.722-0.116-1.407-0.317-2.062h5.823c4.454,0,8.057-3.522,8.057-7.85V40.466H28.917z     M81.53,10.716l1.423-2.092l1.423-2.062l3.17-4.636c0.394-0.572,0.232-1.34-0.356-1.716c-0.58-0.384-1.376-0.226-1.755,0.346    l-4.841,7.052L79.141,9.73C74.54,7.992,69.413,7.021,64,7.021c-5.405,0-10.54,0.971-15.141,2.709l-1.446-2.122l-1.431-2.085    l-3.402-4.967c-0.394-0.572-1.183-0.722-1.771-0.346c-0.58,0.376-0.742,1.144-0.356,1.716l3.17,4.636l1.423,2.062l1.431,2.092    c-10.803,4.899-18.11,14.179-18.11,24.798h71.264C99.632,24.895,92.325,15.616,81.53,10.716z M48.728,25.602    c-2.111,0-3.82-1.663-3.82-3.718c0-2.055,1.709-3.71,3.82-3.71s3.82,1.656,3.82,3.71C52.548,23.939,50.839,25.602,48.728,25.602z     M79.272,25.602c-2.111,0-3.82-1.663-3.82-3.718c0-2.055,1.709-3.71,3.82-3.71c2.111,0,3.82,1.656,3.82,3.71    C83.092,23.939,81.383,25.602,79.272,25.602z"
        fillRule="evenodd"
        id="Android"
      />
    </svg>
  );
}

export default AndroidIcon;
