import React, { useState } from 'react';
import Loader from '../../../../Components/Loader';
import TapNationPng from '../../../../assets/logoTapNation/LogoTapNation40.png';
import AndroidIcon from '../../../../Components/Icons/AndroidIcon';
import AppleIcon from '../../../../Components/Icons/AppleIcon';
import { useGetGamesQuery } from '../../../../data/api/studioApi';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { studioSelectedSelector } from '../../../../data/slices/user';
import ArchiveIcon from '../../../../Components/Icons/ArchiveIcon';

const AbTestGameList = ({ search, onSelect, gameId, showMoreButton = true, color = 'bg-white', activeOnly }) => {
  const { data: games, isLoading: isLoadingGames, error } = useGetGamesQuery();
  const [displayCount, setDisplayCount] = useState(10);
  const studioSelected = useSelector(studioSelectedSelector);

  const handleSelect = (game) => {
    onSelect(game);
  };

  const showMore = () => {
    setDisplayCount(displayCount + 10);
  };

  const filteredNotArchivedGames = games?.filter((game) => game.status !== 'ARCHIVED');
  const filteredArchivedGames = games?.filter((game) => game.status === 'ARCHIVED');
  const sortedFilteredGame = filteredNotArchivedGames?.concat(filteredArchivedGames);

  const filteredGame = sortedFilteredGame
    ?.filter((el) => {
      const isActive = activeOnly ? el.status !== 'ARCHIVED' : true;
      const appName = search ? el.appName.toLowerCase().includes(search.toLowerCase()) : true;
      const studio = studioSelected.studioId ? el.studioId === studioSelected.studioId : true;
      return isActive && appName && (studioSelected ? studio : '');
    })
    ?.slice(0, showMoreButton ? displayCount : games.length);

  return (
    <div className="game-search-list list-hover ">
      {isLoadingGames ? (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 300 }}>
          <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
        </div>
      ) : error ? (
        <div className="alert alert-danger mt-3 fs-7 " role="alert">
          {error?.error || 'Fetching error.'}
        </div>
      ) : (
        <div className="d-flex flex-column">
          {filteredGame?.map((game) => (
            <div
              key={game.id}
              onClick={() => handleSelect(game)}
              className={`list-item ${gameId === game.id ? 'border-1 border' : ''}
               d-flex align-items-center mb-1 rounded cursor-pointer p-2 border-primary ${color}`}
              id="test-Modal-game"
            >
              {game?.status === 'ARCHIVED' ? (
                <div className="position-relative flex-shrink-0 overflow-hidden me-2" style={{ width: 40, height: 40 }}>
                  <div className="position-absolute top-0 start-0 w-100 h-100  d-flex align-items-center justify-content-center">
                    <div className="position-absolute z-0 top-0 start-0 w-100 h-100 bg-dark-300 opacity-50 rounded "></div>
                    <ArchiveIcon className={'z-2'} color="#fff" />
                  </div>
                  <img
                    className="img-fluid img-fit-contain rounded"
                    width={40}
                    height={40}
                    src={game?.logoUrl || TapNationPng}
                    alt={game?.appName}
                  />
                </div>
              ) : (
                <div className="overflow-hidden text-nowrap me-2 flex-shrink-0" style={{ width: 40 }}>
                  <img
                    width={40}
                    height={40}
                    className="rounded img-fit-contain"
                    src={game.logoUrl === '' ? TapNationPng : game.logoUrl}
                    alt={game.appName}
                  />
                </div>
              )}
              <div className="d-flex flex-column w-100">
                <div className="d-flex align-items-center justify-content-between">
                  {game.appName}
                  <span className="me-1 ">
                    {game.multipleOs ? (
                      <div className="d-flex gap-1">
                        <AndroidIcon size={15} />
                        <AppleIcon size={15} />
                      </div>
                    ) : game.os === 'android' ? (
                      <AndroidIcon size={15} />
                    ) : (
                      <AppleIcon size={15} />
                    )}
                  </span>
                </div>
                <p className="text-muted m-0 fs-8 text-truncate overflow-hidden w-75">{game.bundleId}</p>
              </div>
            </div>
          ))}
          {showMoreButton && filteredGame.length >= displayCount && (
            <>
              <Button
                onClick={showMore}
                variant="outline-secondary"
                className="btn-sm m-1 align-self-center w-100 light-button"
              >
                Show More
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default AbTestGameList;
