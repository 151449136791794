import React, { useState } from 'react';
import moment from 'moment';
import { studioApi } from '../../../../data/api/studioApi';
import { Button } from 'react-bootstrap';
import TapNationPng from '../../../../assets/logoTapNation/LogoTapNation40.png';
import AdjustLogo from '../../../../Components/Icons/adjustLogo';
import GaLogo from '../../../../Components/Icons/GaLogo';
import IdeaCommentIcon from '../../../../Components/Icons/CommentIcon';
import CommentModal from '../../../../Modules/CommentModal';
import { generatePath, useHistory } from 'react-router-dom';
import { R_GAME_DRAFT } from '../../../../constants/routes';
import UpdateDraftGameModal from '../../UpdateDraftGame/UpdateDraftGameModal';
import PublishDraftGame from '../../UpdateDraftGame/PublishDraftGame';
import { Restricted } from '../../../../Components/permissions/UserPermissions';
import TapNationLogo from '../../../../assets/logoTapNation/LogoTapNation40.png';

const DraftGameCard = (props) => {
  const history = useHistory();
  const [showCommentModal, setShowCommentModal] = useState(false);
  const { data: studioList } = studioApi.endpoints.listStudios.useQueryState();
  const handleSeeDetails = () => {
    history.push(generatePath(R_GAME_DRAFT, { id: props.data.id }));
  };

  const handleComment = (e) => {
    e.stopPropagation();
    setShowCommentModal(true);
  };

  const infos = [
    props.data.appName,
    props.data.bundleId,
    props.data.appId,
    props.data.bizdevEmail,
    props.data.category,
    props.data.tags,
  ];

  let nbInfos = 0;
  infos.forEach((i) => {
    if (i) nbInfos++;
  });

  let sdkLive;
  let sdkAdjust;
  let sdkGa;

  if (props.data.todoList !== '') {
    let sdkArray = props.data.todoList.split(',');

    let liveVal = ['1'];
    let adjustVal = ['2'];
    let gaVal = ['3'];

    sdkLive = liveVal.every((e) => sdkArray.includes(e));
    sdkAdjust = adjustVal.every((e) => sdkArray.includes(e));
    sdkGa = gaVal.every((e) => sdkArray.includes(e));
  }

  let nbVideos;
  if (props.data.videos.length && props.data.videos[0].file) {
    nbVideos = props.data.videos.length;
  } else {
    nbVideos = 0;
  }
  const studioFound = (studioList && studioList.find((e) => e.studioId === props.data.studioId)) || null;
  return (
    <>
      <tr key={props?.data?.id}>
        <td onClick={handleSeeDetails} className="cursor-pointer">
          <div className="d-flex align-items-center">
            <div className="overflow-hidden text-nowrap me-3 flex-shrink-0" style={{ width: 56 }}>
              <img
                width={56}
                height={56}
                className="rounded img-fit-contain"
                src={props.data.logo_url === null || props.data.logo_url === '' ? TapNationPng : props.data.logoUrl}
                alt={props.data.appName}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = TapNationLogo;
                }}
              />
            </div>
            <div>
              <h6>{props.data.appName}</h6>
              <div className=" ">
                {props.data.category ? (
                  <span className="badge fs-7 me-1 mb-1 light-button px-3 py-2">{props.data.category}</span>
                ) : null}

                {props.data.tags &&
                  props.data.tags.split(',').map((el) => (
                    <span key={el} className="badge fs-7 me-1 mb-1 light-button px-3 py-2">
                      {el}
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </td>
        <td> {moment(props.data.updateTime).format('ll')}</td>
        {studioFound && <td>{studioFound.name}</td>}
        <Restricted permission={'draftGame.update'}>
          <td>{props.data.bizdevEmail}</td>
        </Restricted>
        <td>
          <div className="d-flex">
            <PublishDraftGame id={props?.data?.id} />

            <div className="ms-2">
              <UpdateDraftGameModal id={props?.data?.id} />
            </div>
          </div>
        </td>
        <td data-label="Infos" className="text-center">
          {nbInfos} / 6 <br />
          <span className="text-muted m-0 text-nowrap fs-7">Videos: {nbVideos}</span>
        </td>

        <td data-label="SDK" className="text-center">
          <div className={sdkLive ? 'text-primary' : 'text-muted'}>Live</div>
          <div className="d-flex justify-content-center">
            <div className="m-1">
              <AdjustLogo size={20} color={sdkAdjust ? '#1163f9' : '#BDBDBD'} />
            </div>
            <div className="m-1">
              <GaLogo size={20} color={sdkGa ? '#1163f9' : '#BDBDBD'} />
            </div>
          </div>
        </td>
        <Restricted permission="show.comment.modal">
          <td onClick={(e) => e.stopPropagation()}>
            <Button variant="outline-light" className="p-2 text-nowrap border-0" onClick={handleComment}>
              <IdeaCommentIcon />
            </Button>
            {showCommentModal && (
              <CommentModal
                onClose={() => setShowCommentModal(false)}
                data={{
                  commentText: props.data.comments,
                  commentInfos: props.data.appName,
                  commentAppId: props.data.appId,
                }}
              />
            )}
          </td>
        </Restricted>
      </tr>
    </>
  );
};

export default DraftGameCard;
