import { snakeCaseToCamel } from './converters';

export const formatJoiErrorsArray = (errors) => {
  let returnObject = {};
  if (errors.length) {
    errors.forEach(function (error) {
      returnObject[snakeCaseToCamel(error.path.join('.') || error.type)] = error.message;
    });
  }
  return returnObject;
};
