/** Import Externals */
import axios from 'axios';

/** Import Utils */
import { hmac256 } from '../../utils/common';

/** DRAFT PART */

export const CommentDraftGame = (app_id, comments) => {
  const urlCommentDraftGame = `${process.env.REACT_APP_STUDIO_API}/comment_draft_game`;

  const infosComment = JSON.stringify({
    app_id: app_id,
    comments: comments,
  });

  return axios.post(urlCommentDraftGame, infosComment, {
    headers: {
      Authorization: hmac256(infosComment),
      'Content-Type': 'application/json',
    },
  });
};

/** SQL VIDEOS PART */

/* GET ONE VIDEO IN CLOUD WHEN WE WANT TO DOWNLOAD IT */
export const CloudGetFile = async (pathName) => {
  const url = `${process.env.REACT_APP_GOOGLE_CLOUD_API}?file=${encodeURIComponent(pathName)}`;
  return axios.get(url, {
    headers: {
      Authorization: hmac256(url),
      'Content-Type': 'application/json',
    },
    responseType: 'stream',
  });
};

/** UTILS PART */

export const GetLogo = (app_id) => {
  const url = process.env.REACT_APP_STUDIO_API + '/icon_url?id=' + app_id;
  return axios.get(url, {
    headers: {
      Authorization: hmac256(url),
      'Content-Type': 'application/json',
    },
  });
};

/* Market Insight */

export const CloudUploadPDF = (pdf) => {
  const urlVideo = `${process.env.REACT_APP_GOOGLE_CLOUD_API}/market_insight/upload_pdf`;

  const formData = new FormData();
  formData.append('file', pdf);

  return axios.post(urlVideo, formData, {
    headers: {
      Authorization: hmac256('file=' + pdf.name),
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const DeletePDF = (link) => {
  const url = `${process.env.REACT_APP_GOOGLE_CLOUD_API}/market_insight/delete_pdf?file=${link}`;

  return axios.delete(url, {
    headers: {
      Authorization: hmac256(url),
      'Content-Type': 'application/json',
    },
  });
};
