import Joi from 'joi';

export const updateAccountSchema = Joi.object({
  firstName: Joi.string()
    .required()
    .messages({ 'string.empty': 'First Name is required', 'any.required': 'First Name is required' }),
  lastName: Joi.string()
    .required()
    .messages({ 'string.empty': 'Last Name is required', 'any.required': 'Last Name is required' }),
  studioIds: Joi.array().items(Joi.string()).min(1).max(100).required().messages({
    'string.empty': 'Studio Name is required',
    'any.required': 'Studio Name is required',
  }),
  role: Joi.string().valid('USER', 'MANAGER', 'SDK', 'VIEWER', 'ADMIN').required().messages({
    'string.empty': 'Role is required',
    'any.only': 'Role must be either "USER" or "ADMIN"',
  }),
}).options({ abortEarly: false, allowUnknown: true });

export default updateAccountSchema;
