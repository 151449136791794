import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import sortingGames from '../../../../utils/sortingGames';
import { MONTHS } from '../../../../constants/global';
import { useGetGamesQuery } from '../../../../data/api/studioApi';
import { useSelector } from 'react-redux';
import { studioSelectedSelector } from '../../../../data/slices/user';
import GameCard from './GameCard';
import Loader from '../../../../Components/Loader';
import { useQuery } from '../../../../utils/customHooks';

function GameListTable({ data, setData, showArchived }) {
  const query = useQuery();
  const currentPage = Number(query.get('page')) || data.page || 1;
  let { filter } = useParams();
  const filterGame = filter?.toUpperCase() || 'ALL-GAMES';
  const studioSelected = useSelector(studioSelectedSelector);

  const { data: games, isLoading: isLoadingGames } = useGetGamesQuery();

  useEffect(() => {
    const gameName = query.get('search') || '',
      bizDevEmail = query.get('email') || '',
      categorySelected = query.get('category') || '',
      tags = query.getAll('tags') || '',
      year = query.get('year') || '',
      month = query.get('month') || '',
      os = query.get('os') || '',
      sort = query.get('sort') || 'dateDown';

    if (games) {
      let parsedArray = games;

      if (studioSelected?.name) parsedArray = parsedArray.filter((el) => el.studioId === studioSelected.studioId);

      let searchGameFilter = parsedArray.filter((game) => {
        let filters = {
          appName: gameName,
          bizdevEmail: bizDevEmail,
          category: categorySelected,
          tags: tags,
          year: year,
          month: month,
          os: os,
        };
        return Object.keys(filters).every((key) => {
          if (!filters[key]) return true;
          if (key === 'year') {
            return new Date(game.createdAt).getFullYear().toString() === year;
          } else if (key === 'month') {
            return MONTHS[new Date(game.createdAt).getMonth()] === month;
          } else if (key === 'appName' && game[key]?.toLowerCase()?.includes(filters[key].toLowerCase())) {
            return true;
          } else if (key === 'os' && (game.multipleOs || game.os === filters.os)) {
            return true;
          } else if (game[key]?.includes(filters[key])) {
            return true;
          } else {
            return false;
          }
        });
      });

      let sortedGame = [];
      let ArchiveGame = [];
      let sorted = sortingGames(sort, searchGameFilter);

      sorted.map((item) => {
        if (item.status !== 'ARCHIVED' || showArchived) {
          sortedGame.push(item);
        } else {
          ArchiveGame.push(item);
        }
        return null;
      });

      let switchVar = filterGame !== 'ARCHIVED' ? sortedGame : ArchiveGame;
      if (filterGame !== 'ALL-GAMES' && filterGame !== 'ARCHIVED') {
        switchVar = switchVar.filter((el) => el.status === filterGame);
      }
      setData({ total: switchVar.length });
      const indexOfLastPost = currentPage * data.postsPerPage;
      const indexOfFirstPost = indexOfLastPost - data.postsPerPage;
      const currentPosts = switchVar.slice(indexOfFirstPost, indexOfLastPost);

      setData({ sortedGames: currentPosts, error: currentPosts.length ? '' : 'No Game Found...!' });

      if (currentPage) {
        window.scrollTo({ top: 0 });
      }
    }
  }, [games, filterGame, studioSelected, query, showArchived, data?.page]);

  return isLoadingGames ? (
    <tr>
      <td colSpan={7}>
        <div className="d-flex justify-content-center w-100">
          <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
        </div>
      </td>
    </tr>
  ) : (
    <>
      {data.error ? (
        <tr>
          <td colSpan="7" id="pagination-td">
            <p className="fs-4 m-2">{data.error}</p>
          </td>
        </tr>
      ) : (
        data.sortedGames && data.sortedGames.map((value) => <GameCard key={value.id} data={value} />)
      )}
    </>
  );
}

export default GameListTable;
