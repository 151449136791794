import React from 'react';
import { Form, Nav, InputGroup } from 'react-bootstrap';
import SearchIcon from '../../Icons/SearchIcon';

function FilterSearch({ value, onChange, placeholder }) {
  return (
    <Nav.Item className="dropdown">
      <Form.Group>
        <InputGroup>
          <Form.Control
            className="bg-white text-nowrap"
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            autoComplete={'off'}
          />
          <InputGroup.Text className="bg-white">
            <SearchIcon size={20} />
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
    </Nav.Item>
  );
}

export default FilterSearch;
